import React from "react";

export const Fieldset = ({ label, children }) => (
  <fieldset className="border p-2 mb-4">
    <legend className="w-auto">
      <h4 className="section-name">{label}</h4>
    </legend>

    {children}
  </fieldset>
);
