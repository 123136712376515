import classNames from "classnames";
import React, { useMemo } from "react";

import { isImage, isVideo } from "../helpers";

const fileTypeClassName = (fileType) => {
  if (isImage(fileType)) {
    return "fa-file-image";
  }

  if (isVideo(fileType)) {
    return "fa-file-video";
  }

  return (
    {
      "application/pdf": "fa-file-pdf",
      "application/zip": "fa-file-archive",
      "application/msword": "fa-file-word",
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
        "fa-file-word",
      "application/vnd.openxmlformats-officedocument.presentationml.presentation":
        "fa-file-powerpoint"
    }[fileType] || "fa-file"
  );
};

export const FileTypeIcon = ({ fileType }) => {
  const className = useMemo(
    () => classNames("fa", fileTypeClassName(fileType)),
    [fileType]
  );

  return <i className={className} />;
};
