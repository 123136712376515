import React from "react";

export const getFee = (beds, linenFees) => {
  let totalFee = +linenFees.delivery || 0;

  beds.forEach((bed) => {
    totalFee += +linenFees[bed.mattress_size] || 0;
  });

  return totalFee.toFixed(2);
};

export const Field = ({ beds, linenFees }) => {
  return <input disabled value={getFee(beds, linenFees)} />;
};
