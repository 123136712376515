import classNames from "classnames";
import React, { useState } from "react";

import { Explanation } from "../../../common/Explanation";
import { SelectOptions } from "../../../common/SelectOptions";

export const TaskCategorySelection = ({
  value,
  hasError,
  categories,
  toggleLinkVisible
}) => {
  const { CATEGORIES_EXPLAINED } = window.AirbaseConstants.Task;
  const [selectedCategory, setSelectedCategory] = useState(value);

  const handleChange = ({ target: { value: selectedValue } }) => {
    setSelectedCategory(selectedValue);
  };

  return (
    <div className={classNames({ field_with_errors: hasError })}>
      {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
      <label htmlFor="task_category">Category</label>
      <select
        name="task[category]"
        id="task_category"
        value={selectedCategory || ""}
        onChange={handleChange}
      >
        <SelectOptions includeBlank="-- select --" options={categories} />
      </select>

      <Explanation
        toggleLinkVisible={toggleLinkVisible}
        visible
        text={CATEGORIES_EXPLAINED[selectedCategory]}
      />
    </div>
  );
};
