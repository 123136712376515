import { Formik } from "formik";
import React, { useContext } from "react";

import { FieldsetWithAvailabilitySelect } from "../components/FieldsetWithAvailabilitySelect";
import { FormGroup } from "../components/FormGroup";
import { HomeInfoContext } from "../HomeInfoContext";
import { HomeInfoForm } from "../HomeInfoForm";

export const OperateBoiler = () => {
  const { homeInfo, updateHomeInfo } = useContext(HomeInfoContext);

  return (
    <Formik
      initialValues={homeInfo}
      enableReinitialize
      onSubmit={updateHomeInfo}
    >
      <HomeInfoForm sidebarName="Operate">
        <FieldsetWithAvailabilitySelect
          name="home_info_maintenance_attributes.heating_boiler_available"
          label="Boiler"
        >
          <FormGroup
            name="home_info_maintenance_attributes.heating_boiler_location"
            label="Heating boiler location"
            component="textarea"
            rows={5}
          />

          <FormGroup
            name="home_info_maintenance_attributes.heating_boiler_make_and_model"
            label="Heating boiler make and model"
          />

          <FormGroup
            name="home_info_maintenance_attributes.heating_boiler_notes"
            label="Heating boiler notes"
          />
        </FieldsetWithAvailabilitySelect>
      </HomeInfoForm>
    </Formik>
  );
};
