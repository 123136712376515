import "./GenericSearchFilter.scss";

import debounce from "lodash/debounce";
import React from "react";

const DEBOUNCE_MILLISECONDS = 500;

export const GenericSearchFilter = ({
  onChange,
  filter,
  placeholder,
  className = "rt-generic-search",
  testId
}) => {
  const onChangeDelayed = debounce(onChange, DEBOUNCE_MILLISECONDS);

  return (
    <div className={className} data-testid={testId}>
      <i className="fa fa-search" />
      <input
        type="search"
        placeholder={placeholder}
        defaultValue={filter ? filter.value : ""}
        onChange={(event) => onChangeDelayed(event.target.value)}
      />
    </div>
  );
};
