/* eslint-disable func-names */
import { Form, Formik } from "formik";
import React, { useCallback } from "react";
import * as yup from "yup";

import { useApiClient } from "../../../common/apiClient";
import { FormGroup } from "../../../common/forms/FormGroup";
import { Modal } from "../../../common/Modal";

const validationSchema = yup.object().shape({
  airbnb_block_start: yup.string(),
  airbnb_block_end: yup.string()
});

export const AirbnbBlockForm = ({
  onClose,
  propertyId,
  hostPreferenceSettings
}) => {
  const apiClient = useApiClient();

  const handleSubmit = useCallback(
    async (values, { setSubmitting }) => {
      setSubmitting(true);

      try {
        const { airbnb_block_start, airbnb_block_end } = values;

        await apiClient.put(
          `/api/properties/${propertyId}/host_preference_settings`,
          {
            host_preference_setting: {
              airbnb_block_start,
              airbnb_block_end
            }
          }
        );

        window.location.reload();
      } finally {
        setSubmitting(false);
      }
    },
    [apiClient, propertyId]
  );

  return (
    <Formik
      initialValues={hostPreferenceSettings}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {({ isSubmitting, values, resetForm }) => (
        <Form>
          <Modal onClose={onClose}>
            <Modal.Header>Block Airbnb availability</Modal.Header>
            <Modal.Body>
              <FormGroup
                label="Airbnb block start"
                name="airbnb_block_start"
                type="date"
                max={values.airbnb_block_end ? values.airbnb_block_end : ""}
                required={values.airbnb_block_end}
              />

              <FormGroup
                label="Airbnb block end"
                name="airbnb_block_end"
                required={values.airbnb_block_start}
                min={values.airbnb_block_start ? values.airbnb_block_start : ""}
                type="date"
              />
            </Modal.Body>

            <Modal.Footer>
              {(values.airbnb_block_start || values.airbnb_block_end) && (
                <button
                  type="button"
                  className="btn btn-light"
                  onClick={() =>
                    resetForm({
                      values: {
                        airbnb_block_start: "",
                        airbnb_block_end: ""
                      }
                    })
                  }
                  disabled={isSubmitting}
                >
                  Clear
                </button>
              )}

              <button
                type="button"
                className="btn btn-light"
                onClick={onClose}
                disabled={isSubmitting}
              >
                Cancel
              </button>

              <button
                type="submit"
                className="btn btn-submit"
                disabled={isSubmitting}
              >
                Update
              </button>
            </Modal.Footer>
          </Modal>
        </Form>
      )}
    </Formik>
  );
};

AirbnbBlockForm.defaultProps = { window };
