import React, { useCallback } from "react";

import { DatePicker } from "../../../../common/forms/DatePicker";

export const CheckoutFilters = ({ getFilterValue, onFilterChange }) => {
  const handleCheckoutFromSelect = useCallback(
    (dateString) => onFilterChange("checkout_from", dateString),
    [onFilterChange]
  );

  const handleCheckoutToSelect = useCallback(
    (dateString) => onFilterChange("checkout_to", dateString),
    [onFilterChange]
  );

  return (
    <>
      <label className="font-weight-bold col-form-label col-sm-3 text-right">
        Checkout from
      </label>
      <DatePicker
        className="col-sm-4"
        defaultValue={getFilterValue("checkout_from")}
        onSelect={handleCheckoutFromSelect}
        pikadayOptions={{
          maxDate: new Date(getFilterValue("checkout_to"))
        }}
      />

      <label className="font-weight-bold col-form-label col-auto">to</label>
      <DatePicker
        className="col-sm-4"
        defaultValue={getFilterValue("checkout_to")}
        onSelect={handleCheckoutToSelect}
        pikadayOptions={{
          minDate: new Date(getFilterValue("checkout_from")),
          defaultDate: new Date(getFilterValue("checkout_from"))
        }}
      />
    </>
  );
};
