import React from "react";

import { CleanerRating } from "./CleanerRating";

export const CleanerOption = ({ cleaner, onSelect }) => (
  <div
    className="SearchSelect__option"
    onClick={() => onSelect(cleaner)}
    role="button"
    tabIndex={0}
  >
    <div className="name">{cleaner.name}</div>
    <CleanerRating rating={cleaner.average_rating} />
  </div>
);
