import React from "react";

import { DropdownFilter } from "../../../../common/tables/filters";

const VALUES = [
  ["Yes", true],
  ["No", false]
];

export const CriticalFilter = ({ filter, onChange }) => (
  <DropdownFilter filter={filter} onChange={onChange} values={VALUES} />
);
