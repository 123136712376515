import React, { useState } from "react";

import { addExtraBed } from "./api";

export const ExtraBedRequest = ({ bookingId, extraBedsRequired }) => {
  const [success, setSuccess] = useState();
  const [loading, setLoading] = useState();
  const [error, setError] = useState();

  const onClick = () => {
    setError(false);
    setLoading(true);

    addExtraBed(bookingId)
      .then(() => {
        setSuccess(true);
      })
      .catch(() => {
        setError(true);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  if (success || extraBedsRequired) {
    return (
      <>
        <div className="label">Extra beds:</div>
        <div className="value">
          Requested by Guest <i className="fas fa-exclamation-circle" />
        </div>
      </>
    );
  }
  return (
    <>
      <div className="label">Extra beds:</div>
      <div className="value">
        <button
          className="small"
          type="button"
          onClick={onClick}
          disabled={loading}
        >
          + Add extra bed request
        </button>
        {error && (
          <p className="error">An error occurred, please try again later</p>
        )}
      </div>
    </>
  );
};
