import React from "react";

import { SelectOptions } from "../../../../common/SelectOptions";
import { FormGroup } from "./FormGroup";

export const AvailableSelect = ({ name, label, description, airbnbSync }) => (
  <FormGroup
    name={name}
    label={label}
    component="select"
    description={description}
    airbnbSync={airbnbSync}
  >
    <SelectOptions
      includeBlank={false}
      options={[
        ["", "I don't know"],
        ["true", "Yes"],
        ["false", "No"]
      ]}
    />
  </FormGroup>
);
