import React, { useCallback } from "react";

import { DatePicker } from "../../../../common/forms/DatePicker";

export const CheckinFilters = ({ getFilterValue, onFilterChange }) => {
  const handleCheckinFromSelect = useCallback(
    (dateString) => onFilterChange("checkin_from", dateString),
    [onFilterChange]
  );

  const handleCheckinToSelect = useCallback(
    (dateString) => onFilterChange("checkin_to", dateString),
    [onFilterChange]
  );

  return (
    <>
      <label className="font-weight-bold col-form-label col-sm-3 text-right">
        Checkin from
      </label>
      <DatePicker
        className="col-sm-4"
        defaultValue={getFilterValue("checkin_from")}
        onSelect={handleCheckinFromSelect}
        pikadayOptions={{
          maxDate: new Date(getFilterValue("checkin_to"))
        }}
      />

      <label className="font-weight-bold col-form-label col-auto">to</label>
      <DatePicker
        className="col-sm-4"
        defaultValue={getFilterValue("checkin_to")}
        onSelect={handleCheckinToSelect}
        pikadayOptions={{
          minDate: new Date(getFilterValue("checkin_from")),
          defaultDate: new Date(getFilterValue("checkin_from"))
        }}
      />
    </>
  );
};
