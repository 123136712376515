import axios from "axios";
import NProgress from "nprogress";
import qs from "qs";
import React, { useContext } from "react";

const createApiClient = () => {
  const getToken = () => {
    const meta = document.querySelector('meta[name="csrf-token"]');
    return meta && meta.getAttribute("content");
  };

  const instance = axios.create({
    timeout: 30000,
    headers: {
      "X-CSRF-Token": getToken(),
      "Content-Type": "application/json",
      Accept: "application/json"
    },
    paramsSerializer: (params) =>
      qs.stringify(params, { arrayFormat: "brackets" })
  });

  // Setup global progress bar

  let requestsCounter = 0;

  const startProgress = () => {
    requestsCounter += 1;
    NProgress.start();
  };

  const stopProgress = () => {
    requestsCounter -= 1;
    if (requestsCounter === 0) {
      NProgress.done();
    }
  };

  instance.interceptors.request.use((config) => {
    startProgress();
    return config;
  });

  instance.interceptors.response.use(
    (response) => {
      stopProgress();
      return response;
    },
    (error) => {
      stopProgress();
      return Promise.reject(error);
    }
  );

  return instance;
};

export const apiClient = createApiClient();

export const ApiClientContext = React.createContext(apiClient);

export const useApiClient = () => useContext(ApiClientContext);
