import React, { useState } from "react";

export const Toggle = ({ id, name, label, value = false }) => {
  const [checked, setChecked] = useState(value);

  return (
    <div className="toggle__container">
      <label htmlFor={id} className="form-check-label">
        {label}
      </label>

      <input
        name={name}
        type="hidden"
        value="0"
        onChange={() => {
          setChecked(!checked);
        }}
      />

      <input
        id={id}
        name={name}
        type="checkbox"
        className="toggle__input"
        checked={checked}
        value="1"
        onChange={() => {
          setChecked(!checked);
        }}
      />

      <label htmlFor={id} className="toggle">
        <span className="toggle__track" />
      </label>
    </div>
  );
};
