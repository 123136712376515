import classNames from "classnames";
import React, { useState } from "react";
import { useDropzone } from "react-dropzone";

import { Progress } from "../../../../../common/Progress";
import styles from "./Dropzone.module.scss";
import { handleFileRejections } from "./utils";

const DropText = ({ isDragActive, single, handleSelectFiles }) => {
  if (isDragActive) {
    return (
      <div className={styles.textContainer}>
        Release {single ? "photo" : "photos"} here to start uploading
      </div>
    );
  }

  return (
    <div className={styles.textContainer}>
      Drag and drop {single ? "photo" : "photos"} here to start uploading or{" "}
      <span
        role="link"
        tabIndex={0}
        onClick={handleSelectFiles}
        className={styles.link}
      >
        browse files
      </span>
    </div>
  );
};

const DropProgress = ({ progress }) => {
  return (
    <div className={styles.textContainer}>
      <Progress percentage={progress} />
    </div>
  );
};

export const Dropzone = ({
  acceptContentTypes,
  maxSize,
  single,
  onDrop,
  showGoBack,
  onGoBack,
  progress
}) => {
  const [errors, setErrors] = useState([]);

  const {
    getRootProps,
    getInputProps,
    open: handleSelectFiles,
    isDragActive
  } = useDropzone({
    accept: acceptContentTypes.join(", "),
    maxSize: maxSize ? maxSize.size : undefined,
    maxFiles: single ? 1 : undefined,
    onDrop: (acceptedFiles, fileRejections) => {
      const newErrors = handleFileRejections(
        fileRejections,
        acceptContentTypes,
        maxSize?.formattedSize
      );

      setErrors(newErrors);

      onDrop(acceptedFiles);
    }
  });

  return (
    <div
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...getRootProps({ onClick: (event) => event.stopPropagation() })}
      className={classNames("file-list", { dragging: isDragActive })}
    >
      {errors.map((error, index) => (
        <div
          // eslint-disable-next-line react/no-array-index-key
          key={index}
          className="invalid-file"
        >
          <i className="fa fa-exclamation-circle p-2" />
          <span>{error}</span>
        </div>
      ))}

      <div
        className={classNames(styles.card, { [styles.dragging]: isDragActive })}
      >
        {showGoBack && progress === null ? (
          <button
            className={styles.goBackButton}
            type="button"
            onClick={onGoBack}
          >
            <i className={classNames("fas fa-arrow-left")} />
          </button>
        ) : null}
        <div className={styles.uploadIconContainer}>
          <i
            className={classNames("fas fa-cloud-upload-alt", styles.uploadIcon)}
          />
        </div>

        {progress !== null ? (
          <DropProgress progress={progress} />
        ) : (
          <DropText
            isDragActive={isDragActive}
            single={single}
            handleSelectFiles={handleSelectFiles}
          />
        )}

        <input
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...getInputProps()}
          className="form-control-file d-none"
        />
      </div>
    </div>
  );
};
