import classNames from "classnames";
import React, { useContext, useEffect, useState } from "react";

import api from "../../../../../common/coreApiClient";
import { SwalActions, modal } from "../../../../../common/modals";
import warningImage from "../../../../../images/warning.svg";
import { HomeInfoContext } from "../../HomeInfoContext";

const UNLOCK_URL = "/v1/internal/smart_lock/unlock";
const GET_ATTEMPT_URL = "/v1/internal/smart_lock/attempt/";
const GET_ATTEMPT_INTERVAL = 2000;
const GET_ATTEMPT_RETRIES = 3;
const UNLOCK_BUTTON_STATE_TIMEOUT = 7000;
const BUTTON_TEXTS = {
  null: "Unlock digital lock",
  success: "Doors unlocked!",
  failed: "Failed to unlock",
  default: "Unlocking..."
};

const BUTTON_ICONS = {
  null: "fa-unlock",
  success: "fa-check",
  failed: "fa-times",
  default: "fa-spinner fa-spin"
};

const STATUS_ATTEMPT_FAILED = "failed";
const STATUS_ATTEMPT_SUCCESS = "success";

const getButtonText = (unlockState) =>
  BUTTON_TEXTS[unlockState] || BUTTON_TEXTS.default;

const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

const getAttempt = (action_attempt_id) =>
  sleep(GET_ATTEMPT_INTERVAL).then(() =>
    api.get(GET_ATTEMPT_URL + action_attempt_id)
  );

const isUnlockingFinished = (status) =>
  [STATUS_ATTEMPT_SUCCESS, STATUS_ATTEMPT_FAILED].includes(status);

const pollAttempt = async (action_attempt_id, status) => {
  if (isUnlockingFinished(status)) {
    return status;
  }

  // Poll the attempt status for a specified number of retries (3)
  // with a specified interval (2 seconds)
  // If the status is not finished after the retries, return failed status
  // Otherwise, return the current status
  for (let i = 0; i < GET_ATTEMPT_RETRIES; i += 1) {
    // eslint-disable-next-line no-await-in-loop
    const resp = await getAttempt(action_attempt_id);
    if (isUnlockingFinished(resp.status)) {
      return resp.status;
    }
  }

  return STATUS_ATTEMPT_FAILED;
};

const confirmationPopUp = () =>
  new Promise((res) => {
    const onConfirm = () => {
      res({ confirmed: true });
      modal.close();
    };

    modal.fire({
      html: (
        <>
          <p>This is about to unlock the door of this property</p>
          <SwalActions
            confirmText="Unlock"
            onCancel={modal.close}
            onConfirm={onConfirm}
          />
        </>
      ),
      showConfirmButton: false,
      showCancelButton: false,
      title: "Are you sure?",
      imageUrl: warningImage,
      animation: false
    });
  });

const UnlockButtonContent = ({ unlockState }) => (
  <>
    <i className={classNames("fa", BUTTON_ICONS[unlockState])} />{" "}
    {getButtonText(unlockState)}
  </>
);

export const UnlockButton = () => {
  const [unlockState, setUnlockState] = useState(null);
  const [isUnlocking, setIsUnlocking] = useState(false);
  const {
    property,
    homeInfo: { current_user_id }
  } = useContext(HomeInfoContext);

  useEffect(() => {
    /**
     * Set a timeout to reset the unlockState to null after a specified duration
     * when button is not currently unlocking. If component state changes before
     * the timeout completes, the timeout is cleared to prevent
     * unexpected state changes.
     *
     */
    if (!isUnlocking) {
      const timeoutId = setTimeout(() => {
        setUnlockState(null);
      }, UNLOCK_BUTTON_STATE_TIMEOUT);

      return () => clearTimeout(timeoutId);
    }

    return () => {};
  }, [isUnlocking]);

  const handleUnlock = async () => {
    const { confirmed } = await confirmationPopUp();

    if (!confirmed) {
      return;
    }

    try {
      setIsUnlocking(true);
      const resp = await api.post(UNLOCK_URL, null, {
        property_id: property.id,
        operator_id: current_user_id
      });
      const { action_attempt_id, status } = resp;
      setUnlockState(status);

      const operationResultStatus = await pollAttempt(
        action_attempt_id,
        status
      );
      setUnlockState(operationResultStatus);
    } catch (error) {
      setUnlockState(STATUS_ATTEMPT_FAILED);
    } finally {
      setIsUnlocking(false);
    }
  };

  return (
    <button
      className={classNames("btn mb-2 mr-auto", {
        "btn-danger": [null, STATUS_ATTEMPT_FAILED].includes(unlockState),
        "btn-success": unlockState === STATUS_ATTEMPT_SUCCESS
      })}
      type="button"
      onClick={handleUnlock}
      disabled={unlockState !== null || isUnlocking}
    >
      <UnlockButtonContent unlockState={unlockState} />
    </button>
  );
};
