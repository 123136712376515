import React, { useMemo } from "react";

import { humanize } from "../../../../common/stringUtils";
import { DropdownFilter } from "../../../../common/tables/filters";

export const CategoryFilter = ({ filter, onChange, taskCategories }) => {
  const values = useMemo(
    () =>
      Object.entries(taskCategories).map(([label, value]) => [
        humanize(label),
        value
      ]),
    [taskCategories]
  );

  return <DropdownFilter filter={filter} onChange={onChange} values={values} />;
};
