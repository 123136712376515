// Jest Snapshot v1, https://goo.gl/fbAQLP

exports[`<CleanImage /> 1`] = `
<div>
  <div
    class="CleanImage"
  >
    <div
      class="CleanImage__galleryLink"
    >
      <a
        data-airbase-fancybox="gallery"
        data-caption="Receipt for cleaning stuff"
        href=""
        title="Receipt for cleaning stuff"
      >
        <img
          alt="Receipt for cleaning stuff"
          class="is-exemplary"
          src=""
          width="200"
        />
      </a>
      <div
        class="edit-icon"
      >
        <button
          class="btn btn-light btn-sm"
          type="button"
        >
          <i
            class="fas fa-edit"
          />
        </button>
      </div>
    </div>
    <div>
      Receipt amount 
      $4.99
    </div>
    <div
      class="desc"
    >
      <div>
        Receipt for cleaning stuff
      </div>
      <div
        class="clean-image-detail"
      >
        Cleaning guide image
      </div>
      <div
        class="clean-image-detail"
      >
        09-05-2019
      </div>
    </div>
  </div>
</div>
`;
