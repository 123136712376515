import { Form, Formik } from "formik";
import React, { useContext } from "react";

import { ScrollToFieldError } from "./components/ScrollToFieldError";
import { HomeInfoContext } from "./HomeInfoContext";
import { HomeInfoFormButtons } from "./HomeInfoFormButtons";
import { HomeInfoFormSidebar } from "./HomeInfoFormSidebar";

export const HomeInfoForm = ({ children, sidebarName }) => {
  const { homeInfo, updateInternalNotes } = useContext(HomeInfoContext);

  return (
    <div className="onboarding-form">
      <div className="onboarding-form-wrapper">
        <Form className="onboarding-form-inside">
          <ScrollToFieldError />
          {children}
          <div className="onboarding-form-buttons-container">
            <HomeInfoFormButtons />
          </div>
        </Form>
        {sidebarName && (
          <Formik
            initialValues={{
              home_info_internal_notes_attributes:
                homeInfo.home_info_internal_notes_attributes
            }}
            enableReinitialize
            onSubmit={updateInternalNotes}
          >
            <Form className="onboarding-form-sidebar">
              <HomeInfoFormSidebar sidebarName={sidebarName} />
            </Form>
          </Formik>
        )}
      </div>
    </div>
  );
};
