import { render } from "@testing-library/react";
import React from "react";
import { Provider } from "react-redux";
import configureStore from "redux-mock-store";
import thunk from "redux-thunk";

export function createMockStore({ initialState = {}, api = {} } = {}) {
  const createStore = configureStore([thunk.withExtraArgument({ api })]);
  return createStore(initialState);
}

export function renderWithMockStore(
  ui,
  { initialState = {}, api = {}, wrapper: WrapperComponent, ...options } = {}
) {
  const store = createMockStore({ initialState, api });

  const wrapIfNeeded = (innerElement) =>
    WrapperComponent
      ? React.createElement(WrapperComponent, null, innerElement)
      : innerElement;

  return {
    ...render(ui, {
      ...options,
      wrapper: ({ children }) =>
        wrapIfNeeded(<Provider store={store}>{children}</Provider>)
    }),
    store
  };
}

export const mockFileList = () => {
  const file1 = new Blob(["asdf"], { type: "image/jpeg" });
  file1.name = "image001.jpg";

  const file2 = new Blob(["qwe"], { type: "application/zip" });
  file2.name = "archive.zip";

  return [file1, file2];
};
