import noop from "lodash/noop";
import React, { useCallback } from "react";

import { Toggle as GenericToggle } from "../../../../common/forms/Toggle";
import { useBookingToggle } from "../useBookingToggle";

export const Toggle = ({
  booking,
  attribute, // Attribute to toggle (like muted, skip_auto_clean)
  label,
  reversed = false, // For example when the flag name begins with "skip_"
  onChange = noop,
  disabled = false
}) => {
  const { toggled, toggleAttribute } = useBookingToggle(booking, attribute);

  const handleToggle = useCallback(async () => {
    try {
      const updatedBooking = await toggleAttribute();
      onChange(updatedBooking[attribute]);
    } catch (error) {
      alert(`Unable to toggle ${label}`);
    }
  }, [attribute, label, toggleAttribute, onChange]);

  return (
    <GenericToggle
      id={`${attribute}-toggle`}
      label={label}
      checked={reversed ? !toggled : toggled}
      onChange={handleToggle}
      disabled={disabled}
    />
  );
};
