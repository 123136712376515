import "./CleanDemandsTable.scss";

import React, { useMemo } from "react";
import ReactTable from "react-table";

import { formatDate } from "../../../common/date";
import { DatePicker } from "../../../common/forms/DatePicker";
import { useControlledTable } from "../../../common/tables/useControlledTable";
import ActionOptions from "./ActionOptions";
import { CleanTypeFilter } from "./Filters/CleanTypeFilter";

export const CleanDemandsTable = ({ propertyId }) => {
  const initialState = useMemo(() => {
    const now = new Date();

    return {
      sorted: [{ id: "clean_date", desc: true }],
      filtered: [{ id: "clean_date_range_start", value: now.toISOString() }]
    };
  }, []);

  const controlledProps = useControlledTable({
    api: { endpoint: `/api/properties/${propertyId}/clean_demands` },
    initialState
  });

  const {
    showPagination,
    onFilterChange,
    getFilterValue,
    updateDataRow
  } = controlledProps;

  const columns = useMemo(
    () => [
      {
        Header: "Date",
        accessor: "clean_date",
        className: "rt-cell-with-two-rows",
        sortable: true,
        maxWidth: 130,
        Cell: ({ value, original: { show_path } }) => (
          <a href={show_path} title="Show clean demand">
            {formatDate(new Date(value))}
          </a>
        )
      },
      {
        Header: "Demand Time",
        accessor: "demand_time",
        className: "rt-cell-with-text-wrap",
        sortable: false,
        maxWidth: 120
      },
      {
        Header: "Clean Time",
        accessor: "clean_time",
        className: "rt-cell-with-text-wrap",
        sortable: false,
        maxWidth: 120
      },
      {
        Header: "Duration",
        accessor: "duration",
        width: 90
      },
      {
        Header: "Status",
        accessor: "status",
        className: "rt-clean-status-cell",
        maxWidth: 160,
        getProps: (state, { original: { status_color_class } }) => ({
          className: status_color_class
        }),
        Cell: ({ original: { status_label } }) => status_label,
        sortable: false
      },
      {
        Header: "Source",
        accessor: "source",
        maxWidth: 100,
        sortable: false
      },
      {
        Header: "Rating",
        accessor: "rating",
        maxWidth: 80,
        sortable: false
      },
      {
        Filter: CleanTypeFilter,
        Header: "Type",
        accessor: "clean_type",
        maxWidth: 150,
        filterable: true,
        sortable: true
      },
      {
        Header: "Cleaner",
        accessor: "cleaner_name",
        Cell: ({ value, original: { cleaner_url } }) => (
          <a href={cleaner_url}>{value}</a>
        ),
        sortable: false
      },
      {
        Header: "Notes",
        className: "rt-cell-with-text-wrap",
        accessor: "notes",
        sortable: false
      },
      {
        sortable: false,
        width: 60,
        className: "rt-maintenance-cell maintenance-action-cell",
        Cell: ({ original: { id, status } }) => (
          <ActionOptions id={id} status={status} onUpdate={updateDataRow} />
        )
      }
    ],
    [updateDataRow]
  );

  return (
    <div className="clean-demands-table">
      <div className="twbs clean-demands-filters">
        <div className="form-row align-items-center">
          <div className="col-auto">
            <label
              htmlFor="clean_date_filter_from"
              className="filter-container-clean-date-range"
            >
              Clean date from
            </label>
          </div>
          <div className="col-auto">
            <DatePicker
              id="clean_date_filter_from"
              data-testid="range-start"
              pikadayOptions={{
                numberOfMonths: 2
              }}
              onSelect={(value) =>
                onFilterChange("clean_date_range_start", value)
              }
              defaultValue={getFilterValue("clean_date_range_start")}
            />
          </div>

          <div className="col-auto">
            <label
              htmlFor="clean_date_filter_to"
              className="filter-container-clean-date-range"
            >
              to
            </label>
          </div>
          <div className="col-auto">
            <DatePicker
              id="clean_date_filter_to"
              data-testid="range-end"
              pikadayOptions={{
                minDate: new Date(getFilterValue("clean_date_range_start")),
                numberOfMonths: 2,
                defaultDate: new Date(getFilterValue("clean_date_range_start"))
              }}
              defaultValue={getFilterValue("clean_date_range_end")}
              onSelect={(value) =>
                onFilterChange("clean_date_range_end", value)
              }
            />
          </div>
        </div>
      </div>

      <ReactTable
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...controlledProps}
        className="-striped -highlight"
        multiSort
        columns={columns}
      />

      {showPagination && (
        <h4>Total number of properties {controlledProps.totalCount}</h4>
      )}
    </div>
  );
};
