import React, { useState } from "react";

import { FeedbackResponseForm as Form } from "./FeedbackResponseForm";

export const FeedbackResponse = ({ id, value, responded_at }) => {
  const [response, setResponse] = useState(value);
  const [showTextarea, setShowTextarea] = useState(false);

  if (response) {
    return (
      <div className="feedback-response mt-3">
        <Form readOnly value={response} />
        <strong>Responded at:</strong> {responded_at}
      </div>
    );
  }

  return (
    <div className="feedback-response mt-3">
      {showTextarea ? (
        <Form
          onSuccess={setResponse}
          onCancel={() => setShowTextarea(false)}
          id={id}
          value={response}
        />
      ) : (
        <button
          type="button"
          className="btn btn-link"
          onClick={() => setShowTextarea(true)}
        >
          Respond to feedback
        </button>
      )}
    </div>
  );
};
