import React, { useMemo } from "react";
import ReactTable from "react-table";

import { formatCleanRating } from "../../../common/formatCleanRating";
import { ShowButton } from "../../../common/tables/buttons";
import { dateColumn } from "../../../common/tables/dateColumn";
import {
  GenericSearchFilter,
  LocalityFilter
} from "../../../common/tables/filters";
import { useControlledTable } from "../../../common/tables/useControlledTable";
import { ActiveFilter } from "./ActiveFilter";
import { BadgeFilter } from "./BadgeFilter";
import { CheckboxFilter } from "./CheckboxFilter";
import { ContractSignedFilter } from "./ContractSignedFilter";

const initialState = {
  filtered: [{ id: "active", value: "true" }],
  sorted: [
    { id: "average_rating", desc: true },
    { id: "name", desc: false }
  ]
};

export const CleanersTable = ({ showPods }) => {
  const controlledProps = useControlledTable({
    api: { endpoint: "/api/cleaners" },
    initialState
  });

  const columns = useMemo(
    () => [
      {
        columns: [
          {
            accessor: "id",
            sortable: false,
            filterable: false,
            maxWidth: 50,
            className: "rt-actions",
            Cell: ({ original: { show_path } }) => (
              <span>
                <ShowButton href={show_path} title="Show laundry provider" />
              </span>
            )
          },
          {
            Header: "Name",
            accessor: "name",
            maxWidth: 350,
            filterable: true,
            className: "rt-cell-with-two-rows",
            Cell: ({ original: { active, name, email, show_path } }) => (
              <a href={show_path} className={active ? "active" : "inactive"}>
                <span className="primary">{name}</span>
                {email && <div className="secondary">{email}</div>}
              </a>
            ),
            Filter: ({ onChange, filter }) => (
              <GenericSearchFilter
                onChange={onChange}
                filter={filter}
                placeholder="Search by name or email"
              />
            )
          }
        ]
      },

      {
        Header: "Badges",
        columns: [
          {
            Header: () => (
              <span title="Quality badge">
                <i className="fa fa-certificate" />️
              </span>
            ),
            accessor: "quality_badge",
            sortable: false,
            filterable: true,
            maxWidth: 40,
            className: "rt-cleaner-badge",
            Cell: ({ original: { quality_badge } }) =>
              quality_badge && (
                <span title="Quality badge">
                  <i className="fa fa-certificate" />
                </span>
              ),
            Filter: CheckboxFilter
          },
          {
            Header: () => (
              <span title="Experience badge">
                <i className="fa fa-star" />
              </span>
            ),
            accessor: "experience_badge",
            sortable: false,
            filterable: true,
            maxWidth: 40,
            className: "rt-cleaner-badge",
            Cell: ({ original: { experience_badge } }) =>
              experience_badge && (
                <span title="Experience badge">
                  <i className="fa fa-star" />
                </span>
              ),
            Filter: CheckboxFilter
          },
          {
            Header: () => (
              <span title="Premium badge">
                <i className="fa fa-award" />
              </span>
            ),
            accessor: "premium_badge",
            sortable: false,
            filterable: true,
            maxWidth: 40,
            className: "rt-cleaner-badge",
            Cell: ({ original: { premium_badge } }) =>
              premium_badge && (
                <span title="Premium badge">
                  <i className="fa fa-award" />
                </span>
              ),
            Filter: CheckboxFilter
          }
        ]
      },
      {
        Header: "Badge",
        maxWidth: 100,
        filterable: true,
        Filter: BadgeFilter,
        accessor: "badge",
        className: "rt-cleaner-badge",
        sortable: false,
        Cell: ({ original: { badge } }) => badge || "No badge"
      },
      {
        Header: <span title="For last 60 days">Rating</span>,
        columns: [
          {
            Header: "Avg",
            accessor: "average_rating",
            defaultSortDesc: true,
            maxWidth: 60,
            Cell: ({ original: { average_rating } }) =>
              formatCleanRating(average_rating)
          },
          {
            Header: <span title="Number of rated cleans">No</span>,
            accessor: "completed_cleans_count",
            defaultSortDesc: true,
            maxWidth: 60
          }
        ]
      },
      {
        Header: "Warning",
        maxWidth: 70,
        filterable: true,
        Filter: CheckboxFilter,
        accessor: "warning",
        className: "rt-cleaner-warning",
        sortable: false,
        Cell: ({ original: { show_path, warning } }) =>
          warning && (
            <a
              href={`${show_path}#performance`}
              title="Cleaner is marked with a warning"
              className="red"
            >
              <i className="fas fa-exclamation-circle red" />
            </a>
          )
      },
      {
        Header: "Locality",
        id: "locality.id",
        accessor: "locality.name",
        filterable: true,
        sortable: false,
        maxWidth: 210,
        Filter: LocalityFilter,
        Cell: ({ original: { locality } }) => (
          <span>
            {locality.name} ({locality.country_code})
          </span>
        )
      },
      showPods
        ? {
            Header: "Pod",
            accessor: "pod.name",
            sortable: false,
            getProps: (_, { original }) => {
              const isRestricted = original?.pod?.restricted_access;

              return {
                className: isRestricted ? "orange" : "",
                title: isRestricted ? "This pod has restricted access" : ""
              };
            },
            maxWidth: 150
          }
        : {},
      {
        Header: "Active",
        accessor: "active",
        maxWidth: 100,
        sortable: false,
        filterable: true,
        Filter: ActiveFilter,
        Cell: ({ original: { active } }) => (
          <span>{active ? "Active" : "Inactive"}</span>
        )
      },
      {
        Header: "Contract",
        accessor: "contract_signed",
        maxWidth: 130,
        sortable: false,
        filterable: true,
        Filter: ContractSignedFilter,
        Cell: ({ original: { contract_signed } }) => (
          <span>{contract_signed ? "Signed" : "Not signed"}</span>
        )
      },
      dateColumn()
    ],
    [showPods]
  );

  return (
    <div>
      <ReactTable
        {...controlledProps}
        columns={columns}
        className="-striped -highlight"
        multiSort
      />

      <h4>Total number of cleaners {controlledProps.totalCount}</h4>
    </div>
  );
};
