import React from "react";

import { DropdownFilter } from "../../../../common/tables/filters";

const VALUES = [
  ["Completed", true],
  ["Incomplete", false]
];

export const CompletedFilter = ({ filter, onChange }) => (
  <DropdownFilter filter={filter} onChange={onChange} values={VALUES} />
);
