import { fireEvent, render, waitFor } from "@testing-library/react";
import React from "react";

import { ApiClientContext } from "../../../common/apiClient";
import { EditModal } from "./EditModal";

describe("<EditModal />", () => {
  it("updates", async () => {
    // Given
    const fakeWindow = { location: { reload: jest.fn() } };

    const image = {
      id: 123,
      clean_id: 10,
      type: "location_kitchen",
      receipt_amount: ""
    };

    const apiClient = {
      put: jest.fn().mockReturnValue(Promise.resolve())
    };

    const { container, getByLabelText, getByText } = render(
      <EditModal image={image} window={fakeWindow} />,
      {
        wrapper: ({ children }) => (
          <ApiClientContext.Provider value={apiClient}>
            {children}
          </ApiClientContext.Provider>
        )
      }
    );

    await waitFor(() =>
      expect(container).not.toHaveTextContent("Receipt amount is required")
    );

    // When
    fireEvent.change(getByLabelText("Type"), {
      target: { value: "receipt" }
    });

    await waitFor(() =>
      expect(container).toHaveTextContent("Receipt amount is required")
    );

    fireEvent.change(getByLabelText("Receipt amount"), {
      target: { value: "asdf" }
    });

    await waitFor(() =>
      expect(container).toHaveTextContent("Receipt amount is invalid")
    );

    fireEvent.change(getByLabelText("Receipt amount"), {
      target: { value: "4.99" }
    });

    fireEvent.change(getByLabelText("Description"), {
      target: { value: "Receipt for cleaning equipment" }
    });

    const submitButtonEl = getByText("Update");
    fireEvent.click(submitButtonEl);
    expect(submitButtonEl).toBeDisabled();

    await waitFor(() => expect(submitButtonEl).toBeEnabled());

    // Then
    expect(apiClient.put).toHaveBeenCalledWith("/api/cleans/10/images/123", {
      clean_image: {
        type: "receipt",
        receipt_amount: "4.99",
        description: "Receipt for cleaning equipment"
      }
    });

    expect(fakeWindow.location.reload).toHaveBeenCalled();
  });
});
