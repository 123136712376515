import "./CleanerStatus.scss";

import React, { useEffect, useState } from "react";

import { useAsync } from "../../../common/hooks/useAsync";
import { fetchCleaner } from "../api";
import { Active } from "./Active";
import { confirmTogglingStatus } from "./confirmTogglingStatus";
import { Inactive } from "./Inactive";

export const CleanerStatus = ({ cleaner_id, fetchCleaner }) => {
  const [cleaner, setCleaner] = useState(null);
  const { loading, value } = useAsync(fetchCleaner, cleaner_id);

  useEffect(() => {
    setCleaner(value);
  }, [value]);

  const handleToggleStatus = async () => {
    const { confirmed, cleaner: updatedCleaner } = await confirmTogglingStatus(
      cleaner
    );

    if (confirmed) {
      setCleaner(updatedCleaner);
    }
  };

  if (loading || !cleaner) {
    return <div>Loading...</div>;
  }

  return (
    <div className="cleaner-status">
      <dl>
        {cleaner.active ? (
          <Active cleaner={cleaner} />
        ) : (
          <Inactive cleaner={cleaner} />
        )}
      </dl>
      <button type="button" onClick={handleToggleStatus} className="danger">
        {cleaner.active ? "Deactivate cleaner" : "Activate cleaner"}
      </button>
    </div>
  );
};

CleanerStatus.defaultProps = { fetchCleaner };
