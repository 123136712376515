import { Fancybox } from "@fancyapps/ui/";
import React, { useEffect } from "react";
import Swal from "sweetalert2";

import { LoadingSpinner } from "../../../common/LoadingSpinner";
import { useMaintenanceImages } from "./useMaintenanceImages";

export const MaintenanceImagesModal = ({ maintenanceId }) => {
  const { isLoading, images, fetchImages } = useMaintenanceImages(
    maintenanceId
  );

  const { config: fancyboxConfig } = window.AirbaseConstants.fancybox;

  useEffect(() => {
    fetchImages();
  }, [fetchImages]);

  useEffect(() => {
    if (images.length === 0) {
      return false;
    }

    const galleryItems = images.map((i) => ({
      src: i.original_image_url,
      preload: true
    }));

    Fancybox.show(galleryItems, fancyboxConfig);
    Swal.close();

    return false;
  }, [fancyboxConfig, images]);

  if (isLoading) {
    return <LoadingSpinner message="Fetching images..." />;
  }

  return null;
};
