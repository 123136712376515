const QUERY_PARAMS = ["page", "pageSize", "filtered", "sorted"];

// Read and update query params used for controlling the table
export class QueryParams {
  // If there's more than one table of the page `queryParamsPrefix` should be
  // used in order to avoid params clashing.
  constructor(queryParamsPrefix = {}) {
    this.queryParamsPrefix = queryParamsPrefix;

    this.queryParams = new URLSearchParams(window.location.search);
  }

  keyWithPrefix(key) {
    return [this.queryParamsPrefix, key].filter(Boolean).join("_");
  }

  read() {
    const getParam = (key) => {
      try {
        return this.queryParams.has(this.keyWithPrefix(key))
          ? JSON.parse(this.queryParams.get(this.keyWithPrefix(key)))
          : null;
      } catch (error) {
        return null;
      }
    };

    return QUERY_PARAMS.map((key) => [key, getParam(key)])
      .filter(([, value]) => Boolean(value))
      .reduce((acc, [key, value]) => ({ ...acc, [key]: value }), {});
  }

  update(params) {
    Object.entries(params).forEach(([key, value]) => {
      if (value !== undefined) {
        this.queryParams.set(this.keyWithPrefix(key), JSON.stringify(value));
      }
    });

    window.history.replaceState(
      {},
      "",
      `${window.location.pathname}?${this.queryParams.toString()}`
    );
  }
}
