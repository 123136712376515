import "./DisablingAutoCleanForm.scss";

import { ErrorMessage, Field, Form, Formik } from "formik";
import React from "react";
import * as yup from "yup";

import { SwalActions } from "../../../../common/modals";

const Schema = yup.object().shape({
  skip_auto_clean_reason: yup.string().required("Reason is required")
});

const { SKIP_AUTO_CLEAN_REASONS } = window.AirbaseConstants.Booking;

export const DisablingAutoCleanForm = ({
  initialValues,
  onCancel,
  onSubmit
}) => {
  return (
    <Formik
      initialValues={initialValues}
      isInitialValid={Schema.isValidSync(initialValues)}
      validationSchema={Schema}
      onSubmit={onSubmit}
    >
      {({ isSubmitting, handleSubmit }) => (
        <Form className="ops_form_modal">
          <div>
            <label htmlFor="skip_auto_clean_reason_field">Reason</label>

            <Field
              id="skip_auto_clean_reason_field"
              name="skip_auto_clean_reason"
              component="select"
            >
              <option value="" />
              {Object.entries(SKIP_AUTO_CLEAN_REASONS).map(([value, label]) => (
                <option key={value} value={value}>
                  {label}
                </option>
              ))}
            </Field>

            <ErrorMessage name="skip_auto_clean_reason">
              {(message) => <div className="error">{message}</div>}
            </ErrorMessage>
          </div>

          <SwalActions
            confirmText="OK"
            disabled={isSubmitting}
            onCancel={onCancel}
            onConfirm={handleSubmit}
          />
        </Form>
      )}
    </Formik>
  );
};
