/* eslint-disable react/jsx-props-no-spreading */
import React, { useState } from "react";

import CleanImagesGallery from "./CleanImagesGallery";
import { CleanImagesUpload } from "./CleanImagesUpload";

export const CleanImagesUploadWidget = ({ cleanId }) => {
  const [cleanImages, setCleanImages] = useState([]);

  return (
    <>
      <CleanImagesGallery cleanId={cleanId} images={cleanImages} />

      <CleanImagesUpload
        cleanId={cleanId}
        onUploadFinish={(data) => {
          setCleanImages(data?.clean_images);
        }}
      />
    </>
  );
};
