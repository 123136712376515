import { handleActions } from "redux-actions";

import { deleteFileResponse } from "../files/actions";
import { clearToastMessage } from "./actions";

const defaultState = {
  message: null,
  severity: null
};

export const reducer = handleActions(
  {
    [deleteFileResponse]: () => ({
      message: "File deleted",
      severity: "success"
    }),

    [clearToastMessage]: () => defaultState
  },
  defaultState
);
