import { fireEvent, render, waitFor } from "@testing-library/react";
import React from "react";
import { BrowserRouter, MemoryRouter } from "react-router-dom";

import { HomeInfoContext } from "../HomeInfoContext";
import { PropertyAccess } from "./PropertyAccess";

const {
  ACCESS_TYPE_WALL_MOUNTED_LOCKBOX
} = window.AirbaseConstants.HomeInfoLocation;

test("<PropertyAccess />", async () => {
  // Given
  const fakeSubmit = jest.fn();

  const { container, queryByText, getByLabelText } = render(
    <BrowserRouter>
      <MemoryRouter initialEntries={["/property-access"]}>
        <HomeInfoContext.Provider
          value={{
            homeInfo: {
              property_id: 1,
              locality: { name: "London" },
              property_attributes: {},
              home_info_location_attributes: {
                access_type: "",
                lockbox_code: "",
                digital_lock_code: ""
              },
              home_info_internal_notes_attributes: []
            },
            airbnbListingData: {},
            setIsModified: () => {},
            updateHomeInfo: fakeSubmit
          }}
        >
          <PropertyAccess />
        </HomeInfoContext.Provider>
      </MemoryRouter>
    </BrowserRouter>
  );

  // When
  fireEvent.submit(container.querySelector("form"));

  // Then
  await waitFor(() => {
    expect(queryByText("Please select access type")).toBeInTheDocument();
  });

  // When
  fireEvent.change(getByLabelText("Access type"), {
    target: { value: ACCESS_TYPE_WALL_MOUNTED_LOCKBOX }
  });
  fireEvent.submit(container.querySelector("form"));

  // Then
  await waitFor(() => {
    expect(queryByText("Please enter lockbox code")).toBeInTheDocument();
  });

  // When
  fireEvent.change(getByLabelText("Wall-mounted lockbox code"), {
    target: { value: "1234" }
  });

  // Then
  await waitFor(() => {
    expect(queryByText("Please enter lockbox code")).not.toBeInTheDocument();
  });

  // When
  fireEvent.change(getByLabelText("Access type"), {
    target: { value: "keyless digital lock" }
  });
  fireEvent.submit(container.querySelector("form"));

  // Then
  await waitFor(() => {
    expect(queryByText("Please enter digital lock code")).toBeInTheDocument();
  });
  expect(fakeSubmit).not.toHaveBeenCalled();

  // When
  fireEvent.change(getByLabelText("Digital lock code"), {
    target: { value: "asdf" }
  });
  fireEvent.submit(container.querySelector("form"));

  // Then
  await waitFor(() => {
    expect(
      queryByText("Please enter digital lock code")
    ).not.toBeInTheDocument();
  });
  expect(fakeSubmit).toHaveBeenCalled();

  // When
  fireEvent.change(getByLabelText("Access type"), {
    target: { value: "salto" }
  });
  fireEvent.submit(container.querySelector("form"));

  // Then
  await waitFor(() => {
    expect(
      queryByText("Please enter digital lock code")
    ).not.toBeInTheDocument();
  });
  expect(fakeSubmit).toHaveBeenCalled();
});
