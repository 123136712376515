import "./HomeInfo.scss";

import React from "react";
import { Route, BrowserRouter as Router } from "react-router-dom";

import { HomeInfoProvider } from "./HomeInfoContext";
import { HomeInfoNavigation } from "./HomeInfoNavigation";
import { HomeInfoRoutes } from "./HomeInfoRoutes";
import { ListingPhotosProvider } from "./ListingPhotosContext";
import { PreviewScreen } from "./PreviewScreen";

export const HomeInfo = ({ property, externalKeysCount }) => (
  <Router basename={`/properties/${property.id}/home_info`}>
    <HomeInfoProvider property={property} externalKeysCount={externalKeysCount}>
      <ListingPhotosProvider propertyId={property.id}>
        <div className="twbs property-onboarding">
          <div className="row">
            <div className="d-md-block col-3 navigation-container">
              <HomeInfoNavigation />
            </div>

            <div className="col-12 col-md-9">
              <Route path="/" exact render={() => <PreviewScreen />} />

              <HomeInfoRoutes />
            </div>
          </div>
        </div>
      </ListingPhotosProvider>
    </HomeInfoProvider>
  </Router>
);
