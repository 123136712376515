import classNames from "classnames";
import { ErrorMessage, useFormikContext } from "formik";
import debounce from "lodash/debounce";
import React, { useCallback, useRef, useState } from "react";

import { apiClient } from "../../../../common/apiClient";

export const PropertySearch = ({ onSelect }) => {
  const [properties, setProperties] = useState([]);
  const { errors } = useFormikContext();
  const abortConRef = useRef();

  const handlePropertyListUpdate = useCallback((fetchedProperties) => {
    setProperties(fetchedProperties);
  }, []);

  const handleChange = (event) => {
    const delayedSearchChange = debounce(async (homeCode) => {
      abortConRef.current?.abort?.();
      abortConRef.current = new AbortController();

      const { data } = await apiClient.get(`/api/properties/by_home_code`, {
        signal: abortConRef.current.signal,
        params: { home_code: homeCode }
      });

      handlePropertyListUpdate(data?.rows);
    }, 300);

    handlePropertyListUpdate([]);
    const { value: homeCode } = event.target;
    if (homeCode.length > 1) {
      delayedSearchChange(homeCode);
    }
  };

  return (
    <>
      <input
        id="property_search"
        placeholder="Search by home code"
        autoComplete="off"
        type="search"
        onChange={handleChange}
        className={classNames("form-control", {
          "is-invalid": errors.property_id
        })}
      />
      <ErrorMessage name="property_id">
        {(message) => <div className="invalid-feedback">{message}</div>}
      </ErrorMessage>

      <div className="PropertySelection__options">
        {properties.map((property) => (
          <PropertyOption
            key={property.id}
            property={property}
            onSelect={onSelect}
          />
        ))}
      </div>
    </>
  );
};

export const PropertyOption = ({ property, onSelect }) => (
  <div
    className="PropertySelection__option"
    onClick={() => onSelect(property)}
    role="button"
    tabIndex={0}
  >
    <span>{property.home_code}</span>
    <br />
    <span>{property.full_address}</span>
  </div>
);
