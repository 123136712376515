import { fireEvent, render, waitFor } from "@testing-library/react";
import React from "react";

import { copyToClipboard } from "../../../common/copyToClipboard";
import { showSuccessToast } from "../../../common/modals";
import { CopyTextBox } from "./CopyTextBox";

jest.mock("../../../common/copyToClipboard");
jest.mock("../../../common/modals");

describe("<CopyTextBox />", () => {
  it("displays the given text", () => {
    const { getByText } = render(<CopyTextBox text="Foo bar" />);
    expect(getByText("Foo bar")).toBeInTheDocument();
  });

  it("handles copy to clipboard", async () => {
    // Given
    copyToClipboard.mockReturnValue(Promise.resolve());
    const { getByTestId, getByText } = render(<CopyTextBox text="Foo baz" />);

    expect(getByText("Foo baz")).toBeInTheDocument();

    // When
    fireEvent.click(getByTestId("copy-button"));

    // Then
    expect(copyToClipboard).toHaveBeenCalledWith("Foo baz");
    await waitFor(() =>
      expect(showSuccessToast).toHaveBeenCalledWith(
        '"Foo baz" was copied to the clipboard'
      )
    );
  });
});
