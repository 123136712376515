import debounce from "lodash/debounce";
import React, { useCallback, useEffect, useState } from "react";

import { apiClient } from "../../../common/apiClient";
import { CleanerOption } from "./CleanerOption";

const fetchFilteredCleaners = async (query, localityId) => {
  const byActive = { id: "active", value: "true" };
  const byName = { id: "name", value: query };
  const byLocalityId = { id: "locality.id", value: localityId };

  const params = {
    filtered: [byActive, byName, byLocalityId],
    sorted: [{ id: "average_rating", desc: true }, { id: "name" }],
    limit: 10
  };

  const {
    data: { rows: cleaners }
  } = await apiClient.get("/api/cleaners", { params });

  return cleaners;
};

// On init - display list of cleaners that already cleaned this property
const fetchRecommendedCleaners = async (propertyId) => {
  const url = `/api/properties/${propertyId}/cleaners`;
  const { data } = await apiClient.get(url);

  return data.cleaners;
};

export const CleanerSearch = ({ localityId, propertyId, onSelect }) => {
  const [cleaners, setCleaners] = useState([]);

  useEffect(() => {
    const fetchCleaners = async () => {
      const propertyCleaners = await fetchRecommendedCleaners(propertyId);
      setCleaners(propertyCleaners);
    };

    fetchCleaners();
  }, [propertyId]);

  const handleChange = useCallback(
    (event) => {
      const delayedSearchChange = debounce(async (query) => {
        const filteredCleaners = query
          ? await fetchFilteredCleaners(query, localityId)
          : await fetchRecommendedCleaners(propertyId);

        setCleaners(filteredCleaners);
      }, 300);

      delayedSearchChange(event.target.value);
    },
    [localityId, propertyId]
  );

  return (
    <>
      <div className="SearchSelect__search">
        <input
          id="CleanerSelection"
          type="text"
          placeholder="Search"
          onChange={handleChange}
        />
      </div>

      {cleaners.map((cleaner) => (
        <CleanerOption key={cleaner.id} cleaner={cleaner} onSelect={onSelect} />
      ))}
    </>
  );
};
