import { useCallback, useEffect, useState } from "react";

export const useAsync = (fn, ...args) => {
  const [loading, setLoading] = useState(true);
  const [value, setValue] = useState(undefined);
  const [error, setError] = useState(undefined);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const memoized = useCallback(() => fn(...args), args);

  useEffect(() => {
    let mounted = true;

    setLoading(true);
    setError(undefined);

    const promise = memoized();
    promise
      .then((value) => mounted && setValue(value))
      .catch((error) => mounted && setError(error))
      .finally(() => mounted && setLoading(false));

    return () => {
      mounted = false;
    };
  }, [memoized]);

  return {
    loading,
    value,
    error
  };
};
