import { render } from "@testing-library/react";
import React from "react";

import { FileTypeIcon } from "./FileTypeIcon";

describe("<FileTypeIcon />", () => {
  it.each`
    fileType                                                                       | className
    ${"image/jpeg"}                                                                | ${"fa fa-file-image"}
    ${"image/png"}                                                                 | ${"fa fa-file-image"}
    ${"application/pdf"}                                                           | ${"fa fa-file-pdf"}
    ${"application/zip"}                                                           | ${"fa fa-file-archive"}
    ${"application/msword"}                                                        | ${"fa fa-file-word"}
    ${"application/vnd.openxmlformats-officedocument.wordprocessingml.document"}   | ${"fa fa-file-word"}
    ${"application/vnd.openxmlformats-officedocument.presentationml.presentation"} | ${"fa fa-file-powerpoint"}
    ${"unknown"}                                                                   | ${"fa fa-file"}
  `("has a valid class for $fileType ", ({ fileType, className }) => {
    const { container } = render(<FileTypeIcon fileType={fileType} />);
    expect(container.querySelector("i")).toHaveClass(className);
  });
});
