import React from "react";

import { useDropdownMenu } from "../../../../../common/hooks/useDropdownMenu";

export const FileDropdownMenu = ({
  onEdit,
  onDownload,
  onCopyPublicUrl,
  onDelete,
  contextMenuShowsEdit = true
}) => {
  const { ref, isOpen, open, handle } = useDropdownMenu();

  return (
    <div className="btn-group">
      <button type="button" className="btn" onClick={open}>
        <i className="fas fa-ellipsis-v" />
      </button>

      <div ref={ref} className={`dropdown-menu ${isOpen && "show"}`}>
        {contextMenuShowsEdit && (
          <button
            type="button"
            className="dropdown-item"
            onClick={handle(onEdit)}
          >
            <i className="fas fa-edit" /> Edit
          </button>
        )}

        <button
          type="button"
          className="dropdown-item"
          onClick={handle(onDownload)}
        >
          <i className="fas fa-download" /> Download
        </button>

        <button
          type="button"
          className="dropdown-item"
          onClick={handle(onCopyPublicUrl)}
        >
          <i className="far fa-copy" /> Copy public URL
        </button>

        <div className="dropdown-divider" />

        <button
          type="button"
          className="dropdown-item"
          onClick={handle(onDelete)}
        >
          <i className="fas fa-trash-alt" /> Delete
        </button>
      </div>
    </div>
  );
};
