import React, { useEffect, useState } from "react";

const UploadedImageThumb = ({ file }) => {
  const [loading, setLoading] = useState(false);
  const [thumb, setThumb] = useState(null);

  useEffect(() => {
    setLoading(true);
    const reader = new FileReader();

    reader.onloadend = () => {
      setLoading(false);
      setThumb(reader.result);
    };

    reader.readAsDataURL(file);
  }, [file]);

  if (loading) {
    return <p>Loading...</p>;
  }

  if (!thumb) {
    return null;
  }

  if (file.type.match("video.*")) {
    return (
      // eslint-disable-next-line jsx-a11y/media-has-caption
      <video width="200" controls muted autoPlay={false}>
        <source src={URL.createObjectURL(file)} />
      </video>
    );
  }

  return (
    <img
      src={thumb}
      alt={file.name}
      className="maintenance-uploaded-image"
      width={200}
    />
  );
};

export default UploadedImageThumb;
