import isEmpty from "lodash/isEmpty";
import React from "react";

import { formatAmountWithCurrency } from "../../../common/numbers";
import { PriceBreakdown } from "./PriceBreakdown";
import { Row } from "./Row";

export const BookingRevenue = ({
  booking_revenue: {
    channel_commission_host,
    guest_cleaning_fee,
    houst_commission,
    houst_commission_vat,
    net_host_booking_revenue,
    platform_payout,
    total_booking_revenue,
    city_tax
  },
  currency_symbol,
  total_price_breakdown,
  city_tax_applies
}) => (
  <div className="details-grid">
    <Row
      label={`Total revenue${
        total_price_breakdown && !isEmpty(total_price_breakdown)
          ? ` (${Object.keys(total_price_breakdown).length}-night)`
          : ""
      }:`}
      value={formatAmountWithCurrency(total_booking_revenue, {
        currencySymbol: currency_symbol
      })}
      details={
        total_price_breakdown && !isEmpty(total_price_breakdown) ? (
          <PriceBreakdown
            currencySymbol={currency_symbol}
            data={total_price_breakdown}
          />
        ) : undefined
      }
    />

    <Row
      label="Guest cleaning fee:"
      value={formatAmountWithCurrency(guest_cleaning_fee, {
        currencySymbol: currency_symbol
      })}
    />

    <Row
      label="Platform commission:"
      value={formatAmountWithCurrency(channel_commission_host, {
        currencySymbol: currency_symbol
      })}
    />

    {platform_payout && (
      <Row
        label="Platform payout:"
        value={formatAmountWithCurrency(platform_payout, {
          currencySymbol: currency_symbol
        })}
      />
    )}

    <Row
      label="Houst management fee (Net):"
      value={formatAmountWithCurrency(houst_commission, {
        currencySymbol: currency_symbol
      })}
    />

    <Row
      label="Houst management fee (Tax):"
      value={formatAmountWithCurrency(houst_commission_vat, {
        currencySymbol: currency_symbol
      })}
    />

    <Row
      label="Net revenue:"
      value={formatAmountWithCurrency(net_host_booking_revenue, {
        currencySymbol: currency_symbol
      })}
    />
    {city_tax && city_tax_applies && (
      <Row
        label="City tax:"
        value={formatAmountWithCurrency(city_tax, {
          currencySymbol: currency_symbol
        })}
      />
    )}
  </div>
);
