import classNames from "classnames";
import React from "react";

export const Progress = ({
  percentage,
  stripped = false,
  success = false,
  danger = false
}) => (
  <div className="progress">
    <div
      className={classNames("progress-bar", {
        "progress-bar-striped": stripped,
        "bg-success": success,
        "bg-danger": danger
      })}
      style={{ width: `${percentage}%` }}
    />
  </div>
);
