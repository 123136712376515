import classNames from "classnames";
import { Field, connect } from "formik";
import get from "lodash/get";
import has from "lodash/has";
import React, { useEffect, useMemo, useRef } from "react";

export const FormGroup = connect(
  ({
    name,
    component,
    className,
    label,
    placeholder,
    renderAppend,
    helpText,
    children,
    readOnly,
    renderSideLabel,
    focus,
    formik: { errors },
    type,
    labelFlags,
    description,
    characterCount,
    ...props
  }) => {
    const ref = useRef(null);
    const isCheckbox = type === "checkbox";
    const labelElement = useMemo(
      () =>
        (label || labelFlags?.length ? true : null) && (
          <div className="onboarding-form-label">
            {label && <label htmlFor={name}>{label}</label>}
            {labelFlags}
            {description}
          </div>
        ),
      [label, name, labelFlags, description]
    );

    useEffect(() => {
      if (focus) {
        ref.current.focus();
      }
    }, [focus]);

    return (
      <div
        className={classNames("form-group", className)}
        id={`anchor-${name}`}
      >
        {!isCheckbox && labelElement}
        {renderSideLabel && renderSideLabel()}

        <div
          className={classNames(
            "input-group",
            { "form-check": isCheckbox },
            {
              "with-count": characterCount
            }
          )}
        >
          {isCheckbox && labelElement}

          <Field
            innerRef={ref}
            id={name}
            name={name}
            placeholder={placeholder}
            component={component}
            autoComplete="off"
            readOnly={readOnly}
            className={classNames(
              isCheckbox ? "form-check-input" : "form-control",
              {
                "is-invalid": has(errors, name)
              }
            )}
            type={type}
            {...props}
          >
            {children}
          </Field>

          {renderAppend && (
            <div className="input-group-append">
              <span className="input-group-text">{renderAppend()}</span>
            </div>
          )}
          {has(errors, name) && (
            <div className="invalid-feedback">{get(errors, name)}</div>
          )}

          {characterCount && (
            <p
              className={`character-count ${
                ref?.current?.value?.length > characterCount && "error"
              }`}
            >
              {ref?.current?.value?.length}/{characterCount}
            </p>
          )}
        </div>

        {helpText && <small className="form-text text-muted">{helpText}</small>}
      </div>
    );
  }
);
