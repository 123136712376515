import { format } from "date-fns";

const DEFAULT_DATE_FORMAT = "do MMM yyyy";
const DEFAULT_TIME_FORMAT = "h:mma";

export const formatDate = (date) => format(date, DEFAULT_DATE_FORMAT);

export const formatTime = (date) => format(date, DEFAULT_TIME_FORMAT);

export const formatDateTime = (date) =>
  format(date, `${DEFAULT_DATE_FORMAT} - ${DEFAULT_TIME_FORMAT}`);
