import React, { useCallback } from "react";

import { copyToClipboard } from "../../../common/copyToClipboard";
import { showSuccessToast } from "../../../common/modals";

export const CopyTextButton = ({ title, content, successMessage }) => {
  const handleCopy = useCallback(
    async (event) => {
      event.preventDefault();

      await copyToClipboard(content);
      showSuccessToast(successMessage);
    },
    [content, successMessage]
  );

  return (
    <button type="button" className="btn btn-primary mb-3" onClick={handleCopy}>
      <i role="img" className="far fa-copy mr-2" />
      {title}
    </button>
  );
};
