import classNames from "classnames";
import React, { useState } from "react";
import { useDropzone } from "react-dropzone";
import { useDispatch, useSelector } from "react-redux";

import {
  ACCEPTED_CONTENT_TYPES,
  ERROR_FILE_INVALID_TYPE,
  ERROR_FILE_TOO_LARGE
} from "../../constants";
import { selectUploadQueue } from "../../data/upload/selectors";
import { uploadFiles } from "../../data/upload/thunks";
import { UploadProgressModalContainer } from "../UploadProgressModalContainer";
import { renameFiles } from "./renameFiles";
import { UploadPlaceholder } from "./UploadPlaceholder";

const handleFileRejections = (
  fileRejections,
  acceptContentTypes,
  maxSizeFormatted
) => {
  const errors = [];
  fileRejections.forEach((file) => {
    file.errors.forEach((err) => {
      if (err.code === ERROR_FILE_TOO_LARGE) {
        errors.push(
          `Error uploading file ${file.file.name}. File size cannot exceed ${maxSizeFormatted}.`
        );
      } else if (err.code === ERROR_FILE_INVALID_TYPE) {
        errors.push(
          `Error uploading file ${
            file.file.name
          }. File type must be one of ${acceptContentTypes.join(", ")}.`
        );
      } else {
        errors.push(`Error file ${file.file.name}. ${err.message}`);
      }
    });
  });
  return errors;
};

export const UploadDropZoneContainer = ({
  children,
  acceptContentTypes = ACCEPTED_CONTENT_TYPES,
  maxSize
}) => {
  const dispatch = useDispatch();

  const showUploadProgress = useSelector(
    (state) => selectUploadQueue(state).length > 0
  );

  const [errors, setErrors] = useState([]);

  const {
    getRootProps,
    getInputProps,
    open: handleSelectFiles,
    isDragActive
  } = useDropzone({
    accept: acceptContentTypes.join(", "),
    ...(maxSize && { maxSize: maxSize.size }),
    onDrop: (acceptedFiles, fileRejections) => {
      const newErrors = handleFileRejections(
        fileRejections,
        acceptContentTypes,
        maxSize?.formattedSize
      );

      setErrors(newErrors);

      dispatch(uploadFiles(renameFiles(acceptedFiles)));
    }
  });

  return (
    <div
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...getRootProps({ onClick: (event) => event.stopPropagation() })}
      className={classNames("file-list", { dragging: isDragActive })}
    >
      {errors.map((error, index) => (
        <div key={index} className="invalid-file">
          <i className="fa fa-exclamation-circle p-2" />
          <span>{error}</span>
        </div>
      ))}

      <div className="d-flex flex-wrap justify-content-start">
        <UploadPlaceholder
          onSelectFiles={handleSelectFiles}
          inputProps={getInputProps()}
          maxSizeText={
            maxSize?.formattedSize && `Max ${maxSize.formattedSize} per photo`
          }
        />
        {children}

        {showUploadProgress && <UploadProgressModalContainer errors={errors} />}
      </div>
    </div>
  );
};
