/* eslint-disable func-names */
import { Form, Formik } from "formik";
import get from "lodash/get";
import has from "lodash/has";
import React, { useCallback } from "react";
import * as yup from "yup";

import { useApiClient } from "../../../common/apiClient";
import { isUnprocessableEntityError } from "../../../common/errors";
import { FormGroup } from "../../../common/forms/FormGroup";
import { Modal } from "../../../common/Modal";

const validationSchema = yup.object().shape({
  offboarded_from: yup.string().nullable()
});

const OffboardedFromErrors = ({ errors }) => {
  if (errors && has(errors, ["offboarded_from"])) {
    return (
      <ul>
        {get(errors, ["offboarded_from"]).map((error, index) => (
          <li key={index} className="warning">
            Offboarded from {error}
          </li>
        ))}
      </ul>
    );
  }
  return null;
};

export const OffboardingForm = ({ onClose, property }) => {
  const apiClient = useApiClient();
  const propertyId = property.id;

  const handleSubmit = useCallback(
    async (values, { setSubmitting, setErrors }) => {
      try {
        const { offboarded_from } = values;

        await apiClient.put(
          `/api/properties/${propertyId}/update_offboarded_from`,
          {
            property: {
              offboarded_from
            }
          }
        );

        window.location.reload();
      } catch (error) {
        if (isUnprocessableEntityError(error)) {
          const errors = get(error, "response.data.errors");
          setErrors({ offboarded_from: get(errors, "offboarded_from") });
        }
      } finally {
        setSubmitting(false);
      }
    },
    [apiClient, propertyId]
  );

  return (
    <Formik
      initialValues={property}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {({ isSubmitting, values, resetForm, errors }) => (
        <Form>
          <Modal onClose={onClose}>
            <Modal.Header>Property Offboarding</Modal.Header>
            <Modal.Body>
              {property.offboarded_from && (
                <label className="warning">
                  Clearing the value is going to make the property live again!
                </label>
              )}
              <FormGroup
                label="Offboarded from"
                name="offboarded_from"
                type="date"
                value={values.offboarded_from || ""}
              />
              <OffboardedFromErrors errors={errors} />
            </Modal.Body>

            <Modal.Footer>
              {values.offboarded_from && (
                <button
                  type="button"
                  className="btn btn-light"
                  onClick={() =>
                    resetForm({
                      values: {
                        offboarded_from: ""
                      }
                    })
                  }
                  disabled={isSubmitting}
                >
                  Clear
                </button>
              )}

              <button
                type="button"
                className="btn btn-light"
                onClick={onClose}
                disabled={isSubmitting}
              >
                Cancel
              </button>

              <button
                type="submit"
                className="btn btn-submit"
                disabled={isSubmitting}
              >
                Update
              </button>
            </Modal.Footer>
          </Modal>
        </Form>
      )}
    </Formik>
  );
};

OffboardingForm.defaultProps = { window };
