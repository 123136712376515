import { Fancybox } from "@fancyapps/ui/dist/fancybox.esm";
import Pikaday from "pikaday";

import { videoTemplate } from "./common/fancybox/videoTemplate";

// Initialize datepickers
$(() => {
  $("input[type=date]")
    .attr("type", "text")
    // eslint-disable-next-line func-names
    .each(function () {
      const minDate = $(this).attr("min")
        ? new Date($(this).attr("min"))
        : undefined;

      const maxDate = $(this).attr("max")
        ? new Date($(this).attr("max"))
        : undefined;

      // eslint-disable-next-line no-new
      new Pikaday({
        field: this,
        showTime: false,
        minDate,
        maxDate
      });
    });
});

// Initialize collapsible headers
$(() => {
  $(".collapsible_header").click(function () {
    const $header = $(this);
    const $content = $header.next();

    $content.slideToggle("fast", () => {
      const isVisible = $content.is(":visible");
      $header.toggleClass("collapsible_hidden", !isVisible);
    });
  });
});

// Initialize search filters
$(() => {
  $("#filters form").on("change", function () {
    const params = $(this).serialize();
    window.location.search = params;
  });

  Fancybox.bind(
    "[data-airbase-fancybox]",
    window.AirbaseConstants.fancybox.config
  );

  Fancybox.defaults.video = {
    ...Fancybox.defaults.video,
    tpl: videoTemplate
  };
});
