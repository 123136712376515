import { act, fireEvent, waitFor } from "@testing-library/react";
import React from "react";

import { downloadMultiple } from "../api";
import { renderWithMockStore } from "../data/testHelpers";
import { BulkActionDownload } from "./BulkActionDownload";
import { downloadImages } from "./zipDownload";

jest.mock("./zipDownload", () => ({ downloadImages: jest.fn() }));
jest.mock("../api", () => ({ downloadMultiple: jest.fn() }));

test("<BulkActionDownload />", async () => {
  // Given
  const initialState = {
    property: { id: 123 },
    files: {
      selection: {
        ids: [1, 2]
      }
    }
  };

  const images = [{ id: 1 }, { id: 2 }];

  downloadMultiple.mockImplementation(() => Promise.resolve(images));

  // When
  const { getByTestId } = renderWithMockStore(
    <BulkActionDownload propertyId={123} />,
    { initialState }
  );

  // Then
  expect(getByTestId("bulk-download")).toBeInTheDocument();

  // When
  await act(async () => {
    fireEvent.click(getByTestId("bulk-download"));
  });

  await waitFor(() =>
    expect(downloadMultiple).toHaveBeenCalledWith({
      propertyId: 123,
      ids: [1, 2]
    })
  );

  await waitFor(() => expect(downloadImages).toHaveBeenCalledWith(images));
});
