/* eslint-disable func-names */
import { Form, Formik, ErrorMessage } from "formik";
import { omitBy, isNil } from "lodash";
import React, { useCallback } from "react";
import * as yup from "yup";

import { useApiClient } from "../../../common/apiClient";
import { FormGroup } from "../../../common/forms/FormGroup";
import { Modal } from "../../../common/Modal";

const validationSchema = yup.object().shape({
  pause_notes: yup
    .string()
    .when("pause_payouts", (value, schema) =>
      value
        ? schema.required("Notes are required when pausing payouts")
        : schema.nullable()
    )
});

export const EditBillingAccountForm = ({
  onClose,
  resourceId,
  resourceType,
  billingAccount
}) => {
  const apiClient = useApiClient();

  const handleSubmit = useCallback(
    async (values, { setSubmitting }) => {
      setSubmitting(true);

      try {
        await apiClient.put(
          `/api/${resourceType}/billing_account/${resourceId}`,
          {
            billing_account: { ...values }
          }
        );

        window.location.reload();
      } finally {
        setSubmitting(false);
      }
    },
    [apiClient, resourceId, resourceType]
  );

  const intialValues = {
    pause_payouts: false,
    pause_notes: ""
  };

  return (
    <Formik
      initialValues={{
        ...intialValues,
        ...omitBy(billingAccount, isNil) // drops key/value if value is null/undefined
      }}
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
    >
      {({ isSubmitting, values, isValid }) => (
        <Form>
          <Modal onClose={onClose}>
            <Modal.Header>Edit Billing Account</Modal.Header>
            <Modal.Body>
              <FormGroup
                label="Pause payouts"
                name="pause_payouts"
                type="checkbox"
                checked={values.pause_payouts}
                className="pause-payouts-form-check"
              />

              <FormGroup
                label="Notes"
                name="pause_notes"
                component="textarea"
              />
              <ErrorMessage name="pause_notes">
                {(message) => (
                  <div className="balance-buffer-error">{message}</div>
                )}
              </ErrorMessage>
            </Modal.Body>

            <Modal.Footer>
              <button
                type="button"
                className="btn btn-light"
                onClick={onClose}
                disabled={isSubmitting}
              >
                Cancel
              </button>

              <button
                type="submit"
                className="btn btn-submit"
                disabled={isSubmitting || !isValid}
              >
                Update
              </button>
            </Modal.Footer>
          </Modal>
        </Form>
      )}
    </Formik>
  );
};

EditBillingAccountForm.defaultProps = { window };
