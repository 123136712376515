import "./CleanImagesUpload.scss";

import { ErrorMessage, Form, Formik } from "formik";
import React from "react";
import * as yup from "yup";

import { FormGroup } from "../../../common/forms/FormGroup";
import { modal } from "../../../common/modals";
import { SelectOptions } from "../../../common/SelectOptions";

const { AVAILABLE_TYPES } = window.AirbaseConstants.CleanImage;
const GENERAL_TYPES = AVAILABLE_TYPES.filter((t) => !t.startsWith("location_"));
const PRESENTATION_TYPES = AVAILABLE_TYPES.filter((t) =>
  t.startsWith("location_")
);

const validationSchema = yup.object().shape({
  type: yup.string().required(),
  receipt_amount: yup.string().when("type", (value, schema) =>
    value === "receipt"
      ? schema
          .required("Receipt amount is required")
          .matches(/^\d+(\.\d{1,2})?$/, {
            message: "Receipt amount is invalid"
          })
      : schema
  )
});

export const confirmCleanType = () => {
  return new Promise((resolve) => {
    const handleSubmit = (values) => {
      resolve({ confirmed: true, values });
      modal.close();
    };

    const handleCancel = () => modal.clickCancel();

    modal.fire({
      html: (
        <Formik
          initialValues={{ type: "other" }}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({ values }) => (
            <>
              <h3>Please select clean image type for uploaded files</h3>

              <Form>
                <FormGroup label="Type" name="type" component="select">
                  <optgroup label="General Image">
                    <SelectOptions options={GENERAL_TYPES} />
                  </optgroup>

                  <optgroup label="Presentation Image">
                    <SelectOptions
                      includeBlank={false}
                      options={PRESENTATION_TYPES}
                    />
                  </optgroup>
                </FormGroup>
                <ErrorMessage name="type">
                  {(message) => <div className="error">{message}</div>}
                </ErrorMessage>

                {values.type === "receipt" && (
                  <FormGroup label="Receipt amount" name="receipt_amount" />
                )}

                <div className="CleanTypeModal__actions">
                  <button
                    type="button"
                    className="btn btn-light"
                    onClick={handleCancel}
                  >
                    Cancel
                  </button>

                  <button type="submit" className="btn btn-submit">
                    Upload
                  </button>
                </div>
              </Form>
            </>
          )}
        </Formik>
      ),
      animation: false,
      showConfirmButton: false,
      showCancelButton: false,
      customClass: { content: "twbs CleanTypeModal" },
      width: 800
    });
  });
};
