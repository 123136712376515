import get from "lodash/get";
import { handleActions } from "redux-actions";

import { isUnprocessableEntityError } from "../../../../../common/errors";
import {
  clearUploadQueue,
  uploadFileNotifyProgress,
  uploadFileRequest,
  uploadFileResponse,
  uploadFilesRequest
} from "./actions";
import {
  FILE_STATUS_ERROR,
  FILE_STATUS_PENDING,
  FILE_STATUS_QUEUED,
  FILE_STATUS_SUCCESS
} from "./constants";

const setFileInQueueAttrs = (queue, fileName, attrs) => ({
  ...queue,
  [fileName]: {
    ...queue[fileName],
    ...attrs
  }
});

export const reducer = handleActions(
  {
    [uploadFilesRequest]: (state, { payload: files }) =>
      files.reduce(
        (queue, fileItem) =>
          setFileInQueueAttrs(queue, fileItem.name, {
            fileItem,
            status: FILE_STATUS_QUEUED,
            uploadedBytes: 0
          }),
        {}
      ),

    [uploadFileRequest]: (state, { meta: { fileName } }) =>
      setFileInQueueAttrs(state, fileName, { status: FILE_STATUS_PENDING }),

    [uploadFileResponse]: {
      next: (state, { meta: { fileName } }) =>
        setFileInQueueAttrs(state, fileName, { status: FILE_STATUS_SUCCESS }),

      throw: (state, { payload: error, meta: { fileName } }) =>
        setFileInQueueAttrs(state, fileName, {
          status: FILE_STATUS_ERROR,
          errorMessage: isUnprocessableEntityError(error)
            ? get(error, "response.data.errors", []).join(", ")
            : error.message
        })
    },

    [uploadFileNotifyProgress]: (
      state,
      { payload: uploadedBytes, meta: { fileName } }
    ) => setFileInQueueAttrs(state, fileName, { uploadedBytes }),

    [clearUploadQueue]: () => ({})
  },
  {}
);
