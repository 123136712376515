import React, { useState } from "react";

import { apiClient } from "../../../common/apiClient";
import { SwalActions, modal } from "../../../common/modals";
import warningImage from "../../../images/warning.svg";

export const ClaimNow = ({ invoiceId, blocked }) => {
  const [loading, setLoading] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [error, setError] = useState(false);

  const confirmationPopUp = () =>
    new Promise((res) => {
      const onConfirm = () => {
        res({ confirmed: true });
        modal.close();
      };

      modal.fire({
        html: (
          <>
            <p>
              Are you sure you want to start automatic collection for this
              invoice?
            </p>
            <SwalActions
              confirmText="Yes"
              onCancel={modal.close}
              onConfirm={onConfirm}
            />
          </>
        ),
        title: "Please confirm",
        showConfirmButton: false,
        imageUrl: warningImage,
        showCancelButton: false,
        animation: false
      });
    });

  const onClick = async () => {
    const { confirmed } = await confirmationPopUp();

    if (confirmed) {
      setLoading(true);
      setDisabled(true);
      apiClient
        .patch(`/api/invoices/${invoiceId}`, {
          invoice: {
            state_event: "mark_automatic_collection"
          }
        })
        .then(() => {
          window.location.reload();
        })
        .catch(() => {
          setDisabled(false);
          setError(true);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  return (
    <div className="twbs">
      <button
        type="button"
        className="btn btn-primary mb-3 ml-1"
        onClick={onClick}
        disabled={blocked || disabled}
        title={blocked ? "Automatic collection in progress" : undefined}
      >
        Claim now
        {loading && <i className="fas fa-spinner fa-spin" />}
      </button>
      {error && (
        <p className="error">An error occured, please try again later</p>
      )}
    </div>
  );
};
