import { render } from "@testing-library/react";
import { Form, Formik } from "formik";
import React from "react";

import { AvailableSelect } from "./AvailableSelect";

test("<AvailableSelect />", () => {
  const { container } = render(
    <Formik>
      {() => (
        <Form>
          <AvailableSelect name="paring_available" label="Parking available" />
        </Form>
      )}
    </Formik>
  );

  expect(container).toMatchInlineSnapshot(`
    <div>
      <form
        action="#"
      >
        <div
          class="field-group"
        >
          <div
            class="form-group"
            id="anchor-paring_available"
          >
            <div
              class="onboarding-form-label"
            >
              <label
                for="paring_available"
              >
                Parking available
              </label>
            </div>
            <div
              class="input-group"
            >
              <select
                autocomplete="off"
                class="form-control"
                id="paring_available"
                name="paring_available"
              >
                <option
                  value=""
                >
                  I don't know
                </option>
                <option
                  value="true"
                >
                  Yes
                </option>
                <option
                  value="false"
                >
                  No
                </option>
              </select>
            </div>
          </div>
        </div>
      </form>
    </div>
  `);
});
