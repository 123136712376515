import React, { useEffect, useRef } from "react";
import {
  GoogleMap,
  Marker,
  withGoogleMap,
  withScriptjs
} from "react-google-maps";

const MapComponent = withScriptjs(
  withGoogleMap(({ location, setLocation }) => {
    const ref = useRef();

    useEffect(() => {
      ref.current.panTo(location);
    }, [location]);

    return (
      <GoogleMap
        ref={ref}
        defaultZoom={16}
        defaultCenter={location}
        options={{
          fullscreenControl: true
        }}
      >
        <Marker
          draggable
          position={location}
          onDragEnd={(event) =>
            setLocation({
              lat: event.latLng.lat(),
              lng: event.latLng.lng()
            })
          }
        />
      </GoogleMap>
    );
  })
);

export const LocationPickerMap = ({ location, setLocation }) => (
  <MapComponent
    googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${window.AirbaseConstants.GOOGLE_MAPS_API_KEY}&v=3.exp`}
    loadingElement={<div style={{ height: `100%` }}>Loading map...</div>}
    containerElement={
      <div style={{ height: `400px` }} test-id="map-container" />
    }
    mapElement={<div style={{ height: `100%` }} />}
    location={location}
    setLocation={setLocation}
  />
);
