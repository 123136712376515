import "./CleansTable.scss";

import React, { useMemo } from "react";
import ReactTable from "react-table";

import { formatDate, formatTime } from "../../../common/date";
import { formatCleanDuration } from "../../../common/formatCleanDuration";
import { formatCleanRating } from "../../../common/formatCleanRating";
import { humanize } from "../../../common/stringUtils";
import { EditButton, ShowButton } from "../../../common/tables/buttons";
import { useControlledTable } from "../../../common/tables/useControlledTable";
import { CleanStatusFilter } from "./CleanStatusFilter";
import { CleanTypeFilter } from "./CleanTypeFilter";

const {
  STATUS_COMPLETED,
  STATUS_CONFIRMED,
  STATUS_PAID,
  STATUS_REQUESTED,
  STATUS_STARTED
} = window.AirbaseConstants.Clean;

const STATUS_COLOR_MAP = {
  requested: "light_grey",
  confirmed: "grey",
  started: "orange",
  completed: "green",
  paid: "blue",
  declined: "red",
  cancelled: "red",
  expired: "dark_gray"
};

const UPCOMING_STATUSES = [STATUS_REQUESTED, STATUS_CONFIRMED, STATUS_STARTED];

const DEFAULT_STATUSES = [
  STATUS_CONFIRMED,
  STATUS_STARTED,
  STATUS_COMPLETED,
  STATUS_PAID
];

export const CleansTable = ({
  hidden_columns = [],
  cleaner_id,
  property_id,
  scope
}) => {
  const initialState = useMemo(
    () => ({
      sorted: [{ id: "start", desc: true }],
      filtered: [
        {
          id: "status",
          value: scope === "upcoming" ? UPCOMING_STATUSES : DEFAULT_STATUSES
        }
      ]
    }),
    [scope]
  );

  const api = useMemo(
    () => ({
      endpoint: "/api/cleans",
      params: { cleaner_id, property_id }
    }),
    [cleaner_id, property_id]
  );

  const columns = useMemo(
    () => [
      {
        accessor: "id",
        maxWidth: 70,
        className: "rt-actions",
        Cell: ({ original: { show_path, edit_path } }) => (
          <span>
            <ShowButton href={show_path} />
            <EditButton href={edit_path} />
          </span>
        )
      },
      {
        Header: "Start",
        accessor: "start",
        maxWidth: 120,
        sortable: true,
        defaultSortDesc: true,
        className: "rt-cell-with-two-rows",
        Cell: ({ value, original: { show_path } }) => (
          <a href={show_path}>
            <div className="primary">{formatDate(new Date(value))}</div>
            <div className="secondary">{formatTime(new Date(value))}</div>
          </a>
        )
      },
      {
        Header: "Duration",
        accessor: "duration",
        maxWidth: 90,
        sortable: true,
        Cell: ({ value }) => formatCleanDuration(value)
      },
      {
        Header: "Status",
        accessor: "status",
        maxWidth: 100,
        className: "rt-clean-status-cell",
        getProps: (state, rowInfo) => ({
          className: STATUS_COLOR_MAP[rowInfo && rowInfo.row.status]
        })
      },
      {
        Header: "Home code",
        accessor: "property.home_code",
        maxWidth: 100,
        className: "rt-bold-link",
        show: !hidden_columns.includes("property.home_code"),
        Cell: ({ value, original: { show_property_path } }) => (
          <a href={show_property_path}>{value}</a>
        )
      },
      {
        Header: "Postcode",
        accessor: "property.postcode",
        maxWidth: 100,
        show: !hidden_columns.includes("property.postcode")
      },
      {
        Header: "Cleaner",
        accessor: "cleaner.name",
        className: "rt-cell-with-two-rows",
        Cell: ({ value, original: { show_cleaner_path } }) => (
          <a href={show_cleaner_path}>
            <span className="primary">{value}</span>
          </a>
        )
      },
      {
        Header: "Type",
        accessor: "clean_type",
        maxWidth: 100,
        Cell: ({ value }) => humanize(value)
      },
      {
        Header: "Rating",
        accessor: "rating",
        maxWidth: 80,
        sortable: true,
        Cell: ({ value }) => formatCleanRating(value)
      }
    ],
    [hidden_columns]
  );

  const { getFilter, onFilterChange, ...controlledProps } = useControlledTable({
    api,
    initialState,
    queryParamsPrefix: scope
  });

  const { loading, totalCount, showPagination } = controlledProps;

  return (
    <div>
      <div className="clean-filters">
        <CleanTypeFilter
          filter={getFilter("clean_type")}
          onFilterChange={onFilterChange}
          scope={scope}
        />
        <CleanStatusFilter
          filter={getFilter("status")}
          onFilterChange={onFilterChange}
          scope={scope}
        />
      </div>

      {!loading && totalCount === 0 && (
        <span>There are no cleans matching these criteria</span>
      )}

      {totalCount > 0 && (
        <div>
          <ReactTable
            {...controlledProps}
            columns={columns}
            className="-striped -highlight"
            sortable={false}
          />

          {showPagination && (
            <h4>Total number of cleans {controlledProps.totalCount}</h4>
          )}
        </div>
      )}
    </div>
  );
};
