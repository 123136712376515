import { render, waitFor } from "@testing-library/react";
import React from "react";

import { CleanerStatus } from "./CleanerStatus";

test("<CleanerStatus />", async () => {
  // Given
  const cleaner = {
    id: 1234,
    active: false,
    updated_at: new Date(2019, 2, 8, 15, 15)
  };

  const fetchCleaner = jest.fn(() => Promise.resolve(cleaner));

  // When
  const { queryByText } = render(
    <CleanerStatus cleaner_id={cleaner.id} fetchCleaner={fetchCleaner} />
  );

  // Then
  expect(fetchCleaner).toHaveBeenCalledWith(cleaner.id);
  expect(queryByText("Loading...")).toBeInTheDocument();

  // When
  await waitFor(() =>
    expect(queryByText("Loading...")).not.toBeInTheDocument()
  );

  // Then
  expect(queryByText("Activate cleaner")).toBeInTheDocument();
  expect(queryByText("INACTIVE")).toBeInTheDocument();
  expect(queryByText("8th Mar 2019 - 3:15PM")).toBeInTheDocument();
});
