import React from "react";

import { Modal, MODAL_SIZE_LG } from "../../../common/Modal";
import "./BookingTransactionalMessagesModal.scss";

export const BookingTransactionalMessagesModalDetails = ({
  message,
  onClose
}) => {
  const formatJSON = (header, data) => {
    if (!data) {
      return "";
    }

    return (
      <>
        <h6>{header}:</h6>
        <pre>
          <code>{JSON.stringify(data, null, 2)}</code>
        </pre>
      </>
    );
  };

  return (
    <Modal onClose={onClose} size={MODAL_SIZE_LG}>
      <Modal.Header>Message details</Modal.Header>
      <Modal.Body>
        <div className="BookingMessagesModal_container">
          {formatJSON("Payload", message.payload)}
          {formatJSON("Response", message.response)}
          {formatJSON("Recipients", message.recipients)}
          {formatJSON("CC Recipients", message.cc_recipients)}
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button type="button" className="btn btn-submit" onClick={onClose}>
          Close
        </button>
      </Modal.Footer>
    </Modal>
  );
};

BookingTransactionalMessagesModalDetails.defaultProps = { window };
