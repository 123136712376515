import React from "react";

export const DropdownFilter = ({ id, onChange, filter, values }) => (
  <select
    id={id}
    defaultValue={filter ? filter.value : ""}
    onChange={(event) => onChange(event.target.value)}
    className="form-control"
  >
    <option value="">All</option>
    {values.map(([label, value]) => (
      <option key={value} value={value}>
        {label}
      </option>
    ))}
  </select>
);
