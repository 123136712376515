import * as yup from "yup";

// from https://regex101.com/r/Y1UQsG/1
const regexpEmojis = new RegExp(
  /((?![\u{23}-\u1F6F3]([^\u{FE0F}]|$))\p{Emoji}(?:(?!\u{200D})\p{EComp}|(?=\u{200D})\u{200D}\p{Emoji})*)/,
  "gmu"
);

const regexpLinks = new RegExp(".*(https?|www.|ftp|://)", "im");

// Match sequences of digits that total more than five, where the digits can be separated by spaces or hyphens
const regexpPhoneNumbers = new RegExp(/(?:(?:\d[\s-]?){9,})/, "g");

export const stringWithoutSpecialChars = yup
  .string()
  .test(
    "no-emoji",
    "Emojis and special characters aren't allowed",
    (value) => !regexpEmojis.test(value)
  )
  .test(
    "no-phone-numbers",
    "Phone numbers aren't allowed",
    (value) => !regexpPhoneNumbers.test(value)
  )
  .test(
    "no-links",
    "Links aren't allowed",
    (value) => !regexpLinks.test(value)
  );
