import { createAction } from "redux-actions";

export const changeCurrentFolder = createAction("FILES/CHANGE_CURRENT_FOLDER");

export const fetchFilesRequest = createAction("FILES/FETCH_REQUEST");

export const fetchFilesResponse = createAction("FILES/FETCH_RESPONSE");

export const updateFileResponse = createAction("FILE/UPDATE_RESPONSE");

export const deleteFileResponse = createAction("FILE/DELETE_RESPONSE");
export const deleteFilesResponse = createAction("FILES/DELETE_RESPONSE");

export const changeFilesSortingField = createAction(
  "FILES/CHANGE_SORTING_FIELD"
);

export const toggleFilesSortingDirection = createAction(
  "FILES/TOGGLE_SORTING_DIRECTION",
  () => {}
);

export const batchOperationResponse = createAction(
  "FILES/BATCH_OPERATION_RESPONSE"
);

export const toggleFileSelection = createAction("FILES/TOGGLE_FILE_SELECTION");

export const setFilesSelection = createAction("FILES/SET_FILES_SELECTION");
