import { Formik } from "formik";
import React, { useContext } from "react";
import * as yup from "yup";

import { stringWithoutSpecialChars } from "../../../../common/forms/validationSchemas";
import { SelectOptions } from "../../../../common/SelectOptions";
import { humanize } from "../../../../common/stringUtils";
import { AvailableSelect } from "../components/AvailableSelect";
import { FieldsetWithAvailabilitySelect } from "../components/FieldsetWithAvailabilitySelect";
import { FormGroup } from "../components/FormGroup";
import { LocationFields } from "../components/LocationFields";
import { UnlockButton } from "../components/UnlockButton/UnlockButton";
import { HomeInfoContext } from "../HomeInfoContext";
import { HomeInfoForm } from "../HomeInfoForm";

const ACCESS_TYPES = window.AirbaseConstants.HomeInfoLocation;
const { PARKING_TYPES } = window.AirbaseConstants.HomeInfo;

const {
  ACCESS_TYPE_KEYLESS_DIGITAL_LOCK,
  ACCESS_TYPE_KEYLESS_SMART_LOCK,
  ACCESS_TYPE_KEYLESS_SMART_LOCK_INTERCOM,
  ACCESS_TYPE_RAILING_LOCKBOX,
  ACCESS_TYPE_WALL_MOUNTED_LOCKBOX
} = ACCESS_TYPES;

const hasLockbox = (value) =>
  [ACCESS_TYPE_WALL_MOUNTED_LOCKBOX, ACCESS_TYPE_RAILING_LOCKBOX].includes(
    value
  );

const PARKING_NOTES_CHAR_LIMIT = 300;
const parkingNotesCharLimit = (parking_available) =>
  parking_available === "true" || parking_available === true
    ? null
    : PARKING_NOTES_CHAR_LIMIT;

const hasDigitalLock = (value) =>
  [
    ACCESS_TYPE_KEYLESS_DIGITAL_LOCK,
    ACCESS_TYPE_KEYLESS_SMART_LOCK,
    ACCESS_TYPE_KEYLESS_SMART_LOCK_INTERCOM
  ].includes(value);

export const validationSchema = yup.object().shape({
  home_info_location_attributes: yup.object().shape({
    access_type: yup.string().required("Please select access type"),

    lockbox_code: yup
      .string()
      .when("access_type", (value, schema) =>
        hasLockbox(value)
          ? schema.required("Please enter lockbox code")
          : schema
      ),

    digital_lock_code: yup
      .string()
      .when("access_type", (value, schema) =>
        hasDigitalLock(value)
          ? schema.required("Please enter digital lock code")
          : schema
      )
  }),
  parking_notes: stringWithoutSpecialChars
    .nullable()
    .label("Parking notes")
    .when("parking_available", (parking_available, schema) =>
      parking_available === "true" || parking_available === true
        ? schema
        : schema.max(PARKING_NOTES_CHAR_LIMIT)
    ),
  stairs_notes: yup.string().nullable().max(300).label("Stairs notes")
});

const AccessibilityInstructions = () => (
  <ul>
    <li>Wheelchair accessible entry?</li>
    <li>Low countertops?</li>
    <li>Wide property doors?</li>
    <li>Accessible bathroom? e.g. wide walk-in shower</li>
  </ul>
);

export const PropertyAccess = () => {
  const { homeInfo, updateHomeInfo } = useContext(HomeInfoContext);

  return (
    <Formik
      initialValues={homeInfo}
      enableReinitialize
      validationSchema={validationSchema}
      isInitialValid={
        validationSchema ? validationSchema.isValidSync(homeInfo) : true
      }
      onSubmit={updateHomeInfo}
    >
      {({ values }) => (
        <HomeInfoForm sidebarName="Property">
          <h4 className="section-name">Property Access</h4>
          <FormGroup
            name="home_info_location_attributes.access_type"
            label="Access type"
            component="select"
            visibleToGuests
            airbnbSync
            visibleToCleaners
          >
            <SelectOptions options={Object.values(ACCESS_TYPES)} />
          </FormGroup>
          {hasDigitalLock(values.home_info_location_attributes.access_type) && (
            <FormGroup
              name="home_info_location_attributes.digital_lock_code"
              label="Digital lock code"
              visibleToGuests
              visibleToCleaners
              renderAppend={() => <i className="fas fa-lock" />}
            />
          )}

          {values.home_info_location_attributes.access_type ===
            ACCESS_TYPE_KEYLESS_SMART_LOCK_INTERCOM && <UnlockButton />}

          <FormGroup
            name="home_info_location_attributes.access_type_notes"
            label="Access type notes"
            component="textarea"
            visibleToGuests
            visibleToCleaners
            rows={5}
          />
          {hasLockbox(values.home_info_location_attributes.access_type) && (
            <>
              <h4 className="section-name">
                {humanize(values.home_info_location_attributes.access_type)}{" "}
                location
              </h4>

              <LocationFields
                name={{
                  lat: "home_info_location_attributes.lockbox_lat",
                  lng: "home_info_location_attributes.lockbox_lng"
                }}
              />

              <FormGroup
                name="home_info_location_attributes.lockbox_code"
                label={`${humanize(
                  values.home_info_location_attributes.access_type
                )} code`}
                visibleToGuests
                visibleToCleaners
                renderAppend={() => <i className="fas fa-lock" />}
              />
            </>
          )}
          <FormGroup
            name="home_info_location_attributes.access_map"
            label="Link to Access Guide"
            placeholder="Paste link to any visual access guide"
            visibleToGuests
            visibleToCleaners
          />
          <FieldsetWithAvailabilitySelect
            name="parking_available"
            label="Parking"
            airbnbSync
          >
            <FormGroup
              name="parking_type"
              label="Parking type"
              component="select"
              visibleToGuests
            >
              <SelectOptions options={Object.values(PARKING_TYPES)} />
            </FormGroup>
            <FormGroup
              name="parking_notes"
              label="Parking notes"
              component="textarea"
              rows={3}
              visibleToGuests
              characterCount={parkingNotesCharLimit(values.parking_available)}
              airbnbSync
              description="In sync with Airbnb listing only when there is no parking available"
            />
          </FieldsetWithAvailabilitySelect>
          <FieldsetWithAvailabilitySelect
            name="ev_charger_available"
            label="EV charger"
            description="Guests can charge their electric vehicles on the property"
          >
            <FormGroup
              name="ev_charger_notes"
              label="EV charger notes"
              component="textarea"
              rows={3}
              visibleToGuests
            />
          </FieldsetWithAvailabilitySelect>
          <FieldsetWithAvailabilitySelect name="alarm_available" label="Alarm">
            <FormGroup
              name="alarm_notes"
              label="Alarm notes"
              component="textarea"
              rows={3}
              visibleToGuests
              visibleToCleaners
            />

            <FormGroup
              name="alarm_code"
              label="Alarm code"
              visibleToGuests
              visibleToCleaners
            />
          </FieldsetWithAvailabilitySelect>
          <AvailableSelect
            name="lift_available"
            label="Lift available"
            description="The home or building has a lift that’s at least 52 inches (132cm) deep and a doorway at least 32 inches (81cm) wide."
          />
          <FormGroup
            name="stairs_notes"
            label="Stairs notes"
            component="textarea"
            rows={3}
            visibleToGuests
            characterCount={300}
          />
          <FormGroup
            name="home_info_location_attributes.accessibility_notes"
            label="Accessibility notes"
            component="textarea"
            rows={3}
            instructions={<AccessibilityInstructions />}
          />
        </HomeInfoForm>
      )}
    </Formik>
  );
};
