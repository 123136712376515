import React from "react";

export const Active = () => {
  return (
    <div>
      <dt>Status:</dt>
      <dd className="status active">ACTIVE</dd>
    </div>
  );
};
