import React, { useMemo } from "react";
import ReactTable from "react-table";

import { apiClient } from "../../../common/apiClient";
import { formatDate } from "../../../common/date";
import { humanize } from "../../../common/stringUtils";
import { Switch, Case } from "../../../common/Switch";
import { dateColumn } from "../../../common/tables/dateColumn";
import {
  GenericSearchFilter,
  LocalityFilter
} from "../../../common/tables/filters";
import { useControlledTable } from "../../../common/tables/useControlledTable";
import { Filters } from "./Filters";
import { ApprovalStatusFilter } from "./Filters/ApprovalStatusFilter";
import { CancelledFilter } from "./Filters/CancelledFilter";
import { VerifyFilter } from "./Filters/VerifyFilter";

const fetchBookingChannels = () =>
  apiClient.get("/api/booking_channels").then((response) => response.data.rows);

const STATUS_COLOR_MAP = {
  true: "green",
  false: "red",
  null: "orange"
};

const initialState = {
  pageSize: 20,
  sorted: [{ id: "checkin", desc: false }],
  filtered: [
    {
      id: "channel",
      values: fetchBookingChannels().all
    },
    {
      id: "deleted_at",
      value: false
    }
  ]
};

export const BookingsTable = () => {
  const {
    getFilter,
    getFilterValue,
    onFilterChange,
    ...controlledProps
  } = useControlledTable({
    api: { endpoint: "/api/bookings" },
    initialState
  });

  const columns = useMemo(
    () => [
      {
        Header: "Check-in",
        accessor: "checkin",
        sortable: true,
        className: "rt-cell-with-two-rows",
        maxWidth: 180,
        Cell: ({ original: { checkin, show_path } }) => (
          <a href={show_path} title="Show booking">
            {formatDate(new Date(checkin))}
          </a>
        )
      },
      {
        Header: "Check-out",
        accessor: "checkout",
        sortable: true,
        className: "rt-cell-with-two-rows",
        maxWidth: 180,
        Cell: ({ original: { checkout, show_path } }) => (
          <a href={show_path} title="Show booking">
            {formatDate(new Date(checkout))}
          </a>
        )
      },
      {
        Header: "Property",
        id: "property.home_code",
        accessor: "property.home_code",
        sortable: false,
        className: "rt-cell-with-two-rows",
        maxWidth: 180,
        Cell: ({ original: { property, show_property_path } }) => {
          return (
            <a href={show_property_path}>
              <span>{property.home_code}</span>
            </a>
          );
        }
      },
      {
        Header: "Locality",
        id: "property.locality.id",
        accessor: "property.locality.name",
        filterable: true,
        sortable: false,
        maxWidth: 190,
        Filter: LocalityFilter,
        Cell: ({ original: { property } }) => {
          const { locality } = property;
          return (
            <span>
              {locality.name} ({locality.country_code})
            </span>
          );
        }
      },
      {
        Header: "Guest Name",
        accessor: "guest_name",
        filterable: true,
        className: "rt-cell-with-two-rows",
        Cell: ({ original: { guest_name, show_path } }) => (
          <a href={show_path}>{guest_name}</a>
        ),
        Filter: ({ onChange, filter }) => (
          <GenericSearchFilter
            onChange={onChange}
            filter={filter}
            placeholder="Search by name"
          />
        )
      },
      {
        Header: "Pod Name",
        accessor: "pod_name",
        filterable: true,
        className: "rt-cell-with-two-rows",
        Cell: ({ original: { pod_name, show_path } }) => (
          <a href={show_path}>{pod_name}</a>
        ),
        Filter: ({ onChange, filter }) => (
          <GenericSearchFilter
            onChange={onChange}
            filter={filter}
            placeholder="POD name"
          />
        )
      },
      dateColumn({
        Header: "Booked On",
        accessor: "booked_at",
        id: "booked_at",
        sortable: true
      }),
      {
        Header: "Cancelled",
        accessor: "deleted_at",
        sortable: false,
        filterable: true,
        getProps: (state, rowInfo) => ({
          className: rowInfo.row.deleted_at && "red"
        }),
        Cell: ({ value }) => (
          <span>{value && formatDate(new Date(value))}</span>
        ),
        Filter: CancelledFilter,
        maxWidth: 150
      },
      {
        Header: "Channel",
        id: "booking_channels.name",
        accessor: "booking_channel.name",
        sortable: true,
        Cell: ({ value }) => humanize(value),
        maxWidth: 130
      },
      {
        Header: "Verification",
        accessor: "verified",
        sortable: true,
        filterable: true,
        getProps: (state, rowInfo) => ({
          className: STATUS_COLOR_MAP[rowInfo && rowInfo.row.verified]
        }),
        Filter: VerifyFilter,
        Cell: ({ original: { verified } }) => (
          <span>
            <Switch test={verified}>
              <Case value>Verified</Case>
              <Case value={false}>Unverified</Case>
              <Case default>Pending</Case>
            </Switch>
          </span>
        ),
        maxWidth: 130
      },
      {
        Header: "Approval",
        accessor: "approved",
        sortable: false,
        filterable: true,
        maxWidth: 130,
        getProps: (state, rowInfo) => ({
          className: STATUS_COLOR_MAP[rowInfo && rowInfo.row.approved]
        }),
        Filter: ApprovalStatusFilter,
        Cell: ({ original: { approved } }) => (
          <span>
            <Switch test={approved}>
              <Case value>Approved</Case>
              <Case value={false}>Rejected</Case>
              <Case default>Pending</Case>
            </Switch>
          </span>
        )
      }
    ],
    []
  );

  return (
    <div style={{ marginTop: 20 }}>
      <Filters
        getFilter={getFilter}
        getFilterValue={getFilterValue}
        onFilterChange={onFilterChange}
      />
      <ReactTable
        {...controlledProps}
        columns={columns}
        className="-striped -highlight"
        sortable={false}
      />
    </div>
  );
};
