import classNames from "classnames";
import React, { useState } from "react";

import { Explanation } from "../../../common/Explanation";
import { SelectOptions } from "../../../common/SelectOptions";

export const SpecialistSelection = ({
  value,
  hasError = false,
  explanationVisible = true,
  toggleLinkVisible = true,
  onChange = () => false
}) => {
  const { SPECIALISTS } = window.AirbaseConstants.Maintenance;
  const [selectedSpecialist, setSelectedSpecialist] = useState(value);

  const handleChange = ({ target: { value: selectedValue } }) => {
    setSelectedSpecialist(selectedValue);
    onChange(selectedValue);
  };

  return (
    <div className="SpecialistSelection">
      {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
      <label htmlFor="specialist_select">Specialist required</label>
      <select
        className={classNames("form-control", { "is-invalid": hasError })}
        name="maintenance[specialist_needed]"
        id="specialist_select"
        value={selectedSpecialist || ""}
        onChange={handleChange}
      >
        <SelectOptions includeBlank options={Object.keys(SPECIALISTS)} />
      </select>

      <Explanation
        toggleLinkVisible={toggleLinkVisible}
        visible={explanationVisible}
        text={SPECIALISTS[selectedSpecialist]}
      />
    </div>
  );
};
