import "./DeactivationForm.scss";

import { ErrorMessage, Field, Form, Formik } from "formik";
import React from "react";
import * as yup from "yup";

import { formatDateTime } from "../../../common/date";
import { FormGroup } from "../../../common/forms/FormGroup";
import { useAsync } from "../../../common/hooks/useAsync";
import { SwalActions } from "../../../common/modals";
import { fetchUpcomingNonStandardCleans } from "../api";

const CleanSlim = ({ clean }) => (
  <a
    href={clean.show_path}
    target="_blank"
    rel="noopener noreferrer"
    className="clean"
  >
    <div className="date">{formatDateTime(new Date(clean.start))}</div>
    <div className="home_code">{clean.property.home_code}</div>
    <div className="postcode">{clean.clean_type}</div>
    <div className="status">{clean.status}</div>
  </a>
);

const validationSchema = yup.object().shape({
  deactivation_reason: yup.string().required("Deactivation reason is required"),
  notes: yup.string().required("Please provide a comment")
});

const { DEACTIVATION_REASONS } = window.AirbaseConstants.Cleaner;

export const DeactivationForm = ({
  cleaner,
  onSubmit,
  onCancel,
  fetchUpcomingNonStandardCleans
}) => {
  const { loading: loadingUpcomingCleans, value } = useAsync(
    fetchUpcomingNonStandardCleans,
    cleaner.id
  );
  const upcomingCleans = loadingUpcomingCleans ? [] : value.rows;

  const initialValues = {
    deactivation_reason: "",
    notes: "",
    would_reactivate: false
  };

  return (
    <div>
      {upcomingCleans.length > 0 ? (
        <>
          <span className="header">
            The cleaner has some non-standard future cleans:
          </span>

          <div className="upcoming-cleans">
            {upcomingCleans.map((clean) => (
              <CleanSlim key={clean.id} clean={clean} />
            ))}

            <span>Deactivation will cancel all these clans.</span>
          </div>
        </>
      ) : (
        <span className="header">Deactivating cleaner {cleaner.name}</span>
      )}

      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {({ isSubmitting, handleSubmit, values }) => (
          <Form className="ops_form">
            <div>
              <label htmlFor="deactivation_reason_field">
                Deactivation reason
              </label>
              <Field
                id="deactivation_reason_field"
                name="deactivation_reason"
                component="select"
              >
                <option value="" />
                {Object.entries(DEACTIVATION_REASONS).map(([value, label]) => (
                  <option key={value} value={value}>
                    {label}
                  </option>
                ))}
              </Field>
              <ErrorMessage name="deactivation_reason">
                {(message) => <div className="error">{message}</div>}
              </ErrorMessage>
            </div>

            <div>
              <label htmlFor="notes_field">Deactivation comments</label>
              <Field id="notes_field" name="notes" component="textarea" />
              <ErrorMessage name="notes">
                {(message) => <div className="error">{message}</div>}
              </ErrorMessage>
            </div>

            <div>
              <FormGroup
                label="Would reactivate"
                name="would_reactivate"
                type="checkbox"
                checked={values.would_reactivate}
              />
            </div>

            <SwalActions
              disabled={isSubmitting}
              onCancel={onCancel}
              confirmText={!isSubmitting ? "Deactivate" : "Deactivating..."}
              onConfirm={handleSubmit}
            />
          </Form>
        )}
      </Formik>
    </div>
  );
};

DeactivationForm.defaultProps = { fetchUpcomingNonStandardCleans };
