import React from "react";

import { apiClient } from "../../../common/apiClient";
import {
  SwalActions,
  modal,
  showErrorToast,
  showSuccessToast
} from "../../../common/modals";

export const cancelCleanDemand = (id) =>
  apiClient.put(`/api/clean_demands/${id}/cancel`).then(({ data }) => data);

const confirmationPopUp = () =>
  new Promise((res) => {
    const closeConfirmation = () => modal.close();
    const onConfirm = () => {
      res({ confirmed: true });
      closeConfirmation();
    };

    modal.fire({
      html: (
        <div className="ActionOptions__confirmation">
          <p>Are you sure you want to cancel this clean demand?</p>
          <SwalActions
            confirmText="Yes"
            cancelText="No"
            onCancel={closeConfirmation}
            onConfirm={onConfirm}
          />
        </div>
      ),
      showConfirmButton: false,
      showCancelButton: false
    });
  });

const ActionOptions = ({ id, status, onUpdate }) => {
  const handleRejectClick = async () => {
    const { confirmed } = await confirmationPopUp();

    if (!confirmed) {
      return;
    }

    try {
      const resp = await cancelCleanDemand(id);
      onUpdate(resp);

      showSuccessToast("Clean demand successfully cancelled");
    } catch (error) {
      showErrorToast("Unable to cancel this demand.");
    }
  };

  return (
    <div className="ActionOptions twbs">
      {status !== "cancelled" && (
        <button
          type="button"
          title="Cancel"
          onClick={handleRejectClick}
          data-testid="reject-button"
          className="btn btn-light btn-sm mr-2"
        >
          <i className="fa fa-trash" />
        </button>
      )}
    </div>
  );
};

export default ActionOptions;
