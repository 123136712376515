import { FieldArray, ErrorMessage, Formik } from "formik";
import React, { useContext } from "react";
import * as yup from "yup";

import { stringWithoutSpecialChars } from "../../../../common/forms/validationSchemas";
import { FormGroup } from "../components/FormGroup";
import { NestedFields } from "../components/NestedFields";
import { HomeInfoContext } from "../HomeInfoContext";
import { HomeInfoForm } from "../HomeInfoForm";

const getInitialVerificationTaskValues = (values) => ({
  home_info_id: values.id
});

export const validationSchema = yup.object().shape({
  host_preferences_attributes: yup.object().shape({
    special_guidelines: yup
      .string()
      .max(500, "Guidelines too long. Maxiumum 500 characters")
  }),

  home_info_guest_verification_tasks_attributes: yup.array().of(
    yup.object().shape({
      description: yup.string().required("Please provide a description")
    })
  ),
  house_rules: stringWithoutSpecialChars
});

export const OtherNotes = () => {
  const { homeInfo, updateHomeInfo } = useContext(HomeInfoContext);

  return (
    <Formik
      initialValues={homeInfo}
      enableReinitialize
      validationSchema={validationSchema}
      isInitialValid={
        validationSchema ? validationSchema.isValidSync(homeInfo) : true
      }
      onSubmit={updateHomeInfo}
    >
      {({ values }) => (
        <HomeInfoForm sidebarName="Property">
          <h4 className="section-name">Notes For Guest Experience</h4>

          <FormGroup
            name="host_preferences_attributes.special_guidelines"
            label="Additional instructions for our Guest Communications and Approvals team"
            component="textarea"
            rows={2}
          />
          <ErrorMessage name="host_preferences_attributes.special_guidelines">
            {(message) => <div className="error">{message}</div>}
          </ErrorMessage>

          <label>Guest verification tasks</label>
          <FieldArray
            name="home_info_guest_verification_tasks_attributes"
            render={({ push, remove }) => (
              <div>
                {values.home_info_guest_verification_tasks_attributes.map(
                  (task, index) => (
                    <NestedFields
                      key={index}
                      name={`home_info_guest_verification_tasks_attributes.${index}`}
                      onRemove={() => remove(index)}
                    >
                      <FormGroup
                        name={`home_info_guest_verification_tasks_attributes.${index}.description`}
                        label="Description"
                        placeholder="Description"
                        disabled={task._destroy}
                        component="textarea"
                        rows={2}
                      />
                    </NestedFields>
                  )
                )}

                <NestedFields.AddButton
                  label="Add a Verification Task"
                  onClick={() => push(getInitialVerificationTaskValues(values))}
                />
              </div>
            )}
          />

          <FormGroup
            name="smoking_notes"
            label="Smoking, vaping, e‑cigarettes allowed"
            component="textarea"
            rows={2}
            visibleToGuests
          />

          <FormGroup
            name="house_rules"
            label="House rules"
            component="textarea"
            rows={6}
            visibleToGuests
            description="Guests are expected to follow your rules and can be removed from Airbnb if they cause issues."
          />

          <h4 className="section-name">Other property notes</h4>

          <FormGroup
            name="host_extra_instructions"
            component="textarea"
            rows={5}
            visibleToGuests
          />

          <FormGroup
            name="building_manager_contact"
            label="Building manager contact"
          />
        </HomeInfoForm>
      )}
    </Formik>
  );
};
