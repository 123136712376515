import React from "react";

import { DropdownFilter } from "../../../common/tables/filters";

const VALUES = [
  ["Signed", true],
  ["Not signed", false]
];

export const ContractSignedFilter = ({ onChange, filter }) => (
  <DropdownFilter onChange={onChange} filter={filter} values={VALUES} />
);
