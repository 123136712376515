import { useDispatch, useSelector } from "react-redux";

import { clearToastMessage } from "../components/properties/PropertyFiles/data/toast/actions";
import { showErrorToast, showSuccessToast } from "./modals";

const SEVERITY_SUCCESS = "success";
const SEVERITY_ERROR = "error";

export const useToastMonitor = () => {
  const dispatch = useDispatch();
  const { message, severity } = useSelector((state) => state.toast);

  if (!message) {
    return;
  }

  // We need to delay the toast display to avoid the problem with modal overlay if called from a modal.
  setTimeout(() => {
    const method = {
      [SEVERITY_SUCCESS]: showSuccessToast,
      [SEVERITY_ERROR]: showErrorToast
    };

    const show = method[severity] || showErrorToast;
    show(message);
  }, 30);

  dispatch(clearToastMessage());
};
