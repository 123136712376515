import { Formik, Field } from "formik";
import React, { useContext } from "react";

import { HomeInfoContext } from "../HomeInfoContext";
import { HomeInfoForm } from "../HomeInfoForm";

export const ListingSync = () => {
  const { homeInfo, updateHomeInfo, setIsModified } = useContext(
    HomeInfoContext
  );

  return (
    <Formik
      onSubmit={updateHomeInfo}
      initialValues={{
        host_preferences_attributes: {
          airbnb_listed: homeInfo.host_preferences_attributes.airbnb_listed
            ? "1"
            : "0"
        }
      }}
    >
      {({ handleChange }) => (
        <HomeInfoForm sidebarName="Property">
          <h4 className="section-name">Listing Status</h4>
          <div className="form-row border p-3">
            <div className="col-md-4">
              <h6>Airbnb Listing Status</h6>
              <div className="form-check">
                <Field
                  type="radio"
                  className="form-check-input"
                  id="airbnb_listed_true"
                  name="host_preferences_attributes.airbnb_listed"
                  value="1"
                  onChange={(event) => {
                    handleChange(event);
                    setIsModified(true);
                  }}
                />
              </div>
              <div className="listing-status">
                <label
                  className="form-check-label"
                  htmlFor="airbnb_listed_true"
                >
                  <span className="dot-green" /> Listed
                  <p className="desc">
                    Guests can find this listing in search results and requests
                    or book available dates.
                  </p>
                </label>
              </div>

              <div className="form-check">
                <Field
                  type="radio"
                  className="form-check-input"
                  id="airbnb_listed_false"
                  name="host_preferences_attributes.airbnb_listed"
                  value="0"
                  onChange={(event) => {
                    handleChange(event);
                    setIsModified(true);
                  }}
                />
              </div>
              <div className="listing-status">
                <label
                  className="form-check-label"
                  htmlFor="airbnb_listed_false"
                >
                  <span className="dot-red" /> Unlisted
                  <p className="desc">
                    Guests can&apos;t book this property or find it in search
                    results.
                  </p>
                </label>
              </div>
            </div>
          </div>
        </HomeInfoForm>
      )}
    </Formik>
  );
};
