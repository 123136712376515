import React, { useEffect, useState } from "react";

import { Modal } from "../../../../../common/Modal";
import { geocodeWithFallback } from "./api/googleMaps";
import { CustomMapControls } from "./CustomMapControls";
import { LocationPickerMap } from "./LocationPickerMap";

export const LocationPickerModal = ({
  location: { lat, lng },
  address,
  onCancel,
  onConfirm
}) => {
  const [location, setLocation] = useState({ lat, lng });
  const [isPending, setPending] = useState(false);

  useEffect(() => {
    if (location.lat && location.lng) {
      return;
    }

    // Coordinates are not specified, try to find them using google API
    setPending(true);

    geocodeWithFallback(address)
      .then((location) => setLocation(location))
      .finally(() => setPending(false));
  }, [address, location]);

  return (
    <Modal size="lg" onClose={onCancel}>
      <Modal.Header>Drag the marker in order to pick the location</Modal.Header>

      <Modal.Body>
        {isPending ? (
          <div>Loading...</div>
        ) : (
          <LocationPickerMap location={location} setLocation={setLocation} />
        )}
      </Modal.Body>

      <Modal.Footer>
        <div className="d-flex justify-content-between align-items-center w-100">
          <CustomMapControls setLocation={setLocation} />

          <div>
            <button
              type="button"
              className="btn btn-light mr-2"
              onClick={onCancel}
            >
              Close
            </button>
            <button
              type="button"
              className="btn btn-submit"
              onClick={() => onConfirm(location)}
            >
              Update location
            </button>
          </div>
        </div>
      </Modal.Footer>
    </Modal>
  );
};
