import React from "react";

export const SelectedProperty = ({ property, handleClearSelection }) => (
  <div id="property_search">
    <div className="PropertySelection__selected">
      <div>
        <span>{property.home_code}</span>
        <br />
        <span>{property.full_address}</span>
      </div>
      <span
        className="PropertySelection__remove"
        title="Remove"
        onClick={handleClearSelection}
        role="button"
        tabIndex={0}
      >
        <i className="glyphicons-icon circle_remove" />
      </span>
    </div>
  </div>
);
