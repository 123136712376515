import { round } from "lodash";

export const isBlank = (value) => value === undefined || value === null;
export const coordsMissing = ({ lat, lng }) => isBlank(lat) && isBlank(lng);
export const safelyExtendMarkerBounds = (markerBounds, coords) => {
  if (!coordsMissing(coords)) {
    markerBounds.extend(coords);
  }
};

// Making tests easier
export const googleMapsApi = () => {
  return window.google.maps;
};

export const distanceBetweenPoints = (coords1, coords2) => {
  try {
    const meters = googleMapsApi().geometry.spherical.computeDistanceBetween(
      coords1,
      coords2
    );

    return round(meters / 1000, 2);
  } catch {
    return false;
  }
};
