import "./CleanImagesUpload.scss";
import classNames from "classnames";
import React, { useCallback, useState } from "react";
import { useDropzone } from "react-dropzone";

import { apiClient } from "../../../common/apiClient";
import { LoadingSpinner } from "../../../common/LoadingSpinner";
import { showErrorToast, showSuccessToast } from "../../../common/modals";
import { simplePluralize } from "../../../common/stringUtils";
import { confirmCleanType } from "./confirmCleanType";

export const CleanImagesUpload = ({ cleanId, onUploadFinish }) => {
  const [queueSize, setQueueSize] = useState(0);
  const [currentUpload, setCurrentUpload] = useState(0);

  const uploadFile = useCallback(
    async (file, values) => {
      setCurrentUpload((previous) => previous + 1);

      const formData = new FormData();

      formData.append("clean_image[file]", file);
      Object.entries(values).forEach(([key, value]) => {
        formData.append(`clean_image[${key}]`, value || "");
      });

      const response = await apiClient.post(
        `/api/cleans/${cleanId}/images`,
        formData,
        {
          headers: { "Content-Type": "multipart/form-data" }
        }
      );

      return response.data;
    },
    [cleanId]
  );

  const submitFiles = useCallback(
    async (fileList, values) => {
      let response;
      // eslint-disable-next-line no-restricted-syntax
      for (const file of fileList) {
        // eslint-disable-next-line no-await-in-loop
        response = await uploadFile(file, values);
      }
      return response;
    },
    [uploadFile]
  );

  const handleSubmit = useCallback(
    async (fileList) => {
      const { confirmed, values } = await confirmCleanType();

      if (!confirmed) {
        return;
      }

      setQueueSize(fileList.length);

      submitFiles(fileList, values)
        .then((response) => {
          showSuccessToast(
            `${simplePluralize("image", fileList.length)} uploaded`
          );
          onUploadFinish(response);
        })
        .catch(() => {
          showErrorToast("Something went wrong, please try again");
        })
        .finally(() => {
          setQueueSize(0);
          setCurrentUpload(0);
        });
    },
    [onUploadFinish, submitFiles]
  );

  const {
    getRootProps,
    getInputProps,
    open: handleSelectFiles,
    isDragActive
  } = useDropzone({
    accept: "image/*,video/*",
    onDrop: handleSubmit
  });

  return (
    <>
      {queueSize > 0 ? (
        <div className="CleanImagesUpload">
          <div className="CleanImagesUpload__loading-progress">
            <LoadingSpinner
              message={`Uploading ${currentUpload}/${queueSize}`}
              delay={500}
            />
          </div>
        </div>
      ) : (
        <div
          {...getRootProps({ onClick: (event) => event.stopPropagation() })}
          className={classNames("CleanImagesUpload", {
            dragging: isDragActive
          })}
        >
          <button
            type="button"
            className="btn btn-submit"
            onClick={handleSelectFiles}
          >
            Upload images
          </button>
          <input {...getInputProps()} className="form-control-file d-none" />
          <div className="CleanImagesUpload__drop-area">
            <i className="upload-icon glyphicons-icon folder_plus" />
            or drag here
          </div>
        </div>
      )}
    </>
  );
};
