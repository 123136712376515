import React from "react";
import "./FieldIcon.scss";

const FieldIcon = ({ icon, tooltipText }) => (
  <div className="field-icon mb-2 ml-2">
    {icon} <div className="field-icon__tooltip">{tooltipText}</div>
  </div>
);

export default FieldIcon;
