import { fireEvent, render, waitFor } from "@testing-library/react";
import React from "react";

import { DeactivationForm } from "./DeactivationForm";

test("<DeactivationForm />", async () => {
  // Given
  const cleaner = { id: 1234, name: "Anna" };

  const fetchUpcomingNonStandardCleans = jest
    .fn()
    .mockReturnValue(new Promise(() => {}));

  const handleSubmit = jest.fn();

  const { queryByText, getByLabelText } = render(
    <DeactivationForm
      fetchUpcomingNonStandardCleans={fetchUpcomingNonStandardCleans}
      cleaner={cleaner}
      onSubmit={handleSubmit}
    />
  );

  // When
  fireEvent.click(queryByText("Deactivate"));

  // Then
  await waitFor(() => {
    expect(queryByText("Deactivation reason is required")).toBeInTheDocument();
    expect(queryByText("Please provide a comment")).toBeInTheDocument();
  });

  // When
  fireEvent.change(getByLabelText("Deactivation reason"), {
    target: { value: "other" }
  });
  fireEvent.click(queryByText("Deactivate"));

  // Then
  await waitFor(() =>
    expect(queryByText("Please provide a comment")).toBeInTheDocument()
  );

  // When
  fireEvent.change(getByLabelText("Deactivation comments"), {
    target: { value: "Security reasons" }
  });
  fireEvent.click(getByLabelText("Would reactivate"));
  fireEvent.click(queryByText("Deactivate"));

  await waitFor(() =>
    expect(queryByText("Please provide a comment")).not.toBeInTheDocument()
  );

  // Then
  expect(handleSubmit).toHaveBeenCalledWith(
    {
      notes: "Security reasons",
      deactivation_reason: "other",
      would_reactivate: true
    },
    expect.anything()
  );
});
