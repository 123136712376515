import { Formik } from "formik";
import React, { useContext } from "react";

import { FormGroup } from "../components/FormGroup";
import { HomeInfoContext } from "../HomeInfoContext";
import { HomeInfoForm } from "../HomeInfoForm";

export const Maintenance = () => {
  const { homeInfo, updateHomeInfo } = useContext(HomeInfoContext);

  return (
    <Formik
      initialValues={homeInfo}
      enableReinitialize
      onSubmit={updateHomeInfo}
    >
      <HomeInfoForm sidebarName="Operate">
        <h4 className="section-name">Maintenance, Meters and Fuse</h4>

        <FormGroup
          name="gas_meter_location"
          label="Gas meter location"
          visibleToCleaners
        />

        <FormGroup
          name="electric_meter_location"
          label="Electric meter location"
          visibleToCleaners
        />

        <FormGroup
          name="home_info_maintenance_attributes.water_mains_stop_valve_location"
          label="Water mains/stop valve location"
        />

        <FormGroup
          name="home_info_maintenance_attributes.fusebox_location"
          label="Fusebox location"
          visibleToGuests
          visibleToCleaners
        />

        <FormGroup
          name="home_info_maintenance_attributes.preferred_contractor_name"
          label="Preferred emergency contractor name"
        />

        <FormGroup
          name="home_info_maintenance_attributes.preferred_contractor_telephone"
          label="Preferred emergency contractor contact number or email address"
        />
      </HomeInfoForm>
    </Formik>
  );
};
