import React from "react";

import { formatDateTime } from "../../../common/date";
import { CopyTextBox } from "../../clipboard/CopyTextBox/CopyTextBox";

const Row = ({ label, children }) => (
  <>
    <dt className="col-sm-5">{label}</dt>
    <dd className="col-sm-7">{children}</dd>
  </>
);

export const ExternalKey = ({ externalKey }) => (
  <div className="card border m-2 key-card">
    <div className="card-body">
      {externalKey.collection_type === "internal" && (
        <div className="alert alert-secondary">
          This is an internal key visible in Houstkeeping App
        </div>
      )}

      {externalKey.collection_type === "guest" && (
        <div className="alert alert-success">
          This is a guest key visible in Guest App
        </div>
      )}

      <dl className="row">
        <Row label="Collection Code">
          {externalKey.collection_code ? (
            <span className="font-weight-bold">
              <CopyTextBox text={externalKey.collection_code} />
            </span>
          ) : (
            <span className="font-italic">-- not set --</span>
          )}
        </Row>

        <Row label="External ID">
          <span className="font-weight-bold">
            <a
              href={`https://secure.keynest.com/ManageKey/ManageAccess/${externalKey.external_id}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              {externalKey.external_id}
            </a>
          </span>
        </Row>

        <Row label="Key Name">{externalKey.name}</Row>
        <Row label="Key Status">{externalKey.status || "unknown"}</Row>

        {externalKey.status === "in_office" && externalKey.notes && (
          <Row label="Notes">{externalKey.notes}</Row>
        )}

        {externalKey.keystore_location && (
          <>
            <Row label="Keystore Location">
              <address>
                <span className="font-weight-bold">
                  {externalKey.keystore_location.name}
                </span>
                <br />
                <span>{externalKey.keystore_location.street_address}</span>
                <br />
                {externalKey.keystore_location.lat &&
                  externalKey.keystore_location.lng && (
                    <a
                      target="_blank"
                      rel="noreferrer"
                      href={`https://maps.google.com/?q=${externalKey.keystore_location.lat},${externalKey.keystore_location.lng}`}
                    >
                      show on map
                    </a>
                  )}
              </address>
            </Row>

            <Row label="Opening time">
              {externalKey.keystore_location.opening_time}
            </Row>
          </>
        )}

        <Row label="Last Update At">
          {formatDateTime(new Date(externalKey.updated_at))}
        </Row>

        {externalKey.last_moved_at && (
          <Row label="Last Moved At">
            {formatDateTime(new Date(externalKey.last_moved_at))}
          </Row>
        )}
      </dl>
    </div>
  </div>
);
