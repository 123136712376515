import "./ActionOptions.scss";

import { get } from "lodash";
import React from "react";

import { copyToClipboard } from "../../../common/copyToClipboard";
import {
  SwalActions,
  modal,
  showErrorToast,
  showSuccessToast
} from "../../../common/modals";
import * as api from "../api";
import { MaintenanceImagesModal } from "./MaintenanceImagesModal";

const { STATUS_REJECTED } = window.AirbaseConstants.Maintenance;

const confirmationPopUp = ({ address }) =>
  new Promise((res) => {
    const closeConfirmation = () => modal.close();
    const onConfirm = () => {
      res({ confirmed: true });
      closeConfirmation();
    };

    modal.fire({
      html: (
        <div className="ActionOptions__confirmation">
          <p>Are you sure you want to reject this issue at {address}?</p>
          <SwalActions onCancel={closeConfirmation} onConfirm={onConfirm} />
        </div>
      ),
      showConfirmButton: false,
      showCancelButton: false
    });
  });

const ActionOptions = ({ data, onUpdate }) => {
  const handleRejectClick = async () => {
    const { confirmed } = await confirmationPopUp({
      address: get(data, "property.home_code", null)
    });

    if (confirmed) {
      try {
        const resp = await api.updateMaintenance(
          {
            ...data,
            status: "rejected"
          },
          { skipValidation: true }
        );
        onUpdate(resp);

        showSuccessToast("Maintenance was successfully updated");
      } catch (error) {
        showErrorToast("Unable to save rejection.");
      }
    }
  };

  const handleCopyClick = async () => {
    await copyToClipboard(data.share_url);
    showSuccessToast("Link copied to clipboard");
  };

  const handleOpenGallery = () =>
    modal.fire({
      html: <MaintenanceImagesModal maintenanceId={data.id} />,
      showConfirmButton: false,
      showCancelButton: false
    });

  return (
    <div className="ActionOptions twbs">
      {data.has_images && (
        <button
          type="button"
          title="Preview images"
          onClick={handleOpenGallery}
          data-testid="gallery-button"
          className="btn btn-light btn-sm mr-2"
        >
          <i className="fa fa-image" />
        </button>
      )}

      {data.status !== STATUS_REJECTED && (
        <button
          type="button"
          title="Reject"
          onClick={handleRejectClick}
          data-testid="reject-button"
          className="btn btn-light btn-sm mr-2"
        >
          <i className="fa fa-trash" />
        </button>
      )}

      <button
        type="button"
        title="Copy url to clipboard"
        onClick={handleCopyClick}
        className="btn btn-light btn-sm"
      >
        <i className="far fa-copy" />
      </button>
    </div>
  );
};

export default ActionOptions;
