/* eslint-disable func-names */
import { Form, Formik, ErrorMessage } from "formik";
import React from "react";
import * as yup from "yup";

import { useApiClient } from "../../../common/apiClient";
import { FormGroup } from "../../../common/forms/FormGroup";
import { Modal } from "../../../common/Modal";

const validationSchema = yup.object().shape({
  notes: yup.string().max(200, "Can't be longer than 200 characters.")
});

export const NewNoteModal = ({ onClose, maintenanceId }) => {
  const apiClient = useApiClient();

  const handleSubmit = async (values, { setSubmitting }) => {
    setSubmitting(true);

    try {
      await apiClient.post(
        `/api/maintenance/${maintenanceId}/maintenance_status_changes/`,

        { ...values }
      );

      window.location.reload();
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <Formik
      initialValues={{ notes: "" }}
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
    >
      {({ isSubmitting, isValid }) => (
        <Form>
          <Modal onClose={onClose}>
            <Modal.Header>Add update</Modal.Header>
            <Modal.Body>
              <FormGroup
                label="Maintenance update notes"
                name="notes"
                type="text"
                component="textarea"
                rows={4}
              />

              <ErrorMessage name="pause_notes" />
            </Modal.Body>

            <Modal.Footer>
              <button
                type="submit"
                className="btn btn-primary mb-3"
                disabled={isSubmitting || !isValid}
              >
                Submit
              </button>
            </Modal.Footer>
          </Modal>
        </Form>
      )}
    </Formik>
  );
};
