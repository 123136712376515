import classNames from "classnames";
import React, { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  changeFilesSortingField,
  toggleFilesSortingDirection
} from "../data/files/actions";
import { SORT_ASC, SORT_DESC } from "../data/files/reducer";
import { selectFilesSorting } from "../data/files/selectors";

const SortingArrowIcon = ({ direction }) => (
  <i
    className={classNames(
      "fas",
      {
        [SORT_ASC]: "fa-sort-amount-up",
        [SORT_DESC]: "fa-sort-amount-down"
      }[direction]
    )}
  />
);

export const FileListSortingContainer = () => {
  const sorting = useSelector((state) => selectFilesSorting(state));
  const dispatch = useDispatch();

  const handleChangeField = useCallback(
    (event) => {
      const { value: field } = event.target;
      dispatch(changeFilesSortingField(field));
    },
    [dispatch]
  );

  const handleToggleDirection = useCallback(
    () => dispatch(toggleFilesSortingDirection()),
    [dispatch]
  );

  return (
    <div className="input-group">
      <div className="input-group-append">
        <button
          type="button"
          className="btn btn-light"
          onClick={handleToggleDirection}
        >
          <SortingArrowIcon direction={sorting.direction} />
        </button>
      </div>

      <select
        className="form-control"
        value={sorting.field}
        onChange={handleChangeField}
      >
        <option value="created_at">Date created</option>
        <option value="updated_at">Date updated</option>
        <option value="file_name">File name</option>
        <option value="file_size">File size</option>
      </select>
    </div>
  );
};
