import React from "react";

import { humanFileSize } from "../../../../../common/humanFileSize";
import { Progress } from "../../../../../common/Progress";

export const TotalProgress = ({ isUploadPending, progress }) => (
  <div className="w-100 mb-3">
    <div className="d-flex justify-content-between w-100 mb-1">
      <span>
        {progress.uploadedCount} of {progress.totalCount} uploaded
      </span>

      <span>
        {humanFileSize(progress.uploadedBytes)} /{" "}
        {humanFileSize(progress.totalBytes)}
      </span>
    </div>

    <Progress percentage={progress.percentage} success={!isUploadPending} />
  </div>
);
