export const formatDateTime = (cleanDate, cleanTime) => {
  if (!cleanDate) {
    return null;
  }

  const date = new Date(cleanDate);
  const cleanYear = date.getFullYear();
  const cleanMonth = `0${date.getMonth() + 1}`.slice(-2);
  const cleanDay = `0${date.getDate()}`.slice(-2);
  const time = cleanTime || "00:00";

  return `${cleanYear}-${cleanMonth}-${cleanDay}T${time}`;
};
