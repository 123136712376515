import "./CopyTextBox.scss";

import React, { useCallback } from "react";

import { copyToClipboard } from "../../../common/copyToClipboard";
import { showSuccessToast } from "../../../common/modals";

const CopyButton = ({ text }) => {
  const handleCopy = useCallback(
    async (event) => {
      event.preventDefault();

      await copyToClipboard(text);
      showSuccessToast(`"${text}" was copied to the clipboard`);
    },
    [text]
  );

  return (
    <i data-testid="copy-button" className="far fa-copy" onClick={handleCopy} />
  );
};

export const CopyTextBox = ({ text, buttonOnly }) => (
  <span className="CopyTextBox">
    {buttonOnly ? "" : text}
    <CopyButton text={text} />
  </span>
);
