import React from "react";

import { DropdownFilter } from "../../../common/tables/filters";

const VALUES = [
  ["Active", true],
  ["Inactive", false]
];

export const ActiveFilter = ({ onChange, filter }) => (
  <DropdownFilter onChange={onChange} filter={filter} values={VALUES} />
);
