export const SOURCE_EXPLANATION = {
  internal: "Houst has scheduled this clean",
  host: "Scheduled by host or on their behalf"
};

export const CLEAN_TYPE_EXPLANATION = {
  standard: "A regular clean, typically auto-scheduled after each guest stay",
  post_host: "Clean scheduled after host has stayed at the property",
  first: "First clean of a new property (comes with spare linen for every bed)",
  mid_stay:
    "Clean takes place while guest is still staying at the property - remember to confirm with current guest.",
  final:
    "Manually scheduled clean for a property that is offboarding (no laundry order).",
  deep:
    "Works the same as a standard clean. Extend clean duration and assign to a deep-clean company if required.",
  special_task:
    "Can be scheduled on the same day as another clean. Be sure to communicate necessary tasks to the cleaner (e.g. spot checks, small repairs)."
};

export const ATTRIBUTIONS_EXPLANATION = {
  internal: "Houst is paying for this",
  host: "The host is paying for this clean"
};
