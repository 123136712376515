const ERROR_FILE_TOO_LARGE = "file-too-large";
const ERROR_FILE_INVALID_TYPE = "file-invalid-type";

export const handleFileRejections = (
  fileRejections,
  acceptContentTypes,
  maxSizeFormatted
) => {
  const errors = [];
  fileRejections.forEach((file) => {
    file.errors.forEach((err) => {
      if (err.code === ERROR_FILE_TOO_LARGE) {
        errors.push(
          `Error uploading file ${file.file.name}. File size cannot exceed ${maxSizeFormatted}.`
        );
      } else if (err.code === ERROR_FILE_INVALID_TYPE) {
        errors.push(
          `Error uploading file ${
            file.file.name
          }. File type must be one of ${acceptContentTypes.join(", ")}.`
        );
      } else {
        errors.push(`Error file ${file.file.name}. ${err.message}`);
      }
    });
  });
  return errors;
};
