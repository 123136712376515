import { Formik } from "formik";
import React, { useContext } from "react";
import * as yup from "yup";

import { SelectOptions } from "../../../../common/SelectOptions";
import { FormGroup } from "../components/FormGroup";
import { HomeInfoContext } from "../HomeInfoContext";
import { HomeInfoForm } from "../HomeInfoForm";

export const validationSchema = yup.object().shape({
  host_preferences_attributes: yup.object().shape({
    host_preference_group: yup
      .string()
      .required("Please select host preference group")
  })
});

export const InternalHostNotes = () => {
  const { homeInfo, updateHomeInfo } = useContext(HomeInfoContext);

  return (
    <Formik
      initialValues={homeInfo}
      enableReinitialize
      validationSchema={validationSchema}
      isInitialValid={
        validationSchema ? validationSchema.isValidSync(homeInfo) : true
      }
      onSubmit={updateHomeInfo}
    >
      <HomeInfoForm sidebarName="Configuration">
        <h4 className="section-name">Host Involvement</h4>

        <FormGroup
          name="host_preferences_attributes.host_preference_group"
          label="How involved you would like to be in the management of your Houst home?"
          component="select"
        >
          <SelectOptions
            options={window.AirbaseConstants.HOST_PREFERENCE_GROUPS}
          />
        </FormGroup>

        <FormGroup
          name="host_preferences_attributes.host_notes"
          label="Host notes"
          placeholder="Eg. Host only speaks Spanish"
          component="textarea"
          rows={3}
        />
      </HomeInfoForm>
    </Formik>
  );
};
