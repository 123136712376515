// Jest Snapshot v1, https://goo.gl/fbAQLP

exports[`<ActionOptions /> renders with success 1`] = `
<div>
  <div
    class="ActionOptions twbs"
  >
    <button
      class="btn btn-light btn-sm mr-2"
      data-testid="reject-button"
      title="Reject"
      type="button"
    >
      <i
        class="fa fa-trash"
      />
    </button>
    <button
      class="btn btn-light btn-sm"
      title="Copy url to clipboard"
      type="button"
    >
      <i
        class="far fa-copy"
      />
    </button>
  </div>
</div>
`;
