import React from "react";

import { formatDate } from "../date";

export const dateColumn = (options = {}) => ({
  Header: "Created At",
  accessor: "created_at",
  sortable: true,
  defaultSortDesc: true,
  maxWidth: 150,
  Cell: ({ value }) => <span>{value && formatDate(new Date(value))}</span>,
  ...options
});
