import { isNumber } from "lodash";
import React, { useState } from "react";

import { formatAmountWithCurrency } from "../../../common/numbers";
import { HostPreferencesForm } from "./HostPreferencesForm";

import "./HostPreferences.scss";

export const HostPreferences = ({
  propertyId,
  hostPreferenceSettings,
  currencySymbol
}) => {
  const settings = hostPreferenceSettings
    ? JSON.parse(hostPreferenceSettings)
    : {};
  const [showPropertyModal, setShowPropertyModal] = useState(false);

  const handleModalToggle = () => setShowPropertyModal((prev) => !prev);
  const tameValue = (value, suffix = "") =>
    isNumber(value) ? `${value}${suffix}` : "N/A";

  return (
    <div className="HostPreferences">
      <div className="edit-icon">
        <button
          type="button"
          className="btn btn-primary mb-3"
          onClick={handleModalToggle}
        >
          <i className="fas fa-edit" />
          &nbsp; Update host preferences
        </button>
        {showPropertyModal && (
          <HostPreferencesForm
            propertyId={propertyId}
            settings={settings}
            currency={currencySymbol}
            onClose={handleModalToggle}
          />
        )}
      </div>
      <div className="property_info details_table">
        <div className="row">
          <div className="label">Minimum price</div>
          <div className="value">
            {isNumber(settings.minimum_price)
              ? formatAmountWithCurrency(settings.minimum_price, {
                  currencySymbol,
                  decimalPlaces: 0
                })
              : "N/A"}
          </div>
        </div>

        <div className="row">
          <div className="label">Minimum nights per stay</div>
          <div className="value">{tameValue(settings.minimum_nights)}</div>
        </div>

        <div className="row">
          <div className="label">7-day booking discount</div>
          <div className="value">
            {tameValue(settings.seven_day_discount_percent, "%")}
          </div>
        </div>

        <div className="row">
          <div className="label">1-month booking discount</div>
          <div className="value">
            {tameValue(settings.one_month_discount_percent, "%")}
          </div>
        </div>

        <div className="row">
          <div className="label">Default calendar block</div>
          <div className="value">
            {settings.default_calendar_block ? "True" : "False"}
          </div>
        </div>

        <div className="row_group">
          <div className="head mt-3">
            <div className="cell">Mid-let preferences</div>
          </div>

          <div className="row">
            <div className="label">Opt-in</div>
            <div className="value">
              {settings.mid_let_opt_in ? "True" : "False"}
            </div>
          </div>

          {settings.mid_let_opt_in && (
            <>
              <div className="row">
                <div className="label">Availability window</div>
                <div className="value">
                  <div className="row">
                    <div className="label borderless">Start</div>
                    <div className="value borderless">
                      {settings.mid_let_availability_window_start_date || "N/A"}
                    </div>
                  </div>
                  <div className="row">
                    <div className="label borderless">End</div>
                    <div className="value borderless">
                      {settings.mid_let_availability_window_end_date || "N/A"}
                    </div>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="label">
                  Minimum length of stay (as agreed with host)
                </div>
                <div className="value">
                  {settings.mid_let_min_nights
                    ? `${settings.mid_let_min_nights} nights`
                    : "N/A"}
                </div>
              </div>

              <div className="row">
                <div className="label">
                  Minimum net return per month (as agreed with host)
                </div>
                <div className="value">
                  {settings.mid_let_min_net_return_per_month
                    ? formatAmountWithCurrency(
                        settings.mid_let_min_net_return_per_month,
                        {
                          currencySymbol,
                          decimalPlaces: 0
                        }
                      )
                    : "N/A"}
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};
