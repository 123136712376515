import React from "react";

import { DropdownFilter } from "../../../../common/tables/filters";

const { CLEAN_TYPES } = window.AirbaseConstants.CleanDemand;
const VALUES = Object.entries(CLEAN_TYPES).map((el) => el.reverse());

export const CleanTypeFilter = ({ onChange, filter }) => (
  <DropdownFilter onChange={onChange} filter={filter} values={VALUES} />
);
