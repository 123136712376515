import debounce from "lodash/debounce";
import React, { useCallback, useState } from "react";

import { apiClient } from "../../../common/apiClient";
import { LeadOption } from "./LeadOption";

const fetchFilteredLeads = async (query, currentLead) => {
  const byName = { id: "display_name", value: query };
  const byId = { id: "exclude", value: currentLead };
  const params = {
    filtered: [byName, byId],
    sorted: [{ id: "display_name", desc: true }],
    limit: 10
  };

  const {
    data: { rows: cleaners }
  } = await apiClient.get("/api/leads", { params });

  return cleaners;
};

export const LeadSearch = ({ currentLead, onSelect }) => {
  const [leads, setLeads] = useState([]);

  const handleChange = useCallback(
    (event) => {
      const delayedSearchChange = debounce(async (query) => {
        const filteredLeads = await fetchFilteredLeads(query, currentLead);
        setLeads(filteredLeads);
      }, 300);

      delayedSearchChange(event.target.value);
    },
    [currentLead]
  );

  return (
    <>
      <div className="SearchSelect__search">
        <input
          className="form-control"
          id="LeadSelection"
          type="text"
          placeholder="Search"
          onChange={handleChange}
        />
      </div>

      {leads.map((lead) => (
        <LeadOption key={lead.id} lead={lead} onSelect={onSelect} />
      ))}
    </>
  );
};
