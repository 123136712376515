import { apiClient } from "../../common/apiClient";

export const fetchCleaner = (id) =>
  apiClient.get(`/api/cleaners/${id}`).then((response) => response.data);

export const fetchUpcomingNonStandardCleans = (id) =>
  apiClient
    .get(`/api/cleaners/${id}/upcoming_cleans`)
    .then((response) => response.data);

export const activateCleaner = (id) =>
  apiClient
    .post(`/api/cleaners/${id}/activate`)
    .then((response) => response.data);

export const deactivateCleaner = (id, values) =>
  apiClient
    .post(`/api/cleaners/${id}/deactivate`, values)
    .then((response) => response.data);
