import "./ExternalKeys.scss";

import React, { useCallback, useEffect, useState } from "react";

import { apiClient } from "../../../common/apiClient";
import { CollectionTypeForm } from "./CollectionTypeForm";
import { ExternalKey } from "./ExternalKey";

export const ExternalKeys = ({ property }) => {
  const [loading, setLoading] = useState(false);
  const [externalKeys, setExternalKeys] = useState([]);

  const fetchExternalKeys = useCallback(async () => {
    setLoading(true);

    try {
      const response = await apiClient.get(
        `/api/properties/${property.id}/external_keys`
      );
      setExternalKeys(response.data.external_keys);
    } finally {
      setLoading(false);
    }
  }, [property]);

  useEffect(() => {
    fetchExternalKeys();
  }, [fetchExternalKeys]);

  return (
    <div>
      <h2 className="mr-2">External Keys</h2>
      {loading && <h3>Loading external keys...</h3>}

      <div className="twbs">
        {!loading && (
          <CollectionTypeForm
            propertyId={property.id}
            externalKeys={externalKeys}
            onUpdateCollectionType={fetchExternalKeys}
          />
        )}

        <div className="d-flex flex-row flex-wrap">
          {externalKeys.map((key) => (
            <ExternalKey key={key.id} externalKey={key} />
          ))}
        </div>
      </div>
    </div>
  );
};
