import { render } from "@testing-library/react";
import React from "react";

import { CleanerOption } from "./CleanerOption";
import { CleanerRating } from "./CleanerRating";
import { CleanerSelection } from "./CleanerSelection";

const cleaner = {
  name: "Luke Skywalker",
  average_rating: 4.5,
  telephone_number: "+44123123123"
};

describe("<CleanerRating />", () => {
  it("renders with success", () => {
    const { container } = render(<CleanerRating rating={4.4533333} />);
    expect(container.querySelector("span").textContent).toContain("4.45");
  });
});

describe("<CleanerOption />", () => {
  it("renders with success", () => {
    const { container } = render(<CleanerOption cleaner={cleaner} />);

    expect(container.querySelector(".name").textContent).toContain(
      "Luke Skywalker"
    );
    expect(container.textContent).toContain("4.50");
  });
});

describe("<CleanerSelection />", () => {
  describe("when a cleaner is selected", () => {
    it("renders with success", () => {
      const { container } = render(
        <CleanerSelection selectedCleanerJSON={JSON.stringify(cleaner)} />
      );

      expect(container.querySelector(".name").textContent).toContain(
        cleaner.name
      );

      expect(container.querySelector(".telephone").textContent).toContain(
        cleaner.telephone_number
      );
    });
  });

  describe("when a cleaner is not selected", () => {
    it("renders the list of cleaners", () => {
      const { container } = render(
        <CleanerSelection selectedCleanerJSON={JSON.stringify(null)} />
      );

      expect(
        container.querySelector("input").getAttribute("placeholder")
      ).toContain("Search");
    });
  });
});
