import { act, fireEvent, render } from "@testing-library/react";
import React from "react";

import { ToggleBookingApiContext } from "../useBookingToggle";
import { MessagingToggles } from "./MessagingToggles";

test("<MessagingToggles />", async () => {
  // Given
  const booking = { id: 123, muted: true };
  const toggleBooking = jest.fn(() =>
    Promise.resolve({ ...booking, muted: false })
  );

  // When
  const { queryByLabelText } = render(<MessagingToggles booking={booking} />, {
    wrapper: ({ children }) => (
      <ToggleBookingApiContext.Provider value={toggleBooking}>
        {children}
      </ToggleBookingApiContext.Provider>
    )
  });

  // Then messaging toggles should be hidden
  expect(queryByLabelText("Checkin reminder")).not.toBeInTheDocument();

  // When the booking was un-muted
  await act(async () => {
    fireEvent.click(queryByLabelText("Automatic messages"));
  });

  // Then messaging toggles should be visible
  expect(queryByLabelText("Checkin reminder")).toBeInTheDocument();
  expect(queryByLabelText("Cleaner review")).toBeInTheDocument();
  expect(queryByLabelText("Checkout reminder")).toBeInTheDocument();
  expect(queryByLabelText("Guest review")).toBeInTheDocument();
});

test("it shows info that message will not be sent", () => {
  const booking = { id: 123, unhappy_guest: true };
  const { queryByText } = render(<MessagingToggles booking={booking} />);

  expect(
    queryByText("Unhappy guest, message will not be sent")
  ).toBeInTheDocument();
});
