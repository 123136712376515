export const renameFiles = (files) =>
  files.map((file) => {
    const name = file.name.split(".");
    const fileExtension = name.pop();
    const fileName = name.join("_");
    return new File(
      [file],
      `${fileName.replace(/[^A-Z0-9]+/gi, "_")}.${fileExtension}`,
      {
        type: file.type
      }
    );
  });
