import { queryByTestId, render } from "@testing-library/react";
import React from "react";

import ActionOptions from "./ActionOptions";

const data = {
  id: 12,
  status: "planned",
  property: {
    home_address: "BAL123"
  }
};

const onUpdate = jest.fn();

describe("<ActionOptions />", () => {
  it("renders with success", () => {
    const { container } = render(
      <ActionOptions data={data} onUpdate={onUpdate} />
    );

    expect(container).toMatchSnapshot();
  });

  it("doesn't render Reject button if status === rejected", () => {
    const props = {
      data: {
        ...data,
        status: "rejected"
      },
      onUpdate
    };
    const { container } = render(<ActionOptions {...props} />);

    expect(queryByTestId(container, "reject-button")).toBeNull();
  });

  it("renders gallery button when maintenance has images", () => {
    const props = {
      data: {
        ...data,
        has_images: true
      },
      onUpdate
    };
    const { container } = render(<ActionOptions {...props} />);

    expect(queryByTestId(container, "gallery-button")).toBeInTheDocument();
  });
});
