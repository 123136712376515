import { act, fireEvent, render } from "@testing-library/react";
import React from "react";

import { ToggleBookingApiContext } from "../useBookingToggle";
import { Toggle } from "./Toggle";

test("<Toggle />", async () => {
  // Given
  const booking = {
    id: 1234,
    skip_auto_clean: true
  };

  const onChange = jest.fn();

  const toggleBooking = jest.fn(
    () =>
      new Promise((resolve) =>
        resolve({
          ...booking,
          skip_auto_clean: false
        })
      )
  );

  const { getByLabelText } = render(
    <Toggle
      booking={booking}
      attribute="skip_auto_clean"
      label="Automatic clean scheduling"
      reversed
      onChange={onChange}
    />,
    {
      wrapper: ({ children }) => (
        <ToggleBookingApiContext.Provider value={toggleBooking}>
          {children}
        </ToggleBookingApiContext.Provider>
      )
    }
  );

  const checkboxEl = getByLabelText("Automatic clean scheduling");
  expect(checkboxEl.checked).toBe(false);

  // When
  await act(async () => {
    fireEvent.click(checkboxEl);
  });

  // Then
  expect(toggleBooking).toHaveBeenCalledWith(booking.id, "skip_auto_clean", {});
  expect(checkboxEl.checked).toBe(true);
  expect(onChange).toHaveBeenCalledWith(false);
});
