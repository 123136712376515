export const formatAmount = (amount = 0, decimalPlaces = 2) => {
  const base = 10 ** decimalPlaces;
  return Number(Math.floor(amount * base) / base).toFixed(decimalPlaces);
};

export const formatAmountWithCurrency = (
  amount,
  { currencySymbol = "£", decimalPlaces } = {}
) => {
  return `${currencySymbol}${formatAmount(amount, decimalPlaces)}`;
};
