import React, { useCallback, useState } from "react";

import { ARCHIVIST_ENDPOINT } from "../../../../config";
import { fetchFiles } from "../api";
import { submitForm } from "../helpers";

export const downloadArchive = async ({ propertyId, fileName }) => {
  const propertyFiles = await fetchFiles({ propertyId });

  const data = propertyFiles.reduce(
    (data, { s3_object_key, folder_name, file_name }) => ({
      ...data,
      [`objects[${s3_object_key}]`]: [folder_name, file_name].join("/")
    }),
    {}
  );

  submitForm({
    action: `${ARCHIVIST_ENDPOINT}/archive/${fileName}`,
    data
  });
};

export const DownloadAllButton = ({ property, downloadArchive }) => {
  const [loading, setLoading] = useState(false);

  const handleClick = useCallback(async () => {
    setLoading(true);

    try {
      await downloadArchive({
        propertyId: property.id,
        fileName: `property-${property.home_code}-files.zip`
      });
    } finally {
      setLoading(false);
    }
  }, [downloadArchive, property]);

  return (
    <button
      type="button"
      className="btn btn-primary download-all"
      onClick={handleClick}
      disabled={loading}
    >
      <i className="fa fa-download" /> <span>Download all</span>
    </button>
  );
};

DownloadAllButton.defaultProps = { downloadArchive };
