import React, { useState } from "react";

export const DateAndTimePicker = ({
  html_id: htmlId,
  html_name: htmlName,
  html_input_class: inputClass,
  label,
  min,
  max,
  value: initialValue
}) => {
  const [dateOnly, setDateOnly] = useState(
    initialValue?.split("T")[1] === "00:00:00"
  );
  const [value, setValue] = useState(initialValue ?? "");

  return (
    <>
      <label htmlFor={htmlId}>{label}</label>

      {dateOnly ? (
        <input
          type="date"
          id={htmlId}
          name={htmlName}
          className={inputClass}
          min={min}
          max={max}
          value={value.split("T")[0]}
          onChange={(event) => {
            setValue(event.currentTarget.value);
          }}
        />
      ) : (
        // For some reason, when only the type is changing, the browser still displays the date picker instead of the datetime-local picker
        // When added the fragment tag, it works as expected
        <>
          <input
            id={htmlId}
            name={htmlName}
            className={inputClass}
            type="datetime-local"
            min={min}
            max={max}
            value={value?.includes("T") ? value : `${value}T00:00`}
            onChange={(event) => {
              setValue(event.currentTarget.value);
            }}
          />
        </>
      )}

      <div className="form-check mt-1">
        <input
          id="date_only"
          name="date_only"
          className="form-check-input"
          type="checkbox"
          defaultChecked={dateOnly}
          onChange={(event) => setDateOnly(event.target.checked)}
        />
        <label className="form-check-label" htmlFor="date_only">
          Date only
        </label>
      </div>
    </>
  );
};
