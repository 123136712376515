import React from "react";
import { MemoryRouter } from "react-router-dom";

import { renderWithMockStore } from "../data/testHelpers";
import { NavTabsContainer } from "./NavTabsContainer";

test("<NavTabsContainer />", () => {
  // Given
  const initialState = {
    files: {
      selection: { ids: [], all: false },
      loading: false,
      list: [
        { id: 1, folder_name: "Appliances" },
        { id: 2, folder_name: "Appliances" },
        { id: 3, folder_name: "Damages" }
      ]
    }
  };

  // When
  const { container } = renderWithMockStore(<NavTabsContainer />, {
    initialState,
    wrapper: ({ children }) => <MemoryRouter>{children}</MemoryRouter>
  });

  // Then
  let linkEl = container.querySelector('[href="/appliances"]');
  expect(linkEl.querySelector(".folder-name").textContent).toEqual(
    "Appliances"
  );
  expect(linkEl.querySelector(".badge").textContent).toEqual("2");

  linkEl = container.querySelector('[href="/damages"]');
  expect(linkEl.querySelector(".folder-name").textContent).toEqual("Damages");
  expect(linkEl.querySelector(".badge").textContent).toEqual("1");

  linkEl = container.querySelector('[href="/lockbox"]');
  expect(linkEl.querySelector(".folder-name").textContent).toEqual("Lockbox");
  expect(linkEl.querySelector(".badge").textContent).toEqual("0");
});
