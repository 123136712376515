import "./LaundryProvidersTable.scss";

import React, { useMemo } from "react";
import ReactTable from "react-table";

import { ShowButton } from "../../../common/tables/buttons";
import { dateColumn } from "../../../common/tables/dateColumn";
import {
  GenericSearchFilter,
  LocalityFilter
} from "../../../common/tables/filters";
import { useControlledTable } from "../../../common/tables/useControlledTable";
import { ActiveFilter } from "./ActiveFilter";

const sortableHeader = (title) => (
  <span title={title}>
    {title}
    <i className="fa fa-sort" />
  </span>
);

export const LaundryProvidersTable = ({ showPods }) => {
  const initialState = useMemo(
    () => ({
      filtered: [],
      sorted: [{ id: "created_at", desc: true }]
    }),
    []
  );

  const controlledProps = useControlledTable({
    api: { endpoint: "/api/laundry_providers" },
    initialState
  });

  const { showPagination, onFilterChange, getFilter } = controlledProps;

  return (
    <div className="laundry-providers-table">
      <>
        <ReactTable
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...controlledProps}
          className="-striped -highlight"
          multiSort
          columns={[
            {
              accessor: "id",
              sortable: false,
              filterable: false,
              maxWidth: 50,
              className: "rt-actions",
              Cell: ({ original: { show_path } }) => (
                <span>
                  <ShowButton href={show_path} title="Show laundry provider" />
                </span>
              )
            },
            {
              Header: "Company name",
              accessor: "name",
              filterable: true,
              className: "rt-cell-with-two-rows",
              Cell: ({ original: { active, name, show_path } }) => (
                <a className={active ? "active" : "inactive"} href={show_path}>
                  {name}
                </a>
              ),
              Filter: ({ onChange, filter }) => (
                <GenericSearchFilter
                  onChange={onChange}
                  filter={filter}
                  placeholder="Search by name"
                />
              )
            },
            {
              Header: sortableHeader("Locality"),
              accessor: "locality.name",
              filterable: true,
              className: "properties-cell",
              sortable: true,
              maxWidth: 210,
              Filter: (
                <LocalityFilter
                  id="locality_name_filter"
                  filter={getFilter("locality.id")}
                  onChange={(value) => {
                    onFilterChange("locality.id", value);
                  }}
                />
              ),
              Cell: ({ original: { locality } }) => {
                if (locality.id) {
                  return (
                    <span>
                      {locality.name} ({locality.country_code})
                    </span>
                  );
                }
                return "-- not set --";
              }
            },
            showPods
              ? {
                  Header: "Pod",
                  accessor: "pod.name",
                  sortable: false,
                  getProps: (_, { original }) => {
                    const isRestricted = original?.pod?.restricted_access;

                    return {
                      className: isRestricted ? "orange" : "",
                      title: isRestricted
                        ? "This pod has restricted access"
                        : ""
                    };
                  },
                  maxWidth: 150
                }
              : {},
            {
              Header: "Telephone number",
              accessor: "telephone_number",
              className: "rt-cell-with-text-wrap",
              sortable: false
            },
            {
              Header: "Email",
              accessor: "email",
              className: "rt-cell-with-text-wrap",
              sortable: false
            },
            {
              Header: "Active",
              accessor: "active",
              maxWidth: 100,
              sortable: false,
              filterable: true,
              Filter: ActiveFilter,
              Cell: ({ original: { active } }) => (
                <span>{active ? "Active" : "Inactive"}</span>
              )
            },
            dateColumn()
          ]}
        />

        {showPagination && (
          <h4>
            Total number of laundry providers {controlledProps.totalCount}
          </h4>
        )}
      </>
    </div>
  );
};
