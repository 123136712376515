import classNames from "classnames";
import { ErrorMessage, Field, Form, Formik } from "formik";
import get from "lodash/get";
import React, { useCallback } from "react";
import * as yup from "yup";

import { apiClient } from "../../../common/apiClient";
import { isUnprocessableEntityError } from "../../../common/errors";

const schema = yup.object().shape({
  response: yup
    .string()
    .required("Please enter a response to the feedback")
    .min(10, "Please enter at least 10 characters")
});

export const FeedbackResponseForm = ({
  id,
  onSuccess,
  onCancel,
  value: initialValue,
  readOnly
}) => {
  const handleSubmit = useCallback(
    async ({ response }, { setSubmitting, setErrors }) => {
      setSubmitting(true);

      try {
        const { data } = await apiClient.patch(`/api/airbnb_reviews/${id}`, {
          airbnb_listing_review: { response }
        });

        onSuccess(data);
      } catch (error) {
        if (isUnprocessableEntityError(error)) {
          const errors = get(error, "response.data.errors");
          setErrors({ response: get(errors, "response") });
        } else {
          setErrors({ response: "Something went wrong, please try again." });
        }
      } finally {
        setSubmitting(false);
      }
    },
    [id, onSuccess]
  );
  return (
    <Formik
      initialValues={{ response: initialValue || "" }}
      onSubmit={handleSubmit}
      validationSchema={schema}
    >
      {({ isSubmitting, isValid }) => (
        <Form>
          <div className="form-group">
            <label
              htmlFor="response"
              className={classNames({
                "text-muted": readOnly
              })}
            >
              Feedback response:
            </label>
            <Field
              as="textarea"
              name="response"
              disabled={readOnly}
              className="form-control"
              required
            />
            <ErrorMessage name="response">
              {(message) => (
                <div className="invalid-feedback d-block">{message}</div>
              )}
            </ErrorMessage>
          </div>

          {!readOnly && (
            <>
              <button
                type="submit"
                className="btn btn-primary"
                disabled={!isValid || isSubmitting}
              >
                Submit
              </button>
              <button
                type="button"
                className="btn btn-link ml-2"
                onClick={onCancel}
              >
                Cancel
              </button>
            </>
          )}
        </Form>
      )}
    </Formik>
  );
};
