import React, { useState, useEffect } from "react";

import { Toggle as GenericToggle } from "../../../common/forms/Toggle";
import { showErrorToast, showSuccessToast } from "../../../common/modals";
import { updateCompensation } from "./api";

export const CompensationToggle = ({
  onToggleChange,
  bookingId,
  compensation
}) => {
  const [toggleState, setToggleState] = useState(compensation);
  const [submitting, setSubmitting] = useState(false);

  useEffect(() => {
    setToggleState(compensation);
  }, [compensation]);

  const handleChange = async (checked) => {
    setSubmitting(true);
    setToggleState(checked);

    try {
      await updateCompensation(bookingId, checked);
      showSuccessToast("Compensation discussed have been updated successfully");
      onToggleChange(checked);
    } catch (error) {
      showErrorToast("An error occurred, please try again later");
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <>
      <div className="compensation-details discussed-label">
        Compensation discussed:
      </div>
      <div className="compensation-details compensation-details-toggle">
        <GenericToggle
          id="compensation-toggle"
          checked={toggleState}
          onChange={(e) => handleChange(e.target.checked)}
          disabled={submitting}
          isSmall
        />
      </div>
    </>
  );
};
