import capitalize from "lodash/capitalize";
import React from "react";

const { AVAILABLE_STATUSES } = window.AirbaseConstants.Clean;

export const CleanStatusFilter = ({ filter, onFilterChange, scope = null }) => {
  const handleChange = (value) => onFilterChange("status", value);

  const selectedStatuses = filter ? filter.value : [];

  const checkboxId = (status) =>
    ["clean-status-checkbox", scope, status].filter(Boolean).join("-");

  return (
    <div className="clean-filter clean-status-filter">
      {AVAILABLE_STATUSES.map((status) => (
        <div className="checkbox" key={status}>
          <input
            id={checkboxId(status)}
            type="checkbox"
            defaultChecked={selectedStatuses.includes(status)}
            onChange={(event) => {
              const value = event.target.checked
                ? [...selectedStatuses, status]
                : selectedStatuses.filter((s) => s !== status);

              handleChange(value);
            }}
          />
          <label htmlFor={checkboxId(status)}>{capitalize(status)}</label>
        </div>
      ))}
    </div>
  );
};
