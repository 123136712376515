import React, { useCallback, useState } from "react";

import { Toggle as GenericToggle } from "../../../common/forms/Toggle";
import { SwalActions, modal, showErrorToast } from "../../../common/modals";
import warningImage from "../../../images/warning.svg";
import { toggleWarning } from "./api";

export const CleanerWarningToggle = ({ cleanerId, value, newWarningPath }) => {
  const [toggled, setToggled] = useState(value);

  const confirmationPopUp = () =>
    new Promise((res) => {
      const onConfirm = () => {
        res({ confirmed: true });
        modal.close();
      };

      modal.fire({
        html: (
          <>
            <p>Are you sure you want to turn off warning for this cleaner?</p>
            <SwalActions
              confirmText="Yes"
              onCancel={modal.close}
              onConfirm={onConfirm}
            />
          </>
        ),
        title: "Please confirm",
        showConfirmButton: false,
        imageUrl: warningImage,
        showCancelButton: false,
        animation: false
      });
    });

  const turnOffWarning = useCallback(async () => {
    const { warning } = await toggleWarning(cleanerId, false);
    setToggled(warning);

    if (!warning) {
      window.location.reload();
    }
  }, [cleanerId]);

  const handleToggle = useCallback(async () => {
    if (toggled) {
      const { confirmed } = await confirmationPopUp();

      if (!confirmed) {
        return;
      }

      try {
        await turnOffWarning();
      } catch (error) {
        showErrorToast("Unable to update cleaner");
      }
      return;
    }

    // If trying to turn on warning, redirect to new warning form
    window.location.replace(newWarningPath);
  }, [toggled, newWarningPath, turnOffWarning]);

  return (
    <div className="cleaner-warning-toggle">
      <GenericToggle
        id="warning-toggle"
        label="Cleaner currently on a warning"
        checked={toggled}
        changeOrder
        onChange={handleToggle}
      />
    </div>
  );
};
