import React from "react";

export const CheckboxFilter = ({ label, checked, onChange }) => {
  const id = `checkbox-filter-${label.toLowerCase()}`;

  return (
    <div className="mt-2">
      <input
        type="checkbox"
        className="form-check-input"
        id={id}
        checked={checked}
        onChange={onChange}
      />

      <label className="form-check-label" htmlFor={id}>
        {label}
      </label>
    </div>
  );
};
