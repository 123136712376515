import { ErrorMessage, Field, Form, Formik } from "formik";
import React, { useState } from "react";
import * as yup from "yup";

import { apiClient } from "../../../common/apiClient";
import { Modal } from "../../../common/Modal";

const schema = yup.object().shape({
  locked_notes: yup
    .string()
    .required(
      "Please enter a note, preferably with a reason for locking the demand."
    )
});

export const LockButton = ({ locked, id }) => {
  const icon = locked ? "fas fa-unlock" : "fas fa-lock";
  const title = locked ? "Unlock to make changes" : "Lock to prevent changes";
  const [showModal, setShowModal] = useState(false);

  const handleModalToggle = () => {
    setShowModal((prev) => !prev);
  };

  const handleSubmit = async (values = {}) => {
    await apiClient.patch(`/api/clean_demands/${id}/toggle_lock`, {
      clean_demand: { locked: !locked, locked_notes: values.locked_notes || "" }
    });

    window.location.reload();
  };

  return (
    <>
      {showModal && (
        <Modal onClose={handleModalToggle}>
          <Modal.Header>Locking clean demand and connected cleans</Modal.Header>
          <Formik
            initialValues={{ locked_notes: "" }}
            validationSchema={schema}
            onSubmit={handleSubmit}
          >
            {({ isSubmitting, isValid }) => (
              <Form>
                <Modal.Body>
                  <label htmlFor="locked_notes">Notes for locking</label>
                  <Field
                    as="textarea"
                    id="locked_notes"
                    name="locked_notes"
                    className="form-control"
                    rows="3"
                    required
                  />
                  <ErrorMessage name="locked_notes">
                    {(message) => (
                      <div className="invalid-feedback d-block">{message}</div>
                    )}
                  </ErrorMessage>
                </Modal.Body>

                <Modal.Footer>
                  <button
                    type="button"
                    className="btn btn-light"
                    onClick={handleModalToggle}
                  >
                    Cancel
                  </button>
                  <button
                    type="submit"
                    className="btn btn-submit"
                    disabled={!isValid || isSubmitting}
                  >
                    Submit
                  </button>
                </Modal.Footer>
              </Form>
            )}
          </Formik>
        </Modal>
      )}

      <button
        type="button"
        className="btn btn-primary"
        onClick={() => (locked ? handleSubmit() : handleModalToggle())}
      >
        <i data-testid="lock-button" className={icon} title={title} /> {title}
      </button>
    </>
  );
};
