import "./MaintenanceTable.scss";

import { format } from "date-fns";
import { invert } from "lodash";
import React, { useMemo } from "react";
import ReactTable from "react-table";

import { EditButton, ShowButton } from "../../../common/tables/buttons";
import {
  DropdownFilter,
  LocalityFilter,
  PodFilter,
  ClientSuccessFilter
} from "../../../common/tables/filters";
import { useControlledTable } from "../../../common/tables/useControlledTable";
import ActionOptions from "./ActionOptions";
import { MaintenanceTableFilters } from "./MaintenanceTableFilters";

const {
  STATUSES,
  STAGES,
  REPORTER_TYPES,
  LOCATIONS,
  TYPES
} = window.AirbaseConstants.Maintenance;

export const MaintenanceTable = ({ showPods }) => {
  const initialState = {
    sorted: [{ id: "created_at", desc: true }],
    filtered: [
      {
        id: "status",
        value: {
          pending: true,
          accepted: true,
          planned: true,
          host_planned: true
        }
      }
    ]
  };

  const {
    updateDataRow,
    data,
    onFilterChange,
    getFilter,
    getFilterValue,
    showPagination,
    filtered,
    ...controlledProps
  } = useControlledTable({
    api: { endpoint: "/api/maintenance" },
    initialState
  });

  const columns = useMemo(
    () => [
      {
        sortable: false,
        filterable: false,
        maxWidth: 70,
        className: "rt-actions",
        Cell: ({ original: { share_url, edit_path } }) => (
          <span>
            <ShowButton href={share_url} />
            <EditButton href={edit_path} />
          </span>
        )
      },
      {
        Header: "Id",
        accessor: "id",
        sortable: false,
        width: 50,
        filterable: false,
        className: "rt-maintenance-cell",
        Cell: ({ value, original: { share_url } }) => (
          <a href={share_url}>{value}</a>
        )
      },
      {
        Header: "Home code",
        accessor: "property.home_code",
        className: "rt-maintenance-cell",
        Cell: ({ value, original }) => (
          <a href={original.property.show_path} id={original.id}>
            {value}
          </a>
        )
      },
      {
        Header: "Locality",
        accessor: "property.locality_name",
        className: "rt-maintenance-cell",
        filterable: true,
        Filter: (
          <LocalityFilter
            id="locality_name_filter"
            filter={getFilter("property.locality_id")}
            onChange={(value) => {
              onFilterChange("property.locality_id", value);
            }}
          />
        )
      },
      {
        Header: "Description",
        accessor: "description",
        className: "rt-maintenance-cell",
        Cell: ({ value, original: { share_url } }) => (
          <a href={share_url} title={value}>
            {value}
          </a>
        )
      },

      {
        Header: "Reporter Type",
        accessor: "reporter_type",
        className: "rt-maintenance-cell",
        Cell: ({ value }) => <span>{REPORTER_TYPES[value]}</span>,
        filterable: true,
        Filter: (
          <DropdownFilter
            id="reporter_type_filter"
            filter={getFilter("reporter_type")}
            onChange={(value) => onFilterChange("reporter_type", value)}
            values={Object.entries(invert(REPORTER_TYPES))}
          />
        )
      },
      {
        Header: "Pod",
        accessor: "property.pod_name",
        className: "rt-maintenance-cell",
        filterable: true,
        Filter: (
          <PodFilter
            id="pod_name_filter"
            filter={getFilter("property.pod_id")}
            onChange={(value) => onFilterChange("property.pod_id", value)}
          />
        )
      },
      showPods
        ? {
            Header: "Client Success",
            accessor: "property.client_success_name",
            className: "rt-maintenance-cell",
            filterable: true,
            Filter: (
              <ClientSuccessFilter
                id="cs_name_filter"
                filter={getFilter("property.client_success_id")}
                onChange={(value) =>
                  onFilterChange("property.client_success_id", value)
                }
              />
            )
          }
        : {},
      {
        Header: "Status",
        accessor: "status",
        className: "rt-maintenance-cell",
        Cell: ({ value }) => (
          <span title={STATUSES[value]}>{STATUSES[value]}</span>
        )
      },
      {
        Header: "Stage",
        accessor: "stage",
        className: "rt-maintenance-cell",
        Cell: ({ value }) => (
          <span title={STAGES[value]}>{STAGES[value] || "-- not set --"}</span>
        ),
        filterable: true,
        Filter: (
          <DropdownFilter
            id="stage_filter"
            filter={getFilter("stage")}
            onChange={(value) => onFilterChange("stage", value)}
            values={Object.entries(invert(STAGES))}
          />
        )
      },
      {
        Header: "Issue Location",
        accessor: "location",
        className: "rt-maintenance-cell",
        Cell: ({ value }) => <span>{LOCATIONS[value]}</span>,
        filterable: true,
        Filter: (
          <DropdownFilter
            id="location"
            filter={getFilter("location")}
            onChange={(value) => onFilterChange("location", value)}
            values={Object.entries(invert(LOCATIONS))}
          />
        )
      },
      {
        Header: "Type",
        accessor: "type",
        className: "rt-maintenance-cell",
        Cell: ({ value, original: { description } }) => (
          <span title={description}>{TYPES[value]}</span>
        ),
        filterable: true,
        Filter: (
          <DropdownFilter
            id="type_filter"
            filter={getFilter("type")}
            onChange={(value) => onFilterChange("type", value)}
            values={Object.entries(invert(TYPES))}
          />
        )
      },
      {
        Header: "Date raised",
        accessor: "created_at",
        className: "rt-maintenance-cell",
        Cell: ({ value }) => (
          <span>{format(new Date(value), "dd/MM/yyyy")}</span>
        )
      },
      {
        sortable: false,
        width: 120,
        className: "rt-maintenance-cell maintenance-action-cell",
        Cell: ({ original }) => (
          <ActionOptions data={original} onUpdate={updateDataRow} />
        )
      }
    ],
    [getFilter, onFilterChange, updateDataRow, showPods]
  );

  return (
    <div className="maintenance-table">
      <MaintenanceTableFilters
        getFilter={getFilter}
        getFilterValue={getFilterValue}
        onFilterChange={onFilterChange}
      />

      <ReactTable
        {...controlledProps}
        data={data}
        filtered={filtered}
        defaultSorted={[{ id: "created_at", desc: true }]}
        className="-striped -highlight"
        columns={columns}
      />

      {showPagination && (
        <h4>Total maintenance items {controlledProps.totalCount}</h4>
      )}
    </div>
  );
};
