import { getFee } from "./Field";

const fees = {
  id: 1,
  small_single: "10.0",
  single: "15.22",
  small_double: "20.0",
  double: "25.0",
  queen: "30.0",
  king: "40.11",
  super_king: "50.0",
  delivery: "15.0"
};

const beds = [
  {
    mattress_size: "small_single"
  },
  {
    mattress_size: "single"
  },
  {
    mattress_size: "small_double"
  },
  {
    mattress_size: "double"
  },
  {
    mattress_size: "queen"
  },
  {
    mattress_size: "king"
  },
  {
    mattress_size: "super_king"
  }
];

describe(".getFee", () => {
  it("sums values correctly", () => {
    const result = getFee(beds, fees);

    expect(result).toBe("205.33");
  });

  it("adds 0 if no value is defined", () => {
    const result = getFee(
      [
        {
          matress_size: "some weird size"
        }
      ],
      fees
    );

    expect(result).toBe("15.00"); // delivery fee
  });

  it("adds 0 as delivery fee if no delivery fee", () => {
    delete fees.delivery;

    const result = getFee(beds, fees);

    expect(result).toBe("190.33");
  });
});
