/* eslint-disable func-names */
import React from "react";

import { Modal } from "../../../common/Modal";
import { CopyTextBox } from "../../clipboard/CopyTextBox/CopyTextBox";

export const ModulrAccountDetailsModal = ({
  modulr_account,
  onClose,
  showAirbnbNote
}) => {
  return (
    <Modal onClose={onClose}>
      <Modal.Header>Modulr Account Details</Modal.Header>
      <Modal.Body>
        {showAirbnbNote && (
          <label className="warning">
            Modulr account details below should ONLY be used for the purpose of
            Airbnb payout routing for this property.
          </label>
        )}
        {modulr_account.identifier_type === "SCAN" ? (
          <div className="modulr_account">
            <p>
              <strong>Sort code: </strong>
              <CopyTextBox text={modulr_account.sort_code} />
            </p>
            <p>
              <strong>Account number: </strong>
              <CopyTextBox text={modulr_account.account_number} />
            </p>
          </div>
        ) : (
          <div className="modulr_account">
            <p>
              <strong>IBAN: </strong>
              <CopyTextBox text={modulr_account.iban} />
            </p>
          </div>
        )}
      </Modal.Body>

      <Modal.Footer>
        <button type="button" className="btn btn-light" onClick={onClose}>
          Close
        </button>
      </Modal.Footer>
    </Modal>
  );
};
