import { connect } from "formik";
import React from "react";

import { AvailableSelect } from "./AvailableSelect";
import { Fieldset } from "./Fieldset";

export const FieldsetWithAvailabilitySelect = connect(
  ({ name, label, children, description, airbnbSync }) => (
    <Fieldset label={label}>
      <AvailableSelect
        name={name}
        label={`${label} available`}
        description={description}
        airbnbSync={airbnbSync}
      />
      {children}
    </Fieldset>
  )
);
