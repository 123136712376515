import React, { useState } from "react";

import { formatDate } from "../../../common/date";
import { AirbnbBlockForm } from "./AirbnbBlockForm";

export const AirbnbBlock = ({ propertyId, hostPreferenceSettings }) => {
  const [showPropertyModal, setShowPropertyModal] = useState(false);

  const { airbnb_block_start, airbnb_block_end } = hostPreferenceSettings || {};

  const handleModalToggle = () => setShowPropertyModal((prev) => !prev);

  const hasBlockDatesSet = Boolean(airbnb_block_start || airbnb_block_end);

  return (
    <div className="edit-icon">
      <button
        type="button"
        className="btn btn-primary mb-3"
        onClick={handleModalToggle}
      >
        <i className="fas fa-edit" />
        &nbsp; {hasBlockDatesSet ? "Update" : "Block"} availability
      </button>

      {hasBlockDatesSet && (
        <p>
          Availability is blocked from{" "}
          {formatDate(new Date(airbnb_block_start))} to{" "}
          {formatDate(new Date(airbnb_block_end))}
        </p>
      )}

      {showPropertyModal && (
        <AirbnbBlockForm
          propertyId={propertyId}
          hostPreferenceSettings={hostPreferenceSettings || {}}
          onClose={handleModalToggle}
        />
      )}
    </div>
  );
};
