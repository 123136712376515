import { combineReducers } from "redux";

import { reducer as filesReducer } from "./files/reducer";
import { reducer as propertyReducer } from "./property/reducer";
import { reducer as toastReducer } from "./toast/reducer";
import { reducer as uploadReducer } from "./upload/reducer";

export const reducer = combineReducers({
  property: propertyReducer,
  files: filesReducer,
  upload: uploadReducer,
  toast: toastReducer
});
