import { combineReducers } from "redux";
import { combineActions, handleAction, handleActions } from "redux-actions";

import { DEFAULT_FOLDER } from "../../constants";
import { uploadFileResponse } from "../upload/actions";
import {
  batchOperationResponse,
  changeCurrentFolder,
  changeFilesSortingField,
  deleteFileResponse,
  deleteFilesResponse,
  fetchFilesRequest,
  fetchFilesResponse,
  toggleFileSelection,
  setFilesSelection,
  toggleFilesSortingDirection,
  updateFileResponse
} from "./actions";

export const SORT_DESC = "desc";
export const SORT_ASC = "asc";

export const DEFAULT_SORTING_DIRECTIONS = {
  created_at: SORT_DESC,
  updated_at: SORT_DESC,
  file_name: SORT_ASC,
  file_size: SORT_DESC
};

const loadingReducer = handleActions(
  {
    [fetchFilesRequest]: () => true,
    [fetchFilesResponse]: () => false
  },
  true
);

const listReducer = handleActions(
  {
    [fetchFilesResponse]: (state, { payload: list }) => list,

    [uploadFileResponse]: {
      next: (state, { payload: uploadedFile }) => [...state, uploadedFile]
    },

    [updateFileResponse]: (state, { payload: updatedFile }) =>
      state.map((file) => (file.id === updatedFile.id ? updatedFile : file)),

    [deleteFileResponse]: (state, { payload: id }) =>
      state.filter((file) => file.id !== id),

    [deleteFilesResponse]: (state, { payload: ids }) =>
      state.filter((file) => !ids.includes(file.id))
  },
  []
);

const currentFolderNameReducer = handleAction(
  changeCurrentFolder,
  (state, { payload: folder }) => folder,
  DEFAULT_FOLDER
);

const sortingFieldReducer = handleAction(
  changeFilesSortingField,
  (state, { payload: field }) => field,
  "created_at"
);

const sortingDirectionReducer = handleActions(
  {
    [changeFilesSortingField]: (state, { payload: field }) =>
      DEFAULT_SORTING_DIRECTIONS[field],

    [toggleFilesSortingDirection]: (state) =>
      state === SORT_ASC ? SORT_DESC : SORT_ASC
  },
  DEFAULT_SORTING_DIRECTIONS.created_at
);

export const DEFAULT_SELECTION_STATE = { ids: [] };
const selectionReducer = handleActions(
  {
    [toggleFileSelection]: (state, { payload: { id, isSelected } }) => ({
      ids: isSelected
        ? [...state.ids, id]
        : state.ids.filter((fileId) => fileId !== id)
    }),
    [setFilesSelection]: (state, { payload: ids }) => ({ ids }),
    // Reset selection on any action that changes files list
    [combineActions(
      fetchFilesResponse,
      batchOperationResponse,
      deleteFileResponse,
      deleteFilesResponse,
      changeCurrentFolder,
      updateFileResponse
    )]: () => DEFAULT_SELECTION_STATE
  },
  DEFAULT_SELECTION_STATE
);

export const reducer = combineReducers({
  loading: loadingReducer,
  list: listReducer,
  currentFolderName: currentFolderNameReducer,
  selection: selectionReducer,
  sorting: combineReducers({
    field: sortingFieldReducer,
    direction: sortingDirectionReducer
  })
});
