export const humanize = (str) =>
  (str === null || str === undefined ? "" : str)
    .toString()
    .replace(/^[\s_]+|[\s_]+$/g, "")
    .replace(/[_\s]+/g, " ")
    .replace(/^[a-z]/, (str) => str.toUpperCase());

export const simplePluralize = (noun, amount) => {
  if (amount === 0 || amount > 1) {
    return `${amount} ${noun}s`;
  }

  return `${amount} ${noun}`;
};
