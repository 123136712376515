import { Formik } from "formik";
import React, { useContext } from "react";
import * as yup from "yup";

import { SelectOptions } from "../../../../common/SelectOptions";
import { FormGroup } from "../components/FormGroup";
import { HomeInfoContext } from "../HomeInfoContext";
import { HomeInfoForm } from "../HomeInfoForm";

const NOISE_LEVELS = ["quiet", "medium", "loud"];

const validationSchema = yup.object().shape({
  unit_noise_level_notes: yup.string().max(300).label("Unit noise level notes")
});

export const PropertyNoise = () => {
  const { homeInfo, updateHomeInfo } = useContext(HomeInfoContext);

  return (
    <Formik
      initialValues={homeInfo}
      enableReinitialize
      onSubmit={updateHomeInfo}
      validationSchema={validationSchema}
    >
      <HomeInfoForm sidebarName="Property">
        <h4 className="section-name">Noise levels</h4>

        <div className="form-row">
          <div className="col-md-4">
            <FormGroup
              name="unit_noise_level"
              label="Unit noise level"
              component="select"
            >
              <SelectOptions options={NOISE_LEVELS} />
            </FormGroup>
          </div>
        </div>

        <FormGroup
          name="unit_noise_level_notes"
          label="Unit noise level notes"
          component="textarea"
          rows={5}
          characterCount={300}
        />

        <FormGroup
          name="quiet_time_starts"
          label="Quiet time starts at"
          component="input"
          type="time"
        />

        <FormGroup
          name="quiet_time_ends"
          label="Quiet time ends at"
          component="input"
          type="time"
        />
      </HomeInfoForm>
    </Formik>
  );
};
