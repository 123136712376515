import "./PropertyFiles.scss";

import { createBrowserHistory } from "history";
import find from "lodash/find";
import React, { useEffect } from "react";
import { Provider } from "react-redux";
import { Router } from "react-router-dom";

import { BulkActionsMenu } from "./components/BulkActionsMenu";
import { FileListContainer } from "./components/FileListContainer";
import { FileListSortingContainer } from "./components/FileListSortingContainer";
import { NavTabsContainer } from "./components/NavTabsContainer";
import { SelectAllButton } from "./components/SelectAllButton";
import { FOLDERS } from "./constants";
import { changeCurrentFolder } from "./data/files/actions";
import { fetchFiles } from "./data/files/thunks";
import { fetchPropertyResponse } from "./data/property/actions";
import { store } from "./data/store";

export const PropertyFiles = ({ property }) => {
  const history = createBrowserHistory({
    basename: `/properties/${property.id}/files`
  });

  // Watch the current folder change
  useEffect(() => {
    const folderChangeListener = (location) => {
      const folder = find(FOLDERS, { path: location.pathname });

      if (folder) {
        store.dispatch(changeCurrentFolder(folder.name));
      }
    };

    folderChangeListener(history.location);

    const stopListening = history.listen(folderChangeListener);

    return () => stopListening();
  }, [history, property]);

  // Initially load all files
  useEffect(() => {
    store.dispatch(fetchPropertyResponse(property));
    store.dispatch(fetchFiles());
  }, [property]);

  return (
    <Provider store={store}>
      <Router history={history}>
        <h2 className="mr-2">Property Files</h2>
        <div className="twbs property-files">
          <div className="d-flex property-files-actions">
            <div className="d-flex flex-grow-1 mr-3 actions-item">
              <BulkActionsMenu property={property} />
            </div>

            <div className="d-flex actions-item">
              <SelectAllButton />
              <FileListSortingContainer />
            </div>
          </div>

          <NavTabsContainer />

          <FileListContainer />
        </div>
      </Router>
    </Provider>
  );
};
