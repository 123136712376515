import "./AmenitiesForm.scss";

import classNames from "classnames";
import { FieldArray, Formik } from "formik";
import React, {
  useEffect,
  useState,
  useContext,
  useMemo,
  useCallback
} from "react";

import { Toggle } from "../../../../common/forms/Toggle";
import { Tooltip } from "../../../../common/Tooltip";
import * as api from "../api";
import { AmenityAssets } from "../components/AmenityAssets";
import { FormGroup } from "../components/FormGroup";
import { prepareAmenitiesData } from "../helpers";
import { HomeInfoContext } from "../HomeInfoContext";
import { HomeInfoForm } from "../HomeInfoForm";
import { getInstructions } from "./amenity_instructions";
import { AMENITY_DESCRIPTIONS } from "./constants/amenity_descriptions";
import { ACCESSIBILITY_CATEGORIES } from "./constants/amenityCategories";

const isAlarm = (key) => ["smoke_alarm", "carbon_monoxide_alarm"].includes(key);

const validateLength = (value) => {
  if (value?.length > 300) {
    return "External notes must be at most 300 characters";
  }

  return null;
};

export const AmenitiesForm = ({ category, title }) => {
  const [amenityOptions, setAmenityOptions] = useState([]);
  const { homeInfo, updateHomeInfo, setIsModified, setCategory } = useContext(
    HomeInfoContext
  );
  const amenityValues = useMemo(
    () => homeInfo?.home_info_amenities_attributes[category] || [],
    [homeInfo, category]
  );

  const {
    ACCESSIBILITY_STATUS_IN_REVIEW
  } = window.AirbaseConstants.HomeInfoAmenity;

  const isInReview = (amenity) =>
    amenity.accessibility_amenity_status === ACCESSIBILITY_STATUS_IN_REVIEW;

  useEffect(() => {
    setCategory(category);
  }, [category, setCategory]);

  useEffect(() => {
    api.fetchAmenityTypes(category).then((res) => {
      if (res) {
        const amenities = prepareAmenitiesData(res.rows, amenityValues);

        setAmenityOptions(amenities);
      }
    });
  }, [category, amenityValues]);

  /**
   * Properties in Lisbon that have a registration number
   * must have fire extinguisher and first aid kit amenities turned on.
   * So if it's turned on the toggle is disabled
   * and a message about it appears so it's not accidentally turned off.
   *
   * To rewrite if there are more similar cases to this.
   */
  const isDisabled = useCallback(
    (amenityKey, isAvailable) => {
      if (homeInfo?.locality?.name !== "Lisbon") {
        return false;
      }

      if (isAvailable && homeInfo.registration_number) {
        if (
          amenityKey === "fire_extinguisher" ||
          amenityKey === "first_aid_kit"
        ) {
          return true;
        }
      }

      return false;
    },
    [homeInfo?.locality?.name, homeInfo.registration_number]
  );

  return (
    <Formik
      initialValues={{ amenityOptions }}
      enableReinitialize
      onSubmit={updateHomeInfo}
    >
      {({ setFieldValue, values }) => (
        <HomeInfoForm sidebarName="Operate" type="amenities">
          <h4 className="section-name">{title}</h4>
          <FieldArray
            name={`home_info_amenities_attributes.${category}`}
            render={() => {
              return (
                <div className="amenities-list">
                  {values.amenityOptions &&
                    values.amenityOptions.map((amenity, index) => {
                      const isAvailable =
                        values.amenityOptions[index].available;
                      return (
                        <div key={amenity.key}>
                          <Tooltip
                            text="Amenity is in review"
                            disabled={!isInReview(amenity)}
                          >
                            <div className="amenity-type">
                              <div className={`amenity-icon ${amenity.key}`} />
                              <Toggle
                                id={amenity.name}
                                label={amenity.name}
                                checked={amenity.available}
                                onChange={() => {
                                  setIsModified(true);
                                  const newAvailableValue = !values
                                    .amenityOptions[index].available;
                                  setFieldValue(
                                    `amenityOptions.${index}.available`,
                                    newAvailableValue
                                  );
                                  if (!newAvailableValue) {
                                    setFieldValue(
                                      `amenityOptions.${index}.expanded`,
                                      false
                                    );
                                  }
                                }}
                                changeOrder
                                disabled={
                                  isDisabled(amenity.key, isAvailable) ||
                                  isInReview(amenity)
                                }
                                description={AMENITY_DESCRIPTIONS[amenity.key]}
                                airbnbSync
                              />

                              <div
                                className={classNames("expand", {
                                  disabled: !isAvailable
                                })}
                                onClick={() => {
                                  if (isAvailable) {
                                    setFieldValue(
                                      `amenityOptions.${index}.expanded`,
                                      !values.amenityOptions[index].expanded
                                    );
                                  }
                                }}
                                role="button"
                                tabIndex="0"
                              >
                                {" "}
                              </div>
                            </div>
                          </Tooltip>

                          <div
                            className={classNames("expanded-details", {
                              expanded: values.amenityOptions[index].expanded
                            })}
                          >
                            <div className="details-notes">
                              <FormGroup
                                name={`amenityOptions.${index}.external_notes`}
                                label="External notes"
                                component="textarea"
                                rows={3}
                                visibleToGuests
                                disabled={isInReview(amenity)}
                                instructions={getInstructions(amenity.key)}
                                characterCount={
                                  isAlarm(amenity.key) ? 300 : null
                                }
                                validate={
                                  isAlarm(amenity.key)
                                    ? validateLength
                                    : undefined
                                }
                              />

                              <FormGroup
                                name={`amenityOptions.${index}.internal_notes`}
                                label="Internal notes"
                                component="textarea"
                                disabled={isInReview(amenity)}
                                rows={3}
                              />
                            </div>
                            {ACCESSIBILITY_CATEGORIES.includes(category) &&
                            values.amenityOptions[index].expanded ? (
                              <div>
                                <div className="photo-info-section">
                                  <div>Upload files:</div>
                                  {amenity.photo_description && (
                                    <>
                                      <div className="expand-label">
                                        Requirements
                                        <div
                                          className="expand"
                                          onClick={() => {
                                            if (isAvailable) {
                                              setFieldValue(
                                                `amenityOptions.${index}.showRequirements`,
                                                !values.amenityOptions[index]
                                                  .showRequirements
                                              );
                                            }
                                          }}
                                          role="button"
                                          tabIndex="0"
                                        >
                                          {" "}
                                        </div>
                                      </div>
                                      <div
                                        className={classNames(
                                          "expanded-details",
                                          {
                                            expanded:
                                              values.amenityOptions[index]
                                                .showRequirements
                                          }
                                        )}
                                      >
                                        {amenity.photo_description}
                                      </div>
                                    </>
                                  )}
                                </div>
                                <AmenityAssets
                                  amenityTypeId={
                                    amenity.home_info_amenity_type_id
                                  }
                                  amenityStatus={
                                    amenity.accessibility_amenity_status
                                  }
                                  thumbnailUrl={amenity.image_thumbnail_url}
                                />
                              </div>
                            ) : null}
                          </div>
                          {isDisabled(amenity.key, isAvailable) && (
                            <p>
                              This amenity must be turned on for Lisbon
                              properties with a registration number
                            </p>
                          )}
                        </div>
                      );
                    })}
                </div>
              );
            }}
          />
        </HomeInfoForm>
      )}
    </Formik>
  );
};
