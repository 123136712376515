import "./PropertiesTable.scss";

import React, { useMemo } from "react";
import ReactTable from "react-table";

import {
  GenericSearchFilter,
  LocalityFilter,
  PodFilter
} from "../../../common/tables/filters";
import { useControlledTable } from "../../../common/tables/useControlledTable";
import { StatusFilter } from "./StatusFilter";

const sortableHeader = (title) => (
  <span title={title}>
    {title}
    <i className="fa fa-sort" />
  </span>
);

const STATUS_COLOR_MAP = {
  Live: "green",
  Offboarded: "red",
  Pending: "orange",
  Lost: "red"
};

export const PropertiesTable = ({ isRestricted }) => {
  const controlledProps = useControlledTable({
    api: { endpoint: "/api/properties" },
    initialState: {}
  });

  const { showPagination, onFilterChange, getFilter } = controlledProps;

  const columns = useMemo(
    () => [
      {
        Header: sortableHeader("Home code"),
        accessor: "home_code",
        maxWidth: 130,
        className: "rt-uppercase",
        Cell: ({ original: { display_id, edit_path } }) => (
          <a href={edit_path}>{display_id}</a>
        )
      },
      {
        Header: "Status",
        accessor: "status",
        sortable: false,
        filterable: true,
        Filter: StatusFilter,
        maxWidth: 130,
        getProps: (state, rowInfo) => ({
          className: STATUS_COLOR_MAP[rowInfo && rowInfo.row.status]
        })
      },
      {
        Header: sortableHeader("Locality"),
        accessor: "locality.name",
        filterable: true,
        className: "properties-cell",
        sortable: true,
        maxWidth: 210,
        Filter: (
          <LocalityFilter
            id="locality_name_filter"
            filter={getFilter("locality.id")}
            onChange={(value) => {
              onFilterChange("locality.id", value);
            }}
          />
        ),
        Cell: ({ original: { locality } }) => {
          if (locality.id) {
            return (
              <span>
                {locality.name} ({locality.country_code})
              </span>
            );
          }
          return "-- not set --";
        }
      },
      {
        Header: sortableHeader("Pod"),
        accessor: "pod.name",
        filterable: true,
        className: "properties-cell",
        sortable: true,
        maxWidth: 210,
        Filter: (
          <PodFilter
            id="pod_name_filter"
            filter={getFilter("pod.id")}
            onChange={(value) => {
              onFilterChange("pod.id", value);
            }}
          />
        ),
        Cell: ({ original: { pod } }) => {
          if (pod.id) {
            return <span>{pod.name}</span>;
          }
          return "-- not set --";
        }
      },
      {
        Header: sortableHeader("Street"),
        accessor: "street",
        className: "rt-cell-with-text-wrap",
        sortable: true
      },
      {
        Header: sortableHeader("Postcode"),
        className: "rt-uppercase",
        accessor: "postcode",
        sortable: true
      },
      {
        Header: sortableHeader("City"),
        accessor: "city",
        className: "rt-cell-with-text-wrap",
        sortable: true
      },
      {
        Header: "Lead",
        accessor: "lead",
        sortable: false,
        className: "rt-cell-with-two-rows",
        Cell: ({ value: { full_name, show_path } }) => {
          return (
            <a href={show_path}>
              <span className="primary">{full_name}</span>
            </a>
          );
        }
      },
      isRestricted
        ? {
            Header: "Source",
            sortable: false,
            className: "rt-cell-with-two-rows",
            Cell: ({ original }) => {
              const houstSource = original.closeio_opportunity_id && "Houst";
              const otherSource = original.nextmatter_instance_id
                ? original.pod.name
                : "N/A";

              return (
                <span className="primary">{houstSource || otherSource}</span>
              );
            }
          }
        : {}
    ],
    [getFilter, onFilterChange, isRestricted]
  );

  return (
    <div className="properties-table">
      <div className="filter-options twbs">
        <GenericSearchFilter
          onChange={(value) => onFilterChange("search", value)}
          filter={getFilter("search")}
          placeholder="Search by Home code, Address / Post code, Airbnb id / login and Lead name / email"
        />
      </div>

      <>
        <ReactTable
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...controlledProps}
          className="-striped -highlight"
          multiSort
          columns={columns}
        />

        {showPagination && (
          <h4>Total number of properties {controlledProps.totalCount}</h4>
        )}
      </>
    </div>
  );
};
