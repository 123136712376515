import { SORT_ASC, SORT_DESC } from "./reducer";
import {
  allFilesSelected,
  countFilesByFolder,
  selectCurrentFolderName,
  selectFilesSorting,
  selectSortedFiles
} from "./selectors";

const state = {
  files: {
    selection: { ids: [1] },
    list: [
      { id: 1, created_at: 1000, folder_name: "Appliances" },
      { id: 2, created_at: 1000, folder_name: "Other" },
      { id: 3, created_at: 999, folder_name: "Appliances" }
    ],
    currentFolderName: "Appliances",
    sorting: {
      field: "created_at",
      direction: SORT_ASC
    }
  }
};

describe(".countFilesByFolder", () => {
  it("counts files in the given folder", () => {
    expect(countFilesByFolder(state)).toEqual({
      Appliances: 2,
      Other: 1
    });
  });
});

describe(".selectCurrentFolderName", () => {
  it("returns the current folder", () => {
    expect(selectCurrentFolderName(state)).toBe("Appliances");
  });
});

describe(".selectFilerSorting", () => {
  it("return files sorting", () => {
    expect(selectFilesSorting(state)).toEqual({
      field: "created_at",
      direction: SORT_ASC
    });
  });
});

describe(".selectSortedFiles", () => {
  it("returns sorted files", () => {
    // When
    const files = selectSortedFiles(state);

    // Then
    expect(files.length).toBe(2);
    expect(files).toEqual([
      { id: 3, created_at: 999, folder_name: "Appliances", selected: false },
      { id: 1, created_at: 1000, folder_name: "Appliances", selected: true }
    ]);
  });

  it('returns sorted files for "Other" folder', () => {
    // When
    const files = selectSortedFiles({
      files: {
        ...state.files,
        currentFolderName: "Other"
      }
    });

    // Then
    expect(files.length).toBe(1);
    expect(files).toEqual([
      { id: 2, created_at: 1000, folder_name: "Other", selected: false }
    ]);
  });

  it("returns sorted files with different sorting", () => {
    // When
    const files = selectSortedFiles({
      files: {
        ...state.files,
        sorting: {
          field: "folder_name",
          direction: SORT_DESC
        }
      }
    });

    // Then
    expect(files.length).toBe(2);
    expect(files).toEqual([
      { id: 1, created_at: 1000, folder_name: "Appliances", selected: true },
      { id: 3, created_at: 999, folder_name: "Appliances", selected: false }
    ]);
  });

  it("returns files with correct selected flag when all selected", () => {
    // When
    const files = selectSortedFiles({
      files: {
        ...state.files,
        selection: { ids: [1, 3] }
      }
    });

    // Then
    expect(files).toEqual([
      { id: 3, created_at: 999, folder_name: "Appliances", selected: true },
      { id: 1, created_at: 1000, folder_name: "Appliances", selected: true }
    ]);
  });

  it("returns files with correct selected flag when none selected", () => {
    // When
    const files = selectSortedFiles({
      files: {
        ...state.files,
        selection: { ids: [] }
      }
    });

    // Then
    expect(files).toEqual([
      { id: 3, created_at: 999, folder_name: "Appliances", selected: false },
      { id: 1, created_at: 1000, folder_name: "Appliances", selected: false }
    ]);
  });
});

describe(".allFilesSelected", () => {
  it("returns true when all files from current folder are selected", () => {
    // When
    const result = allFilesSelected({
      files: {
        ...state.files,
        selection: { ids: [1, 3] }
      }
    });

    // Then
    expect(result).toBe(true);
  });

  it("returns false when not all files are selected", () => {
    // When
    const result = allFilesSelected({
      files: {
        ...state.files,
        selection: { ids: [2] }
      }
    });

    // Then
    expect(result).toBe(false);
  });
});
