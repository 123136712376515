import "./StartEndMap.scss";
import React, { useRef, useState } from "react";
import { withGoogleMap, withScriptjs } from "react-google-maps";

import {
  GoogleMapWrapper,
  MARKER_COMPLETE_ICON,
  MARKER_HOME_ICON,
  MARKER_START_ICON
} from "./GoogleMapWrapper";
import { coordsMissing, distanceBetweenPoints } from "./utils";

export const MapWithLegend = withScriptjs(
  withGoogleMap(({ visible, coordinates }) => {
    const mapRef = useRef();
    const [zoom, setZoom] = useState(18);
    const { property, start, complete } = coordinates;

    const handleLegendIconClick = (location) => () => {
      mapRef.current.panTo(location);
      setZoom(20);
    };

    const handleZoomChanged = () => {
      setZoom(mapRef.current.getZoom());
    };

    return (
      <>
        <GoogleMapWrapper
          coordinates={coordinates}
          ref={mapRef}
          zoom={zoom}
          onZoomChanged={handleZoomChanged}
          visible={visible}
        />

        <figcaption>
          <div>
            {coordsMissing(property) && (
              <div>Property coordinates are missing</div>
            )}
          </div>
          <div>
            {coordsMissing(start) && (
              <div>Clean start coordinates are missing</div>
            )}
          </div>
          <div>
            {coordsMissing(complete) && (
              <div>Clean completion coordinates are missing</div>
            )}
          </div>

          <div className="StartEndMap--map-buttons">
            <div className="StartEndMap--map-button">
              <button
                type="button"
                data-testid="center-map-button-property"
                className="btn btn-light"
                title="Center map on property"
                onClick={handleLegendIconClick(property)}
              >
                <img
                  width="30"
                  height="30"
                  src={MARKER_HOME_ICON}
                  alt="Center map on property"
                />
              </button>
              <span>Property</span>
            </div>

            {!coordsMissing(start) && (
              <div className="StartEndMap--map-button">
                <button
                  data-testid="center-map-button-start"
                  type="button"
                  className="btn btn-light"
                  title="Center map on clean start"
                  onClick={handleLegendIconClick(start)}
                >
                  <img
                    src={MARKER_START_ICON}
                    alt="Center map on clean start"
                  />
                </button>
                <span>
                  Clean start
                  <br />
                  {distanceBetweenPoints(property, start)}km away
                </span>
              </div>
            )}

            {!coordsMissing(complete) && (
              <div className="StartEndMap--map-button">
                <button
                  data-testid="center-map-button-complete"
                  type="button"
                  className="btn btn-light"
                  title="Center map on clean completion"
                  onClick={handleLegendIconClick(complete)}
                >
                  <img
                    src={MARKER_COMPLETE_ICON}
                    alt="Center map on clean completion"
                  />
                </button>
                <span>
                  Clean end
                  <br />
                  {distanceBetweenPoints(property, complete)}km away
                </span>
              </div>
            )}
          </div>
        </figcaption>
      </>
    );
  })
);
