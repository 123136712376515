import "./NestedFields.scss";

import { connect } from "formik";
import get from "lodash/get";
import has from "lodash/has";
import React from "react";

const NestedFields = connect(
  ({ name, formik: { values, setFieldValue }, onRemove, children }) => {
    const removed = get(values, `${name}._destroy`);

    const handleRemove = () => {
      if (!window.confirm("Are you sure?")) {
        return;
      }

      if (has(values, `${name}.id`)) {
        setFieldValue(`${name}._destroy`, true);
      } else {
        onRemove();
      }
    };

    if (removed) {
      return null;
    }

    return (
      <div className="card nested-fields">
        <div className="card-body">
          {onRemove && (
            <div className="remove-button">
              <button
                type="button"
                className="btn btn-light btn-sm"
                onClick={handleRemove}
              >
                <span className="fa fa-trash" />
              </button>
            </div>
          )}

          {children}
        </div>
      </div>
    );
  }
);

NestedFields.AddButton = ({ onClick, label }) => (
  <div className="mt-2 text-right add-button">
    <button type="button" onClick={onClick} className="btn btn-light">
      <i className="fa fa-plus" /> {label}
    </button>
  </div>
);

export { NestedFields };
