import classNames from "classnames";
import React from "react";
import { useDispatch, useSelector } from "react-redux";

import { setFilesSelection } from "../data/files/actions";
import {
  allFilesSelected as allFilesSelectedSelector,
  selectSortedFiles
} from "../data/files/selectors";

export const SelectAllButton = () => {
  const dispatch = useDispatch();
  const allFilesSelected = useSelector(allFilesSelectedSelector);
  const files = useSelector(selectSortedFiles);

  const handleClick = () => {
    const allFilesIds = files.map(({ id }) => id);
    return dispatch(setFilesSelection(allFilesSelected ? [] : allFilesIds));
  };

  return (
    <button
      title={allFilesSelected ? "Deselect all" : "Select all"}
      type="button"
      className="btn btn-light"
      onClick={handleClick}
    >
      <i
        className={classNames("far", {
          "fa-check-circle": !allFilesSelected,
          "fa-circle": allFilesSelected
        })}
      />
    </button>
  );
};
