import React, { useState } from "react";

import { LocationPickerModal } from "./LocationPickerModal";

export const PickLocationButton = ({ location, address, onConfirm }) => {
  const [showLocationPicker, setShowLocationPicker] = useState(false);

  return (
    <>
      <div className="btn-group w-100">
        <button
          type="button"
          className="btn btn-info"
          onClick={() => setShowLocationPicker(true)}
        >
          Pick location
        </button>
      </div>

      {showLocationPicker && (
        <LocationPickerModal
          location={location}
          address={address}
          onCancel={() => setShowLocationPicker(false)}
          onConfirm={(location) => {
            onConfirm(location);
            setShowLocationPicker(false);
          }}
        />
      )}
    </>
  );
};
