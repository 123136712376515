import { FieldArray, Formik } from "formik";
import range from "lodash/range";
import React, { useCallback, useContext } from "react";
import * as yup from "yup";

import { SelectOptions } from "../../../../common/SelectOptions";
import { FieldsetWithAvailabilitySelect } from "../components/FieldsetWithAvailabilitySelect";
import { FormGroup } from "../components/FormGroup";
import { NestedFields } from "../components/NestedFields";
import { HomeInfoContext } from "../HomeInfoContext";
import { HomeInfoForm } from "../HomeInfoForm";

const BED_SIZES = [
  "small_single",
  "single",
  "small_double",
  "double",
  "king",
  "super_king",
  "queen"
];
const BED_TYPES = ["primary", "sofa", "airbed", "trundle"];

const NUMBER_OR_BEDROOMS_OPTIONS = range(0, 11);

export const validationSchema = yup.object().shape({
  property_attributes: yup.object().shape({
    bedrooms: yup.number().required("Please select")
  }),
  beds_attributes: yup
    .array()
    .of(
      yup.object().shape({
        mattress_size: yup.string().required("Please select mattress size"),
        bed_type: yup.string().required("Please select bed type")
      })
    )
    .test(
      "at-least-one-primary",
      "The property must have at least one primary bed set up",
      (values) => values.some((value) => value.bed_type === "primary")
    )
});

const getInitialBedValues = (values) => ({
  property_id: values.property_attributes.id
});

const BabyCotInstructions = () => (
  <ul>
    <li>Travel cot available?</li>
    <li>High chair available?</li>
    <li>Where are items located?</li>
  </ul>
);

export const ConfigurationBeds = () => {
  const { homeInfo, updateHomeInfo } = useContext(HomeInfoContext);

  const handleOnRemove = useCallback((remove, bed, beds, index) => {
    const allBeds = beds.filter((b) => !b._destroy);

    if (allBeds.length === 1) {
      return undefined;
    }

    const primaryBedsCount = beds.filter((b) => b.bed_type === "primary")
      .length;

    if (bed.bed_type === "primary" && primaryBedsCount === 1) {
      return undefined;
    }

    return () => remove(index);
  }, []);

  return (
    <Formik
      initialValues={homeInfo}
      enableReinitialize
      validationSchema={validationSchema}
      validateOnMount
      onSubmit={updateHomeInfo}
    >
      {({ values, errors }) => (
        <HomeInfoForm sidebarName="Configuration">
          <h4 className="section-name">Beds</h4>

          <div className="form-row">
            <div className="col-md-4">
              <FormGroup
                label="Number of bedrooms"
                name="property_attributes.bedrooms"
                component="select"
                airbnbSync
              >
                <SelectOptions options={NUMBER_OR_BEDROOMS_OPTIONS} />
              </FormGroup>
            </div>
          </div>

          <FieldArray
            name="beds_attributes"
            render={({ push, remove }) => (
              <div>
                {values.beds_attributes.map((bed, index) => (
                  <NestedFields
                    key={index}
                    name={`beds_attributes.${index}`}
                    onRemove={handleOnRemove(
                      remove,
                      bed,
                      values.beds_attributes,
                      index
                    )}
                  >
                    <FormGroup
                      name={`beds_attributes.${index}.location`}
                      label="Location"
                      placeholder="eg Bedroom 1 - upstairs to left"
                      disabled={bed._destroy}
                    />

                    <div className="form-row">
                      <div className="col-md-6">
                        <FormGroup
                          name={`beds_attributes.${index}.mattress_size`}
                          label="Mattress size"
                          component="select"
                          disabled={bed._destroy}
                        >
                          <SelectOptions options={BED_SIZES} />
                        </FormGroup>
                      </div>

                      <div className="col-md-6">
                        <FormGroup
                          name={`beds_attributes.${index}.bed_type`}
                          label="Bed type"
                          component="select"
                          disabled={bed._destroy}
                        >
                          <SelectOptions options={BED_TYPES} />
                        </FormGroup>
                      </div>
                    </div>
                  </NestedFields>
                ))}

                <div className="d-flex justify-content-between">
                  {errors?.beds_attributes &&
                    typeof errors?.beds_attributes === "string" && (
                      <span className="invalid-feedback pt-2 w-auto">
                        {errors.beds_attributes}
                      </span>
                    )}

                  <NestedFields.AddButton
                    label="Add Bed"
                    onClick={() => push(getInitialBedValues(values))}
                  />
                </div>
              </div>
            )}
          />

          <FieldsetWithAvailabilitySelect
            name="baby_cot_available"
            label="Baby Cot"
          >
            <FormGroup
              name="baby_cot_notes"
              label="Baby Cot Notes"
              visibleToGuests
              component="textarea"
              rows={5}
              instructions={<BabyCotInstructions />}
            />
          </FieldsetWithAvailabilitySelect>
        </HomeInfoForm>
      )}
    </Formik>
  );
};
