import { render } from "@testing-library/react";
import React from "react";

import { StartEndMap } from "./StartEndMap";

jest.mock("./GoogleMapWrapper");

jest.mock("./utils", () => ({
  ...jest.requireActual("./utils"),
  distanceBetweenPoints: jest.fn(() => "1km"),
  googleMapsApi: jest.fn(() => ({ Animation: { DROP: 1 } }))
}));

jest.mock("react-google-maps", () => ({
  useLoadScript: () => ({
    isLoaded: true,
    loadError: null
  }),
  withGoogleMap: (identity) => identity,
  withScriptjs: (identity) => identity,
  GoogleMap: <div />,
  Marker: <div />
}));

describe("<StartEndMap /> without clean coordinates", () => {
  it("renders only info about missing coordinates", () => {
    // Given
    const coordinates = {
      property: { lat: "1.1", lng: "0.0" },
      start: {},
      complete: {}
    };

    // When
    const { container } = render(<StartEndMap coordinates={coordinates} />);

    // Then
    expect(container).toMatchInlineSnapshot(`
      <div>
        <span>
          No coordinates sent
        </span>
      </div>
    `);
  });
});

describe("<StartEndMap /> with no start & complete coordinates", () => {
  const coordinates = {
    property: { lat: "0.00", lng: "0.11" },
    start: { lat: "1.1", lng: "0.0" },
    complete: {}
  };

  it("renders info about missing start & completion coordinates", () => {
    // When
    const { getByText, getByTestId } = render(
      <StartEndMap coordinates={coordinates} />
    );

    // Then
    expect(getByTestId("GoogleMapWrapperMock")).toBeInTheDocument();

    expect(
      getByText("Clean completion coordinates are missing")
    ).toBeInTheDocument();
  });
});

describe("<StartEndMap /> without property coordinates", () => {
  it("renders only info about missing coordinates", () => {
    // Given
    const coordinates = {
      property: {},
      start: { lat: "1.1", lng: "0.0" },
      complete: {}
    };

    // When
    const { container } = render(<StartEndMap coordinates={coordinates} />);

    // Then
    expect(container).toMatchInlineSnapshot(`
      <div>
        <span>
          No coordinates set for this property
        </span>
      </div>
    `);
  });
});

describe("<StartEndMap /> with all coordinates", () => {
  // Given
  const coordinates = {
    property: { lat: "0.00", lng: "0.00" },
    start: { lat: "0.11", lng: "0.11" },
    complete: { lat: "0.22", lng: "0.22" }
  };

  it("renders no info about missing start & completion coordinates", () => {
    // When
    const { queryByText, getByTestId } = render(
      <StartEndMap coordinates={coordinates} />
    );

    // Then
    expect(getByTestId("GoogleMapWrapperMock")).toBeInTheDocument();

    expect(
      queryByText("Clean start coordinates are missing")
    ).not.toBeInTheDocument();

    expect(
      queryByText("Clean completion coordinates are missing")
    ).not.toBeInTheDocument();
  });

  it("render buttons to center map on selected pin", () => {
    // When
    const { queryByTestId } = render(<StartEndMap coordinates={coordinates} />);

    expect(queryByTestId("center-map-button-property")).toBeInTheDocument();
    expect(queryByTestId("center-map-button-complete")).toBeInTheDocument();
    expect(queryByTestId("center-map-button-start")).toBeInTheDocument();
  });
});
