import React from "react";

import { Modal, MODAL_SIZE_LG } from "../../../common/Modal";
import "./BookingMessagesModal.scss";

export const BookingMessagesModal = ({ data, onClose }) => {
  return (
    <Modal onClose={onClose} size={MODAL_SIZE_LG}>
      <Modal.Header>Message response details</Modal.Header>
      <Modal.Body>
        <pre className="BookingMessagesModal_code">
          <code>{data}</code>
        </pre>
      </Modal.Body>
      <Modal.Footer>
        <button type="button" className="btn btn-submit" onClick={onClose}>
          Close
        </button>
      </Modal.Footer>
    </Modal>
  );
};

BookingMessagesModal.defaultProps = { window };
