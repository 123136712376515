import { format } from "date-fns";
import { Formik, Form, ErrorMessage } from "formik";
import React, { useCallback, useState } from "react";
import * as yup from "yup";

import "./BookingTransactionalMessagesModal.scss";
import { useApiClient } from "../../../common/apiClient";
import { FormGroup } from "../../../common/forms/FormGroup";
import { Modal, MODAL_SIZE_LG } from "../../../common/Modal";
import { RailsErrors } from "../../../common/RailsErrors";
import { SelectOptions } from "../../../common/SelectOptions";

const validationSchema = yup.object().shape({
  send_after: yup
    .date()
    .required("Please set send after date")
    .test(
      "send_after",
      "Must be before the 'send before' date",
      function validateSendAfter(sendAfter) {
        const sendBefore = new Date(this.parent.send_before);
        return sendAfter <= sendBefore;
      }
    ),
  send_before: yup
    .date()
    .required("Please set send before date")
    .test(
      "send_before-is-after-send_after",
      'Must be after the "send after" date',
      function validateSendBefore(sendBefore) {
        const sendAfter = new Date(this.parent.send_after);
        return sendBefore >= sendAfter;
      }
    ),
  status: yup.string().required("Please select status")
});

const {
  BLOCKED_REASONS,
  STATUSES
} = window.AirbaseConstants.TransactionalMessage;

export const BookingTransactionalMessagesModalForm = ({ message, onClose }) => {
  const apiClient = useApiClient();
  const [serverErrors, setServerErrors] = useState([]);

  const handleSubmit = useCallback(
    async (values, { setSubmitting }) => {
      setSubmitting(true);

      try {
        await apiClient.patch(`/api/transactional_messages/${message.id}/`, {
          transactional_message: values
        });

        window.location.reload();
      } catch (e) {
        if (e.response?.data?.errors) {
          setServerErrors(e.response?.data?.errors);
        }
      } finally {
        setSubmitting(false);
      }
    },
    [apiClient, message.id]
  );

  const initialValues = {
    status: message.status,
    blocked_reason: message.blocked_reason || "",
    send_after: message.send_after
      ? format(new Date(message.send_after), "yyyy-MM-dd'T'HH:mm")
      : "",
    send_before: message.send_before
      ? format(new Date(message.send_before), "yyyy-MM-dd'T'HH:mm")
      : ""
  };

  return (
    <Modal onClose={onClose} size={MODAL_SIZE_LG}>
      <Modal.Header>Edit message</Modal.Header>
      <Modal.Body>
        <div className="twbs">
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {({ isSubmitting, values, isValid }) => (
              <Form>
                <RailsErrors errors={serverErrors} />

                <FormGroup label="Status" name="status" component="select">
                  <SelectOptions options={STATUSES} />
                  <ErrorMessage name="status">
                    {(errorMsg) => (
                      <div className="invalid-feedback">{errorMsg}</div>
                    )}
                  </ErrorMessage>
                </FormGroup>

                {values.status === "blocked" && (
                  <FormGroup
                    label="Blocked reason"
                    name="blocked_reason"
                    component="select"
                  >
                    <SelectOptions options={BLOCKED_REASONS} />
                    <ErrorMessage name="blocked_reason">
                      {(errorMsg) => (
                        <div className="invalid-feedback">{errorMsg}</div>
                      )}
                    </ErrorMessage>
                  </FormGroup>
                )}

                <FormGroup
                  type="datetime-local"
                  label="Send after"
                  name="send_after"
                  min={format(new Date(), "yyyy-MM-dd'T'HH:mm")}
                  value={
                    values.send_after
                      ? format(
                          new Date(values.send_after),
                          "yyyy-MM-dd'T'HH:mm"
                        )
                      : ""
                  }
                />

                <FormGroup
                  type="datetime-local"
                  label="Send before"
                  name="send_before"
                  min={format(new Date(), "yyyy-MM-dd'T'HH:mm")}
                  value={
                    values.send_after
                      ? format(
                          new Date(values.send_before),
                          "yyyy-MM-dd'T'HH:mm"
                        )
                      : ""
                  }
                />

                <div className="form-group d-flex justify-content-end align-items-baseline mt-5">
                  <button
                    type="button"
                    className="btn btn-link btn-sm mr-2"
                    onClick={onClose}
                  >
                    Cancel
                  </button>
                  <button
                    type="submit"
                    className="btn btn-submit"
                    disabled={isSubmitting || !isValid}
                  >
                    Save
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </Modal.Body>
    </Modal>
  );
};

BookingTransactionalMessagesModalForm.defaultProps = { window };
