import React, { createContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

import { fetchPropertyFiles } from "./api";

export const ListingPhotosContext = createContext({
  numberOfPhotos: 0
});

export const ListingPhotosProvider = ({ children, propertyId }) => {
  const [numberOfPhotos, setNumberOfPhotos] = useState(0);
  const location = useLocation();

  useEffect(() => {
    fetchPropertyFiles(propertyId).then((res) => {
      if (res?.files) {
        const { FOLDER_NAME_AIRBNB } = window.AirbaseConstants.PropertyFile;

        const listingPhotos = res.files.filter(
          (el) => el.folder_name === FOLDER_NAME_AIRBNB
        );
        setNumberOfPhotos(listingPhotos.length);
      }
    });
  }, [location, propertyId]);

  return (
    <ListingPhotosContext.Provider
      value={{
        numberOfPhotos,
        setNumberOfPhotos
      }}
    >
      {children}
    </ListingPhotosContext.Provider>
  );
};
