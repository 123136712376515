import { Formik } from "formik";
import range from "lodash/range";
import React, { useContext } from "react";
import * as yup from "yup";

import { SelectOptions } from "../../../../common/SelectOptions";
import { FormGroup } from "../components/FormGroup";
import { HomeInfoContext } from "../HomeInfoContext";
import { HomeInfoForm } from "../HomeInfoForm";

const ACCOMMODATES_OPTIONS = range(1, 21);

const NUMBER_OF_BATHROOMS_OPTIONS = range(0, 9);

const NUMBER_OF_ADDITIONAL_WCS_OPTIONS = range(0, 5);

export const validationSchema = yup.object().shape({
  property_attributes: yup.object().shape({
    accommodates: yup.number().required("Please select"),
    bathrooms: yup.number().required("Please select"),
    additional_wcs: yup.number().required("Please select")
  })
});

export const ConfigurationSize = () => {
  const { homeInfo, updateHomeInfo } = useContext(HomeInfoContext);
  return (
    <Formik
      initialValues={homeInfo}
      enableReinitialize
      validationSchema={validationSchema}
      validateOnMount
      onSubmit={updateHomeInfo}
    >
      <HomeInfoForm sidebarName="Size">
        <h4 className="section-name">Size</h4>

        <FormGroup
          name="property_attributes.accommodates"
          label="Number of guests"
          component="select"
          airbnbSync
        >
          <SelectOptions options={ACCOMMODATES_OPTIONS} />
        </FormGroup>

        <FormGroup
          name="property_attributes.bathrooms"
          label="Number of bathrooms"
          component="select"
          airbnbSync
        >
          <SelectOptions options={NUMBER_OF_BATHROOMS_OPTIONS} />
        </FormGroup>

        <FormGroup
          name="property_attributes.additional_wcs"
          label="Number of additional WCs"
          component="select"
          airbnbSync
        >
          <SelectOptions options={NUMBER_OF_ADDITIONAL_WCS_OPTIONS} />
        </FormGroup>
        <FormGroup
          name="property_attributes.square_meters"
          label="Square metre"
        />
      </HomeInfoForm>
    </Formik>
  );
};
