import { useCallback, useState } from "react";

import * as api from "../api";

export const useMaintenanceImages = (maintenanceId) => {
  const [isLoading, setIsLoading] = useState(true);
  const [images, setImages] = useState([]);

  const fetchImages = useCallback(async () => {
    setIsLoading(true);
    const { clean_images } = await api.getMaintenanceImages(maintenanceId);
    setImages(clean_images);
    setIsLoading(false);
  }, [maintenanceId]);

  return { images, isLoading, fetchImages };
};
