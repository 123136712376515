import React from "react";

export const ActionButton = ({ href, title, className }) => (
  <a href={href} title={title}>
    <i className={className} />
  </a>
);

export const ActionButtonSVG = ({ href, title, imgUrl }) => (
  <a href={href} title={title}>
    <img src={imgUrl} alt="action-icon" className="action-image" />
  </a>
);
