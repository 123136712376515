export const isImage = (type) => type.startsWith("image/");

export const isVideo = (type) => type.startsWith("video/");

export const splitFileName = (fileName) => {
  const index = fileName.lastIndexOf(".");

  if (index === -1) {
    return [fileName];
  }

  const name = fileName.slice(0, index);
  const extension = fileName.slice(index + 1);

  return [name, extension];
};

export const joinFileName = (name, extension) => {
  return [name, extension].filter(Boolean).join(".");
};

export const submitForm = ({ method = "POST", action, data = {} }) => {
  const form = document.createElement("form");

  form.method = method;
  form.action = action;

  Object.entries(data).forEach(([key, value]) => {
    const input = document.createElement("input");

    input.type = "text";
    input.name = key;
    input.value = value;

    form.appendChild(input);
  });

  document.body.appendChild(form);

  form.submit();
  document.body.removeChild(form);
};

export function downloadFile(url) {
  const link = document.createElement("a");

  link.href = url;
  document.body.appendChild(link);

  link.click();
  document.body.removeChild(link);
}
