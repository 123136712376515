import { saveAs } from "file-saver";
import JSZip from "jszip";

const FILE_NAME = "airbase-files.zip";

export const downloadImages = async (files) => {
  const zip = new JSZip();
  const imgFolder = zip.folder("images");

  const fetchAndAddImages = files.map(async (file) => {
    const response = await fetch(file.download_url);
    const blob = await response.blob();
    imgFolder.file(file.file_name, blob);
  });

  await Promise.all(fetchAndAddImages);

  const zipBlob = await zip.generateAsync({ type: "blob" });
  saveAs(zipBlob, FILE_NAME);
};
