import classNames from "classnames";
import { format } from "date-fns";
import React, { useState } from "react";

import { humanize } from "../../../common/stringUtils";
import { BookingTransactionalMessagesModalDetails } from "./BookingTransactionalMessagesModalDetails";
import { BookingTransactionalMessagesModalForm } from "./BookingTransactionalMessagesModalForm";

const MESSAGES_COUNT_TO_SHOW = 5;
const STATUS_COLOR_MAP = {
  scheduled: "light_grey",
  sent: "green",
  cancelled: "red",
  blocked: "red",
  failed: "red"
};

export const BookingTransactionalMessages = ({ messages }) => {
  const [showMessageModal, setShowMessageModal] = useState(false);
  const [visibleMessagesCount, setVisibleMessagesCount] = useState(
    MESSAGES_COUNT_TO_SHOW
  );
  const [showEditModal, setShowEditModal] = useState(false);
  const [modalMessage, setModalMessage] = useState(null);

  const handleModalToggle = (message) => {
    setModalMessage(message);
    setShowMessageModal((prev) => !prev);
  };

  const handleEditModalToggle = (message) => {
    setModalMessage(message);
    setShowEditModal((prev) => !prev);
  };

  const formatDate = (dateString) => {
    if (!dateString) {
      return "-";
    }

    return format(new Date(dateString), "dd/MM/yyyy");
  };

  const formatDateUTC = (dateString) => {
    if (!dateString) {
      return "";
    }

    return new Date(dateString).toUTCString();
  };

  const visibleMessages = messages.slice(0, visibleMessagesCount);

  return (
    <div className="twbs booking-transactional-messages">
      {showMessageModal && (
        <BookingTransactionalMessagesModalDetails
          message={modalMessage}
          onClose={handleModalToggle}
        />
      )}

      {showEditModal && (
        <BookingTransactionalMessagesModalForm
          message={modalMessage}
          onClose={handleEditModalToggle}
        />
      )}

      <div className="collection_table">
        <div className="header">
          <div className="cell short">Sent at</div>
          <div className="cell short">Send after</div>
          <div className="cell short">Send before</div>
          <div className="cell short">Category</div>
          <div className="cell short">Channel</div>
          <div className="cell short">Status</div>
          <div className="cell short">Blocked reason</div>
          <div className="cell short">Actions</div>
        </div>

        {visibleMessages.map((m) => (
          <div className="item" key={m.id}>
            <div
              title={formatDateUTC(m.sent_at)}
              className={classNames(
                "cell",
                "short",
                m.sent_at ? "green" : "grey"
              )}
            >
              {formatDate(m.sent_at)}
            </div>
            <div
              className="cell short grey"
              title={formatDateUTC(m.send_after)}
            >
              {formatDate(m.send_after)}
            </div>
            <div
              className="cell short grey"
              title={formatDateUTC(m.send_before)}
            >
              {formatDate(m.send_before)}
            </div>
            <div className="cell short">{humanize(m.category)}</div>
            <div className="cell short">{m.channel}</div>
            <div
              className={classNames(
                "cell",
                "short",
                STATUS_COLOR_MAP[m.status]
              )}
            >
              {m.status}
            </div>
            <div className="cell short">
              {humanize(m.blocked_reason) || "-"}
            </div>
            <div className="cell short">
              <button
                type="button"
                className="btn btn-link btn-sm"
                onClick={() => handleModalToggle(m)}
              >
                View
              </button>
              &nbsp;|&nbsp;
              <button
                type="button"
                className={classNames("btn btn-link btn-sm", {
                  disabled: m.sent_at
                })}
                onClick={() => handleEditModalToggle(m)}
                disabled={m.sent_at}
                title={
                  m.status !== "scheduled"
                    ? "Only scheduled messages can be edited"
                    : ""
                }
              >
                Edit
              </button>
            </div>
          </div>
        ))}
      </div>

      {messages.length > visibleMessagesCount && (
        <div className="text-center mt-2">
          <button
            type="button"
            className="btn btn-link btn-sm"
            onClick={() =>
              setVisibleMessagesCount(
                visibleMessagesCount + MESSAGES_COUNT_TO_SHOW
              )
            }
          >
            Show more
          </button>
        </div>
      )}
    </div>
  );
};
