import React, { useState } from "react";

import { NewNoteModal } from "./NewNoteModal";

export const NewNote = ({ maintenanceId }) => {
  const [showModal, setShowModal] = useState(false);
  return (
    <>
      <button
        type="submit"
        className="btn btn-primary mb-3"
        onClick={() => {
          setShowModal(true);
        }}
      >
        Add update
      </button>

      {showModal && (
        <NewNoteModal
          maintenanceId={maintenanceId}
          onClose={() => {
            setShowModal(false);
          }}
        />
      )}
    </>
  );
};
