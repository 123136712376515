import React from "react";

import { modal } from "../../../../common/modals";
import { DisablingAutoCleanForm } from "./DisablingAutoCleanForm";

export const confirmDisablingAutoClean = (booking) => {
  return new Promise((resolve) => {
    const closeConfirmation = () => modal.close();

    modal.fire({
      title: "Confirm switching off automatic clean scheduling",
      html: (
        <DisablingAutoCleanForm
          initialValues={{
            skip_auto_clean_reason: booking.skip_auto_clean_reason || ""
          }}
          onCancel={closeConfirmation}
          onSubmit={(values) => {
            resolve({ confirmed: true, values });
            closeConfirmation();
          }}
        />
      ),
      animation: false,
      showConfirmButton: false,
      showCancelButton: false,
      customClass: "booking-disabling-auto-clean-form",
      onClose: () => resolve({ confirmed: false })
    });
  });
};
