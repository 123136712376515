import { groupBy } from "lodash";
import React from "react";

import { apiClient } from "../../apiClient";
import { useAsync } from "../../hooks/useAsync";

const clientSuccessesRequest = () =>
  apiClient
    .get("/api/operators?type=client_success")
    .then((response) => response.data.rows);

export const ClientSuccessFilter = ({
  filter,
  onChange,
  fetchClientSuccesses = clientSuccessesRequest
}) => {
  const { loading, value: clientSuccesses = [] } = useAsync(
    fetchClientSuccesses
  );

  const groupedClientSuccesses = {
    active: [],
    inactive: [],
    ...groupBy(clientSuccesses, ({ active }) =>
      active ? "active" : "inactive"
    )
  };

  const optionForSelect = ({ id, full_name }) => (
    <option key={id} value={id}>
      {full_name}
    </option>
  );

  if (loading) {
    return (
      <select className="form-control">
        <option value="">Loading...</option>
      </select>
    );
  }

  return (
    <select
      defaultValue={filter ? filter.value : ""}
      onChange={(event) => onChange(event.target.value)}
      className="form-control"
    >
      <option value="">All</option>
      <optgroup label="Active">
        {groupedClientSuccesses.active.map(optionForSelect)}
      </optgroup>
      <optgroup label="Inactive">
        {groupedClientSuccesses.inactive.map(optionForSelect)}
      </optgroup>
    </select>
  );
};
