import "../MaintenanceTable/MaintenanceTable.scss";

import { format } from "date-fns";
import { invert } from "lodash";
import React, { useMemo } from "react";
import ReactTable from "react-table";

import { ShowButtonSVG } from "../../../common/tables/buttons";
import {
  DropdownFilter,
  PodFilter,
  ClientSuccessFilter
} from "../../../common/tables/filters";
import { useControlledTable } from "../../../common/tables/useControlledTable";
import { MaintenanceTableFilterNew } from "./MaintenanceTableFiltersNew";

const { STATUSES, STAGES, SEVERITIES } = window.AirbaseConstants.Maintenance;

export const MaintenanceTableNew = ({ showPods }) => {
  const initialState = {
    sorted: [{ id: "created_at", desc: true }],
    filtered: [
      {
        id: "status",
        value: {
          pending: true,
          accepted: true,
          planned: true,
          host_planned: true
        }
      }
    ]
  };

  const {
    updateDataRow,
    data,
    onFilterChange,
    getFilter,
    getFilterValue,
    showPagination,
    filtered,
    ...controlledProps
  } = useControlledTable({
    api: { endpoint: "/api/maintenance" },
    initialState
  });

  const columns = useMemo(
    () => [
      {
        Header: "Home code",
        accessor: "property.home_code",
        className: "rt-maintenance-cell-new",
        size: 154,
        Cell: ({ value, original }) => (
          <a href={original.property.show_path} id={original.id}>
            {value}
          </a>
        )
      },
      {
        Header: "Pod",
        accessor: "property.pod_name",
        className: "rt-maintenance-cell-new",
        filterable: true,
        Filter: (
          <PodFilter
            id="pod_name_filter"
            filter={getFilter("property.pod_id")}
            onChange={(value) => onFilterChange("property.pod_id", value)}
          />
        )
      },
      showPods
        ? {
            Header: "Client Success",
            accessor: "property.client_success_name",
            className: "rt-maintenance-cell-new",
            filterable: true,
            Filter: (
              <ClientSuccessFilter
                id="cs_name_filter"
                filter={getFilter("property.client_success_id")}
                onChange={(value) =>
                  onFilterChange("property.client_success_id", value)
                }
              />
            )
          }
        : {},
      {
        Header: "Severity",
        accessor: "severity",
        className: "rt-maintenance-cell-new",
        Cell: ({ value }) => (
          <span title={SEVERITIES[value]} className={`severity-pill ${value}`}>
            {SEVERITIES[value]}
          </span>
        )
      },
      {
        Header: "Status",
        accessor: "status",
        className: "rt-maintenance-cell-new",
        Cell: ({ value }) => (
          <span title={STATUSES[value]}>{STATUSES[value]}</span>
        )
      },
      {
        Header: "Stage",
        accessor: "stage",
        className: "rt-maintenance-cell-new",
        Cell: ({ value }) => (
          <span title={STAGES[value]}>{STAGES[value] || "-- not set --"}</span>
        ),
        filterable: true,
        Filter: (
          <DropdownFilter
            id="stage_filter"
            filter={getFilter("stage")}
            onChange={(value) => onFilterChange("stage", value)}
            values={Object.entries(invert(STAGES))}
          />
        )
      },
      {
        Header: "Date raised",
        accessor: "created_at",
        className: "rt-maintenance-cell-new",
        Cell: ({ value }) => (
          <span>{format(new Date(value), "dd/MM/yyyy")}</span>
        )
      },
      {
        Header: "Repair date",
        accessor: "requested_repair_date",
        className: "rt-maintenance-cell-new",
        Cell: ({ value }) => (
          <span>{format(new Date(value), "dd/MM/yyyy")}</span>
        )
      },
      {
        sortable: false,
        filterable: false,
        maxWidth: 70,
        className: "rt-actions",
        Cell: ({ original: { share_url } }) => (
          <ShowButtonSVG href={share_url} />
        )
      }
    ],
    [getFilter, onFilterChange, showPods]
  );

  return (
    <div className="maintenance-table">
      <MaintenanceTableFilterNew
        getFilter={getFilter}
        getFilterValue={getFilterValue}
        onFilterChange={onFilterChange}
      />

      <ReactTable
        {...controlledProps}
        data={data}
        filtered={filtered}
        defaultSorted={[{ id: "created_at", desc: true }]}
        className="-highlight"
        columns={columns}
      />

      {showPagination && (
        <h4>Total maintenance items {controlledProps.totalCount}</h4>
      )}
    </div>
  );
};
