import React from "react";

import { formatDateTime } from "../../../common/date";

const { DEACTIVATION_REASONS } = window.AirbaseConstants.Cleaner;

const DeactivationReason = ({ reason }) => (
  <>
    <dt>Reason:</dt>
    <dd>{DEACTIVATION_REASONS[reason]}</dd>
  </>
);

const DeactivationComments = ({ notes }) => (
  <>
    <dt>Comments:</dt>
    <dd>{notes}</dd>
  </>
);

const DeactivatedBy = ({ deactivator }) => (
  <>
    <dt>Deactivated by:</dt>
    <dd>
      {deactivator ? (
        <>
          {deactivator.first_name} {deactivator.last_name} ({deactivator.email})
        </>
      ) : (
        "Cleaner deleted their account via the Housekeeping app"
      )}
    </dd>
  </>
);

const WouldReactivate = ({ value }) => (
  <>
    <dt>Would reactivate:</dt>
    <dd>{value ? "Yes" : "No"}</dd>
  </>
);

export const Inactive = ({ cleaner }) => (
  <>
    <div>
      <dt>Status:</dt>
      <dd className="status inactive">INACTIVE</dd>
    </div>
    <div>
      {cleaner.deactivation_reason && (
        <DeactivationReason reason={cleaner.deactivation_reason} />
      )}
    </div>
    <div>{cleaner.notes && <DeactivationComments notes={cleaner.notes} />}</div>
    <div>
      <DeactivatedBy deactivator={cleaner.deactivator} />
    </div>
    <div>
      <WouldReactivate value={cleaner.would_reactivate} />
    </div>
    <div>
      <dt>Deactivated at:</dt>
      <dd>{formatDateTime(new Date(cleaner.updated_at))}</dd>
    </div>
  </>
);
