import "./CleanImagesGallery.scss";

import { Fancybox } from "@fancyapps/ui";
import { groupBy, sortBy } from "lodash";
import React, { useCallback, useEffect, useMemo, useState } from "react";

import { Collapsible } from "../../../common/Collapsible";
import { bindImageDelete } from "../../../common/fancybox/bindImageDelete";
import { LoadingSpinner } from "../../../common/LoadingSpinner";
import { showErrorToast, showSuccessToast } from "../../../common/modals";
import { humanize } from "../../../common/stringUtils";
import { CleanImage } from "../CleanImage/CleanImage";
import { deleteImage as deleteImageRequest } from "./api";
import { useCleanImages } from "./useCleanImages";

const { config: fancyboxConfig } = window.AirbaseConstants.fancybox;

const CleanImagesGallery = ({ cleanId, images: imagesParam }) => {
  const [images, setImages] = useState([]);
  const { isLoading, images: fetchedImages, fetchImages } = useCleanImages(
    cleanId
  );

  useEffect(() => {
    if (imagesParam?.length > 0) {
      return setImages(imagesParam);
    }

    return fetchImages();
  }, [fetchImages, imagesParam]);

  useEffect(() => {
    setImages(fetchedImages);
  }, [fetchedImages]);

  const deleteImage = useCallback(
    async (id) => {
      try {
        const response = await deleteImageRequest({ cleanId, id });
        Fancybox.getInstance().close();
        showSuccessToast("Image deleted");

        setImages(response.clean_images);
      } catch (e) {
        showErrorToast("Couldn't delete this image. Please try again.");
      }
    },
    [cleanId]
  );

  useEffect(() => {
    if (images.length === 0) {
      return false;
    }
    const fancyboxSelector = ".clean-image-thumbnail";
    bindImageDelete({ fancyboxSelector, deleteImageRequest: deleteImage });
    Fancybox.bind(fancyboxSelector, fancyboxConfig);

    return () => {
      Fancybox.destroy();
    };
  }, [deleteImage, images.length]);

  const groupedImages = useMemo(() => {
    const grouped = Object.entries(groupBy(images, ({ type }) => type || ""));

    return sortBy(grouped, ([type]) => type);
  }, [images]);

  if (isLoading) {
    return (
      <div className="CleanImagesGallery">
        <LoadingSpinner message="Fetching images..." delay={500} />
      </div>
    );
  }

  return (
    <div className="CleanImagesGallery">
      {groupedImages.map(([type, imagesOfType]) => {
        const header = type ? humanize(type) : "Missing type";

        return (
          <Collapsible
            collapsed
            header={`${header} (${imagesOfType.length})`}
            key={type}
          >
            <div className="CleanImagesGallery__group">
              <ImagesGrid images={imagesOfType} />
            </div>
          </Collapsible>
        );
      })}
    </div>
  );
};

const ImagesGrid = ({ images }) => (
  <div className="ImagesGrid">
    {images.map((image) => (
      <CleanImage
        showDate={false}
        thumbnailClassName="clean-image-thumbnail"
        image={image}
        key={image.id}
      />
    ))}
  </div>
);

export default CleanImagesGallery;
