import classNames from "classnames";
import React, { useState } from "react";

import { BookingMessagesModal } from "./BookingMessagesModal";

export const BookingMessages = ({ messages }) => {
  const [showMessageModal, setShowMessageModal] = useState(false);
  const [messageData, setMessageData] = useState(null);

  const handleModalToggle = (message) => {
    if (message) {
      setMessageData(JSON.stringify(message.response, null, 2));
    }
    setShowMessageModal((prev) => !prev);
  };

  const sentAt = (message) => {
    const time = message.completed_at || message.created_at;

    return new Date(time).toUTCString();
  };

  const statusColorMap = {
    sent: "green",
    failed: "red",
    muted: "light_grey",
    queued: "orange"
  };

  return (
    <div className="twbs collection_table">
      <div className="header">
        <div className="cell short">Sent at</div>
        <div className="cell short">Type</div>
        <div className="cell short">Channel</div>
        <div className="cell short">Status</div>
      </div>

      {messages.map((m) => (
        <div className="item" key={m.id}>
          <div className="cell short grey">{sentAt(m)}</div>
          <div className="cell short">{m.message_type}</div>
          <div className="cell short light_grey">{m.message_channel}</div>
          <div
            tabIndex="0"
            role="button"
            className={classNames("cell", "short", statusColorMap[m.status])}
            onClick={() => handleModalToggle(m)}
          >
            {m.status}
          </div>
        </div>
      ))}

      {showMessageModal && (
        <BookingMessagesModal data={messageData} onClose={handleModalToggle} />
      )}
    </div>
  );
};
