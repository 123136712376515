import React from "react";

export const SelectedLead = ({ lead, onClearSelection, fieldName }) => (
  <>
    <div className="SearchSelect__selected" id="LeadSelection">
      <div className="SearchSelect__selected-header">
        <span>{lead.display_name}</span>

        <span
          className="SearchSelect__close"
          onClick={onClearSelection}
          role="button"
          tabIndex={0}
        >
          <i className="glyphicons-icon circle_remove header_blue" />
        </span>
      </div>
    </div>
    <input
      type="hidden"
      name={fieldName || "lead[referred_by_id]"}
      value={lead.id}
    />
  </>
);
