import axios from "axios";
import findIndex from "lodash/findIndex";
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState
} from "react";

import { apiClient } from "../apiClient";
import { showErrorToast } from "../modals";
import { QueryParams } from "./QueryParams";

const PAGE_SIZE_OPTIONS = [10, 20, 50, 100];
const DEFAULT_PAGE_SIZE = PAGE_SIZE_OPTIONS[0];
const FIRST_PAGE = 0;

const fetchData = async (url, params, signal) => {
  const { data } = await apiClient.get(url, { params, signal });

  return {
    data: data.rows,
    pages: data.pages,
    totalCount: data.total_count
  };
};

// Done this way to make tests easier
export const FetchDataContext = React.createContext(fetchData);

// Fully controlled table component,
// see https://github.com/tannerlinsley/react-table/tree/v6/#fully-controlled-component
export const useControlledTable = ({
  api: { endpoint, params },
  initialState = {},
  useQueryParams = true,
  queryParamsPrefix = null
}) => {
  const fetchAll = useContext(FetchDataContext);
  const queryParams = useMemo(
    () => (useQueryParams ? new QueryParams(queryParamsPrefix) : null),
    [useQueryParams, queryParamsPrefix]
  );

  // Get the initial state for the table
  const {
    page: initialPage,
    pageSize: initialPageSize,
    sorted: initialSorted,
    filtered: initialFiltered
  } = useMemo(
    () => ({
      page: FIRST_PAGE,
      pageSize: DEFAULT_PAGE_SIZE,
      sorted: undefined,
      filtered: undefined,
      ...initialState,
      ...(queryParams?.read() || {})
    }),
    [initialState, queryParams]
  );

  const [page, setPage] = useState(initialPage);
  const [pageSize, setPageSize] = useState(initialPageSize);
  const [sorted, setSorted] = useState(initialSorted);
  const [filtered, setFiltered] = useState(initialFiltered);

  const [loading, setLoading] = useState(false);
  const [{ data, pages, totalCount }, setData] = useState({
    data: [],
    pages: 0,
    totalCount: 0
  });

  const loadRows = useCallback(
    async (abortControllerSignal) => {
      setLoading(true);

      try {
        const response = await fetchAll(
          endpoint,
          {
            page: page + 1, // Page number in react-table is zero based
            limit: pageSize,
            sorted,
            filtered,
            ...params
          },
          abortControllerSignal
        );

        setData(response);
      } catch (error) {
        if (axios.isCancel(error)) {
          return;
        }

        showErrorToast(error.message);
      } finally {
        setLoading(false);
      }
    },
    [fetchAll, endpoint, page, pageSize, sorted, filtered, params]
  );

  // Handle fetching data
  useEffect(() => {
    const controller = new AbortController();
    loadRows(controller.signal);

    return () => {
      controller.abort();
    };
  }, [loadRows]);

  // Handle update query params
  useEffect(() => {
    queryParams?.update({ page, pageSize, sorted, filtered });
  }, [useQueryParams, page, pageSize, sorted, filtered, queryParams]);

  // Update sorting and navigate to the first page
  const onSortedChange = (sorted) => {
    setSorted(sorted);
    setPage(FIRST_PAGE);
  };

  // Update filtering and navigate to the first page
  const onFilteredChange = (filtered) => {
    setFiltered(filtered);
    setPage(FIRST_PAGE);
  };

  const updateDataRow = (rowData) => {
    const rowIndex = findIndex(data, ["id", rowData.id]);

    if (rowIndex === -1) {
      return false;
    }

    const newData = [
      ...data.slice(0, rowIndex),
      rowData,
      ...data.slice(rowIndex + 1)
    ];

    setData({ data: newData, pages, totalCount });

    return true;
  };

  const getFilter = useCallback(
    (id) => (filtered || []).find((filter) => filter.id === id),
    [filtered]
  );

  const getFilterValue = useCallback(
    (id) => {
      const filter = getFilter(id);
      return filter && filter.value;
    },
    [getFilter]
  );

  return {
    loadRows,

    manual: true,
    pageSizeOptions: PAGE_SIZE_OPTIONS,
    // Do not display padding rows,
    minRows: 0,
    // Show the pagination only when necessary
    showPagination: totalCount > Math.min(...PAGE_SIZE_OPTIONS),

    loading,
    data,
    updateDataRow,
    pages,
    totalCount,
    page,
    pageSize,

    // Like: [{ id: "created_at", desc: "true" }]
    sorted,

    // Like: [{ id: "name", value: "Anna" }, { id: "status", value: "active" }]
    filtered,

    onPageChange: (page) => setPage(page),
    onPageSizeChange: (pageSize) => setPageSize(pageSize),
    onSortedChange,
    onFilteredChange,

    // Get filter for the individual field
    getFilter,
    getFilterValue,

    // Set filter for the individual field
    onFilterChange: (id, value) => {
      const newFiltered = [
        ...(filtered || []).filter((filter) => filter.id !== id),
        value ? { id, value } : null
      ].filter(Boolean);

      onFilteredChange(newFiltered);
    }
  };
};
