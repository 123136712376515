import { format } from "date-fns";
import React from "react";

import { formatAmountWithCurrency } from "../../../common/numbers";

export const PriceBreakdown = ({ data, currencySymbol }) =>
  Object.entries(data).map(([key, value]) => (
    <React.Fragment key={key}>
      <div className="details">{format(new Date(key), "EEE dd/MM")}</div>
      <div className="details">
        {formatAmountWithCurrency(value, { currencySymbol })}
      </div>
    </React.Fragment>
  ));
