import { reducer } from "../reducer";
import { fetchPropertyResponse } from "./actions";
import { selectPropertyId } from "./selectors";

describe(".reducer", () => {
  it(`handles ${fetchPropertyResponse}`, () => {
    // Given
    let state = { property: null };

    // When
    state = reducer(
      state,
      fetchPropertyResponse({ id: 1234, home_code: "ASDF123" })
    );

    // Then
    expect(selectPropertyId(state)).toBe(1234);
  });
});
