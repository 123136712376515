import { renameFiles } from "./renameFiles";

describe("renameFiles", () => {
  it("removes special chars and spaces and replaces them with single underscore", () => {
    const files = [
      {
        name: "abcd.png"
      },
      {
        name: "123.jpg"
      },
      {
        name: "test xd.png"
      },
      {
        name: "#photo.png"
      },
      {
        name: "photo&123.gif"
      },
      {
        name: "photo&^@*#(xyz.png"
      },
      {
        name: "photo.with.dots.png"
      }
    ];

    const result = renameFiles(files);

    expect(result[0].name).toBe("abcd.png");
    expect(result[1].name).toBe("123.jpg");
    expect(result[2].name).toBe("test_xd.png");
    expect(result[3].name).toBe("_photo.png");
    expect(result[4].name).toBe("photo_123.gif");
    expect(result[5].name).toBe("photo_xyz.png");
    expect(result[6].name).toBe("photo_with_dots.png");
  });
});
