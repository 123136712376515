/* eslint-disable jsx-a11y/anchor-is-valid */
import "./StartEndMap.scss";
import classNames from "classnames";
import React, { useState } from "react";

import { MapWithLegend } from "./MapWithLegend";
import { coordsMissing } from "./utils";

const MAPS_URL = `https://maps.googleapis.com/maps/api/js?key=${window.AirbaseConstants.GOOGLE_MAPS_API_KEY}&v=3.exp&libraries=geometry`;

export const StartEndMap = ({ coordinates }) => {
  const [visible, setVisible] = useState(false);
  const handleMapToggle = () => {
    setVisible(!visible);
  };

  const { property, start, complete } = coordinates;

  if (coordsMissing(start) && coordsMissing(complete)) {
    return <span>No coordinates sent</span>;
  }

  if (coordsMissing(property)) {
    return <span>No coordinates set for this property</span>;
  }

  return (
    <div className="twbs">
      <button className="btn btn-link" type="button" onClick={handleMapToggle}>
        {visible ? "Hide" : "Show"} map
      </button>

      <div className={classNames("StartEndMap", { visible })}>
        <MapWithLegend
          coordinates={coordinates}
          visible={visible}
          loadingElement={
            <div className="StartEndMap--loading-map">Loading map...</div>
          }
          containerElement={<figure className="StartEndMap--map-container" />}
          mapElement={<div className="StartEndMap--map-element" />}
          googleMapURL={MAPS_URL}
        />
      </div>
    </div>
  );
};
