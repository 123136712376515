import React, { useMemo } from "react";
import ReactTable from "react-table";

import { EditButton, ShowButton } from "../../common/tables/buttons";
import { dateColumn } from "../../common/tables/dateColumn";
import { GenericSearchFilter } from "../../common/tables/filters";
import { useControlledTable } from "../../common/tables/useControlledTable";

const initialState = { sorted: [{ id: "created_at", desc: true }] };

export const LeadsTable = () => {
  const controlledProps = useControlledTable({
    api: { endpoint: "/api/leads" },
    initialState
  });

  const columns = useMemo(
    () => [
      {
        Header: "Actions",
        accessor: "id",
        maxWidth: 80,
        className: "rt-actions",
        sortable: false,
        Cell: ({ original: { show_path, edit_path } }) => (
          <span>
            <ShowButton href={show_path} />
            <EditButton href={edit_path} />
          </span>
        )
      },
      {
        Header: "Name",
        accessor: "display_name",
        filterable: true,
        Cell: ({ original: { display_name, show_path } }) => (
          <a href={show_path}>
            <span className="primary">{display_name}</span>
          </a>
        ),
        Filter: ({ onChange, filter }) => (
          <GenericSearchFilter
            onChange={onChange}
            filter={filter}
            placeholder="Search by name"
            testId="name-search"
          />
        )
      },
      {
        Header: "Primary Email",
        accessor: "emails.address",
        filterable: true,
        Cell: ({ original: { email } }) => (
          <div className="primary">{email}</div>
        ),
        Filter: ({ onChange, filter }) => (
          <GenericSearchFilter
            onChange={onChange}
            filter={filter}
            placeholder="Search by email"
          />
        )
      },
      {
        Header: "Primary Phone",
        accessor: "phones.number",
        filterable: true,
        Cell: ({ original: { primary_phone } }) => (
          <div className="primary">{primary_phone}</div>
        ),
        Filter: ({ onChange, filter }) => (
          <GenericSearchFilter
            onChange={onChange}
            filter={filter}
            placeholder="Search by phone"
          />
        )
      },
      dateColumn()
    ],
    []
  );

  return (
    <div>
      <ReactTable
        {...controlledProps}
        columns={columns}
        className="-striped -highlight"
        sortable
      />

      <h4>Total number of leads {controlledProps.totalCount}</h4>
    </div>
  );
};

export default LeadsTable;
