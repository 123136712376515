import React, { useState } from "react";

import { EditBillingAccountForm } from "./EditBillingAccountForm";

export const EditBillingAccount = ({
  resourceId,
  resourceType,
  billingAccount,
  currencySymbol
}) => {
  const [showPropertyModal, setShowPropertyModal] = useState(false);

  const handleModalToggle = () => setShowPropertyModal((prev) => !prev);

  return (
    <div className="twbs">
      <a className="btn btn-primary mb-3" onClick={handleModalToggle}>
        <i className="fas fa-edit" />
        &nbsp; Edit Account
      </a>

      {showPropertyModal && (
        <EditBillingAccountForm
          resourceId={resourceId}
          resourceType={resourceType}
          billingAccount={billingAccount || {}}
          currencySymbol={currencySymbol}
          onClose={handleModalToggle}
        />
      )}
    </div>
  );
};
