import React, { useState } from "react";

import { copyToClipboard } from "../../../../../common/copyToClipboard";
import { formatDate } from "../../../../../common/date";
import { showSuccessToast } from "../../../../../common/modals";
import { downloadFile } from "../../helpers";
import { DeleteFileModalContainer } from "../DeleteFileModalContainer";
import { EditFileModalContainer } from "../EditFileModalContainer";
import { FileDropdownMenu } from "./FileDropdownMenu";
import { FileThumbnail } from "./FileThumbnail";

export const FileListItem = ({
  file,
  handleGalleryInit,
  contextMenuShowsEdit = true,
  children
}) => {
  const [showEdit, setShowEdit] = useState(false);
  const [showDelete, setShowDelete] = useState(false);

  return (
    <div className="card file-card m-1 shadow-sm bg-white rounded">
      <FileThumbnail file={file} handleGalleryInit={handleGalleryInit} />

      <div className="card-body d-flex flex-column p-2">
        <div className="d-flex justify-content-between align-items-center">
          <span className="text-truncate" title={file.file_name}>
            {file.file_name}
          </span>

          <FileDropdownMenu
            contextMenuShowsEdit={contextMenuShowsEdit}
            onEdit={() => setShowEdit(true)}
            onDownload={() => downloadFile(file.download_url)}
            onCopyPublicUrl={() =>
              copyToClipboard(file.public_url).then(
                showSuccessToast("Public URL was copied to clipboard.")
              )
            }
            onDelete={() => setShowDelete(true)}
          />
        </div>

        <div className="small">{formatDate(new Date(file.created_at))}</div>
        <div title={file.notes} className="small text-truncate">
          {file.notes}
        </div>

        {children}
      </div>

      {showEdit && (
        <EditFileModalContainer
          file={file}
          onClose={() => setShowEdit(false)}
        />
      )}

      {showDelete && (
        <DeleteFileModalContainer
          file={file}
          onClose={() => setShowDelete(false)}
        />
      )}
    </div>
  );
};
