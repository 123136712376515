import {
  FILE_STATUS_PENDING,
  FILE_STATUS_QUEUED,
  FILE_STATUS_SUCCESS
} from "./constants";

export const selectUploadQueue = (state) =>
  Object.values(state.upload).map(
    ({
      fileItem: { name: fileName, type: fileType, size: totalBytes },
      status,
      errorMessage,
      uploadedBytes
    }) => ({
      fileName,
      fileType,

      status,
      errorMessage,

      uploadedBytes,
      totalBytes,

      percentage: Math.ceil((uploadedBytes / totalBytes) * 100)
    })
  );

export const selectIsUploadPending = (state) =>
  selectUploadQueue(state).some(({ status }) =>
    [FILE_STATUS_QUEUED, FILE_STATUS_PENDING].includes(status)
  );

export const selectUploadTotalProgress = (state) => {
  const queue = selectUploadQueue(state);

  const uploadedBytes = queue
    .map((file) => file.uploadedBytes)
    .reduce((bytes, uploadedBytes) => bytes + uploadedBytes);

  const totalBytes = queue
    .map((file) => file.totalBytes)
    .reduce((bytes, totalBytes) => bytes + totalBytes);

  return {
    uploadedCount: queue
      .map((file) => file.status)
      .reduce(
        (count, status) => (status === FILE_STATUS_SUCCESS ? count + 1 : count),
        0
      ),
    totalCount: queue.length,

    uploadedBytes,
    totalBytes,

    percentage: Math.ceil((uploadedBytes / totalBytes) * 100)
  };
};

export const selectQueuedFileItem = (state, fileName) =>
  state.upload[fileName].fileItem;
