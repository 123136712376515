import { Formik } from "formik";
import React, { useContext } from "react";

import { FormGroup } from "../components/FormGroup";
import { HomeInfoContext } from "../HomeInfoContext";
import { HomeInfoForm } from "../HomeInfoForm";

export const Emergency = () => {
  const { homeInfo, updateHomeInfo } = useContext(HomeInfoContext);
  return (
    <Formik
      initialValues={homeInfo}
      enableReinitialize
      onSubmit={updateHomeInfo}
    >
      <HomeInfoForm sidebarName="Operate">
        <h4 className="section-name">Emergency</h4>
        <FormGroup name="emergency_contact" label="Emergency contact" />
        <FormGroup name="emergency_number" label="Emergency number" />
        <FormGroup name="building_manager" label="Building manager" />
      </HomeInfoForm>
    </Formik>
  );
};
