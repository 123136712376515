import { render, waitFor, screen, fireEvent } from "@testing-library/react";
import userEvent from "@testing-library/user-event";
import React from "react";

import api from "./api";
import { CalendarDays } from "./CalendarDays";

jest.mock("./api", () => ({ fetchDays: jest.fn(), updateDays: jest.fn() }));

describe("<CalendarDays />", () => {
  const dayData = {
    override_price: 20,
    price_multiplier: 15,
    override_min_nights: undefined,
    max_nights: 666,
    available: true,
    base_price: 100,
    base_min_nights: 3
  };

  beforeEach(() => {
    // eslint-disable-next-line import/no-named-as-default-member
    api.fetchDays.mockClear();
  });

  it("prefills date picker for range start", async () => {
    const fetchDays = jest.spyOn(api, "fetchDays");
    fetchDays.mockImplementation(() => Promise.resolve(dayData));
    const wrapper = render(
      <CalendarDays rangeStart="2021-05-20" propertyId="3" />
    );

    await waitFor(() => expect(fetchDays).toHaveBeenCalledTimes(1));
    expect(fetchDays).toHaveBeenCalledWith("3", "2021-05-20", undefined);
    expect(wrapper.getByTestId("range-start").value).toBe("2021-05-20");
  });

  it("shows values of fetched day", async () => {
    const fetchDays = jest.spyOn(api, "fetchDays");
    fetchDays.mockImplementation(() => Promise.resolve(dayData));

    const { getByTestId, container } = render(
      <CalendarDays rangeStart="2021-05-20" propertyId="1" />
    );

    await waitFor(() =>
      expect(fetchDays).toHaveBeenCalledWith("1", "2021-05-20", undefined)
    );

    // Then
    expect(getByTestId("base-price").textContent).toBe("£100.00");
    expect(getByTestId("base-min-nights").textContent).toBe("3");
    expect(container.querySelector('input[name="override_price"]').value).toBe(
      "20"
    );
    expect(
      container.querySelector('input[name="price_multiplier"]').value
    ).toBe("15");
    expect(
      container.querySelector('input[name="override_min_nights"]').value
    ).toBe("");
    expect(container.querySelector(`select[name="available"]`).value).toBe(
      "true"
    );
  });

  describe("for range of days", () => {
    const daysData = {
      override_price: 20,
      price_multiplier: 15,
      override_min_nights: undefined,
      available: undefined
    };

    it("renders arming checkboxes when range selected", async () => {
      const fetchDays = jest.spyOn(api, "fetchDays");
      fetchDays.mockImplementation(() => Promise.resolve(daysData));

      render(
        <CalendarDays
          rangeStart="2050-05-20"
          rangeEnd="2050-05-25"
          propertyId="1"
        />
      );

      await waitFor(() =>
        expect(fetchDays).toHaveBeenCalledWith("1", "2050-05-20", "2050-05-25")
      );

      const checkboxes = screen.getAllByRole("checkbox", { name: "Save" });
      expect(checkboxes).toHaveLength(6);
      checkboxes.forEach((el) => {
        expect(el).not.toBeChecked();
      });

      // Submit button should be disabled when all arming checkboxes are off
      const submit = screen.getByRole("button", { name: "Update" });
      expect(submit).toBeDisabled();
    });

    it("allows edition of armed inputs only and submits correct values", async () => {
      // Given
      const fetchDays = jest.spyOn(api, "fetchDays");
      fetchDays.mockImplementation(() => Promise.resolve(daysData));

      const updateDays = jest.spyOn(api, "updateDays");
      fetchDays.mockImplementation(() => Promise.resolve({ ok: true }));

      // When
      render(
        <CalendarDays
          rangeStart="2050-05-20"
          rangeEnd="2050-05-25"
          propertyId="1"
        />
      );

      await waitFor(() =>
        expect(fetchDays).toHaveBeenCalledWith("1", "2050-05-20", "2050-05-25")
      );

      // Arm override_min_nights price_multiplier and override_price
      fireEvent.click(screen.getByTestId("arm_override_price"));
      fireEvent.click(screen.getByTestId("arm_price_multiplier"));
      fireEvent.click(screen.getByTestId("arm_override_min_nights"));

      userEvent.type(
        screen.getByRole("spinbutton", { name: /Override price/i }),
        "200"
      );

      userEvent.type(
        screen.getByRole("spinbutton", { name: "Price adjustment (%)" }),
        "30"
      );

      userEvent.type(
        screen.getByRole("spinbutton", {
          name: /Override minimum nights for Airbnb/i
        }),
        "19"
      );

      // Disarm override_min_nights and override_price
      fireEvent.click(screen.getByTestId("arm_override_price"));
      fireEvent.click(screen.getByTestId("arm_override_min_nights"));

      // Inputs should be disabled now
      expect(
        screen.getByRole("spinbutton", { name: /Override price/i })
      ).toBeDisabled();
      expect(
        screen.getByRole("spinbutton", {
          name: /Override minimum nights for Airbnb/i
        })
      ).toBeDisabled();

      const submit = screen.getByRole("button", { name: "Update" });
      fireEvent.click(submit);

      await waitFor(() => expect(updateDays).toHaveBeenCalled());

      // Then
      expect(updateDays.mock.calls[0][1]).toMatchInlineSnapshot(`
        Object {
          "range_end": "2050-05-25",
          "range_start": "2050-05-20",
          "values": Object {
            "arm_available": false,
            "arm_max_nights": false,
            "arm_override_min_nights": false,
            "arm_override_min_nights_multiplat": false,
            "arm_override_price": false,
            "arm_price_multiplier": true,
            "available": "",
            "max_nights": "",
            "override_min_nights": 19,
            "override_min_nights_multiplat": "",
            "override_price": 200,
            "price_multiplier": 30,
          },
        }
      `);
    });
  });
});
