import React from "react";

import { DropdownFilter } from "../../../../common/tables/filters";

const VALUES = [
  ["Not cancelled", false],
  ["Only cancelled", true]
];

export const CancelledFilter = ({ onChange }) => (
  <DropdownFilter
    id="deleted_at"
    onChange={onChange}
    filter={{ value: false }}
    values={VALUES}
  />
);
