import "./CalendarDays.scss";

import { startOfTomorrow } from "date-fns";
import { Form, Formik } from "formik";
import { get } from "lodash";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import * as yup from "yup";

import {
  formatErrors,
  isUnprocessableEntityError
} from "../../../common/errors";
import { DatePicker } from "../../../common/forms/DatePicker";
import { FormGroup } from "../../../common/forms/FormGroup";
import { LoadingSpinner } from "../../../common/LoadingSpinner";
import { showErrorToast, showSuccessToast } from "../../../common/modals";
import { formatAmountWithCurrency } from "../../../common/numbers";
import { SelectOptions } from "../../../common/SelectOptions";
import { fetchDays, updateDays } from "./api";
import { InputWithArmCheckbox } from "./InputWithArmCheckbox";

const handleErrors = (error) => {
  if (isUnprocessableEntityError(error)) {
    const errors = get(error, "response.data.errors", {});
    const date = get(error, "response.data.date");
    const message = date ? ` ${date} has errors:` : "";

    showErrorToast(`Save failed ${message}\n${formatErrors(errors)}`, {
      timer: 10000
    });
  } else {
    showErrorToast("Unable to save the form");
  }
};

const validationSchema = yup.object().shape({
  override_price: yup.number().integer().nullable().moreThan(0),
  price_multiplier: yup.number().integer().nullable().moreThan(-100),
  override_min_nights: yup.number().integer().nullable().moreThan(0),
  max_nights: yup.number().integer().nullable().moreThan(0)
});

export const CalendarDays = ({
  propertyId,
  currencySymbol,
  rangeStart: rangeStartProp,
  rangeEnd: rangeEndProp // used in tests
}) => {
  const [rangeStart, setRangeStart] = useState(rangeStartProp);
  const [rangeEnd, setRangeEnd] = useState(rangeEndProp);
  const [day, setDay] = useState(undefined);
  const [loading, setLoading] = useState(true);

  const isSingleDay = useMemo(() => !rangeEnd || rangeStart === rangeEnd, [
    rangeEnd,
    rangeStart
  ]);

  const fetchDates = useCallback(
    async (rangeStartParam, rangeEndParam = undefined) => {
      setLoading(true);

      setDay(undefined);
      const data = await fetchDays(propertyId, rangeStartParam, rangeEndParam);

      if (isSingleDay) {
        setDay(data);
      }

      setLoading(false);
    },
    [isSingleDay, propertyId]
  );

  useEffect(() => {
    if (!rangeStart) {
      return;
    }

    // Clear rangeEnd if it's before rangeStart
    if (rangeEnd && new Date(rangeEnd) < new Date(rangeStart)) {
      setRangeEnd(undefined);
    }

    if (rangeStart && isSingleDay) {
      fetchDates(rangeStart);
    } else if (rangeEnd) {
      fetchDates(rangeStart, rangeEnd);
    } else {
      setDay(undefined);
    }
  }, [rangeStart, rangeEnd, isSingleDay, fetchDates]);

  const handleSubmit = useCallback(
    async (values, { setSubmitting }) => {
      try {
        setSubmitting(true);

        await updateDays(propertyId, {
          values,
          range_start: rangeStart,
          range_end: rangeEnd || rangeStart
        });
        showSuccessToast(`${isSingleDay ? "Day" : "Days"} updated`);
      } catch (error) {
        handleErrors(error);
      } finally {
        setSubmitting(false);
      }

      return true;
    },
    [isSingleDay, propertyId, rangeEnd, rangeStart]
  );

  const initialValues = {
    override_price: day?.override_price || "",
    price_multiplier: day?.price_multiplier || "",
    override_min_nights: day?.override_min_nights || "",
    override_min_nights_multiplat: day?.override_min_nights_multiplat || "",
    max_nights: day?.max_nights || "",
    available: day?.available ?? "",
    arm_override_price: false,
    arm_price_multiplier: false,
    arm_override_min_nights: false,
    arm_override_min_nights_multiplat: false,
    arm_max_nights: false,
    arm_available: false
  };

  if (loading) {
    return <LoadingSpinner />;
  }

  const noneFieldsArmed = (values) =>
    !isSingleDay &&
    !values.arm_override_price &&
    !values.arm_price_multiplier &&
    !values.arm_override_min_nights &&
    !values.arm_override_min_nights_multiplat &&
    !values.arm_max_nights &&
    !values.arm_available;

  return (
    <div className="twbs CalendarDays">
      <h2>Edit Calendar days</h2>

      <Formik
        initialValues={initialValues}
        enableReinitialize
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ isSubmitting, values }) => (
          <Form className="CalendarDays__form">
            <div className="form-group CalendarDays__date-picker">
              <span className="label">From</span>
              <DatePicker
                data-testid="range-start"
                appendClear={false}
                pikadayOptions={{
                  minDate: startOfTomorrow(),
                  numberOfMonths: 2,
                  defaultDate: new Date(rangeStart)
                }}
                className="w-25 mr-2"
                defaultValue={rangeStart}
                onSelect={(dateString) => setRangeStart(dateString)}
              />

              <span className="label">to</span>
              <DatePicker
                data-testid="range-end"
                className="CalendarDays__datepicker-to"
                pikadayOptions={{
                  minDate: new Date(rangeStart),
                  numberOfMonths: 2,
                  defaultDate: new Date(rangeStart) // Which month should be shown initially
                }}
                defaultValue={rangeEnd}
                onSelect={(dateString) => setRangeEnd(dateString)}
              />
            </div>

            {!isSingleDay && (
              <span className="CalendarDays__arm-note">
                <strong>Note: </strong>
                <span>
                  Tick &quot;Save&quot; next to fields that you wish to
                  override. Uncheck those that should remain unchanged.
                </span>
              </span>
            )}

            <div className="form-row d-block d-md-flex">
              <div className="col">
                <fieldset className="border rounded p-3 mb-1">
                  <legend className="float-none w-auto px-3">
                    All platforms:
                  </legend>
                  {day && isSingleDay && (
                    <div className="form-group">
                      <span className="label">
                        Base price (set automatically)
                      </span>
                      <br />
                      <span data-testid="base-price">
                        {day.base_price
                          ? formatAmountWithCurrency(day.base_price, {
                              currencySymbol
                            })
                          : "not set"}
                      </span>
                    </div>
                  )}

                  <InputWithArmCheckbox
                    isSingleDay={isSingleDay}
                    name="override_price"
                  >
                    <FormGroup
                      className="w-50"
                      label="Override price"
                      renderAppend={() => currencySymbol}
                      disabled={!isSingleDay && !values.arm_override_price}
                      type="number"
                      min="0"
                      step="0.01"
                    />
                  </InputWithArmCheckbox>

                  <InputWithArmCheckbox
                    isSingleDay={isSingleDay}
                    name="price_multiplier"
                  >
                    <FormGroup
                      className="w-50"
                      label="Price adjustment (%)"
                      renderAppend={() => "%"}
                      disabled={!isSingleDay && !values.arm_price_multiplier}
                      type="number"
                      min="-99"
                      step="1"
                    />
                  </InputWithArmCheckbox>

                  <InputWithArmCheckbox
                    isSingleDay={isSingleDay}
                    name="max_nights"
                  >
                    <FormGroup
                      className="w-50"
                      label="Max nights"
                      type="number"
                      step="1"
                      min="0"
                      disabled={!isSingleDay && !values.arm_max_nights}
                    />
                  </InputWithArmCheckbox>

                  <InputWithArmCheckbox
                    isSingleDay={isSingleDay}
                    name="available"
                  >
                    <FormGroup
                      className="w-50"
                      label="Available"
                      component="select"
                      disabled={!isSingleDay && !values.arm_available}
                    >
                      <SelectOptions
                        includeBlank={false}
                        options={[
                          ["", "Default"],
                          [true, "True"],
                          [false, "False"]
                        ]}
                      />
                    </FormGroup>
                  </InputWithArmCheckbox>
                </fieldset>
              </div>
              <div className="col">
                <fieldset className="border rounded p-3 mb-1">
                  <legend className="float-none w-auto px-3">Airbnb:</legend>
                  {day && isSingleDay && (
                    <div className="form-group">
                      <span className="label">
                        Base min nights (set automatically)
                      </span>
                      <br />
                      <span data-testid="base-min-nights">
                        {day.base_min_nights || "not set"}
                      </span>
                    </div>
                  )}

                  <InputWithArmCheckbox
                    isSingleDay={isSingleDay}
                    name="override_min_nights"
                  >
                    <FormGroup
                      className="w-50"
                      label="Override minimum nights for Airbnb"
                      type="number"
                      step="1"
                      min="0"
                      disabled={!isSingleDay && !values.arm_override_min_nights}
                    />
                  </InputWithArmCheckbox>
                </fieldset>

                <fieldset className="border rounded p-3">
                  <legend className="float-none w-auto px-3">Multiplat:</legend>
                  {day && isSingleDay && (
                    <div className="form-group">
                      <span className="label">
                        Base min nights (set automatically)
                      </span>
                      <br />
                      <span data-testid="base-min-nights-multiplat">
                        {day.base_min_nights_multiplat || "not set"}
                      </span>
                    </div>
                  )}

                  <InputWithArmCheckbox
                    isSingleDay={isSingleDay}
                    name="override_min_nights_multiplat"
                  >
                    <FormGroup
                      className="w-50"
                      label="Override minimum nights for multiplat"
                      type="number"
                      step="1"
                      min="0"
                      disabled={
                        !isSingleDay &&
                        !values.arm_override_min_nights_multiplat
                      }
                    />
                  </InputWithArmCheckbox>
                </fieldset>
              </div>
            </div>

            <div className="form-actions mt-3 mb-3 d-flex justify-content-end align-items-center">
              <a
                href={`/properties/${propertyId}/calendar_days`}
                className="mr-3"
              >
                Back to calendar
              </a>

              <button
                type="submit"
                className="btn btn-submit"
                disabled={isSubmitting || noneFieldsArmed(values)}
              >
                Update
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};
