import { isImage, joinFileName, splitFileName } from "./helpers";

describe(".isImage", () => {
  it.each`
    type                 | expected
    ${"image/jpeg"}      | ${true}
    ${"image/png"}       | ${true}
    ${"application/zip"} | ${false}
    ${"application/pdf"} | ${false}
  `("return $expected for $type", ({ type, expected }) => {
    expect(isImage(type)).toBe(expected);
  });
});

describe(".splitFileName", () => {
  it.each`
    fileName                  | result
    ${"image001.jpg"}         | ${["image001", "jpg"]}
    ${"the.archive.zip"}      | ${["the.archive", "zip"]}
    ${"filewithoutextension"} | ${["filewithoutextension"]}
  `("returns correct file name and extension", ({ fileName, result }) => {
    expect(splitFileName(fileName)).toEqual(result);
  });
});

describe(".joinFileName", () => {
  describe("when the extension is given", () => {
    it("returns name and extension", () => {
      expect(joinFileName("image", "jpg")).toEqual("image.jpg");
    });
  });

  describe("when the extension is not given", () => {
    it("returns name without the extension", () => {
      expect(joinFileName("test")).toEqual("test");
    });
  });
});
