import { showErrorToast, showSuccessToast } from "../../../../../common/modals";
import { selectPropertyId } from "../property/selectors";
import {
  deleteFileResponse,
  deleteFilesResponse,
  fetchFilesRequest,
  fetchFilesResponse,
  updateFileResponse
} from "./actions";

export const fetchFiles = () => async (dispatch, getState, { api }) => {
  dispatch(fetchFilesRequest());

  const propertyId = selectPropertyId(getState());
  const files = await api.fetchFiles({ propertyId });

  dispatch(fetchFilesResponse(files));
};

export const updateFile = (id, values) => async (
  dispatch,
  getState,
  { api }
) => {
  const updatedFile = await api.updateFile(
    { property_id: selectPropertyId(getState()), id },
    values
  );

  dispatch(updateFileResponse(updatedFile));
};

export const updateFiles = (property_files) => async (
  dispatch,
  getState,
  { api }
) => {
  try {
    const updatedFiles = await api.updateFiles(
      { property_id: selectPropertyId(getState()) },
      { property_files }
    );

    showSuccessToast("Changes saved");
    dispatch(fetchFilesResponse(updatedFiles));
  } catch (error) {
    showErrorToast("Something went wrong. Please try again.");
  }
};

export const deleteFile = (id) => async (dispatch, getState, { api }) => {
  await api.deleteFile({ property_id: selectPropertyId(getState()), id });
  dispatch(deleteFileResponse(id));
};

export const deleteFiles = (ids) => async (dispatch, getState, { api }) => {
  try {
    await api.deleteFiles({ property_id: selectPropertyId(getState()), ids });
    showSuccessToast("Files deleted");
    dispatch(deleteFilesResponse(ids));
  } catch (error) {
    showErrorToast("Something went wrong. Please try again.");
  }
};
