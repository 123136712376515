import React from "react";

import arrowIcon from "../../../images/arrow-icon.svg";
import { ActionButton, ActionButtonSVG } from "./ActionButton";

export const ShowButton = ({ href, title = "Show" }) => (
  <ActionButton href={href} title={title} className="fas fa-address-card" />
);

export const ShowButtonSVG = ({ href, title = "Show" }) => (
  <ActionButtonSVG href={href} title={title} imgUrl={arrowIcon} />
);
