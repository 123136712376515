import { truncate } from "lodash";
import React, { useCallback, useMemo } from "react";

import { humanFileSize } from "../../../../../common/humanFileSize";
import { Progress } from "../../../../../common/Progress";
import {
  FILE_STATUS_ERROR,
  FILE_STATUS_QUEUED,
  FILE_STATUS_SUCCESS
} from "../../data/upload/constants";
import { FileTypeIcon } from "../FileTypeIcon";
import { FileStatusIcon } from "./FileStatusIcon";

export const FileProgress = ({ queuedFile, onRetry }) => {
  const { status } = queuedFile;

  const handleFileIconClick = useCallback(() => {
    if (status === FILE_STATUS_ERROR) {
      onRetry();
    }
  }, [status, onRetry]);

  const uploadedBytes = useMemo(() => humanFileSize(queuedFile.uploadedBytes), [
    queuedFile
  ]);

  const totalBytes = useMemo(() => humanFileSize(queuedFile.totalBytes), [
    queuedFile
  ]);

  const fileName = useMemo(() => {
    const extension = queuedFile.fileName.split(".").pop();
    return truncate(queuedFile.fileName, {
      length: 40,
      omission: `[...].${extension}`
    });
  }, [queuedFile]);

  return (
    <div className="mb-3">
      <div className="d-flex justify-content-between w-100 mb-1">
        <span>
          <FileTypeIcon fileType={queuedFile.fileType} />{" "}
          <span className="text-truncate">{fileName}</span>
        </span>

        <span>
          <span className="mr-2">
            {status === FILE_STATUS_QUEUED
              ? totalBytes
              : `${uploadedBytes} / ${totalBytes}`}
          </span>
          <FileStatusIcon status={status} onClick={handleFileIconClick} />
        </span>
      </div>

      {status !== FILE_STATUS_ERROR ? (
        <Progress
          percentage={queuedFile.percentage}
          stripped={[FILE_STATUS_QUEUED, FILE_STATUS_ERROR].includes(status)}
          success={status === FILE_STATUS_SUCCESS}
          danger={status === FILE_STATUS_ERROR}
        />
      ) : (
        <div className="w-100 alert alert-danger p-0 pl-2">
          {queuedFile.errorMessage}
        </div>
      )}
    </div>
  );
};
