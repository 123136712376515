import { createMockStore } from "../testHelpers";
import {
  deleteFileResponse,
  deleteFilesResponse,
  fetchFilesRequest,
  fetchFilesResponse,
  updateFileResponse
} from "./actions";
import { deleteFile, deleteFiles, fetchFiles, updateFile } from "./thunks";

describe(".fetchFiles", () => {
  it("dispatches valid actions", async () => {
    // Given
    const initialState = {
      property: { id: 124 }
    };

    const files = [{ id: 1 }, { id: 2 }];
    const api = {
      fetchFiles: jest.fn().mockReturnValue(Promise.resolve(files))
    };

    const store = createMockStore({ initialState, api });

    // When
    await store.dispatch(fetchFiles());

    // Then
    expect(api.fetchFiles).toHaveBeenCalledWith({
      propertyId: initialState.property.id
    });

    const actions = store.getActions();
    expect(actions.length).toBe(2);
    expect(actions[0]).toEqual(fetchFilesRequest());
    expect(actions[1]).toEqual(fetchFilesResponse(files));
  });
});

describe(".updateFile", () => {
  it("dispatches valid actions", async () => {
    // Given
    const values = { id: 1, file_name: "file1.jpg" };
    const api = {
      updateFile: jest.fn().mockReturnValue(Promise.resolve(values))
    };
    const store = createMockStore({
      initialState: { property: { id: 1234 } },
      api
    });

    // When
    await store.dispatch(updateFile(values.id, values));

    // Then
    expect(api.updateFile).toHaveBeenCalledWith(
      { property_id: 1234, id: values.id },
      values
    );

    const actions = store.getActions();
    expect(actions.length).toBe(1);
    expect(actions[0]).toEqual(updateFileResponse(values));
  });
});

describe(".deleteFile", () => {
  it("dispatches valid actions", async () => {
    // Given
    const api = {
      deleteFile: jest.fn().mockReturnValue(Promise.resolve())
    };
    const store = createMockStore({
      initialState: { property: { id: 1234 } },
      api
    });

    // When
    await store.dispatch(deleteFile(1));

    // Then
    expect(api.deleteFile).toHaveBeenCalledWith({ property_id: 1234, id: 1 });

    const actions = store.getActions();
    expect(actions.length).toBe(1);
    expect(actions[0]).toEqual(deleteFileResponse(1));
  });
});

describe(".deleteFiles", () => {
  it("dispatches valid actions", async () => {
    // Given
    const api = {
      deleteFiles: jest.fn().mockReturnValue(Promise.resolve())
    };
    const store = createMockStore({
      initialState: { property: { id: 1234 } },
      api
    });

    // When
    await store.dispatch(deleteFiles([1, 2]));

    // Then
    expect(api.deleteFiles).toHaveBeenCalledWith({
      property_id: 1234,
      ids: [1, 2]
    });

    const actions = store.getActions();
    expect(actions.length).toBe(1);
    expect(actions[0]).toEqual(deleteFilesResponse([1, 2]));
  });
});
