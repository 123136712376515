import React from "react";

import { DropdownFilter } from "../../../../common/tables/filters";

const VALUES = [
  ["Approved", true],
  ["Rejected", false],
  ["Pending", null]
];

export const ApprovalStatusFilter = ({ onChange, filter }) => (
  <DropdownFilter onChange={onChange} filter={filter} values={VALUES} />
);
