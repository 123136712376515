import { UNPROCESSABLE_ENTITY } from "../../../../../common/errors";
import { reducer } from "../reducer";
import { mockFileList } from "../testHelpers";
import {
  clearUploadQueue,
  uploadFileNotifyProgress,
  uploadFileRequest,
  uploadFileResponse,
  uploadFilesRequest
} from "./actions";
import {
  FILE_STATUS_ERROR,
  FILE_STATUS_PENDING,
  FILE_STATUS_QUEUED,
  FILE_STATUS_SUCCESS
} from "./constants";

describe("reducer", () => {
  it(`handles ${uploadFilesRequest}`, () => {
    // Given
    let state = {
      upload: {}
    };

    const fileList = mockFileList();

    // When
    state = reducer(state, uploadFilesRequest(fileList));

    expect(state).toMatchObject({
      upload: {
        "image001.jpg": {
          status: FILE_STATUS_QUEUED,
          fileItem: {
            name: "image001.jpg",
            type: "image/jpeg",
            size: 4
          },
          uploadedBytes: 0
        },
        "archive.zip": {
          status: FILE_STATUS_QUEUED,
          fileItem: {
            name: "archive.zip",
            type: "application/zip",
            size: 3
          },
          uploadedBytes: 0
        }
      }
    });
  });

  it(`handles ${uploadFileRequest}`, () => {
    // Given
    let state = {
      upload: {
        "image001.jpg": {
          status: FILE_STATUS_QUEUED,
          uploadedBytes: 0,
          totalBytes: 4
        }
      }
    };
    const fileItem = { name: "image001.jpg" };

    // When
    state = reducer(state, uploadFileRequest(fileItem));

    // Then
    expect(state).toMatchObject({
      upload: {
        "image001.jpg": {
          status: FILE_STATUS_PENDING,
          uploadedBytes: 0,
          totalBytes: 4
        }
      }
    });
  });

  it(`handles ${uploadFileResponse}`, () => {
    // Given
    let state = {
      upload: {
        "image001.jpg": {
          status: FILE_STATUS_PENDING
        }
      }
    };

    // When
    state = reducer(state, uploadFileResponse({ name: "image001.jpg" }));

    // Then
    expect(state.upload).toEqual({
      "image001.jpg": {
        status: FILE_STATUS_SUCCESS
      }
    });
  });

  it(`handles ${uploadFileResponse} error`, () => {
    // Given
    let state = {
      upload: {
        "image001.jpg": {
          status: FILE_STATUS_PENDING
        }
      }
    };

    // When
    const error = new Error();
    error.response = {
      status: UNPROCESSABLE_ENTITY,
      data: {
        errors: ["File name has already been taken"]
      }
    };

    state = reducer(state, uploadFileResponse({ name: "image001.jpg" }, error));

    // Then
    expect(state.upload).toEqual({
      "image001.jpg": {
        status: FILE_STATUS_ERROR,
        errorMessage: "File name has already been taken"
      }
    });
  });

  it(`handles ${uploadFileNotifyProgress}`, () => {
    // Given
    let state = {
      upload: {
        "image001.jpg": {
          status: FILE_STATUS_PENDING,
          uploadedBytes: 0
        }
      }
    };
    const fileItem = { name: "image001.jpg" };

    // When
    state = reducer(state, uploadFileNotifyProgress(fileItem, { loaded: 16 }));

    // Then
    expect(state).toMatchObject({
      upload: {
        "image001.jpg": {
          uploadedBytes: 16
        }
      }
    });
  });

  it(`handles ${clearUploadQueue}`, () => {
    // Given
    let state = {
      upload: {
        "file001.jpg": {
          status: FILE_STATUS_SUCCESS
        }
      }
    };

    // When
    state = reducer(state, clearUploadQueue());

    // Then
    expect(state).toMatchObject({ upload: {} });
  });
});
