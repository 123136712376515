/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useState } from "react";

import { ModulrAccountDetailsModal } from "./ModulrAccountDetailsModal";

export const ModulrAccountDetails = ({ modulr_account, showAirbnbNote }) => {
  const [showPropertyModal, setShowPropertyModal] = useState(false);

  const handleModalToggle = (show) => {
    setShowPropertyModal(show);
  };

  return (
    <div className="modulr_id twbs">
      <a className="modulr_id" onClick={() => handleModalToggle(true)}>
        {modulr_account.modulr_id}
      </a>

      {showPropertyModal && (
        <ModulrAccountDetailsModal
          showAirbnbNote={showAirbnbNote}
          modulr_account={modulr_account}
          onClose={() => handleModalToggle(false)}
        />
      )}
    </div>
  );
};
