import { connect } from "formik";
import React from "react";

import { FormGroup } from "../../../common/forms/FormGroup";

export const InputWithArmCheckbox = connect(
  ({ name, isSingleDay, children }) => {
    const checkboxName = `arm_${name}`;

    const childrenWithProps = React.Children.map(children, (child) => {
      return React.cloneElement(child, { name });
    });

    return (
      <div className="CalendarDays__inputWithCheckbox">
        {childrenWithProps}

        {!isSingleDay && (
          <FormGroup
            className="CalendarDays__armCheckbox"
            data-testid={checkboxName}
            name={checkboxName}
            label="Save"
            type="checkbox"
          />
        )}
      </div>
    );
  }
);
