const AIRBNB_SYNC_REQUIRED_FIELDS = {
  LOCATION: [
    "property_attributes.street",
    "property_attributes.city",
    "property_attributes.postcode",
    "property_attributes.country"
  ],
  SYNC: ["host_preferences_attributes.airbnb_listed"],
  LISTING_PHOTOS: ["listing_photos"],
  ACCESS: ["home_info_location_attributes.access_type"],
  DESCRIPTION: ["listing_title", "listing_summary"],
  SIZE: [
    "property_attributes.accommodates",
    "property_attributes.bathrooms",
    "property_attributes.additional_wcs"
  ],
  BEDS: ["property_attributes.bedrooms", "beds_attributes"]
};

export default AIRBNB_SYNC_REQUIRED_FIELDS;
