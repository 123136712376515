import "./LoadingSpinner.scss";

import React, { useState } from "react";

export const LoadingSpinner = ({ message = "Loading...", delay = 0 } = {}) => {
  const [show, setShow] = useState(false);

  React.useEffect(() => {
    if (delay === 0) {
      setShow(true);
      return false;
    }
    const timeout = setTimeout(() => {
      setShow(true);
    }, delay);

    return () => clearTimeout(timeout);
  }, [delay]);

  if (!show) return null;

  return (
    <div className="loading-spinner">
      <i className="fas fa-spinner fa-spin" />

      <span>{message}</span>
    </div>
  );
};
