import React, { useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";

import { SwalActions, modal } from "../../../../common/modals";
import {
  selectedFiles,
  selectedFilesIds as selectedFilesIdsSelector
} from "../data/files/selectors";
import { deleteFiles } from "../data/files/thunks";

export const BulkDeleteButton = () => {
  const dispatch = useDispatch();
  const selectedFilesIds = useSelector(selectedFilesIdsSelector);
  const allSelectedFiles = useSelector(selectedFiles);

  const selectedAmenityAssets = allSelectedFiles.filter(
    ({ home_info_amenities_id }) => Boolean(home_info_amenities_id)
  );

  const confirmationPopUp = useCallback(
    () =>
      new Promise((res) => {
        const closeConfirmation = () => modal.close();
        const onConfirm = () => {
          res({ confirmed: true });
          closeConfirmation();
        };

        const html =
          selectedAmenityAssets.length > 0 ? (
            <>
              <p>
                Accessibility photo selected. Accessibility photos can only be
                deleted from Home Info
              </p>
              <SwalActions
                cancelText="Ok"
                onCancel={closeConfirmation}
                onlyCancel
              />
            </>
          ) : (
            <>
              <p>Are you sure you want to delete selected files?</p>
              <SwalActions
                confirmText="Yes, delete forever"
                cancelText="No"
                onCancel={closeConfirmation}
                onConfirm={onConfirm}
              />
            </>
          );

        modal.fire({
          html,
          showConfirmButton: false,
          showCancelButton: false,
          icon: "error",
          iconHtml: '<i class="fas fa-exclamation"></i>',
          animation: false
        });
      }),
    [selectedAmenityAssets.length]
  );

  const handleSubmit = useCallback(
    async (e) => {
      e.preventDefault();

      const { confirmed } = await confirmationPopUp();

      if (!confirmed) {
        return;
      }

      await dispatch(deleteFiles(selectedFilesIds));
    },
    [confirmationPopUp, dispatch, selectedFilesIds]
  );

  return (
    <>
      <button type="button" className="btn btn-danger" onClick={handleSubmit}>
        <i className="fa fa-trash" /> Delete
      </button>
    </>
  );
};
