/* eslint-disable jsx-a11y/label-has-associated-control */
import "./MaintenanceTable.scss";

import React from "react";

import { DatePicker } from "../../../common/forms/DatePicker";
import { CheckboxFilter } from "../../../common/tables/filters/CheckboxFilter";
import { GenericSearchFilter } from "../../../common/tables/filters/GenericSearchFilter";

const { STATUSES } = window.AirbaseConstants.Maintenance;

export const MaintenanceTableFilters = ({
  getFilter,
  getFilterValue,
  onFilterChange
}) => {
  const onStatusChange = (field) => (event) =>
    onFilterChange("status", {
      ...getFilterValue("status"),
      [field]: event.target.checked
    });

  const allSelected = Object.keys(STATUSES).every(
    (key) => getFilterValue("status")?.[key]
  );

  const onToggleAll = () => {
    const values = Object.keys(STATUSES).reduce(
      (prev, current) => ({
        ...prev,
        [current]: !allSelected
      }),
      {}
    );
    onFilterChange("status", values);
  };

  return (
    <div className="filter-options twbs">
      <div className="filter-top-row">
        <GenericSearchFilter
          onChange={(value) => onFilterChange("search", value)}
          filter={getFilter("search")}
          placeholder="Search by home code, pod name, locality, property address or status"
        />

        <label
          htmlFor="created_at_filter"
          className="filter-container-created-at-range"
        >
          <div className="form-group MaintenanceFilters__date-picker">
            <span className="label font-weight-bold">Raised from</span>
            <DatePicker
              id="created_at_filter"
              data-testid="range-start"
              pikadayOptions={{ numberOfMonths: 2 }}
              onSelect={(value) =>
                onFilterChange("created_at_range_start", value)
              }
              defaultValue={getFilterValue("created_at_range_start")}
            />

            <span className="label font-weight-bold">to</span>
            <DatePicker
              data-testid="range-end"
              pikadayOptions={{
                minDate: new Date(getFilterValue("created_at_range_start")),
                numberOfMonths: 2,
                defaultDate: new Date(getFilterValue("created_at_range_start"))
              }}
              defaultValue={getFilterValue("created_at_range_end")}
              onSelect={(value) =>
                onFilterChange("created_at_range_end", value)
              }
            />
          </div>
        </label>
      </div>

      <div className="status-filter-container">
        <label
          htmlFor="filter_status"
          className="status-checkboxes-label font-weight-bold mt-2"
        >
          Status
        </label>
        <div id="filter_status" className="status-checkboxes">
          <CheckboxFilter
            label="Select all"
            onChange={onToggleAll}
            checked={allSelected}
          />
          {Object.entries(STATUSES).map(([key, val]) => (
            <CheckboxFilter
              key={key}
              label={val}
              checked={getFilterValue("status")?.[key] || false}
              onChange={onStatusChange(key)}
            />
          ))}
        </div>
      </div>
    </div>
  );
};
