import { createMockStore, mockFileList } from "../testHelpers";
import {
  uploadFileNotifyProgress,
  uploadFileRequest,
  uploadFileResponse,
  uploadFilesRequest
} from "./actions";
import { uploadFiles } from "./thunks";

test(".uploadFiles", async () => {
  // Given
  const initialState = {
    property: { id: 124 },
    files: { folder: "Lockbox" }
  };

  const error = new Error();
  const api = {
    uploadFile: jest.fn().mockImplementation(({ fileItem }, callback) => {
      if (fileItem.name === "image001.jpg") {
        callback({ loaded: 0, total: 16 });
        callback({ loaded: 8, total: 16 });
        callback({ loaded: 16, total: 16 });

        return Promise.resolve({
          id: 1,
          file_name: "image001.jpg"
        });
      }

      if (fileItem.name === "archive.zip") {
        return Promise.reject(error);
      }

      return null;
    })
  };

  const store = createMockStore({ initialState, api });

  const fileList = mockFileList();

  // When
  await store.dispatch(uploadFiles(fileList));
  const actions = store.getActions();

  // Then
  expect(actions.length).toBe(8);
  expect(actions[0]).toEqual(uploadFilesRequest(fileList));

  expect(actions[1]).toEqual(uploadFileRequest(fileList[0]));
  expect(actions[2]).toEqual(
    uploadFileNotifyProgress(fileList[0], {
      loaded: 0,
      total: 16
    })
  );
  expect(actions[3]).toEqual(
    uploadFileNotifyProgress(fileList[0], {
      loaded: 8,
      total: 16
    })
  );
  expect(actions[4]).toEqual(
    uploadFileNotifyProgress(fileList[0], {
      loaded: 16,
      total: 16
    })
  );
  expect(actions[5]).toEqual(
    uploadFileResponse(fileList[0], { id: 1, file_name: "image001.jpg" })
  );

  expect(actions[6]).toEqual(uploadFileRequest(fileList[1]));
  expect(actions[7]).toEqual(uploadFileResponse(fileList[1], error));
});
