import "./UploadPlaceholder.scss";

import React from "react";

export const UploadPlaceholder = ({
  onSelectFiles,
  inputProps,
  maxSizeText
}) => (
  <div className="card file-card m-1 shadow-sm bg-white rounded">
    <div className="card-body text-center">
      <i className="fas fa-cloud-upload-alt upload-placeholder" />
      <button type="button" className="btn btn-light" onClick={onSelectFiles}>
        Select files to upload
      </button>
      <input {...inputProps} className="form-control-file d-none" />
      or drag them here.
      {maxSizeText && <p className="text-muted mt-2 small">{maxSizeText}</p>}
    </div>
  </div>
);
