import React, { useEffect } from "react";
import { GoogleMap, Marker } from "react-google-maps";

import {
  coordsMissing,
  googleMapsApi,
  safelyExtendMarkerBounds
} from "./utils";

export const MARKER_HOME_ICON =
  "https://maps.gstatic.com/mapfiles/ms2/micons/red-dot.png";
export const MARKER_START_ICON =
  "https://maps.gstatic.com/mapfiles/ms2/micons/yellow.png";
export const MARKER_COMPLETE_ICON =
  "https://maps.gstatic.com/mapfiles/ms2/micons/green.png";

export const GoogleMapWrapper = React.forwardRef((props, ref) => {
  const {
    visible,
    coordinates: { property, start, complete }
  } = props;

  useEffect(() => {
    // Sets zoom and centers map so all markers are visible
    const markerBounds = new (googleMapsApi().LatLngBounds)();
    safelyExtendMarkerBounds(markerBounds, property);
    safelyExtendMarkerBounds(markerBounds, start);
    safelyExtendMarkerBounds(markerBounds, complete);

    ref.current.fitBounds(markerBounds, 18);
  }, [complete, property, ref, start, visible]);

  return (
    <GoogleMap
      ref={ref}
      zoom={props.zoom}
      options={{
        mapTypeControl: false,
        fullscreenControl: true,
        streetViewControl: false
      }}
    >
      {!coordsMissing(start) && (
        <Marker
          zIndex={1}
          animation={googleMapsApi().Animation.DROP}
          position={start}
          icon={MARKER_START_ICON}
        />
      )}
      {!coordsMissing(complete) && (
        <Marker
          zIndex={1}
          animation={googleMapsApi().Animation.DROP}
          position={complete}
          icon={MARKER_COMPLETE_ICON}
        />
      )}
      {!coordsMissing(property) && (
        <Marker
          zIndex={0}
          animation={googleMapsApi().Animation.DROP}
          position={property}
          icon={MARKER_HOME_ICON}
        />
      )}
    </GoogleMap>
  );
});
