/* eslint-disable react/jsx-props-no-spreading */
import React, { useState } from "react";

import { MaintenanceAssetUpload } from "../MaintenanceTable/MaintenanceAssetUpload";
import MaintenanceGallery from "../MaintenanceTable/MaintenanceGallery";

export const ImagesUpload = ({ maintenanceId }) => {
  const [cleanImages, setCleanImages] = useState([]);

  return (
    <>
      <MaintenanceGallery maintenanceId={maintenanceId} images={cleanImages} />

      <MaintenanceAssetUpload
        maintenanceId={maintenanceId}
        onUploadFinish={(data) => {
          setCleanImages(data?.clean_images);
        }}
      />
    </>
  );
};
