import axios from "axios";
import get from "lodash/get";

export async function geocode({ street, city, postcode, country }) {
  const params = {
    key: window.AirbaseConstants.GOOGLE_MAPS_API_KEY,
    address: [street, city, postcode, country].filter(Boolean).join(", ")
  };

  const response = await axios.get(
    "https://maps.googleapis.com/maps/api/geocode/json",
    { params }
  );

  return get(response, "data.results.0.geometry.location");
}

export const geocodeWithFallback = async ({
  street,
  city,
  postcode,
  country
}) => {
  let geocodeResult = await geocode({ street, city, postcode, country });

  // Accurate location cannot be found, try to find a location of the city.
  if (!geocodeResult) {
    geocodeResult = await geocode({ city });
  }

  return geocodeResult;
};
