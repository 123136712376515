import React, { useCallback } from "react";
import { useSelector } from "react-redux";

import { downloadMultiple } from "../api";
import { selectedFilesIds as selectedFilesIdsSelector } from "../data/files/selectors";
import { downloadImages } from "./zipDownload";

export const BulkActionDownload = ({ propertyId }) => {
  const selectedFilesIds = useSelector(selectedFilesIdsSelector);

  const handleClick = useCallback(
    async (e) => {
      e.preventDefault();
      const propertyFiles = await downloadMultiple({
        propertyId,
        ids: selectedFilesIds
      });

      downloadImages(propertyFiles);
    },
    [selectedFilesIds, propertyId]
  );

  return (
    <>
      <button
        type="button"
        className="btn btn-primary"
        onClick={handleClick}
        data-testid="bulk-download"
      >
        <i className="fa fa-folder-open" aria-hidden="true" /> Download selected
      </button>
    </>
  );
};
