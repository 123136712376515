// Jest Snapshot v1, https://goo.gl/fbAQLP

exports[`<FileListSortingContainer /> renders with success 1`] = `
<div>
  <div
    class="input-group"
  >
    <div
      class="input-group-append"
    >
      <button
        class="btn btn-light"
        type="button"
      >
        <i
          class="fas fa-sort-amount-up"
        />
      </button>
    </div>
    <select
      class="form-control"
    >
      <option
        value="created_at"
      >
        Date created
      </option>
      <option
        value="updated_at"
      >
        Date updated
      </option>
      <option
        value="file_name"
      >
        File name
      </option>
      <option
        value="file_size"
      >
        File size
      </option>
    </select>
  </div>
</div>
`;
