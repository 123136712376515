import React from "react";

export const LeadOption = ({ lead, onSelect }) => (
  <div
    className="SearchSelect__option"
    onClick={() => onSelect(lead)}
    role="button"
    tabIndex={0}
  >
    <div className="name">{lead.display_name}</div>
  </div>
);
