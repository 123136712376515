import { Formik } from "formik";
import React, { useContext } from "react";

import { SelectOptions } from "../../../../common/SelectOptions";
import { FormGroup } from "../components/FormGroup";
import { HomeInfoContext } from "../HomeInfoContext";
import { HomeInfoForm } from "../HomeInfoForm";
import { SIDEBAR_NAMES } from "../sections";

const WASTE_COLLECTION_FREQUENCIES = ["daily", "weekly or fortnightly"];

const WASTE_COLLECTION_DAYS = [
  "Mon",
  "Tue",
  "Wed",
  "Thu",
  "Fri",
  "Sat",
  "Sun",
  "all"
];

export const Waste = () => {
  const { homeInfo, updateHomeInfo } = useContext(HomeInfoContext);

  return (
    <Formik
      initialValues={homeInfo}
      enableReinitialize
      onSubmit={updateHomeInfo}
    >
      {({ setFieldValue }) => (
        <HomeInfoForm sidebarName={SIDEBAR_NAMES.CLEANING}>
          <h4 className="section-name">Waste</h4>

          <FormGroup
            name="home_info_cleaning_attributes.waste_instructions"
            placeholder="Describe in detail how to take out the waste and where spare bin bags can be found"
            label="Waste instructions"
            component="textarea"
            rows={5}
            visibleToGuests
            visibleToCleaners
          />

          <FormGroup
            name="home_info_cleaning_attributes.waste_collection_frequency"
            label="Waste collection frequency"
            component="select"
            visibleToCleaners
          >
            <SelectOptions options={WASTE_COLLECTION_FREQUENCIES} />
          </FormGroup>

          <FormGroup
            name="home_info_cleaning_attributes.waste_collection_days"
            label="Waste collection days"
            helpText="Use CTRL + click to select several days"
            component="select"
            multiple
            visibleToCleaners
            size={9}
            onChange={(event) =>
              setFieldValue(
                "home_info_cleaning_attributes.waste_collection_days",
                Array.from(event.target.selectedOptions).map(
                  (option) => option.value
                )
              )
            }
          >
            <SelectOptions options={WASTE_COLLECTION_DAYS} />
          </FormGroup>

          <h4 className="section-name">Recycling</h4>

          <FormGroup
            name="home_info_cleaning_attributes.recycling_instructions"
            placeholder="Describe how to recycle if available"
            label="Recycling instructions"
            component="textarea"
            rows={5}
            visibleToGuests
            visibleToCleaners
          />
        </HomeInfoForm>
      )}
    </Formik>
  );
};
