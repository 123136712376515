import React, { useCallback } from "react";

import { DatePicker } from "../../../../common/forms/DatePicker";

export const BookedOnFilters = ({ getFilterValue, onFilterChange }) => {
  const handleBookedOnFromSelect = useCallback(
    (dateString) => onFilterChange("booked_at_from", dateString),
    [onFilterChange]
  );

  const handleBookedOnToSelect = useCallback(
    (dateString) => onFilterChange("booked_at_to", dateString),
    [onFilterChange]
  );

  return (
    <>
      <label className="font-weight-bold col-form-label col-sm-3 text-right">
        Booked on from
      </label>
      <DatePicker
        className="col-sm-4"
        defaultValue={getFilterValue("booked_at_from")}
        onSelect={handleBookedOnFromSelect}
        pikadayOptions={{
          maxDate: new Date(getFilterValue("booked_at_to"))
        }}
      />

      <label className="font-weight-bold col-form-label col-auto">to</label>
      <DatePicker
        className="col-sm-4"
        defaultValue={getFilterValue("booked_at_to")}
        onSelect={handleBookedOnToSelect}
        pikadayOptions={{
          minDate: new Date(getFilterValue("booked_at_from")),
          defaultDate: new Date(getFilterValue("booked_at_from"))
        }}
      />
    </>
  );
};
