import { applyMiddleware, compose, createStore } from "redux";
import thunk from "redux-thunk";

import * as api from "../api";
import { reducer } from "./reducer";

const middlewares = [thunk.withExtraArgument({ api })];

if (process.env.NODE_ENV === "development") {
  const { createLogger, defaults } = require("redux-logger");

  middlewares.push(
    createLogger({
      collapsed: true,
      colors: {
        ...defaults.colors,
        title: (action) =>
          action.error ? "red" : defaults.colors.title(action)
      }
    })
  );
}

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
export const store = createStore(
  reducer,
  composeEnhancers(applyMiddleware(...middlewares))
);
