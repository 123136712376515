import React from "react";

import { humanize } from "./stringUtils";

export const SelectOptions = ({
  includeBlank = true,
  disabled = false,
  options
}) => {
  const sanitizedOptions = Array.isArray(options)
    ? options
    : Object.entries(options);

  return (
    <>
      {includeBlank && <option value="">{includeBlank}</option>}

      {sanitizedOptions.map((option) => {
        const [value, label] = Array.isArray(option) ? option : [option];

        return (
          <option key={value} value={value} disabled={disabled}>
            {label || humanize(value)}
          </option>
        );
      })}
    </>
  );
};
