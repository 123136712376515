import React from "react";

import { humanize } from "../../../common/stringUtils";
import { DropdownFilter } from "../../../common/tables/filters";

const { AVAILABLE_TYPES } = window.AirbaseConstants.Clean;

const VALUES = AVAILABLE_TYPES.map((type) => [humanize(type), type]);

export const CleanTypeFilter = ({ filter, onFilterChange, scope }) => {
  const handleChange = (value) => onFilterChange("clean_type", value);

  const checkboxId = ["clean-type-select", scope].filter(Boolean).join("-");

  return (
    <div className="clean-filter">
      <label htmlFor={checkboxId}>Choose clean type</label>

      <DropdownFilter
        id={checkboxId}
        onChange={handleChange}
        filter={filter}
        values={VALUES}
      />
    </div>
  );
};
