import { Formik } from "formik";
import React, { useContext } from "react";

import { FormGroup } from "../components/FormGroup";
import { HomeInfoContext } from "../HomeInfoContext";
import { HomeInfoForm } from "../HomeInfoForm";
import { SIDEBAR_NAMES } from "../sections";

export const ItemLocation = () => {
  const { homeInfo, updateHomeInfo } = useContext(HomeInfoContext);

  return (
    <Formik
      initialValues={homeInfo}
      enableReinitialize
      onSubmit={updateHomeInfo}
    >
      <HomeInfoForm sidebarName={SIDEBAR_NAMES.CLEANING}>
        <h4 className="section-name">Vacuum</h4>

        <FormGroup
          name="home_info_maintenance_attributes.vacuum_make_and_model"
          label="Vacuum make and model"
          component="textarea"
          rows={3}
          visibleToCleaners
        />

        <FormGroup
          name="home_info_cleaning_attributes.vacuum_location"
          label="Vacuum location"
          component="textarea"
          rows={3}
          visibleToGuests
          visibleToCleaners
        />

        <FormGroup
          name="home_info_maintenance_attributes.vacuum_troubleshooting"
          label="Vacuum troubleshooting"
          component="textarea"
          rows={3}
        />

        <h4 className="section-name">Equipment</h4>

        <FormGroup
          name="home_info_cleaning_attributes.cleaning_equipment_location"
          label="Cleaning equipment location"
          component="textarea"
          rows={3}
          visibleToGuests
          visibleToCleaners
        />

        <FormGroup
          name="home_info_cleaning_attributes.mop_bucket_location"
          label="Mop & bucket location"
          component="textarea"
          rows={3}
          visibleToGuests
          visibleToCleaners
        />

        <FormGroup
          name="home_info_cleaning_attributes.ironing_board_location"
          label="Ironing board location"
          component="textarea"
          rows={3}
          visibleToGuests
        />

        <h4 className="section-name">Items</h4>

        <FormGroup
          name="home_info_cleaning_attributes.spare_toiletries"
          label="Spare toiletries"
          component="textarea"
          rows={3}
        />

        <FormGroup
          name="home_info_cleaning_attributes.spare_bedding_location"
          label="Spare bedding location"
          component="textarea"
          rows={3}
        />

        <FormGroup
          name="home_info_cleaning_attributes.linen_storage_location"
          label="Linen storage location"
          component="textarea"
          rows={3}
          visibleToGuests
        />
      </HomeInfoForm>
    </Formik>
  );
};
