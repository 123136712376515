import React, { useMemo } from "react";
import ReactTable from "react-table";

import { apiClient } from "../../../common/apiClient";
import { formatDate } from "../../../common/date";
import { humanize } from "../../../common/stringUtils";
import { Case, Switch } from "../../../common/Switch";
import { dateColumn } from "../../../common/tables/dateColumn";
import { GenericSearchFilter } from "../../../common/tables/filters";
import { useControlledTable } from "../../../common/tables/useControlledTable";
import { ApprovalStatusFilter } from "../../bookings/BookingsTable/Filters/ApprovalStatusFilter";
import { VerifyFilter } from "../../bookings/BookingsTable/Filters/VerifyFilter";

const fetchBookingChannels = () =>
  apiClient.get("/api/booking_channels").then((response) => response.data.rows);

const STATUS_COLOR_MAP = {
  true: "green",
  false: "red",
  null: "orange"
};

const WARNING_COLOR_MAP = {
  Overlapping: "red",
  "Not managed": "orange"
};

const initialState = {
  pageSize: 20,
  sorted: [{ id: "checkin", desc: true }],
  filtered: [
    {
      id: "channel",
      values: fetchBookingChannels().all
    }
  ]
};

export const PropertyBookingsTable = ({ propertyId }) => {
  const {
    getFilter,
    getFilterValue,
    onFilterChange,
    ...controlledProps
  } = useControlledTable({
    api: { endpoint: `/api/properties/${propertyId}/bookings` },
    initialState
  });

  const columns = useMemo(
    () => [
      {
        Header: "Check-in",
        accessor: "checkin",
        sortable: true,
        className: "rt-cell-with-two-rows",
        maxWidth: 180,
        Cell: ({ original: { checkin, show_path } }) => (
          <a href={show_path} title="Show booking">
            {formatDate(new Date(checkin))}
          </a>
        )
      },
      {
        Header: "Check-out",
        accessor: "checkout",
        sortable: true,
        className: "rt-cell-with-two-rows",
        maxWidth: 180,
        Cell: ({ original: { checkout, show_path } }) => (
          <a href={show_path} title="Show booking">
            {formatDate(new Date(checkout))}
          </a>
        )
      },
      dateColumn({
        Header: "Booked On",
        accessor: "booked_at",
        id: "booked_at",
        sortable: true
      }),
      {
        Header: "Nights",
        accessor: "nights",
        sortable: true,
        Cell: ({ value }) => humanize(value),
        maxWidth: 80
      },
      {
        Header: "Channel",
        id: "booking_channels.name",
        accessor: "booking_channel.name",
        sortable: true,
        Cell: ({ value }) => humanize(value),
        maxWidth: 130
      },
      {
        Header: "Warning",
        accessor: "warning",
        sortable: false,
        getProps: (state, rowInfo) => ({
          className: WARNING_COLOR_MAP[rowInfo?.row?.warning]
        }),
        Cell: ({ value }) => humanize(value),
        maxWidth: 130
      },
      {
        Header: "Cancelled",
        accessor: "cancelled_at",
        sortable: false,
        getProps: (state, rowInfo) => ({
          className: rowInfo?.row?.cancelled_at ? "red" : "grey"
        }),
        Cell: ({ value }) => humanize(value),
        maxWidth: 130
      },
      {
        Header: "Guest Name",
        accessor: "guest_name",
        filterable: true,
        className: "rt-cell-with-two-rows",
        Cell: ({ original: { guest_name, show_path } }) => (
          <a href={show_path}>{guest_name}</a>
        ),
        Filter: ({ onChange, filter }) => (
          <GenericSearchFilter
            onChange={onChange}
            filter={filter}
            placeholder="Search by name"
          />
        )
      },
      {
        Header: "Verified",
        accessor: "verified",
        sortable: true,
        filterable: true,
        getProps: (state, rowInfo) => ({
          className: STATUS_COLOR_MAP[rowInfo?.row?.verified]
        }),
        Filter: VerifyFilter,
        Cell: ({ original: { verified } }) => (
          <span>
            <Switch test={verified}>
              <Case value>Yes</Case>
              <Case value={false}>No</Case>
              <Case default>Pending</Case>
            </Switch>
          </span>
        ),
        maxWidth: 130
      },
      {
        Header: "Approved",
        accessor: "approved",
        sortable: false,
        filterable: true,
        maxWidth: 130,
        getProps: (state, rowInfo) => ({
          className: STATUS_COLOR_MAP[rowInfo?.row?.approved]
        }),
        Filter: ApprovalStatusFilter,
        Cell: ({ original: { approved } }) => (
          <span>
            <Switch test={approved}>
              <Case value>Approved</Case>
              <Case value={false}>Rejected</Case>
              <Case default>Pending</Case>
            </Switch>
          </span>
        )
      }
    ],
    []
  );

  return (
    <div style={{ marginTop: 20 }}>
      <ReactTable
        {...controlledProps}
        columns={columns}
        className="-striped -highlight"
        sortable={false}
      />

      <h4>Total number of bookings {controlledProps.totalCount}</h4>
    </div>
  );
};
