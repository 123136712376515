import { fireEvent, render } from "@testing-library/react";
import React from "react";

import { CleanTypeFilter } from "./CleanTypeFilter";

const { TYPE_POST_HOST, TYPE_MID_STAY } = window.AirbaseConstants.Clean;

describe("<CleanTypeFilter />", () => {
  it("renders the dropdown with clean type", () => {
    // Given
    const filter = { id: "clean_type", value: TYPE_POST_HOST };

    // When
    const { getByText } = render(<CleanTypeFilter filter={filter} />);

    // Then
    expect(getByText("First")).toBeDefined();

    expect(getByText("Post host")).toBeDefined();
    expect(getByText("Post host")).toHaveAttribute("selected");

    expect(getByText("Mid stay")).toBeDefined();
    expect(getByText("Standard")).toBeDefined();
  });

  it("handles clean type change", () => {
    // Given
    const filter = { id: "clean_type", value: TYPE_POST_HOST };
    const onFilterChange = jest.fn();

    const { getByLabelText } = render(
      <CleanTypeFilter filter={filter} onFilterChange={onFilterChange} />
    );

    // When
    fireEvent.change(getByLabelText("Choose clean type"), {
      target: { value: TYPE_MID_STAY }
    });

    // Then
    expect(onFilterChange).toHaveBeenLastCalledWith(
      "clean_type",
      TYPE_MID_STAY
    );
  });
});
