import { apiClient } from "../../../common/apiClient";

export const updateCompensation = (id, compensation) =>
  apiClient
    .put(`/api/bookings/${id}/update_compensation`, {
      compensation
    })
    .then((response) => response.data);

export const updateCompensationNotes = (id, notes) =>
  apiClient
    .put(`/api/bookings/${id}/update_compensation_notes`, {
      compensation_notes: notes
    })
    .then((response) => response.data);
