import React, { useState } from "react";

import { Toggle } from "../Toggle";

const MESSAGING_TOGGLES = {
  skip_checkin_reminder: "Checkin reminder",
  skip_cleaner_review: "Cleaner review",
  skip_checkout_reminder: "Checkout reminder",
  skip_auto_review: "Guest review"
};

export const MessagingToggles = ({ booking, disabledToggles = {} }) => {
  const [muted, setMuted] = useState(booking.muted);

  return (
    <div className="automatic__messaging__wrapper">
      <Toggle
        booking={booking}
        attribute="muted"
        reversed
        value={booking.muted}
        onChange={setMuted}
        label="Automatic messages"
      />

      {!muted && (
        <div className="automatic__messaging">
          {Object.entries(MESSAGING_TOGGLES).map(([attribute, label]) => (
            <Toggle
              key={attribute}
              booking={booking}
              attribute={attribute}
              reversed
              label={label}
              disabled={disabledToggles[attribute]}
            />
          ))}
          {booking.unhappy_guest && (
            <span>Unhappy guest, message will not be sent</span>
          )}
        </div>
      )}
    </div>
  );
};
