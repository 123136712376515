export const AMENITY_DESCRIPTIONS = {
  barbecue: "Grill, charcoal, bamboo skewers/iron skewers, etc.",
  cooking_basics: "Pots and pans, oil, salt and pepper",
  dishes_and_silverware: "Bowls, chopsticks, plates, cups, etc.",
  kitchen: "Space where guests can cook their own meals",
  kitchenette: "Space where guests can heat up and refrigerate food",
  elevator:
    "The home or building has a lift that’s at least 52 inches (132cm) deep and a doorway at least 32 inches (81cm) wide.",
  ev_charger: "Guests can charge their electric vehicles on the property",
  single_level_home: "No stairs in home",
  bed_linens: "Material (Cotton, Egyptian cotton, Brushed Cotton, Silk); Brand",
  dryer:
    "Add details (tickboxes): Price (Free/paid), Location (In unit/In building)",
  essentials: "Towels, bed sheets, soap, toilet paper, and pillows",
  washing_machine:
    "Add details (tickboxes): Price (Free/paid), Location (In unit/In building)",
  carbon_monoxide_alarm:
    "Check your local laws, which may require a working carbon monoxide detector in every room.",
  smoke_alarm:
    "Check your local laws, which may require a working carbon monoxide detector in every room.",
  designated_workspace:
    "Guests have a desk or table that’s used just for working, along with a comfortable chair",
  wireless_internet: "Available throughout the listing",
  beach_access: "Guests can enjoy a nearby beach",
  lake_access: "Guests can get to a lake using a path or dock",
  private_entrance: "Separate street or building entrance",
  resort_access: "Guests can use nearby resort facilities",
  ski_in_ski_out:
    "Guests can access ski lifts without driving or taking paid transport",
  waterfront: "Right next to a body of water",
  garden_or_backyard: "An open space on the property usually covered in grass",
  beach_essentials: "Beach towels, umbrella, beach blanket, snorkelling gear"
};
