import React, { useCallback, useContext, useState } from "react";

import { toggleBooking } from "./api";

export const ToggleBookingApiContext = React.createContext(toggleBooking);

export const useBookingToggle = (booking, attribute) => {
  const toggleBooking = useContext(ToggleBookingApiContext);
  const [toggled, setToggled] = useState(booking[attribute]);

  const toggleAttribute = useCallback(
    async (params = {}) => {
      const updatedBooking = await toggleBooking(booking.id, attribute, params);

      const updatedToggled = updatedBooking[attribute];
      setToggled(updatedToggled);

      return updatedBooking;
    },
    [booking, attribute, toggleBooking]
  );

  return {
    toggled,
    toggleAttribute
  };
};
