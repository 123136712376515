import "./Explanation.scss";
import React, { useMemo, useState } from "react";

export const Explanation = ({
  visible: visibleProp,
  toggleLinkVisible = true,
  text
}) => {
  const [visible, setVisible] = useState(visibleProp);
  const toggleLink = useMemo(() => {
    if (!toggleLinkVisible) {
      return null;
    }

    return visible ? "Hide explanation" : "Show explanation";
  }, [toggleLinkVisible, visible]);

  if (!text) {
    return null;
  }

  return (
    <div className="SpecialistSelection--container twbs">
      {visible && (
        <>
          <span className="SpecialistSelection--explanation-label">
            Explanation
          </span>
          <div className="Explanation">
            {text}
            <br />
          </div>
        </>
      )}

      <button
        className="btn btn-link"
        type="button"
        onClick={() => setVisible(!visible)}
      >
        {toggleLink}
      </button>
    </div>
  );
};
