import { useFormikContext } from "formik";
import { get } from "lodash";
import React from "react";

import { formatAmountWithCurrency } from "../../../common/numbers";

const { HOST_MAINTENANCE_TYPES } = window.AirbaseConstants;

const PLACEHOLDER = "--";

const resolutionText = (value) => {
  return HOST_MAINTENANCE_TYPES[value] || PLACEHOLDER;
};

export const HostPreferencesHint = ({ hostPreferences, currencySymbol }) => {
  const withCurrency = (value) => {
    if (!value) {
      return PLACEHOLDER;
    }

    return formatAmountWithCurrency(value, { currencySymbol });
  };

  const {
    values: { severity }
  } = useFormikContext();

  if (!hostPreferences || severity === "") {
    return false;
  }

  const preferencesMap = {
    emergency: "maintenance_emergencies",
    urgent: "maintenance_urgent",
    non_urgent: "maintenance_non_urgent"
  };

  const preference = get(hostPreferences, preferencesMap[severity]);
  const spendLimit = get(hostPreferences, `${preferencesMap[severity]}_spend`);

  return (
    <div className="HostPreferencesHint">
      Host preference: {resolutionText(preference)} (spend limit{" "}
      {withCurrency(spendLimit, currencySymbol)})
    </div>
  );
};
