import { render } from "@testing-library/react";
import React from "react";

import { CleanImage } from "./CleanImage";

test("<CleanImage />", () => {
  const image = {
    type: "receipt",
    description: "Receipt for cleaning stuff",
    receipt_amount_formatted: "$4.99",
    original_image_url: "",
    thumbnail_image_url: "",
    created_at: "09-05-2019",
    exemplary: true
  };

  const { container } = render(<CleanImage image={image} />);

  expect(container).toMatchSnapshot();
});
