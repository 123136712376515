import { apiClient } from "../../../common/apiClient";

export const getCleanImages = (cleanId) =>
  apiClient
    .get(`/api/cleans/${cleanId}/images/`)
    .then((response) => response.data);

export const deleteImage = ({ cleanId, id }) => {
  return apiClient
    .delete(`/api/cleans/${cleanId}/images/${id}`)
    .then((response) => response.data);
};
