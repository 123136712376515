import { FieldArray, Formik } from "formik";
import range from "lodash/range";
import React, { useContext } from "react";
import * as yup from "yup";

import { SelectOptions } from "../../../../common/SelectOptions";
import { FormGroup } from "../components/FormGroup";
import { NestedFields } from "../components/NestedFields";
import { HomeInfoContext } from "../HomeInfoContext";
import { HomeInfoForm } from "../HomeInfoForm";

const DOOR_TYPES = [
  "communal internal",
  "communal external",
  "front door",
  "parking garage",
  "other"
];

const DOOR_ACCESS_SOLUTIONS = {
  key: "Key (Standard)",
  key_special: "Key (Specialist)",
  swipe_card: "Swipe card",
  fob: "Fob",
  other: "Other"
};

const KEY_SETS_OPTIONS = range(0, 10);

export const validationSchema = yup.object().shape({
  doors_attributes: yup.array().of(
    yup.object().shape({
      type: yup.string().required("Please select door type")
    })
  )
});

const getInitialDoorValues = (values) => ({ home_info_id: values.id });

export const ConfigurationDoorsAndKeys = () => {
  const { externalKeysCount, homeInfo, updateHomeInfo } = useContext(
    HomeInfoContext
  );

  return (
    <Formik
      initialValues={homeInfo}
      enableReinitialize
      validationSchema={validationSchema}
      isInitialValid={
        validationSchema ? validationSchema.isValidSync(homeInfo) : true
      }
      onSubmit={updateHomeInfo}
    >
      {({ values }) => (
        <HomeInfoForm sidebarName="Configuration">
          <h4 className="section-name">Doors</h4>

          <FieldArray
            name="doors_attributes"
            render={({ push, remove }) => (
              <div>
                {values.doors_attributes.map((door, index) => (
                  <NestedFields
                    key={index}
                    name={`doors_attributes.${index}`}
                    onRemove={() => remove(index)}
                  >
                    <FormGroup
                      name={`doors_attributes.${index}.type`}
                      label="Door type"
                      component="select"
                      disabled={door._destroy}
                    >
                      <SelectOptions options={DOOR_TYPES} />
                    </FormGroup>

                    <FormGroup
                      name={`doors_attributes.${index}.access_solution`}
                      label="Key / fob / access solution"
                      component="select"
                      disabled={door._destroy}
                    >
                      <SelectOptions options={DOOR_ACCESS_SOLUTIONS} />
                    </FormGroup>

                    <FormGroup
                      name={`doors_attributes.${index}.note`}
                      label="Note"
                      placeholder="Swipe fob on left-hand side of the door"
                      type="textarea"
                      disabled={door._destroy}
                    />
                  </NestedFields>
                ))}

                <NestedFields.AddButton
                  label="Add Door"
                  onClick={() => push(getInitialDoorValues(values))}
                />
              </div>
            )}
          />

          <hr />

          <h4 className="section-name">Keys</h4>

          <div className="form-row">
            <div className="col-md-3">
              <FormGroup
                name="key_sets"
                label="Total key sets"
                component="select"
              >
                <SelectOptions options={KEY_SETS_OPTIONS} />
              </FormGroup>
            </div>

            <div className="col-md-3">
              <FormGroup
                name="guest_key_sets"
                label="Guest key sets"
                component="select"
              >
                <SelectOptions options={KEY_SETS_OPTIONS} />
              </FormGroup>
            </div>

            <div className="col-md-3">
              <FormGroup
                name="houst_key_sets"
                label="Houst key sets"
                component="select"
              >
                <SelectOptions options={KEY_SETS_OPTIONS} />
              </FormGroup>
            </div>
          </div>

          <FormGroup
            name="key_notes"
            label="General notes"
            placeholder="eg Key 1: Description / Opens communal Doors"
            component="textarea"
            rows={2}
          />

          <a
            href={`/properties/${values.property_attributes.id}/external_keys`}
          >
            View External Keys ({externalKeysCount})
          </a>

          <hr />

          <FieldArray
            name="keys_attributes"
            render={({ push, remove }) => (
              <div>
                {values.keys_attributes.map((key, index) => (
                  <NestedFields
                    key={index}
                    name={`keys_attributes.${index}`}
                    onRemove={() => remove(index)}
                  >
                    <FormGroup
                      name={`keys_attributes.${index}.type`}
                      label="Type"
                      placeholder="Key brand and type e.g. ‘Yale latch’ or ‘Chubb mortice’"
                    />

                    <FormGroup
                      name={`keys_attributes.${index}.note`}
                      label="Note"
                      placeholder="Enter serial number if present"
                    />
                  </NestedFields>
                ))}

                <NestedFields.AddButton
                  label="Add Keys"
                  onClick={() => push({ home_info_id: values.id })}
                />
              </div>
            )}
          />
        </HomeInfoForm>
      )}
    </Formik>
  );
};
