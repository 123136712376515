import { formatDateTime } from "./helpers";

test.each`
  time         | date            | expected
  ${"16:15"}   | ${"2023-07-17"} | ${"2023-07-17T16:15"}
  ${"16:15"}   | ${"2023-10-17"} | ${"2023-10-17T16:15"}
  ${"09:00"}   | ${"2023-10-17"} | ${"2023-10-17T09:00"}
  ${"09:07"}   | ${"2023-10-03"} | ${"2023-10-03T09:07"}
  ${"09:07"}   | ${undefined}    | ${null}
  ${undefined} | ${"2023-10-03"} | ${"2023-10-03T00:00"}
`("return expected values", ({ time, date, expected }) => {
  expect(formatDateTime(date, time)).toEqual(expected);
});
