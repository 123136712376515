import classNames from "classnames";
import React, { useEffect, useMemo, useState } from "react";

import { SelectOptions } from "../../../common/SelectOptions";

const {
  ATTRIBUTION_HOUST,
  ATTRIBUTION_HOST
} = window.AirbaseConstants.Maintenance;

const AttributionWarning = ({
  attribution,
  houstAttributionWarning,
  houstAttributionLink,
  hostAttributionWarning,
  hostAttributionLink,
  linkTitle
}) => {
  const hasLink =
    (attribution === ATTRIBUTION_HOUST && houstAttributionLink) ||
    (attribution === ATTRIBUTION_HOST && hostAttributionLink);

  if (attribution === ATTRIBUTION_HOUST || attribution === ATTRIBUTION_HOST) {
    return (
      <div className="twbs">
        <div
          className={`mb-3 mt-2 ${
            hasLink ? "alert alert-warning p-0" : "card bg-light"
          }`}
        >
          <div className="card-body">
            <p>
              {attribution === ATTRIBUTION_HOUST
                ? houstAttributionWarning
                : hostAttributionWarning}
            </p>
            {hasLink && (
              <p>
                You might need to use the{" "}
                <strong>
                  <a
                    href={
                      attribution === ATTRIBUTION_HOUST
                        ? houstAttributionLink
                        : hostAttributionLink
                    }
                  >
                    {linkTitle}
                  </a>
                </strong>{" "}
                form instead.
              </p>
            )}
            <p>If you are unsure, please check with your manager.</p>
          </div>
        </div>
      </div>
    );
  }

  return null;
};

export const MaintenanceSelection = ({
  items,
  html_maintenance_id: htmlMaintenanceId,
  html_maintenance_name: htmlMaintenanceName,
  html_maintenance_job_id: htmlMaintenanceJobId,
  html_maintenance_job_name: htmlMaintenanceJobName,
  value_maintenance: valueMaintenance,
  value_maintenance_job: valueJob,
  hide_maintenance_job: hideMaintenanceJob,
  hasErrorMaintenance = false,
  hasErrorMaintenanceJob = false,
  houstAttributionWarning,
  houstAttributionLink,
  hostAttributionWarning,
  hostAttributionLink,
  link,
  linkTitle
}) => {
  const [selectedMaintenanceId, setSelectedMaintenanceId] = useState(
    valueMaintenance
  );
  const [selectedMaintenanceJobId, setSelectedMaintenanceJobId] = useState(
    valueJob
  );
  const [selectedAttribution, setSelectedAttribution] = useState(
    valueMaintenance
  );

  const handleChangeMaintenance = (event) => {
    setSelectedMaintenanceId(event.target.value);
  };

  const handleChangeMaintenanceJob = (event) => {
    setSelectedMaintenanceJobId(event.target.value);
  };

  const selectedMaintenance = useMemo(
    () => items.find((item) => item.id === Number(selectedMaintenanceId)),
    [items, selectedMaintenanceId]
  );

  useEffect(() => {
    setSelectedAttribution(selectedMaintenance?.attribution);
  }, [items, selectedMaintenance?.attribution, selectedMaintenanceId]);

  const maintenanceOptionsForSelect = items.map(({ id, label }) => [id, label]);
  const maintenanceJobOptionsForSelect = selectedMaintenance?.jobs?.map(
    ({ id, label }) => [id, label]
  );

  return (
    <div className="MaintenanceSelection">
      <div className={classNames({ "is-invalid": hasErrorMaintenance })}>
        {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
        <label htmlFor={htmlMaintenanceId}>Maintenance</label>
        <select
          id={htmlMaintenanceId}
          name={htmlMaintenanceName}
          value={selectedMaintenanceId || ""}
          onChange={handleChangeMaintenance}
        >
          <SelectOptions
            includeBlank="-- select --"
            options={maintenanceOptionsForSelect}
          />
        </select>

        <AttributionWarning
          attribution={selectedAttribution}
          houstAttributionWarning={houstAttributionWarning}
          houstAttributionLink={houstAttributionLink}
          hostAttributionWarning={hostAttributionWarning}
          hostAttributionLink={hostAttributionLink}
          link={link}
          linkTitle={linkTitle}
        />
      </div>

      {!hideMaintenanceJob && (
        <div className={classNames({ "is-invalid": hasErrorMaintenanceJob })}>
          {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
          <label htmlFor={htmlMaintenanceJobId}>Maintenance job</label>
          <select
            id={htmlMaintenanceJobId}
            name={htmlMaintenanceJobName}
            value={selectedMaintenanceJobId || ""}
            onChange={handleChangeMaintenanceJob}
          >
            <SelectOptions
              includeBlank="-- select --"
              options={maintenanceJobOptionsForSelect || []}
            />
          </select>
        </div>
      )}
    </div>
  );
};
