import React, { useState } from "react";

import { formatDate } from "../../../common/date";
import { OffboardingForm } from "./OffboardingForm";

export const PropertyOffboarding = ({ property, offboarder_name }) => {
  const [showPropertyModal, setShowPropertyModal] = useState(false);

  const handleModalToggle = () => setShowPropertyModal((prev) => !prev);

  const { offboarded_from } = property || {};

  const hasOffboardedFrom = Boolean(offboarded_from);

  return (
    <div className="edit-icon">
      <button
        type="button"
        className="btn btn-primary mb-3"
        onClick={handleModalToggle}
      >
        <i className="fas fa-edit" />
        &nbsp; {hasOffboardedFrom ? "Update" : "Set"} offboarded from date
      </button>

      {hasOffboardedFrom && (
        <p className="warning">
          Property has been offboarded on{" "}
          {formatDate(new Date(offboarded_from))} by {offboarder_name}
        </p>
      )}

      {showPropertyModal && (
        <OffboardingForm property={property} onClose={handleModalToggle} />
      )}
    </div>
  );
};
