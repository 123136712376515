import { fireEvent, waitFor } from "@testing-library/react";
import React from "react";

import { fetchCleaningTasks } from "../api";
import { updateFileResponse } from "../data/files/actions";
import { renderWithMockStore } from "../data/testHelpers";
import { EditFileModalContainer } from "./EditFileModalContainer";

jest.mock("../api", () => ({
  fetchCleaningTasks: jest.fn().mockReturnValue(Promise.resolve([]))
}));

describe("<EditFileModalContainer />", () => {
  const initialState = {
    property: { id: 123 }
  };

  const file = {
    id: 1234,
    file_name: "image01.jpg",
    folder_name: "Lockbox",
    uploader: { first_name: "Luke", last_name: "Skywalker" },
    created_at: "2019-10-23T12:37",
    property_id: 123
  };

  const updatedFile = {
    ...file,
    file_name: "image02.jpg",
    folder_name: "Damages",
    notes: "Test note...",
    home_info_cleaning_task_id: null
  };

  const file2 = {
    id: 98,
    file_name: "image01.jpg",
    folder_name: "Lockbox",
    uploader: { first_name: "Luke", last_name: "Skywalker" },
    created_at: "2019-10-23T12:37",
    property_id: 123
  };

  const updatedFileWithTask = {
    ...file2,
    file_name: "image02.jpg",
    folder_name: "Tasks",
    notes: "Test note...",
    home_info_cleaning_task_id: 3
  };

  const tasks = [
    {
      id: 2,
      description: "Clean the carpet",
      property_file_id: 98
    },
    {
      id: 3,
      description: "Don't clean the carpet"
    },
    {
      id: 4,
      description: "Don't clean the carpet"
    }
  ];

  beforeEach(() => {
    fetchCleaningTasks.mockResolvedValue(tasks);
  });

  it("validates file name", async () => {
    // Given
    const { getByLabelText, getByText } = renderWithMockStore(
      <EditFileModalContainer file={file} />,
      {
        initialState
      }
    );

    const submitButtonEl = getByText("Update");

    // When
    fireEvent.change(getByLabelText("File name"), {
      target: { value: "" }
    });
    fireEvent.click(submitButtonEl);

    // Then
    await waitFor(() =>
      expect(getByText("File name is required")).toBeInTheDocument()
    );

    // When
    fireEvent.change(getByLabelText("File name"), {
      target: { value: "/invalid file name/" }
    });
    fireEvent.click(submitButtonEl);

    // Then
    await waitFor(() =>
      expect(getByText("Invalid file name")).toBeInTheDocument()
    );
  });

  it("updates a file", async () => {
    // Given
    const api = {
      updateFile: jest.fn().mockReturnValue(Promise.resolve(updatedFile))
    };

    const onClose = jest.fn();

    // When
    const {
      getByLabelText,
      getByText,
      store
    } = renderWithMockStore(
      <EditFileModalContainer file={file} onClose={onClose} />,
      { initialState, api }
    );

    const submitButtonEl = getByText("Update");

    fireEvent.change(getByLabelText("File name"), {
      target: { value: "image02" }
    });
    fireEvent.change(getByLabelText("Folder"), {
      target: { value: "Damages" }
    });
    fireEvent.change(getByLabelText("Notes"), {
      target: { value: "Test note..." }
    });

    fireEvent.click(submitButtonEl);
    expect(submitButtonEl).toBeDisabled();

    await waitFor(() => expect(submitButtonEl).toBeEnabled());

    // Then
    expect(api.updateFile).toHaveBeenCalledWith(
      { id: 1234, property_id: 123 },
      updatedFile
    );

    expect(store.getActions()[0]).toEqual(updateFileResponse(updatedFile));
  });

  it("updates a file with task", async () => {
    // Given
    const api = {
      updateFile: jest.fn().mockReturnValue(Promise.resolve(updatedFile))
    };

    const onClose = jest.fn();

    // When
    const {
      getByLabelText,
      getByText,
      store
    } = renderWithMockStore(
      <EditFileModalContainer file={file2} onClose={onClose} />,
      { initialState, api }
    );

    await waitFor(() => {
      expect(fetchCleaningTasks).toHaveBeenCalledWith({
        propertyId: file.property_id
      });
    });

    const submitButtonEl = getByText("Update");

    fireEvent.change(getByLabelText("File name"), {
      target: { value: "image02" }
    });
    fireEvent.change(getByLabelText("Folder"), {
      target: { value: "Tasks" }
    });
    fireEvent.change(getByLabelText("Notes"), {
      target: { value: "Test note..." }
    });
    fireEvent.change(getByLabelText("Cleaning task"), {
      target: { value: "3" }
    });

    fireEvent.click(submitButtonEl);
    expect(submitButtonEl).toBeDisabled();

    await waitFor(() => expect(submitButtonEl).toBeEnabled());

    // Then
    expect(api.updateFile).toHaveBeenCalledWith(
      { id: 98, property_id: 123 },
      updatedFileWithTask
    );

    expect(store.getActions()[0]).toEqual(updateFileResponse(updatedFile));
  });
});
