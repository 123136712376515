import { Field, Form, Formik } from "formik";
import React, { useMemo } from "react";

import { apiClient } from "../../../common/apiClient";

const CollectionTypeSelect = ({
  externalKeys,
  collectionType,
  emptyLabel = "-- not set --"
}) => (
  <Field
    component="select"
    name={`${collectionType}_key_id`}
    id={`${collectionType}-key-select`}
  >
    <option value="">{emptyLabel}</option>

    {externalKeys.map((key) => (
      <option key={key.id} value={key.id}>
        {key.name} ({key.external_id})
      </option>
    ))}
  </Field>
);

export const getInitialValues = (externalKeys) =>
  externalKeys.reduce(
    (result, key) => {
      if (!key.collection_type) {
        return result;
      }

      return {
        ...result,
        [`${key.collection_type}_key_id`]: key.id.toString()
      };
    },
    { internal_key_id: "", guest_key_id: "" }
  );

export const CollectionTypeForm = ({
  propertyId,
  externalKeys,
  onUpdateCollectionType
}) => {
  const initialValues = useMemo(() => getInitialValues(externalKeys), [
    externalKeys
  ]);

  const handleSubmit = async (values, { setSubmitting }) => {
    if (!window.confirm(`Are you sure to update collection types?`)) {
      return;
    }

    try {
      await apiClient.put(
        `/api/properties/${propertyId}/external_keys/update_collection_types`,
        {
          data: values
        }
      );
      onUpdateCollectionType();
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <Formik onSubmit={handleSubmit} initialValues={initialValues}>
      {({ dirty, isSubmitting }) => (
        <Form className="form-inline">
          <div className="form-group mb-2">
            <label className="mr-2" htmlFor="guest-key-select">
              External key
            </label>

            <CollectionTypeSelect
              collectionType="guest"
              externalKeys={externalKeys}
            />
          </div>

          <div className="form-group mx-sm-3 mb-2">
            <label className="mr-2" htmlFor="internal-key-select">
              Internal key
            </label>

            <CollectionTypeSelect
              collectionType="internal"
              externalKeys={externalKeys}
              emptyLabel="Leave empty to use guest key"
            />
          </div>

          <button
            disabled={isSubmitting || !dirty}
            type="submit"
            className="btn btn-sm btn-submit mb-2"
          >
            Update
          </button>
        </Form>
      )}
    </Formik>
  );
};
