import { apiClient } from "../../../common/apiClient";

export const fetchDays = (propertyId, rangeStart, rangeEnd) => {
  if (!propertyId || !rangeStart) {
    return {};
  }

  return apiClient
    .get(`/api/properties/${propertyId}/calendar_days/edit`, {
      params: { range_start: rangeStart, range_end: rangeEnd || rangeStart }
    })
    .then((response) => response.data);
};

export const sanitizeValues = (values) => {
  const sanitizedValues = {};

  [
    "override_min_nights",
    "override_min_nights_multiplat",
    "override_price",
    "price_multiplier",
    "max_nights",
    "available"
  ].forEach((key) => {
    if (values[`arm_${key}`]) {
      sanitizedValues[key] = values[key];
    }
  });

  return sanitizedValues;
};

export const updateDays = (propertyId, { range_start, range_end, values }) =>
  apiClient
    .patch(`/api/properties/${propertyId}/calendar_days`, {
      calendar_days:
        range_start === range_end ? values : sanitizeValues(values),
      range_start,
      range_end
    })
    .then((response) => response.data);

export default { fetchDays, updateDays };
