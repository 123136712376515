import React, { useState } from "react";

import { CleanerSearch } from "./CleanerSearch";
import { SelectedCleaner } from "./SelectedCleaner";

export const CleanerSelection = ({
  selectedCleanerJSON,
  localityId,
  propertyId,
  fieldName,
  handleCleanerIdChange
}) => {
  const [selectedCleaner, setSelectedCleaner] = useState(
    JSON.parse(selectedCleanerJSON)
  );

  const handleSelect = (cleaner) => {
    setSelectedCleaner(cleaner);
    handleCleanerIdChange(cleaner.id);
  };

  return (
    <div className="SearchSelect">
      {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
      <label htmlFor="CleanerSelection" className="SearchSelect__header">
        Cleaner
      </label>

      {selectedCleaner ? (
        <SelectedCleaner
          cleaner={selectedCleaner}
          fieldName={fieldName}
          onClearSelection={() => setSelectedCleaner(null)}
          handleCleanerIdChange={handleCleanerIdChange}
        />
      ) : (
        <CleanerSearch
          localityId={localityId}
          propertyId={propertyId}
          onSelect={handleSelect}
        />
      )}
    </div>
  );
};
