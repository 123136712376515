import React from "react";

import { FormGroup } from "../../../../common/forms/FormGroup";

export const CleaningTaskSelect = ({ tasks }) => (
  <FormGroup
    name="home_info_cleaning_task_id"
    label="Cleaning task"
    component="select"
  >
    <option>Select cleaning task</option>
    {tasks &&
      tasks.map((task) => (
        <option key={task.id} value={task.id}>
          {task.description}
        </option>
      ))}
  </FormGroup>
);
