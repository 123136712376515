import compact from "lodash/compact";

const MINUTES_IN_AN_HOUR = 60;

export function formatCleanDuration(duration) {
  const hours = Math.floor(duration);
  const minutes = Math.round((duration - hours) * MINUTES_IN_AN_HOUR);

  return compact([
    hours > 0 && `${hours}h`,
    minutes > 0 && `${minutes}min`
  ]).join(" ");
}
