import React from "react";

import { modal } from "../../../common/modals";
import { activateCleaner, deactivateCleaner } from "../api";
import { ActivationForm } from "./ActivationForm";
import { DeactivationForm } from "./DeactivationForm";

export const confirmTogglingStatus = (cleaner) => {
  return new Promise((resolve) => {
    const closeConfirmation = () => modal.close();

    const makeHandlerFor = (fn) => async (values, { setSubmitting }) => {
      setSubmitting(true);

      try {
        const updatedCleaner = await fn(cleaner.id, values);

        resolve({ confirmed: true, cleaner: updatedCleaner });
        closeConfirmation();
      } catch (error) {
        alert(error);
      } finally {
        setSubmitting(false);
      }
    };

    modal.fire({
      html: cleaner.active ? (
        <DeactivationForm
          cleaner={cleaner}
          onSubmit={makeHandlerFor(deactivateCleaner)}
          onCancel={closeConfirmation}
        />
      ) : (
        <ActivationForm
          onSubmit={makeHandlerFor(activateCleaner)}
          onCancel={closeConfirmation}
        />
      ),
      animation: false,
      showConfirmButton: false,
      showCancelButton: false,
      onClose: () => resolve({ confirmed: false }),
      customClass: "cleaner-deactivation-modal-form",
      width: 800
    });
  });
};
