import { getInitialValues } from "./CollectionTypeForm";

test(".getInitialValues", () => {
  const externalKeys = [
    { id: 123, collection_type: "internal" },
    { id: 234, collection_type: "guest" },
    { id: 567, collection_type: null }
  ];

  expect(getInitialValues(externalKeys)).toEqual({
    internal_key_id: "123",
    guest_key_id: "234"
  });
});
