import { render, screen } from "@testing-library/react";
import * as Formik from "formik";
import React from "react";

import { PropertyOption } from "./PropertySearch";
import { PropertySelection } from "./PropertySelection";

const property = {
  id: 1,
  full_address: "Apt 131, 207 Federal Street, Auckland 1010",
  home_code: "FED4A7"
};

describe("<PropertyOption />", () => {
  it("renders with success", () => {
    render(<PropertyOption property={property} />);
    expect(screen.getByText(property.full_address)).toBeInTheDocument();
    expect(screen.getByText(property.home_code)).toBeInTheDocument();
  });
});

describe("<PropertySelection />", () => {
  const useFormikContextMock = jest.spyOn(Formik, "useFormikContext");

  describe("when a property is selected", () => {
    it("renders with success", () => {
      useFormikContextMock.mockReturnValue({
        setFieldValue: () => false,
        values: { property_id: property.id }
      });

      render(<PropertySelection property={property} />);
      expect(screen.getByText(property.full_address)).toBeInTheDocument();
      expect(screen.getByText(property.home_code)).toBeInTheDocument();
    });
  });
});
