import "./AmenitiesForm.scss";

import { Formik } from "formik";
import React, { useContext } from "react";
import * as yup from "yup";

import { CheckoutInstructionsToggle } from "../components/CheckoutInstructionsToggle";
import { HomeInfoContext } from "../HomeInfoContext";
import { HomeInfoForm } from "../HomeInfoForm";

const noteLengthError = "Please provide a note with less than 140 characters";
const notesWithLengthLimit = yup
  .string()
  .max(140, noteLengthError)
  .label("Notes");
const validationSchema = yup.object().shape({
  home_info_checkout_tasks_attributes: yup.object().shape({
    gather_towels_notes: notesWithLengthLimit,
    throw_trash_notes: notesWithLengthLimit,
    lock_up_notes: notesWithLengthLimit,
    return_keys_notes: notesWithLengthLimit,
    additional_requests_notes: yup
      .string()
      .label("Notes")
      .when("additional_requests", {
        is: true,
        then: yup
          .string()
          .required("Please provide additional requests notes")
          .max(140, noteLengthError)
      })
  })
});

export const CheckoutInstructions = () => {
  const { homeInfo, updateHomeInfo } = useContext(HomeInfoContext);

  return (
    <Formik
      initialValues={homeInfo}
      enableReinitialize
      onSubmit={updateHomeInfo}
      validationSchema={validationSchema}
    >
      <HomeInfoForm sidebarName="Property">
        <h4 className="section-name">Checkout Instructions</h4>

        <CheckoutInstructionsToggle
          name="gather_towels"
          label="Gather used towels"
          name_notes="gather_towels_notes"
          characterCount={140}
        />

        <CheckoutInstructionsToggle
          name="throw_trash"
          label="Throw rubbish away"
          name_notes="throw_trash_notes"
          characterCount={140}
        />

        <CheckoutInstructionsToggle
          name="lock_up"
          label="Lock up"
          name_notes="lock_up_notes"
          characterCount={140}
        />

        <CheckoutInstructionsToggle
          name="return_keys"
          label="Return keys"
          name_notes="return_keys_notes"
          characterCount={140}
        />

        <CheckoutInstructionsToggle
          name="additional_requests"
          label="Additional requests"
          name_notes="additional_requests_notes"
          characterCount={140}
        />
      </HomeInfoForm>
    </Formik>
  );
};
