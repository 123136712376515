import classNames from "classnames";
import React, { useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { toggleFileSelection } from "../../data/files/actions";
import { anyFilesSelected } from "../../data/files/selectors";
import { isImage, isVideo } from "../../helpers";
import { FileTypeIcon } from "../FileTypeIcon";

const canPreview = (fileType) =>
  [
    "application/pdf",
    "application/msword",
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
  ].includes(fileType);

const ThumbnailIcon = ({ content_type, error }) => {
  if (isImage(content_type) && !error) {
    return <FileTypeIcon fileType={content_type} />;
  }

  return (
    <div className="d-flex flex-column align-items-stretch align-self-center">
      <i className="fa fa-exclamation-circle p-2" />
      <div className="file-thumbnail__error ml-2">
        Error loading file preview
      </div>
    </div>
  );
};

export const FileThumbnail = ({ file, handleGalleryInit }) => {
  const dispatch = useDispatch();
  const anySelected = useSelector(anyFilesSelected);
  const [loadError, setLoadError] = useState(false);

  const href = useMemo(
    () =>
      (isImage(file.content_type) && file.image_url) ||
      (canPreview(file.content_type) &&
        `https://docs.google.com/gview?url=${file.public_url}`) ||
      file.public_url,
    [file]
  );

  const handleFileClick = () => {
    if (isImage(file.content_type) || isVideo(file.content_type)) {
      return handleGalleryInit();
    }

    return window.open(href, "_blank");
  };

  const handleFilesSelectionToggle = () => {
    dispatch(toggleFileSelection({ id: file.id, isSelected: !file.selected }));
  };

  return (
    <div
      className={classNames("file-thumbnail-container", {
        "file-thumbnail-container--selected": file.selected,
        "file-thumbnail-container--selectable": anySelected,
        "file-thumbnail-container--error": loadError
      })}
    >
      <div className="file-top-menu">
        <input
          type="checkbox"
          checked={file.selected}
          onChange={handleFilesSelectionToggle}
        />
      </div>
      <button
        className="file-thumbnail"
        href={href}
        target="_blank"
        rel="noopener noreferrer"
        onClick={handleFileClick}
        type="button"
        data-caption={file.notes}
      >
        {isImage(file.content_type) && !loadError ? (
          <img
            src={file.image_thumbnail_url}
            alt={file.file_name}
            className="card-img-top"
            onError={() => {
              setLoadError(true);
            }}
          />
        ) : (
          <ThumbnailIcon content_type={file.content_type} error={loadError} />
        )}
      </button>
    </div>
  );
};
