export const copyToClipboard = async (
  text,
  { navigator = window.navigator } = {}
) => {
  const result = await navigator.permissions.query({ name: "clipboard-write" });

  if (["granted", "prompt"].includes(result.state)) {
    return navigator.clipboard.writeText(text);
  }

  throw new Error();
};
