import React from "react";

import "./Filters.scss";
import { getSelectedOptionValues } from "../../../../common/forms/getSelectedOptionValues";
import { GenericSearchFilter } from "../../../../common/tables/filters";
import { ChannelFilter } from "../../../../common/tables/filters/ChannelFilter";
import { BookedOnFilters } from "./BookedOnFilters";
import { CheckinFilters } from "./CheckinFilters";
import { CheckoutFilters } from "./CheckoutFilters";

export const Filters = ({ getFilter, getFilterValue, onFilterChange }) => {
  return (
    <form className="twbs Filters">
      <div className="form-group row">
        <div className="col-sm-4">
          <div className="row mb-4">
            <div className="col-sm input-group">
              <GenericSearchFilter
                className="input-group rt-generic-search"
                onChange={(value) => onFilterChange("search", value)}
                filter={getFilter("search")}
                placeholder="home code, property id, booking id, platform ref"
              />
            </div>
          </div>

          <div className="row mb-1">
            <label className="col-sm-2 col-form-label font-weight-bold text-right">
              Channels
            </label>
            <div className="col-sm-10 ChannelFilter">
              <ChannelFilter
                defaultValue={getFilterValue("channel")}
                filter={getFilter("channel")}
                onChange={(event) =>
                  onFilterChange(
                    "channel",
                    getSelectedOptionValues(event.target.options)
                  )
                }
              />
            </div>
          </div>
        </div>

        <div className="col-sm-1" />

        <div className="col-sm-7">
          <div className="row">
            <CheckinFilters
              getFilterValue={getFilterValue}
              onFilterChange={onFilterChange}
            />
            <div className="w-100 mb-4" />
            <CheckoutFilters
              getFilterValue={getFilterValue}
              onFilterChange={onFilterChange}
            />
            <div className="w-100 mb-4" />
            <BookedOnFilters
              getFilterValue={getFilterValue}
              onFilterChange={onFilterChange}
            />
          </div>
        </div>
      </div>
    </form>
  );
};
