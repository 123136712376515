const ENVIRONMENT_PRODUCTION = "production";
const ENVIRONMENT_STAGING = "staging";
const ENVIRONMENT_DEVELOPMENT = "development";

const getEnvironment = () => {
  const { origin } = window.location;

  if (origin === "https://airbase.houst.com") {
    return ENVIRONMENT_PRODUCTION;
  }

  if (origin === "https://airbase.staging.houst.com") {
    return ENVIRONMENT_STAGING;
  }

  return ENVIRONMENT_DEVELOPMENT;
};

export const ENVIRONMENT = getEnvironment();

export const ARCHIVIST_ENDPOINT =
  // eslint-disable-next-line no-nested-ternary
  ENVIRONMENT === ENVIRONMENT_PRODUCTION
    ? "https://archivist.production.houst.com/airsorted-property-files-production"
    : ENVIRONMENT === ENVIRONMENT_STAGING
    ? "https://archivist.staging.houst.com/airsorted-property-files-staging"
    : "http://localhost:3001/airsorted-property-files-staging";
