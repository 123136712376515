import React, { useMemo, useState } from "react";

import { SelectOptions } from "../../../common/SelectOptions";

export const MaintenanceCharge = ({
  suppliers,
  value_supplier: valueSupplier,
  value_supplier_charge: valueSupplierCharge,
  value_host_charge: valueHostCharge,
  can_skip_vat: canSkipVat
}) => {
  const [selectedSupplierId, setSelectedSupplierId] = useState(valueSupplier);
  const [supplierCharge, setSupplierCharge] = useState(valueSupplierCharge);
  const [hostStaticCharge, setHostStaticCharge] = useState(valueHostCharge);

  const handleSupplierChargeChanged = (event) => {
    setSupplierCharge(Number(event.target.value));
  };

  const handleChangeSupplier = (event) => {
    setSelectedSupplierId(event.target.value);
  };

  const selectedSupplier = useMemo(
    () =>
      suppliers.find((supplier) => supplier.id === Number(selectedSupplierId)),
    [suppliers, selectedSupplierId]
  );

  const selectedMarkup = useMemo(
    () => (selectedSupplier ? selectedSupplier.markup : ""),
    [selectedSupplier]
  );

  const hostCharge = useMemo(
    () => Math.round(supplierCharge * (1 + selectedMarkup)) / 100,
    [selectedMarkup, supplierCharge]
  );

  const suppliersForSelect = suppliers.map(({ id, label }) => [id, label]);

  return (
    <>
      <div className="MaintenanceCharge">
        <label htmlFor="charge_supplier_id">Supplier</label>
        <select
          id="charge_supplier_id"
          name="charge[supplier_id]"
          value={selectedSupplierId}
          onChange={handleChangeSupplier}
        >
          <SelectOptions
            includeBlank="-- choose if applies --"
            options={suppliersForSelect}
          />
        </select>
      </div>
      <label htmlFor="charge_amount">
        Host charge excluding VAT
        <span data-tooltip="Net amount to be charged to the host, excluding VAT">
          <i className="fa fa-question-circle" />
        </span>
      </label>
      {selectedSupplierId ? (
        <input
          id="charge_amount"
          name="charge[amount]"
          value={hostCharge}
          readOnly
        />
      ) : (
        <input
          id="charge_amount"
          name="charge[amount]"
          value={hostStaticCharge}
          onChange={(e) => setHostStaticCharge(e.target.value)}
        />
      )}
      <div
        className="MaintenanceCharge"
        id="charge_supplier_payout_amount_field"
      >
        <label htmlFor="charge_supplier_payout_amount">
          Supplier payout excluding VAT
          <span data-tooltip="Total amount to be paid to supplier, excluding VAT">
            <i className="fa fa-question-circle" />
          </span>
        </label>
        <input
          id="charge_supplier_payout_amount"
          name="charge[supplier_payout_amount]"
          type="number"
          step={0.01}
          value={supplierCharge}
          onChange={handleSupplierChargeChanged}
        />
        {canSkipVat && (
          <>
            <label htmlFor="skip_vat">
              Skip VAT
              <span data-tooltip="Check this box if VAT shouldn't be automatically added to the host charge">
                <i className="fa fa-question-circle" />
              </span>
            </label>
            <input id="skip_vat" type="checkbox" />
          </>
        )}
      </div>
    </>
  );
};
