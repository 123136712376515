import { fireEvent, render, waitFor } from "@testing-library/react";
import React from "react";

import { DisablingAutoCleanForm } from "./DisablingAutoCleanForm";

test("<DisablingAutoCleanForm />", async () => {
  // Given
  const onSubmit = jest.fn();
  const { getByLabelText, queryByText } = render(
    <DisablingAutoCleanForm
      initialValues={{ skip_auto_clean_reason: "" }}
      onSubmit={onSubmit}
    />
  );

  // When
  fireEvent.click(queryByText("OK"));

  // Then
  await waitFor(() => {
    expect(queryByText("Reason is required")).toBeInTheDocument();
  });

  // When
  fireEvent.change(getByLabelText("Reason"), {
    target: { value: "booking_cancelled" }
  });

  fireEvent.click(queryByText("OK"));

  // Then
  await waitFor(() => {
    expect(queryByText("Reason is required")).not.toBeInTheDocument();
  });

  expect(onSubmit).toHaveBeenCalledWith(
    {
      skip_auto_clean_reason: "booking_cancelled"
    },
    expect.anything()
  );
});
