import classNames from "classnames";
import React from "react";

import { Tooltip } from "../../../../../common/Tooltip";
import styles from "./AssetPreview.module.scss";

export const AssetPreview = ({
  src,
  onEdit,
  showRemove,
  onRemove,
  actionsDisabled,
  actionsDisabledReason
}) => {
  return (
    <div className={styles.card}>
      <img className={styles.image} src={src} alt="Amenity" />
      <Tooltip
        className={styles.tooltip}
        text={actionsDisabledReason}
        disabled={!actionsDisabled}
      >
        <div className={classNames(styles.actions)}>
          {showRemove && (
            <button
              className={styles.actionButton}
              onClick={onRemove}
              disabled={actionsDisabled}
              type="button"
            >
              <i className="fa fa-trash" />
            </button>
          )}
          <button
            className={styles.actionButton}
            onClick={onEdit}
            disabled={actionsDisabled}
            type="button"
          >
            <i className="fa fa-pencil-alt" />
          </button>
        </div>
      </Tooltip>
    </div>
  );
};
