import classNames from "classnames";
import { useFormikContext } from "formik";
import React, { useState, useContext } from "react";

import { Toggle } from "../../../../common/forms/Toggle";
import { HomeInfoContext } from "../HomeInfoContext";
import { FormGroup } from "./FormGroup";

export const CheckoutInstructionsToggle = ({
  name,
  label,
  name_notes,
  characterCount = null
}) => {
  const [expanded, setExpanded] = useState(false);
  const { values, setFieldValue } = useFormikContext();
  const { setIsModified } = useContext(HomeInfoContext);

  return (
    <div>
      <div className="amenity-type">
        <Toggle
          name={`home_info_checkout_tasks_attributes.${name}`}
          id={`home_info_checkout_tasks_attributes.${name}`}
          label={label}
          checked={values.home_info_checkout_tasks_attributes[name]}
          onChange={() => {
            setIsModified(true);
            const newValue = !values.home_info_checkout_tasks_attributes[name];
            setFieldValue(
              `home_info_checkout_tasks_attributes.${name}`,
              newValue
            );

            setExpanded(newValue);
          }}
          changeOrder
        />

        <div
          className={classNames("expand", {
            disabled: !values[name]
          })}
          onClick={() => {
            setExpanded(!expanded);
          }}
          role="button"
          tabIndex="0"
        >
          {" "}
        </div>
      </div>

      <div
        className={classNames("expanded-details", {
          expanded
        })}
      >
        <FormGroup
          name={`home_info_checkout_tasks_attributes.${name_notes}`}
          label="Notes"
          component="textarea"
          rows={3}
          characterCount={characterCount}
        />
      </div>
    </div>
  );
};
