import classNames from "classnames";
import React from "react";

import styles from "./Tooltip.module.scss";

export const Tooltip = ({ className, children, text, disabled }) => {
  if (disabled) {
    return (
      <div className={classNames(styles.tooltipContainer, className)}>
        {children}
      </div>
    );
  }

  return (
    <div className={classNames(styles.tooltipContainer, className)}>
      <div className={styles.tooltip}>{text}</div>
      <div className={styles.childrenContainer}>{children}</div>
    </div>
  );
};
