import "./NavTabsContainer.scss";

import React, { useState } from "react";
import { useSelector } from "react-redux";
import { NavLink } from "react-router-dom";

import { useDropdownMenu } from "../../../../common/hooks/useDropdownMenu";
import { DEFAULT_FOLDER, FOLDERS } from "../constants";
import {
  countFilesByFolder,
  selectLoadingFiles
} from "../data/files/selectors";

export const NavTabsContainer = () => {
  const loadingFiles = useSelector((state) => selectLoadingFiles(state));
  const filesCountByFolder = useSelector((state) => countFilesByFolder(state));
  const [active, setActive] = useState(DEFAULT_FOLDER);

  const { ref, isOpen, open, close } = useDropdownMenu();
  return (
    <>
      <ul className="nav nav-tabs">
        {FOLDERS.map(({ name: folderName, path }) => (
          <li key={folderName} className="nav-item">
            <NavLink to={path} className="nav-link" activeClassName="active">
              <span className="folder-name">{folderName}</span>

              {!loadingFiles && (
                <span className="badge badge-light">
                  {filesCountByFolder[folderName] || 0}
                </span>
              )}
            </NavLink>
          </li>
        ))}
      </ul>

      <div className="mobile-nav actions-item">
        <p className="folder">Folder name</p>
        <div className="btn-group">
          <button type="button" className="btn select-folder" onClick={open}>
            {active}
            <i className="fas fa-chevron-down" />
          </button>
          <ul ref={ref} className={`dropdown-menu ${isOpen && "show"}`}>
            {FOLDERS.map(({ name: folderName, path }) => (
              <li key={folderName} className="nav-item">
                <NavLink
                  to={path}
                  exact={folderName === DEFAULT_FOLDER}
                  className="nav-link"
                  activeClassName="active"
                  onClick={() => {
                    setActive(folderName);
                    close();
                  }}
                >
                  <span className="folder-name">{folderName}</span>

                  {!loadingFiles && (
                    <span className="badge badge-light">
                      {filesCountByFolder[folderName] || 0}
                    </span>
                  )}
                </NavLink>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </>
  );
};
