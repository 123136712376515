import { FieldArray, Formik } from "formik";
import React, { useContext } from "react";
import * as yup from "yup";

import { SelectOptions } from "../../../../common/SelectOptions";
import { FormGroup } from "../components/FormGroup";
import { NestedFields } from "../components/NestedFields";
import { HomeInfoContext } from "../HomeInfoContext";
import { HomeInfoForm } from "../HomeInfoForm";
import { SIDEBAR_NAMES } from "../sections";

export const validationSchema = yup.object().shape({
  cleaning_tasks_attributes: yup.array().of(
    yup.object().shape({
      description: yup
        .string()
        .required("Please provide a description")
        .label("Description")
        .max(200),
      external_url: yup.string().url("Please provide a valid url")
    })
  )
});

const getInitialCleaningTaskValues = (values) => ({ home_info_id: values.id });

// Reversing logic for better user experience
const CLEANS_AUTOSCHEDULING_OPTIONS = [
  [false, "Yes"],
  [true, "No"]
];

// Reversing logic for better user experience
const CLEANS_IGNORE_REPLENISHMENTS_OPTIONS = [
  [false, "Yes"],
  [true, "No"]
];

const IMAGE_REQUIRED_OPTIONS = [
  [false, "No"],
  [true, "Yes"]
];

const FRIDGE_CLEANING_OPTIONS = [
  ["leave_all", "Do not remove any item"],
  ["remove_perishables", "Discard perishables and expired food"],
  ["remove_all", "Remove all items"]
];

export const SpecialTasks = () => {
  const { homeInfo, updateHomeInfo, property } = useContext(HomeInfoContext);

  return (
    <Formik
      initialValues={homeInfo}
      enableReinitialize
      validationSchema={validationSchema}
      isInitialValid={
        validationSchema ? validationSchema.isValidSync(homeInfo) : true
      }
      onSubmit={updateHomeInfo}
    >
      {({ values }) => (
        <HomeInfoForm sidebarName={SIDEBAR_NAMES.CLEANING}>
          <div className="special-tasks">
            <h4 className="section-name">Special Tasks</h4>

            <FormGroup
              name="property_attributes.skip_auto_clean"
              label="Auto schedule cleans for bookings"
              component="select"
            >
              <SelectOptions
                includeBlank={false}
                options={CLEANS_AUTOSCHEDULING_OPTIONS}
              />
            </FormGroup>

            <FormGroup
              name="home_info_cleaning_attributes.ignore_replenishments"
              label="Cleaners should buy replenishments"
              component="select"
            >
              <SelectOptions
                includeBlank={false}
                options={CLEANS_IGNORE_REPLENISHMENTS_OPTIONS}
              />
            </FormGroup>

            {values.property_attributes.skip_auto_clean.toString() ===
              "true" && (
              <>
                <div className="alert alert-danger">
                  No cleans will be scheduled for bookings for this property,
                  however, the host can still request cleans through the
                  dashboard.
                </div>

                <FormGroup
                  name="property_attributes.skip_auto_clean_notes"
                  label="Skip auto schedule for bookings notes"
                  component="textarea"
                  rows={2}
                />
              </>
            )}

            <FormGroup
              name="host_preferences_attributes.fridge_cleaning"
              label="Fridge Cleaning Preferences"
              component="select"
              visibleToCleaners
            >
              <SelectOptions options={FRIDGE_CLEANING_OPTIONS} />
            </FormGroup>

            <label>Instructions For Cleaners</label>

            <FieldArray
              name="cleaning_tasks_attributes"
              render={({ push, remove }) => (
                <div>
                  {values.cleaning_tasks_attributes.map(
                    (cleaning_task, index) => (
                      <>
                        <NestedFields
                          key={cleaning_task.id || index}
                          name={`cleaning_tasks_attributes.${index}`}
                          onRemove={() => remove(index)}
                        >
                          <FormGroup
                            name={`cleaning_tasks_attributes.${index}.description`}
                            label="Description"
                            placeholder="Description"
                            disabled={cleaning_task._destroy}
                            component="textarea"
                            rows={2}
                          />
                          <FormGroup
                            name={`cleaning_tasks_attributes.${index}.external_url`}
                            label="External url"
                          />
                          <FormGroup
                            name={`cleaning_tasks_attributes.${index}.image_required`}
                            label="Image Required"
                            component="select"
                          >
                            <SelectOptions
                              includeBlank={false}
                              options={IMAGE_REQUIRED_OPTIONS}
                            />
                          </FormGroup>

                          {cleaning_task.id && (
                            <a href={`/properties/${property.id}/files/tasks`}>
                              {cleaning_task.property_file_id
                                ? "Go to task example image"
                                : "Add task example image"}
                            </a>
                          )}
                        </NestedFields>
                      </>
                    )
                  )}

                  <NestedFields.AddButton
                    label="Add a Cleaning Task"
                    onClick={() => push(getInitialCleaningTaskValues(values))}
                  />
                </div>
              )}
            />
          </div>
        </HomeInfoForm>
      )}
    </Formik>
  );
};
