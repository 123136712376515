import React, { useCallback, useState } from "react";

import { geolocation } from "./api/geolocation";

const GeolocationButton = ({ setLocation }) => {
  const [isPending, setPending] = useState(false);

  const handleGeolocation = useCallback(async () => {
    try {
      setPending(true);

      const location = await geolocation();
      setLocation(location);
    } catch (error) {
      alert(error.message);
    } finally {
      setPending(false);
    }
  }, [setLocation]);

  return (
    <button
      type="button"
      className="btn btn-light btn-sm ml-3"
      onClick={handleGeolocation}
      disabled={isPending}
    >
      {isPending ? (
        <i className="fa fa-spinner fa-spin" />
      ) : (
        <i className="fa fa-map-marker" />
      )}
    </button>
  );
};

export const CustomMapControls = ({ setLocation }) => {
  return (
    <div className="input-group input-group-sm" style={{ width: "200px" }}>
      <GeolocationButton setLocation={setLocation} />
    </div>
  );
};
