export function geolocation() {
  if (!navigator.geolocation) {
    return Promise.reject(new Error("Geolocation is not supported!"));
  }

  return new Promise((resolve, reject) => {
    navigator.geolocation.getCurrentPosition(
      ({ coords }) => {
        const { latitude: lat, longitude: lng } = coords;
        resolve({ lat, lng });
      },
      () => reject(new Error("Unable to find the location!"))
    );
  });
}
