import { fireEvent } from "@testing-library/react";
import React from "react";

import {
  changeFilesSortingField,
  toggleFilesSortingDirection
} from "../data/files/actions";
import { SORT_ASC } from "../data/files/reducer";
import { renderWithMockStore } from "../data/testHelpers";
import { FileListSortingContainer } from "./FileListSortingContainer";

describe("<FileListSortingContainer />", () => {
  function renderComponent() {
    const initialState = {
      files: { sorting: { field: "created_at", direction: SORT_ASC } }
    };

    return renderWithMockStore(<FileListSortingContainer />, { initialState });
  }

  it("renders with success", () => {
    const { container } = renderComponent();
    expect(container).toMatchSnapshot();
  });

  it("handles changing sorting direction", () => {
    // Given
    const { container, store } = renderComponent();

    // When
    fireEvent.click(container.querySelector("button"));

    // Then
    expect(store.getActions()[0]).toEqual(toggleFilesSortingDirection());
  });

  it("handles changing sorting field", () => {
    const { container, store } = renderComponent();

    // When
    const event = { target: { value: "file_name" } };
    fireEvent.change(container.querySelector("select"), event);

    // Then
    expect(store.getActions()[0]).toEqual(changeFilesSortingField("file_name"));
  });
});
