import { groupBy } from "lodash";
import React from "react";

import { apiClient } from "../../apiClient";
import { useAsync } from "../../hooks/useAsync";

const localitiesRequest = () =>
  apiClient.get("/api/localities").then((response) => response.data.rows);

export const LocalityFilter = ({
  filter,
  onChange,
  fetchLocalities = localitiesRequest
}) => {
  const { loading, value: localities = [] } = useAsync(fetchLocalities);

  const groupedLocalities = {
    active: [],
    inactive: [],
    ...groupBy(localities, ({ active }) => (active ? "active" : "inactive"))
  };

  const optionForSelect = ({ name, id, country_code: country }) => (
    <option key={id} value={id}>
      {name} ({country})
    </option>
  );

  if (loading) {
    return (
      <select className="form-control">
        <option value="">Loading...</option>
      </select>
    );
  }

  return (
    <select
      defaultValue={filter ? filter.value : ""}
      onChange={(event) => onChange(event.target.value)}
      className="form-control"
    >
      <option value="">All</option>
      <optgroup label="Active">
        {groupedLocalities.active.map(optionForSelect)}
      </optgroup>
      <optgroup label="Inactive">
        {groupedLocalities.inactive.map(optionForSelect)}
      </optgroup>
    </select>
  );
};
