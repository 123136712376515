/**
 * Check if an element is in viewport
 *
 * @param {ref} [reference]
 * @param {parentRef} [reference]
 * @param {offset} [number]
 * @returns {boolean}
 */
export const isInViewport = (ref, parentRef = window, offset = 0) => {
  if (!ref) {
    return false;
  }

  const { top } = ref.getBoundingClientRect();
  const { height } = parentRef.getBoundingClientRect();

  return top >= offset && top - offset <= height;
};
