import classNames from "classnames";
import React from "react";

import {
  FILE_STATUS_ERROR,
  FILE_STATUS_PENDING,
  FILE_STATUS_QUEUED,
  FILE_STATUS_SUCCESS
} from "../../data/upload/constants";

export const FileStatusIcon = ({ status, ...props }) => (
  <i
    {...props}
    className={classNames(
      {
        [FILE_STATUS_QUEUED]: "far fa-circle",
        [FILE_STATUS_PENDING]: "far fa-circle",
        [FILE_STATUS_SUCCESS]: "fa fa-check-circle",
        [FILE_STATUS_ERROR]: "fas fa-exclamation-triangle"
      }[status],
      {
        [FILE_STATUS_QUEUED]: "text-secondary",
        [FILE_STATUS_PENDING]: "text-primary",
        [FILE_STATUS_SUCCESS]: "text-success",
        [FILE_STATUS_ERROR]: "text-danger"
      }[status],
      {
        "cursor-pointer": FILE_STATUS_ERROR === status
      }
    )}
  />
);
