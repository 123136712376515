import { Formik } from "formik";
import React, { useContext } from "react";

import { FieldsetWithAvailabilitySelect } from "../components/FieldsetWithAvailabilitySelect";
import { FormGroup } from "../components/FormGroup";
import { HomeInfoContext } from "../HomeInfoContext";
import { HomeInfoForm } from "../HomeInfoForm";

export const Wifi = () => {
  const { homeInfo, updateHomeInfo } = useContext(HomeInfoContext);
  return (
    <Formik
      initialValues={homeInfo}
      enableReinitialize
      onSubmit={updateHomeInfo}
    >
      <HomeInfoForm sidebarName="Operate">
        <FieldsetWithAvailabilitySelect
          name="wifi_available"
          label="WiFi"
          description="Available throughout the listing"
        >
          <FormGroup
            name="wifi_network_name"
            label="WiFi network name"
            visibleToGuests
            visibleToCleaners
          />

          <FormGroup
            name="wifi_password"
            label="WiFi password"
            visibleToGuests
            visibleToCleaners
          />

          <FormGroup
            name="home_info_maintenance_attributes.wifi_router_location"
            label="WiFi router location"
            visibleToGuests
          />

          <FormGroup name="wifi_troubleshooting" label="WiFi troubleshooting" />

          <FormGroup
            name="wifi_speed"
            label="WiFi speed"
            renderAppend={() => <span>Mbps</span>}
          />

          <FormGroup name="wifi_provider" label="WiFi provider details" />
        </FieldsetWithAvailabilitySelect>
      </HomeInfoForm>
    </Formik>
  );
};
