import { sanitizeValues } from "./api";

describe("sanitizeValues", () => {
  it("drops values for which arm is disabled", () => {
    const result = sanitizeValues({
      override_min_nights: 10,
      arm_override_min_nights: false,
      override_price: 333.33,
      arm_max_nights: false,
      arm_override_price: true,
      available: true,
      arm_available: true
    });

    expect(result).toMatchInlineSnapshot(`
      Object {
        "available": true,
        "override_price": 333.33,
      }
    `);
  });
});
