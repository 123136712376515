import { Form, Formik } from "formik";
import React, { useCallback } from "react";
import * as yup from "yup";

import { useApiClient } from "../../../common/apiClient";
import { FormGroup } from "../../../common/forms/FormGroup";
import { Modal } from "../../../common/Modal";
import { SelectOptions } from "../../../common/SelectOptions";

const { AVAILABLE_TYPES } = window.AirbaseConstants.CleanImage;
const GENERAL_TYPES = AVAILABLE_TYPES.filter((t) => !t.startsWith("location_"));
const PRESENTATION_TYPES = AVAILABLE_TYPES.filter((t) =>
  t.startsWith("location_")
);

const validationSchema = yup.object().shape({
  receipt_amount: yup
    .string()
    .nullable()
    .when("type", (value, schema) =>
      value === "receipt"
        ? schema
            .required("Receipt amount is required")
            .matches(/^\d+(\.\d{1,2})?$/, {
              message: "Receipt amount is invalid"
            })
        : schema
    )
});

export const EditModal = ({ image, onClose, window }) => {
  const apiClient = useApiClient();

  const handleSubmit = useCallback(
    async (values, { setSubmitting }) => {
      setSubmitting(true);

      try {
        const {
          clean_id,
          id,
          type,
          receipt_amount,
          description,
          exemplary
        } = values;

        await apiClient.put(`/api/cleans/${clean_id}/images/${id}`, {
          clean_image: { type, receipt_amount, description, exemplary }
        });

        window.location.reload();
      } finally {
        setSubmitting(false);
      }
    },
    [apiClient, window]
  );

  return (
    <Formik
      initialValues={image}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {({ values, isSubmitting }) => (
        <Form>
          <Modal onClose={onClose}>
            <Modal.Body>
              <FormGroup label="Type" name="type" component="select">
                <optgroup label="General Image">
                  <SelectOptions includeBlank options={GENERAL_TYPES} />
                </optgroup>

                <optgroup label="Presentation Image">
                  <SelectOptions
                    includeBlank={false}
                    options={PRESENTATION_TYPES}
                  />
                </optgroup>
              </FormGroup>

              {values.type === "receipt" && (
                <FormGroup label="Receipt amount" name="receipt_amount" />
              )}

              <FormGroup
                label="Description"
                name="description"
                component="textarea"
                rows={3}
              />

              <FormGroup
                label="Show as cleaning guide image"
                helpText="This image will show to the cleaner when they are cleaning the property. This will be used as a guide on how to present this area."
                name="exemplary"
                type="checkbox"
                checked={values.exemplary}
              />
            </Modal.Body>

            <Modal.Footer>
              <button
                type="button"
                className="btn btn-light"
                onClick={onClose}
                disabled={isSubmitting}
              >
                Cancel
              </button>

              <button
                type="submit"
                className="btn btn-submit"
                disabled={isSubmitting}
              >
                Update
              </button>
            </Modal.Footer>
          </Modal>
        </Form>
      )}
    </Formik>
  );
};

EditModal.defaultProps = { window };
