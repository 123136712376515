import { isEmpty, reject } from "lodash";
import React, { useMemo } from "react";
import ReactTable from "react-table";

import { ShowButton } from "../../../common/tables/buttons";
import { dateColumn } from "../../../common/tables/dateColumn";
import { GenericSearchFilter } from "../../../common/tables/filters";
import { useControlledTable } from "../../../common/tables/useControlledTable";

const { TYPES, USAGES } = window.AirbaseConstants.Supplier;

const initialState = {
  filtered: [{ id: "active", value: "true" }],
  sorted: [{ id: "name", desc: false }]
};

export const SuppliersTable = ({ showPods }) => {
  const controlledProps = useControlledTable({
    api: { endpoint: "/api/suppliers" },
    initialState
  });

  const columns = useMemo(
    () => [
      {
        accessor: "id",
        sortable: false,
        filterable: false,
        maxWidth: 50,
        className: "rt-actions",
        Cell: ({ original: { show_path } }) => (
          <span>
            <ShowButton href={show_path} title="Show supplier" />
          </span>
        )
      },
      {
        Header: "Name",
        accessor: "name",
        filterable: true,
        Cell: ({ original: { name, show_path } }) => (
          <a href={show_path} className="active">
            <span className="primary">{name}</span>
          </a>
        ),
        Filter: ({ onChange, filter }) => (
          <GenericSearchFilter
            onChange={onChange}
            filter={filter}
            placeholder="Search by name"
          />
        )
      },
      {
        Header: "Used?",
        accessor: "usage",
        sortable: false,
        maxWidth: 200,
        Cell: ({ value }) => (
          <span title={USAGES[value]}>{USAGES[value] || "-- not set --"}</span>
        )
      },
      {
        Header: "Type",
        accessor: "type",
        sortable: false,
        maxWidth: 250,
        Cell: ({ value }) => (
          <span title={TYPES[value]}>{TYPES[value] || "-- not set --"}</span>
        )
      },
      {
        Header: "Vat registered",
        accessor: "vat_registered",
        sortable: false,
        maxWidth: 150,
        Cell: ({ value }) => (value ? "Yes" : "No")
      },
      {
        Header: "Vat rate",
        accessor: "vat_rate",
        sortable: false,
        maxWidth: 150
      },
      showPods
        ? {
            Header: "Pod",
            accessor: "pod.name",
            sortable: false,
            getProps: (_, { original }) => {
              const isRestricted = original?.pod?.restricted_access;

              return {
                className: isRestricted ? "orange" : "",
                title: isRestricted ? "This pod has restricted access" : ""
              };
            },
            maxWidth: 150
          }
        : {},
      dateColumn()
    ],
    [showPods]
  );

  return (
    <div>
      <ReactTable
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...controlledProps}
        columns={reject(columns, isEmpty)}
        className="-striped -highlight"
        multiSort
      />

      <h4>Total number of suppliers {controlledProps.totalCount}</h4>
    </div>
  );
};
