import pick from "lodash/pick";

import { apiClient } from "../../common/apiClient";

export const updateMaintenance = (values, options = {}) => {
  const { skipValidation } = options;
  const allowed = [
    "status",
    "severity",
    "location",
    "type",
    "description",
    "resolution_date",
    "host_communications_link",
    "requested_repair_date",
    "resolution_notes",
    "proposed_solution",
    "estimate",
    "host_response",
    "host_comment",
    "job_report",
    "job_report_file",
    "attribution",
    "specialist_needed",
    "fee_type",
    "plus_materials",
    "hide_from_host"
  ];

  const allowedValues = pick(values, allowed);
  const formData = new FormData();

  Object.keys(allowedValues).forEach((name) => {
    formData.append(`maintenance[${name}]`, allowedValues[name] || "");
  });

  if (skipValidation) {
    formData.append("skip_validation", true);
  }

  return apiClient
    .patch(`/api/maintenance/${values.id}`, formData, {
      headers: {
        "Content-Type": "multipart/form-data"
      }
    })
    .then((response) => response.data);
};

export const deleteMaintenanceImage = ({ maintenanceId, id }) => {
  return apiClient
    .delete(`/api/maintenance/${maintenanceId}/images/${id}`)
    .then((response) => response.data);
};

export const getMaintenanceImages = (maintenanceId) => {
  return apiClient
    .get(`/api/maintenance/${maintenanceId}/images/`)
    .then((response) => response.data);
};
