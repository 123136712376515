import { useFormikContext } from "formik";
import React, { useCallback } from "react";
import "./PropertySelection.scss";

import { PropertySearch } from "./PropertySearch";
import { SelectedProperty } from "./SelectedProperty";

export const PropertySelection = ({ handlePropertyChange, property }) => {
  const {
    setFieldValue,
    values: { property_id }
  } = useFormikContext();

  const handleClearSelection = useCallback(() => {
    setFieldValue("property_id", "");
    handlePropertyChange(null);
  }, [handlePropertyChange, setFieldValue]);

  return (
    <div className="PropertySelection form-group">
      {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
      <label htmlFor="property_search">Property</label>
      {property_id ? (
        <SelectedProperty
          property={property}
          handleClearSelection={handleClearSelection}
        />
      ) : (
        <PropertySearch onSelect={handlePropertyChange} />
      )}
    </div>
  );
};
