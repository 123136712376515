import "./AirbnbResolutionModal.scss";
import React, { useMemo, useState } from "react";

import { formatDateTime } from "../../../common/date";
import { Modal } from "../../../common/Modal";

export const AirbnbResolutionModal = ({
  resolution: {
    resolution_id,
    initiated_by_host,
    initiated_by_airbnb,
    confirmation_code,
    reason,
    description,
    status_name,
    status_text,
    suggested_action,
    total_amount_requested,
    airbnb_updated_at,
    total_amount_paid
  }
}) => {
  const [modalVisible, setModalVisible] = useState("");

  const initiator = useMemo(() => {
    if (initiated_by_host === undefined && initiated_by_airbnb === undefined) {
      return "N/A";
    }

    return initiated_by_host ? "Host" : "Airbnb";
  }, [initiated_by_host, initiated_by_airbnb]);

  const handleToggleModal = () => {
    setModalVisible((prev) => !prev);
  };

  return (
    <div className="twbs">
      {modalVisible && (
        <Modal onClose={handleToggleModal}>
          <Modal.Header>Airbnb Resolution {resolution_id}</Modal.Header>
          <Modal.Body>
            <div className="details_table">
              <div className="row">
                <div className="label">Airbnb Resolution ID:</div>
                <div className="value">{resolution_id}</div>
              </div>
              <div className="row">
                <div className="label">Initiated by:</div>
                <div className="value">{initiator}</div>
              </div>
              <div className="row">
                <div className="label">Reservation confirmation code:</div>
                <div className="value">{confirmation_code}</div>
              </div>
              <div className="row">
                <div className="label">Reason:</div>
                <div className="value">{reason}</div>
              </div>
              <div className="row">
                <div className="label">Description:</div>
                <div className="value">{description}</div>
              </div>
              <div className="row">
                <div className="label">Status:</div>
                <div className="value">
                  {status_name} - {status_text}
                </div>
              </div>
              <div className="row">
                <div className="label">Suggested action:</div>
                <div className="value">{suggested_action}</div>
              </div>
              <div className="row">
                <div className="label">Airbnb updated at:</div>
                <div className="value">
                  {airbnb_updated_at &&
                    formatDateTime(new Date(airbnb_updated_at))}
                </div>
              </div>

              <div className="row">
                <div className="label">Total amount requested:</div>
                <div className="value">{total_amount_requested}</div>
              </div>

              <div className="row">
                <div className="label">Total amount paid:</div>
                <div className="value">{total_amount_paid}</div>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      )}

      <button
        type="button"
        className="btn btn-link"
        data-toggle="modal"
        data-target="#airbnbResolutionModal"
        onClick={handleToggleModal}
      >
        View
      </button>
    </div>
  );
};
