import axios from "axios";

import { apiClient } from "../../../common/apiClient";

export const fetchHomeInfo = (property_id) =>
  apiClient
    .get(`/api/properties/${property_id}/home_info`)
    .then((response) => response.data);

export const updateHomeInfo = (property_id, values) =>
  apiClient
    .patch(`/api/properties/${property_id}/home_info`, { home_info: values })
    .then((response) => response.data);

export const updateHomeInfoInternalNotes = (property_id, values) =>
  apiClient
    .patch(`/api/properties/${property_id}/home_info/update_internal_notes`, {
      home_info: values
    })
    .then((response) => response.data);

export const fetchAmenityTypes = (category) => {
  return apiClient
    .get("/api/home_info_amenity_types", { params: { category } })
    .then((response) => response.data);
};

export const fetchPropertyFiles = (property_id) => {
  return apiClient
    .get(`/api/properties/${property_id}/files`)
    .then((response) => response.data);
};

export const fetchAirbnbListingData = (listing_id) => {
  return apiClient
    .get(`/api/${listing_id}/airbnb_listing_data`, {
      validateStatus(status) {
        return status < 500;
      }
    })
    .then((response) => response.data);
};

export const createPresignedPost = async (file) => {
  const { property_id, folder_name, file_name, content_type } = file;

  const response = await apiClient.post(
    `/api/properties/${property_id}/files/presigned_post`,
    {
      file: { folder_name, file_name, content_type }
    }
  );

  return response.data;
};

export const uploadFileToS3 = async (url, fields, file, onUploadProgress) => {
  const formData = new FormData();

  Object.keys(fields).forEach((name) => {
    formData.append(name, fields[name]);
  });

  formData.append("file", file);

  await axios.post(url, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
      "Content-Encoding": "base64"
    },
    onUploadProgress
  });

  return fields;
};
