import { groupBy } from "lodash";
import React from "react";

import { apiClient } from "../../apiClient";
import { useAsync } from "../../hooks/useAsync";

const podsRequest = () =>
  apiClient.get("/api/pods").then((response) => response.data.rows);

export const PodFilter = ({ filter, onChange, fetchPods = podsRequest }) => {
  const { loading, value: pods = [] } = useAsync(fetchPods);

  const groupedPods = {
    active: [],
    inactive: [],
    ...groupBy(pods, ({ active }) => (active ? "active" : "inactive"))
  };

  const optionForSelect = ({ name, id }) => (
    <option key={id} value={id}>
      {name}
    </option>
  );

  if (loading) {
    return (
      <select className="form-control">
        <option value="">Loading...</option>
      </select>
    );
  }

  return (
    <select
      defaultValue={filter ? filter.value : ""}
      onChange={(event) => onChange(event.target.value)}
      className="form-control"
    >
      <option value="">All</option>
      <optgroup label="Active">
        {groupedPods.active.map(optionForSelect)}
      </optgroup>
      <optgroup label="Inactive">
        {groupedPods.inactive.map(optionForSelect)}
      </optgroup>
    </select>
  );
};
