import { createAction } from "redux-actions";

export const uploadFilesRequest = createAction("UPLOAD/FILES_REQUEST");

export const uploadFileRequest = createAction(
  "UPLOAD/FILE_REQUEST",
  () => {},
  (fileItem) => ({ fileName: fileItem.name })
);

export const uploadFileNotifyProgress = createAction(
  "UPLOAD/FILE_NOTIFY_PROGRESS",
  (fileItem, progressEvent) => progressEvent.loaded,
  (fileItem) => ({ fileName: fileItem.name })
);

export const uploadFileResponse = createAction(
  "UPLOAD/FILE_RESPONSE",
  (fileItem, uploadedFile) => uploadedFile,
  (fileItem) => ({ fileName: fileItem.name })
);

export const clearUploadQueue = createAction("UPLOAD/CLEAN_QUEUE", () => {});
