import range from "lodash/range";

export const PROPERTY_GROUP_TYPES = [
  "apartments",
  "bnb",
  "boutique_hotels_and_more",
  "houses",
  "secondary_units",
  "unique_homes"
];

export const APARTMENTS = [
  "apartment",
  "casa_particular",
  "condominium",
  "loft",
  "serviced_apartment"
];
export const BNB = [
  "bnb",
  "casa_particular",
  "farm_stay",
  "lodge",
  "minsu",
  "ryokan"
];
export const BOUTIQUE_HOTELS_AND_MORE = [
  "aparthotel",
  "boutique_hotel",
  "heritage_hotel",
  "hostel",
  "hotel",
  "kezhan",
  "lodge",
  "resort",
  "serviced_apartment"
];
export const HOUSES = [
  "bungalow",
  "cabin",
  "casa_particular",
  "chalet",
  "cottage",
  "cycladic_house",
  "dammuso",
  "dome_house",
  "earthhouse",
  "farm_stay",
  "house",
  "houseboat",
  "hut",
  "lighthouse",
  "pension",
  "tiny_house",
  "townhouse",
  "trullo",
  "villa",
  "shepherds_hut"
];
export const SECONDARY_UNITS = ["farm_stay", "guesthouse", "guest_suite"];
export const UNIQUE_HOMES = [
  "barn",
  "boat",
  "campsite",
  "castle",
  "cave",
  "dome_house",
  "earthhouse",
  "farm_stay",
  "holiday_park",
  "houseboat",
  "hut",
  "igloo",
  "island",
  "lighthouse",
  "pension",
  "plane",
  "ranch",
  "religious_building",
  "riad",
  "rv",
  "shepherds_hut",
  "shipping_container",
  "tent",
  "tiny_house",
  "tipi",
  "tower",
  "train",
  "treehouse",
  "windmill",
  "yurt"
];

export const PROPERTY_TYPES = {
  apartments: APARTMENTS,
  bnb: BNB,
  boutique_hotels_and_more: BOUTIQUE_HOTELS_AND_MORE,
  houses: HOUSES,
  secondary_units: SECONDARY_UNITS,
  unique_homes: UNIQUE_HOMES
};

export const ROOM_TYPE_CATEGORY = [
  "entire_home",
  "private_room",
  "shared_room"
];
export const PET_CAPACITY_OPTIONS = range(0, 11);
export const BOOLEAN_OPTIONS = [
  ["false", "No"],
  ["true", "Yes"]
];

export const REVERSE_BOOLEAN_OPTIONS = [
  ["false", "Yes"],
  ["true", "No"]
];
