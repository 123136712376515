import React from "react";

import { DropdownFilter } from "../../../common/tables/filters";

const VALUES = [
  ["No badge", false],
  ["Silver", "silver"],
  ["Gold", "gold"],
  ["Platinum", "platinum"]
];

export const BadgeFilter = ({ onChange, filter }) => (
  <DropdownFilter onChange={onChange} filter={filter} values={VALUES} />
);
