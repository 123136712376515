import classNames from "classnames";
import React, { useCallback, useState } from "react";

import { EditModal } from "./EditModal";

export const CleanImage = ({ image, showDate = true, thumbnailClassName }) => {
  const [showEditModal, setShowEditModal] = useState(false);
  const handleEdit = useCallback(() => setShowEditModal(true), []);

  return (
    <div className="CleanImage">
      <div className="CleanImage__galleryLink">
        <a
          href={image.original_image_url}
          title={image.description}
          className={thumbnailClassName}
          data-caption={image.description}
          data-id={image.id}
          data-airbase-fancybox="gallery"
        >
          <img
            className={classNames({ "is-exemplary": image.exemplary })}
            alt={image.description}
            src={image.thumbnail_image_url}
            width="200"
          />
        </a>
        <div className="edit-icon">
          <button
            type="button"
            className="btn btn-light btn-sm"
            onClick={handleEdit}
          >
            <i className="fas fa-edit" />
          </button>
        </div>
      </div>

      {image.type === "receipt" && (
        <div>Receipt amount {image.receipt_amount_formatted}</div>
      )}

      <div className="desc">
        <div>{image.description}</div>
        <div className="clean-image-detail">
          {image.exemplary && "Cleaning guide image"}
        </div>
        {showDate && (
          <div className="clean-image-detail">{image.created_at}</div>
        )}
      </div>

      {showEditModal && (
        <EditModal image={image} onClose={() => setShowEditModal(false)} />
      )}
    </div>
  );
};
