import React, { useCallback, useMemo } from "react";
import ReactTable from "react-table";

import { apiClient } from "../../../common/apiClient";
import { humanize } from "../../../common/stringUtils";
import { EditButton, ShowButton } from "../../../common/tables/buttons";
import { dateColumn } from "../../../common/tables/dateColumn";
import { useControlledTable } from "../../../common/tables/useControlledTable";
import { CategoryFilter, CompletedFilter, CriticalFilter } from "./filters";

export const TasksTable = ({
  parentType,
  parentId,
  filterable = true,
  showPagination = true,
  initialFiltered = [],
  useQueryParams = true
}) => {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const memoizedInitialFiltered = useMemo(() => initialFiltered, []);
  const { CATEGORIES_ENUM } = window.AirbaseConstants.Task;

  const options = useMemo(
    () => ({
      api: {
        endpoint: "/api/tasks",
        params: {
          parent_type: parentType,
          parent_id: parentId
        }
      },
      initialState: {
        sorted: [{ id: "date", desc: true }],
        filtered: memoizedInitialFiltered
      },
      useQueryParams
    }),
    [parentId, parentType, memoizedInitialFiltered, useQueryParams]
  );

  const { updateDataRow, loadRows, ...controlledProps } = useControlledTable(
    options
  );

  const handleUpdateTask = useCallback(
    async (id, data) => {
      const resp = await apiClient.put(`/api/tasks/${id}`, { task: data });
      updateDataRow(resp.data);
      await loadRows();
    },
    [updateDataRow, loadRows]
  );

  const columns = useMemo(
    () => [
      {
        accessor: "id",
        maxWidth: 70,
        className: "rt-actions",
        Cell: ({ original: { show_path, edit_path } }) => (
          <span>
            <ShowButton href={show_path} />
            <EditButton href={edit_path} />
          </span>
        )
      },
      {
        Header: "Completed",
        accessor: "completed_at",
        sortable: true,
        maxWidth: 100,
        filterable,
        Filter: CompletedFilter,
        Cell: ({ original: { id, completed_at } }) => (
          <span style={{ margin: "auto" }}>
            <button
              type="button"
              className="btn btn-sm"
              onClick={() => handleUpdateTask(id, { completed: !completed_at })}
            >
              {completed_at ? (
                <i
                  className="fas fa-check-circle"
                  title={`Completed at ${completed_at}`}
                />
              ) : (
                <i className="far fa-circle" />
              )}
            </button>
          </span>
        )
      },
      {
        Header: "Name / Description",
        accessor: "name",
        sortable: true,
        className: "rt-cell-with-two-rows",
        Cell: ({ original: { name, description, show_path } }) => (
          <a href={show_path} className="active">
            <span className="primary">{name}</span>
            {description && <div className="secondary">{description}</div>}
          </a>
        )
      },
      {
        Header: "Critical",
        accessor: "critical",
        maxWidth: 80,
        filterable,
        Cell: ({ original: { critical } }) => (
          <span style={{ margin: "auto" }}>
            {critical && <i className="fas fa-exclamation-circle" />}
          </span>
        ),
        Filter: CriticalFilter
      },
      {
        Header: "Category",
        accessor: "category",
        maxWidth: 200,
        sortable: true,
        filterable,
        Filter: ({ filter, onChange }) => (
          <CategoryFilter
            filter={filter}
            onChange={onChange}
            taskCategories={CATEGORIES_ENUM}
          />
        ),
        Cell: ({ value }) => humanize(value)
      },
      {
        Header: "External link",
        accessor: "external_url",
        maxWidth: 200,
        Cell: ({ value }) => (
          <a className="text-truncate" href={value}>
            {value}
          </a>
        )
      },

      dateColumn({ Header: "Date", accessor: "date" })
    ],
    [filterable, handleUpdateTask, CATEGORIES_ENUM]
  );

  return (
    <div>
      <div className="twbs">
        <ReactTable
          {...controlledProps}
          columns={columns}
          className="-striped -highlight"
          sortable={false}
          showPagination={showPagination}
        />
      </div>
      <h4>Total number of tasks {controlledProps.totalCount}</h4>
    </div>
  );
};
