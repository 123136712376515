import { Fancybox } from "@fancyapps/ui";

import { modal } from "../modals";

const confirmDeletion = (preConfirm) =>
  modal.fire({
    title: "Please confirm",
    text: "Are you sure you want to delete this image?",
    animation: false,
    showConfirmButton: true,
    confirmButtonText: "Delete",
    showCancelButton: true,
    preConfirm
  });

export const bindImageDelete = ({ deleteImageRequest, fancyboxSelector }) => {
  Fancybox.Plugins.Toolbar.defaults.items.delete = {
    type: "button",
    class: "fancybox__button--delete",
    label: "Delete",
    html: '<i class="fa fa-trash" />',
    click(event) {
      event.preventDefault();

      // eslint-disable-next-line react/no-this-in-sfc
      const { id } = this.fancybox.getSlide();
      confirmDeletion(() => deleteImageRequest(id));
    }
  };

  Fancybox.bind(fancyboxSelector, {
    groupAll: true,
    Toolbar: {
      display: [
        { id: "counter", position: "center" },
        "zoom",
        "download",
        "delete",
        "close"
      ]
    }
  });
};
