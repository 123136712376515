import React from "react";
import SweetAlert2 from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

const Swal = withReactContent(SweetAlert2);

export const MESSAGE_TTL_MS = 3000;

export const modal = Swal.mixin({
  showCancelButton: true,
  showConfirmButton: true,
  reverseButtons: true,
  focusCancel: true
});

export const toast = Swal.mixin({
  toast: true,
  position: "top-end",
  showConfirmButton: false,
  timer: MESSAGE_TTL_MS,
  timerProgressBar: true
});

export const showSuccessToast = (title) => {
  toast.fire({ title }, "", "info");
};

export const showErrorToast = (title, options = {}) => {
  toast.fire({ title, ...options }, "", "error");
};

// Action buttons for SweetAlert modals that looks like the default buttons but
// it can be placed and controlled from the react component.
export const SwalActions = ({
  disabled = false,
  cancelText = "Cancel",
  onCancel,
  confirmText = "Ok",
  onConfirm,
  onlyCancel = false
}) => (
  <div className="swal2-actions twbs d-flex">
    <button
      type="button"
      onClick={onCancel}
      disabled={disabled}
      className="swal-button-cancel"
    >
      {cancelText}
    </button>

    {!onlyCancel && (
      <button
        type="button"
        onClick={onConfirm}
        disabled={disabled}
        className="swal-button-confirm"
      >
        {confirmText}
      </button>
    )}
  </div>
);
