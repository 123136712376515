import React, { useState } from "react";

import { LeadSearch } from "./LeadSearch";
import { SelectedLead } from "./SelectedLead";

export const LeadSelection = ({ selectedLeadJSON, fieldName, currentLead }) => {
  const [selectedLead, setSelectedLead] = useState(
    JSON.parse(selectedLeadJSON)
  );

  return (
    <div className="SearchSelect">
      {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
      <label htmlFor="LeadSelection" className="SearchSelect__header">
        Referred by
      </label>

      {selectedLead.id ? (
        <SelectedLead
          lead={selectedLead}
          fieldName={fieldName}
          onClearSelection={() =>
            setSelectedLead({ id: null, display_name: "" })
          }
        />
      ) : (
        <LeadSearch currentLead={currentLead} onSelect={setSelectedLead} />
      )}
    </div>
  );
};
