import { useCallback, useState } from "react";

import * as api from "./api";

export const useCleanImages = (cleanId) => {
  const [isLoading, setIsLoading] = useState(true);
  const [images, setImages] = useState([]);

  const fetchImages = useCallback(async () => {
    setIsLoading(true);

    const { clean_images } = await api.getCleanImages(cleanId);

    setImages(clean_images);
    setIsLoading(false);
  }, [cleanId]);

  return { images, isLoading, fetchImages };
};
