import { DEFAULT_FOLDER } from "../constants";
import { SORT_DESC } from "./files/reducer";
import { reducer } from "./reducer";

it("has a valid initial state", () => {
  const state = reducer(undefined, {});

  expect(state).toEqual({
    property: null,
    files: {
      loading: true,
      selection: {
        ids: []
      },
      list: [],
      currentFolderName: DEFAULT_FOLDER,
      sorting: {
        field: "created_at",
        direction: SORT_DESC
      }
    },
    toast: {
      message: null,
      severity: null
    },
    upload: {}
  });
});
