import React from "react";

import { CleanerRating } from "./CleanerRating";

export const SelectedCleaner = ({ cleaner, onClearSelection, fieldName }) => (
  <>
    <div className="SearchSelect__selected" id="CleanerSelection">
      <div className="SearchSelect__selected-header">
        <div className="name">{cleaner.name}</div>

        <span
          className="SearchSelect__close"
          onClick={onClearSelection}
          role="button"
          tabIndex={0}
        >
          <i className="glyphicons-icon circle_remove header_blue" />
        </span>
      </div>

      <div>
        Rating: <CleanerRating rating={cleaner.average_rating} />
      </div>
      <div className="telephone">
        Telephone Number: {cleaner.telephone_number}
      </div>
    </div>
    <input
      type="hidden"
      name={fieldName || "clean[cleaner_id]"}
      value={cleaner.id}
    />
  </>
);
