import React, { useState } from "react";

import { SwalActions } from "../../../common/modals";

export const ActivationForm = ({ onSubmit, onCancel }) => {
  const [isSubmitting, setSubmitting] = useState(false);

  return (
    <div>
      <h3>Are you sure to mark this cleaner as active?</h3>

      <SwalActions
        disabled={isSubmitting}
        onCancel={onCancel}
        confirmText={!isSubmitting ? "Activate" : "Activating..."}
        onConfirm={() => onSubmit(null, { setSubmitting })}
      />
    </div>
  );
};
