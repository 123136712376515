import axios from "axios";
import noop from "lodash/noop";

import { apiClient } from "../../../common/apiClient";

export const fetchFiles = async ({ propertyId }) => {
  const response = await apiClient.get(`/api/properties/${propertyId}/files`);
  return response.data.files;
};

export const downloadMultiple = async ({ propertyId, ids }) => {
  const response = await apiClient.get(
    `/api/properties/${propertyId}/files/download_multiple`,
    {
      params: { ids }
    }
  );

  return response.data.files;
};

const createPresignedPost = async (file) => {
  const { property_id, folder_name, file_name, content_type } = file;

  const response = await apiClient.post(
    `/api/properties/${property_id}/files/presigned_post`,
    {
      file: { folder_name, file_name, content_type }
    }
  );

  return response.data;
};

const uploadFileToS3 = async (file, fileItem, onUploadProgress) => {
  const { url, fields } = await createPresignedPost(file);

  const formData = new FormData();

  Object.keys(fields).forEach((name) => {
    formData.append(name, fields[name]);
  });

  formData.append("file", fileItem);

  await axios.post(url, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
      "Content-Encoding": "base64"
    },
    onUploadProgress
  });

  return fields;
};

export const uploadFile = async (
  { file, fileItem },
  onUploadProgress = noop
) => {
  const { key: s3_object_key } = await uploadFileToS3(
    file,
    fileItem,
    onUploadProgress
  );

  const response = await apiClient.post(
    `/api/properties/${file.property_id}/files`,
    { file: { ...file, s3_object_key } }
  );

  return response.data;
};

export const updateFile = async ({ id, property_id }, file) => {
  const response = await apiClient.put(
    `/api/properties/${property_id}/files/${id}`,
    { file }
  );

  return response.data;
};

export const updateFiles = async ({ property_id }, files) => {
  const response = await apiClient.patch(
    `/api/properties/${property_id}/files/batch_update`,
    files
  );

  return response.data.files;
};

export const deleteFile = ({ id, property_id }) =>
  apiClient.delete(`/api/properties/${property_id}/files/${id}`);

export const deleteFiles = ({ ids, property_id }) =>
  apiClient.delete(`/api/properties/${property_id}/files`, { data: { ids } });

export const fetchCleaningTasks = async ({ propertyId }) => {
  const response = await apiClient.get(
    `/api/properties/${propertyId}/files/fetch_tasks`
  );
  return response.data;
};
