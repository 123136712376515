import { useCallback, useEffect, useRef, useState } from "react";

const useClickAway = (ref, callback) => {
  useEffect(() => {
    const handleClickOutside = (event) => {
      const { current: el } = ref;

      if (el && !el.contains(event.target) && callback) {
        callback();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [callback, ref]);
};

export const useDropdownMenu = () => {
  const [isOpen, setShow] = useState(false);

  const open = useCallback(() => setShow(true), []);
  const close = useCallback(() => setShow(false), []);

  const handle = useCallback(
    (callback) => (event) => {
      close();
      return callback(event);
    },
    [close]
  );

  const ref = useRef(null);
  useClickAway(ref, close);

  return {
    ref,
    isOpen,
    open,
    handle,
    close
  };
};
