import React from "react";

import { DropdownFilter } from "../../../../common/tables/filters";

const VALUES = [
  ["Yes", true],
  ["No", false],
  ["Pending", null]
];

export const VerifyFilter = ({ onChange, filter }) => (
  <DropdownFilter onChange={onChange} filter={filter} values={VALUES} />
);
