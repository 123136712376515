import { fireEvent, render } from "@testing-library/react";
import React from "react";
import { act } from "react-dom/test-utils";

import { fetchFiles } from "../api";
import { submitForm } from "../helpers";
import { DownloadAllButton, downloadArchive } from "./DownloadAllButton";

jest.mock("../api");
jest.mock("../helpers");

test(".downloadArchive", async () => {
  // Given
  fetchFiles.mockReturnValue(
    Promise.resolve([
      {
        s3_object_key: "Damages/asdf.jpg",
        folder_name: "Damages",
        file_name: "image01.jpg"
      },
      {
        s3_object_key: "Other/asdf.jpg",
        folder_name: "Other",
        file_name: "image02.jpg"
      }
    ])
  );

  // When
  await downloadArchive({ propertyId: 123, fileName: "archive.zip" });

  // Then
  expect(fetchFiles).toHaveBeenCalledWith({ propertyId: 123 });
  expect(submitForm).toHaveBeenCalledWith({
    action:
      "https://archivist.staging.houst.com/airsorted-property-files-staging/archive/archive.zip",
    data: {
      "objects[Damages/asdf.jpg]": "Damages/image01.jpg",
      "objects[Other/asdf.jpg]": "Other/image02.jpg"
    }
  });
});

test("<DownloadAllButton />", async () => {
  // Given
  const property = { id: 123, home_code: "ASD-123" };
  const downloadArchiveMock = jest.fn().mockReturnValue(Promise.resolve());

  const { getByText } = render(
    <DownloadAllButton
      property={property}
      downloadArchive={downloadArchiveMock}
    />
  );

  // When
  await act(async () => fireEvent.click(getByText("Download all")));

  // Then
  expect(downloadArchiveMock).toHaveBeenCalledWith({
    propertyId: 123,
    fileName: "property-ASD-123-files.zip"
  });
});
