import { Fancybox } from "@fancyapps/ui";
import { Formik } from "formik";
import { find } from "lodash";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { Provider, useDispatch, useSelector } from "react-redux";

import { LoadingSpinner } from "../../../../common/LoadingSpinner";
import { useToastMonitor } from "../../../../common/useToastMonitor";
import { UploadDropZoneContainer } from "../../PropertyFiles/components/FileList/UploadDropZoneContainer";
import {
  ACCEPTED_AIRBNB_PHOTOS_CONTENT_TYPES,
  AIRBNB_PHOTO_MAX_SIZE
} from "../../PropertyFiles/constants";
import {
  changeCurrentFolder,
  changeFilesSortingField,
  toggleFilesSortingDirection
} from "../../PropertyFiles/data/files/actions";
import {
  selectLoadingFiles,
  selectSortedFiles
} from "../../PropertyFiles/data/files/selectors";
import { fetchFiles, updateFiles } from "../../PropertyFiles/data/files/thunks";
import { fetchPropertyResponse } from "../../PropertyFiles/data/property/actions";
import { store } from "../../PropertyFiles/data/store";
import { AirbnbSync } from "../components/FormGroup";
import { HomeInfoContext } from "../HomeInfoContext";
import { HomeInfoForm } from "../HomeInfoForm";
import { ListingPhotosContext } from "../ListingPhotosContext";
import { ListingPhotosSyncToggle } from "../ListingPhotosSyncToggle";
import { ListingPhoto } from "./ListingPhoto";

const { FOLDER_NAME_AIRBNB } = window.AirbaseConstants.PropertyFile;

const { config: fancyboxConfig } = window.AirbaseConstants.fancybox;

export const ListingGallery = () => {
  return (
    <Provider store={store}>
      <DndProvider backend={HTML5Backend}>
        <ListingGalleryInternal />
      </DndProvider>
    </Provider>
  );
};

const ListingGalleryInternal = () => {
  useToastMonitor();
  const { property, homeInfo, updateHomeInfo } = useContext(HomeInfoContext);
  const dispatch = useDispatch();
  const loading = useSelector(selectLoadingFiles);
  const files = useSelector(selectSortedFiles);
  const [locallySortedFiles, setLocallySortedFiles] = useState([]);
  const { setNumberOfPhotos } = useContext(ListingPhotosContext);

  useEffect(() => {
    // As we're using same fetching methods as for property files under the hood
    // we have to change the current folder to the one that is used for listing
    // to make selectSortedFiles selector work properly
    dispatch(changeCurrentFolder(FOLDER_NAME_AIRBNB));
    dispatch(changeFilesSortingField("order"));
    dispatch(toggleFilesSortingDirection()); // Set to ASC
  }, [dispatch]);

  useEffect(() => {
    // Set current property on init, as it's needed later
    dispatch(fetchPropertyResponse(property));
    // Fetch on init
    dispatch(fetchFiles());
  }, [dispatch, property]);

  // We need local state to handle sorting without launching requests on dragging
  useEffect(() => {
    setLocallySortedFiles(files);
    setNumberOfPhotos(files.length);
  }, [files, setNumberOfPhotos]);

  const handleGalleryInit = useCallback(
    (startIndex) => () => {
      const galleryItems = files.map(({ image_url, file_name, notes }) => ({
        src: image_url,
        caption: `${file_name}<br/>${notes || ""}`
      }));

      Fancybox.show(galleryItems, { ...fancyboxConfig, startIndex });
    },
    [files]
  );

  const findFile = useCallback(
    (id) => {
      const file = find(locallySortedFiles, { id });

      return { file, index: locallySortedFiles.indexOf(file) };
    },
    [locallySortedFiles]
  );

  // Handles files sorting while dragging over to new position
  const moveFile = useCallback(
    (id, newIndex) => {
      const { file, index: oldIndex } = findFile(id);
      const newFiles = [...locallySortedFiles];
      newFiles.splice(oldIndex, 1); // Remove file from old position
      newFiles.splice(newIndex, 0, file); // Insert file to new position

      newFiles.forEach((f, i) => {
        // eslint-disable-next-line no-param-reassign
        f.order = i + 1;
      });

      setLocallySortedFiles(newFiles);
    },
    [findFile, locallySortedFiles]
  );

  // Handles files sorting after dropping
  const submitNewOrder = async () => {
    const newOrder = locallySortedFiles.map(({ id, order }) => ({
      id,
      values: { order }
    }));

    await dispatch(updateFiles(newOrder));
  };

  if (loading || !files) {
    return <LoadingSpinner message="Loading files..." />;
  }

  return (
    <div className="onboarding-form listing-gallery">
      <Formik
        initialValues={homeInfo}
        enableReinitialize
        onSubmit={updateHomeInfo}
      >
        <HomeInfoForm sidebarName="Property">
          <h4 className="section-name">
            Property listing photos
            <AirbnbSync />
          </h4>
          <ListingPhotosSyncToggle />
          <UploadDropZoneContainer
            acceptContentTypes={ACCEPTED_AIRBNB_PHOTOS_CONTENT_TYPES}
            maxSize={AIRBNB_PHOTO_MAX_SIZE}
          >
            {locallySortedFiles.map((file, i) => (
              <ListingPhoto
                key={file.id}
                file={file}
                findFile={findFile}
                moveFile={moveFile}
                submitNewOrder={submitNewOrder}
                handleGalleryInit={handleGalleryInit(i)}
              />
            ))}
          </UploadDropZoneContainer>
        </HomeInfoForm>
      </Formik>
    </div>
  );
};
