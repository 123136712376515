import { groupBy, sortBy } from "lodash";
import React, { useMemo } from "react";

import { apiClient } from "../../apiClient";
import { useAsync } from "../../hooks/useAsync";
import { LoadingSpinner } from "../../LoadingSpinner";

const fetchBookingChannels = () =>
  apiClient.get("/api/booking_channels").then((response) => response.data.rows);

export const ChannelFilter = ({ defaultValue, onChange }) => {
  const { loading, value: booking_channels } = useAsync(fetchBookingChannels);

  const groupedChannels = useMemo(() => {
    const sorted = sortBy(booking_channels, "name");

    return {
      active: [],
      inactive: [],
      ...groupBy(sorted, ({ active }) => (active ? "active" : "inactive"))
    };
  }, [booking_channels]);

  const optionForSelect = ({ code, name }) => (
    <option key={code} value={code}>
      {name}
    </option>
  );

  if (loading) {
    return <LoadingSpinner message="Loading" />;
  }

  return (
    <>
      <select
        defaultValue={defaultValue}
        onChange={onChange}
        className="form-control"
        multiple
      >
        <optgroup label="Active">
          {groupedChannels.active.map(optionForSelect)}
        </optgroup>
        <optgroup label="Inactive">
          {groupedChannels.inactive.map(optionForSelect)}
        </optgroup>
      </select>
      <small className="form-text text-muted">
        Select more with (cmd or ctrl)+click
      </small>
    </>
  );
};
