import "./MaintenanceAssetUpload.scss";
import classNames from "classnames";
import React, { useCallback, useState } from "react";
import { useDropzone } from "react-dropzone";

import { apiClient } from "../../../common/apiClient";
import { showErrorToast, showSuccessToast } from "../../../common/modals";
import { simplePluralize } from "../../../common/stringUtils";

export const MaintenanceAssetUpload = ({ maintenanceId, onUploadFinish }) => {
  const [queueSize, setQueueSize] = useState(0);
  const [currentUpload, setCurrentUpload] = useState(0);

  const uploadFile = useCallback(
    (file) => {
      setCurrentUpload((previous) => previous + 1);

      const formData = new FormData();
      formData.append("maintenance[clean_image_file]", file);

      return apiClient
        .post(`/api/maintenance/${maintenanceId}/images`, formData, {
          headers: { "Content-Type": "multipart/form-data" }
        })
        .then((response) => {
          return response.data;
        });
    },
    [maintenanceId]
  );

  const submitFiles = useCallback(
    async (fileList) => {
      let response;
      // eslint-disable-next-line no-restricted-syntax
      for (const file of fileList) {
        // eslint-disable-next-line no-await-in-loop
        response = await uploadFile(file);
      }
      return response;
    },
    [uploadFile]
  );

  const handleSubmit = useCallback(
    (fileList) => {
      setQueueSize(fileList.length);

      submitFiles(fileList)
        .then((response) => {
          showSuccessToast(
            `${simplePluralize("image", fileList.length)} uploaded`
          );
          onUploadFinish(response);
        })
        .catch(() => {
          showErrorToast("Something went wrong, please try again");
        })
        .finally(() => {
          setQueueSize(0);
          setCurrentUpload(0);
        });
    },
    [onUploadFinish, submitFiles]
  );

  const {
    getRootProps,
    getInputProps,
    open: handleSelectFiles,
    isDragActive
  } = useDropzone({
    accept: "image/*,video/*",
    onDrop: handleSubmit
  });

  return (
    <>
      {queueSize > 0 && (
        <div className="-loading -active">
          <div className="-loading-inner">
            Uploading {currentUpload}/{queueSize}
          </div>
        </div>
      )}
      <div
        {...getRootProps({ onClick: (event) => event.stopPropagation() })}
        className={classNames("MaintenanceAssetUpload", {
          dragging: isDragActive
        })}
      >
        <button
          type="button"
          className="btn btn-light"
          onClick={handleSelectFiles}
        >
          Upload images or videos
        </button>
        <input {...getInputProps()} className="form-control-file d-none" />
        <div className="MaintenanceAssetUpload__drop-area">
          <i className="upload-icon glyphicons-icon folder_plus" />
          or drag here
        </div>
      </div>
    </>
  );
};
