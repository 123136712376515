import classNames from "classnames";
import React, { useCallback, useEffect, useRef } from "react";

export const MODAL_SIZE_LG = "lg";
export const Modal = ({ size, children, onClose, containerCssClass }) => {
  const ref = useRef();

  useEffect(() => {
    const prevClassName = document.body.className;
    document.body.className = classNames(prevClassName, "modal-open");

    return () => {
      document.body.className = prevClassName;
    };
  }, []);

  const handleBackdropClick = useCallback(
    (event) => {
      if (event.target.isSameNode(ref.current) && onClose) {
        onClose();
      }
    },
    [onClose]
  );

  return (
    <>
      {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
      <div
        className={classNames("modal", containerCssClass)}
        ref={ref}
        onClick={handleBackdropClick}
        style={{ display: "block" }}
      >
        <div
          className={classNames("modal-dialog", {
            "modal-lg": size === MODAL_SIZE_LG
          })}
        >
          <div className="modal-content">{children}</div>
        </div>
      </div>
      <div className="modal-backdrop fade show" />
    </>
  );
};

const ModalHeader = ({ children }) => (
  <div className="modal-header">
    <div className="modal-title">{children}</div>
  </div>
);
ModalHeader.displayName = "Modal.Header";

const ModalBody = ({ children }) => (
  <div className="modal-body">{children}</div>
);
ModalBody.displayName = "Modal.Body";

const ModalFooter = ({ children }) => (
  <div className="modal-footer">{children}</div>
);
ModalFooter.displayName = "Modal.Footer";

Modal.Header = ModalHeader;
Modal.Body = ModalBody;
Modal.Footer = ModalFooter;
