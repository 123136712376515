import React, { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Modal } from "../../../../common/Modal";
import { clearUploadQueue } from "../data/upload/actions";
import {
  selectIsUploadPending,
  selectUploadQueue,
  selectUploadTotalProgress
} from "../data/upload/selectors";
import { retryFileUpload } from "../data/upload/thunks";
import { FileProgress } from "./UploadProgressModal/FileProgress";
import { TotalProgress } from "./UploadProgressModal/TotalProgress";

export const UploadProgressModalContainer = ({ errors }) => {
  const dispatch = useDispatch();

  const isUploadPending = useSelector(selectIsUploadPending);
  const queue = useSelector(selectUploadQueue);
  const totalProgress = useSelector(selectUploadTotalProgress);

  const handleClearUploadQueue = useCallback(
    () => dispatch(clearUploadQueue()),
    [dispatch]
  );

  const handleClose = useCallback(
    () => !isUploadPending && handleClearUploadQueue(),
    [isUploadPending, handleClearUploadQueue]
  );

  return (
    <Modal
      onClose={handleClose}
      size="lg"
      containerCssClass="file-upload-modal"
    >
      <Modal.Header>
        Uploading {totalProgress.totalCount > 1 ? "files" : "file"}...
      </Modal.Header>

      <Modal.Body>
        {queue.map((queuedFile) => (
          <FileProgress
            key={queuedFile.fileName}
            queuedFile={queuedFile}
            onRetry={() => dispatch(retryFileUpload(queuedFile.fileName))}
          />
        ))}

        {totalProgress.totalCount > 1 && (
          <>
            <hr />

            <TotalProgress
              isUploadPending={isUploadPending}
              progress={totalProgress}
            />
          </>
        )}

        {errors.map((error, index) => (
          <div key={index} className="invalid-file ">
            <i className="fa fa-exclamation-circle p-2" />
            <span>{error}</span>
          </div>
        ))}
      </Modal.Body>

      <Modal.Footer>
        <button
          type="button"
          className="bt btn-light w-100"
          disabled={isUploadPending}
          onClick={handleClearUploadQueue}
        >
          {isUploadPending ? "Please wait..." : "Close"}
        </button>
      </Modal.Footer>
    </Modal>
  );
};
