import classNames from "classnames";
import React from "react";
import { useDrag, useDrop } from "react-dnd";

import { FileListItem } from "../../PropertyFiles/components/FileList/FileListItem";

const DRAGGABLE_TYPE = "file";

export const ListingPhoto = ({
  file,
  handleGalleryInit,
  moveFile,
  findFile,
  submitNewOrder
}) => {
  const [{ isDragging }, connectDragTarget] = useDrag({
    type: DRAGGABLE_TYPE,
    item: {
      id: file.id,
      // initialIndex is to save item position at the start of dragging
      // later available in end() method
      initialIndex: findFile(file.id).index
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging()
    }),
    end: (item, monitor) => {
      const { id, initialIndex } = item;
      const didDrop = monitor.didDrop();

      if (!didDrop) {
        // If user drops image somewhere outside of the drop zone
        // move it back to the initial position
        moveFile(id, initialIndex);
      } else if (initialIndex !== findFile(id).index) {
        // If user drops image to a different position
        submitNewOrder();
      }
    }
  });

  const [, connectDropTarget] = useDrop(
    () => ({
      accept: DRAGGABLE_TYPE,
      hover: ({ id: draggedId }) => {
        if (draggedId !== file.id) {
          const { index: overIndex } = findFile(file.id);
          moveFile(draggedId, overIndex);
        }
      }
    }),
    [findFile, moveFile]
  );

  return (
    <div
      className={classNames("listing-photo", {
        "listing-photo--dragging": isDragging
      })}
      ref={(node) => connectDragTarget(connectDropTarget(node))}
    >
      <FileListItem
        contextMenuShowsEdit={false}
        key={file.id}
        file={file}
        handleGalleryInit={handleGalleryInit}
      >
        <div className="listing-photo--cover-info">Cover Photo</div>
      </FileListItem>
    </div>
  );
};
