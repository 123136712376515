import { Formik, Form, Field, ErrorMessage } from "formik";
import React, { useState } from "react";
import * as yup from "yup";

import { showErrorToast, showSuccessToast } from "../../../common/modals";
import { updateCompensationNotes } from "./api";
import "./CompensationNotes.scss";

const validationSchema = yup.object().shape({
  notes: yup.string().nullable().max(300)
});

export const CompensationNotes = ({ bookingId, notes }) => {
  const [submitting, setSubmitting] = useState(false);

  const onSubmit = async (values) => {
    setSubmitting(true);
    updateCompensationNotes(bookingId, values.notes)
      .then(() => {
        showSuccessToast("Compensation notes have been updated successfully");
      })
      .catch(() => {
        showErrorToast("An error occurred, please try again later");
      })
      .finally(() => setSubmitting(false));
  };

  return (
    <>
      <div className="compensation-details notes-label">
        Compensation details:
      </div>
      <div className="compensation-details notes-value">
        <Formik
          initialValues={{ notes }}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
        >
          <Form>
            <div className="wrapper">
              <Field name="notes" component="textarea" />
              <ErrorMessage name="notes">
                {(message) => <div className="error">{message}</div>}
              </ErrorMessage>
            </div>

            <button
              type="submit"
              disabled={submitting}
              className="details-save-button"
            >
              Save
            </button>
          </Form>
        </Formik>
      </div>
    </>
  );
};
