import "./Collapsible.scss";
import classNames from "classnames";
import React, { useCallback, useState } from "react";

export const Collapsible = ({ collapsed: collapsedProp, header, children }) => {
  const [collapsed, setCollapsed] = useState(collapsedProp);
  const handleToggle = useCallback(() => {
    setCollapsed(!collapsed);
  }, [collapsed]);

  return (
    <div className="Collapsible">
      {/* eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions */}
      <h5
        className={classNames("Collapsible__header", {
          Collapsible__headerHidden: collapsed
        })}
        onClick={handleToggle}
      >
        {header}
      </h5>

      <div
        className={classNames("Collapsible__content", {
          Collapsible__hidden: collapsed
        })}
      >
        {children}
      </div>
    </div>
  );
};
