import { render } from "@testing-library/react";
import React from "react";

import { HomeInfoContext } from "./HomeInfoContext";
import { PreviewScreen } from "./PreviewScreen";

test("<PreviewScreen />", () => {
  // Freezing time to ensure token stability
  Date.now = jest.fn(() => new Date(Date.UTC(2022, 6, 8)).valueOf());

  const { getByText } = render(
    <HomeInfoContext.Provider value={{ homeInfo: { property_id: 1 } }}>
      <PreviewScreen />
    </HomeInfoContext.Provider>
  );

  expect(getByText("Open preview in Guest App")).toBeInTheDocument();
  expect(getByText("Open preview in Guest App")).toHaveAttribute(
    "href",
    "https://guests.test.houst.com/p/cHJvcGVydHlfaWQ9MSZ0aW1lc3RhbXA9MTY1NzIzODQwMA=="
  );
});
