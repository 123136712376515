import classNames from "classnames";
import moment from "moment";
import Pikaday from "pikaday";
import React, { useCallback, useEffect, useRef } from "react";

// TODO: Pikaday is quite old and it seems to be abandoned.
//  Consider replace it with something more modern.
export const DatePicker = ({
  onSelect,
  format = "YYYY-MM-DD",
  pikadayOptions = {},
  appendClear = true,
  className,
  ...props
}) => {
  const inputEl = useRef();
  const pickerInstance = useRef();

  useEffect(() => {
    pickerInstance.current = new Pikaday({
      field: inputEl.current,
      onSelect: (date) => onSelect(moment(date).format(format)),
      format,
      ...pikadayOptions
    });

    return () => pickerInstance.current.destroy();
  }, [onSelect, format, inputEl, pikadayOptions]);

  const handleClear = useCallback(
    (event) => {
      event.preventDefault();

      pickerInstance.current.setDate(null);
      onSelect("");
    },
    [pickerInstance, onSelect]
  );

  return (
    <div className={classNames("input-group", className)}>
      <input className="form-control" type="text" ref={inputEl} {...props} />
      {appendClear && (
        <div className="input-group-append">
          <button
            className="btn btn-outline-secondary"
            type="button"
            onClick={handleClear}
          >
            <i className="fas fa-eraser" />
          </button>
        </div>
      )}
    </div>
  );
};
