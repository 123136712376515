import { Fancybox } from "@fancyapps/ui";
import React, { useCallback } from "react";
import { useSelector } from "react-redux";

import { LoadingSpinner } from "../../../../common/LoadingSpinner";
import {
  ACCEPTED_AIRBNB_PHOTOS_CONTENT_TYPES,
  AIRBNB_PHOTO_MAX_SIZE
} from "../constants";
import {
  selectCurrentFolderName,
  selectLoadingFiles,
  selectSortedFiles
} from "../data/files/selectors";
import { FileListItem } from "./FileList/FileListItem";
import { UploadDropZoneContainer } from "./FileList/UploadDropZoneContainer";

const { config: fancyboxConfig } = window.AirbaseConstants.fancybox;
const { FOLDER_NAME_AIRBNB } = window.AirbaseConstants.PropertyFile;

export const FileListContainer = () => {
  const loadingFiles = useSelector((state) => selectLoadingFiles(state));
  const files = useSelector((state) => selectSortedFiles(state));
  const folderName = useSelector((state) => selectCurrentFolderName(state));

  const handleGalleryInit = useCallback(
    (startIndex) => {
      const galleryItems = files.map((file) => ({
        src: file.image_url,
        caption: `${file.file_name}<br/>${file.notes || ""}`
      }));

      Fancybox.show(galleryItems, { ...fancyboxConfig, startIndex });
    },
    [files]
  );

  if (!folderName) {
    return (
      <div className="d-flex justify-content-around p-5 bg-light">
        <p className="mt-2">Please choose a folder to view files.</p>
      </div>
    );
  }

  const uploadDropZoneProps =
    folderName === FOLDER_NAME_AIRBNB
      ? {
          acceptContentTypes: ACCEPTED_AIRBNB_PHOTOS_CONTENT_TYPES,
          maxSize: AIRBNB_PHOTO_MAX_SIZE
        }
      : {};

  return (
    <div className="tab-content">
      <div className="tab-pane active">
        {loadingFiles ? (
          <LoadingSpinner message="Loading files..." />
        ) : (
          <UploadDropZoneContainer {...uploadDropZoneProps}>
            {files.map((file, i) => (
              <FileListItem
                key={file.id}
                file={file}
                handleGalleryInit={() => handleGalleryInit(i)}
              />
            ))}
          </UploadDropZoneContainer>
        )}
      </div>
    </div>
  );
};
