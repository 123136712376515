import { reducer } from "../reducer";
import { uploadFileResponse } from "../upload/actions";
import {
  changeCurrentFolder,
  changeFilesSortingField,
  deleteFileResponse,
  fetchFilesRequest,
  fetchFilesResponse,
  toggleFileSelection,
  setFilesSelection,
  toggleFilesSortingDirection,
  updateFileResponse
} from "./actions";
import { DEFAULT_SELECTION_STATE, SORT_ASC, SORT_DESC } from "./reducer";
import {
  selectCurrentFolderName,
  selectFilesSorting,
  selectLoadingFiles
} from "./selectors";

describe("reducer", () => {
  it(`handles ${changeCurrentFolder}`, () => {
    // Given
    let state = {};

    // When
    state = reducer(state, changeCurrentFolder("Damages"));

    // Then
    expect(selectCurrentFolderName(state)).toEqual("Damages");
  });

  it(`handles ${fetchFilesRequest}`, () => {
    // Given
    let state = { files: { loading: false } };

    // When
    state = reducer(state, fetchFilesRequest());

    // Then
    expect(selectLoadingFiles(state)).toBe(true);
  });

  it(`handles ${fetchFilesResponse}`, () => {
    // Given
    let state = { files: { loading: true, list: [] } };

    // When
    state = reducer(
      state,
      fetchFilesResponse([{ id: 1, file_name: "foo.bar" }])
    );

    // Then
    expect(selectLoadingFiles(state)).toBe(false);
    expect(state.files.list).toEqual([{ id: 1, file_name: "foo.bar" }]);
  });

  it(`handles ${uploadFileResponse}`, () => {
    // Given
    let state = { files: { list: [{ id: 1 }] } };
    const uploadedFile = { id: 2 };
    const fileItem = { name: "foo.jpg" };

    // When
    state = reducer(state, uploadFileResponse(fileItem, uploadedFile));

    // Then
    expect(state.files.list).toEqual([{ id: 1 }, uploadedFile]);
  });

  it(`handles ${updateFileResponse}`, () => {
    // Given
    let state = { files: { list: [{ id: 1 }, { id: 2 }] } };

    // When
    state = reducer(state, updateFileResponse({ id: 2, file_name: "foo.jpg" }));

    // Then
    expect(state.files.list).toEqual([
      { id: 1 },
      { id: 2, file_name: "foo.jpg" }
    ]);
  });

  it(`handles ${deleteFileResponse}`, () => {
    // Given
    let state = { files: { list: [{ id: 1 }, { id: 2 }, { id: 3 }] } };

    // When
    state = reducer(state, deleteFileResponse(2));

    // Then
    expect(state.files.list).toEqual([{ id: 1 }, { id: 3 }]);
  });

  it(`handles ${changeFilesSortingField}`, () => {
    // Given
    let state;

    // When
    state = reducer(state, changeFilesSortingField("created_at"));

    // Then
    expect(selectFilesSorting(state)).toEqual({
      field: "created_at",
      direction: SORT_DESC
    });

    // When
    state = reducer(state, changeFilesSortingField("file_name"));

    // Then
    expect(selectFilesSorting(state)).toEqual({
      field: "file_name",
      direction: SORT_ASC
    });
  });

  it(`handles ${toggleFilesSortingDirection}`, () => {
    // When
    const state = reducer(undefined, toggleFilesSortingDirection());

    // Then
    expect(selectFilesSorting(state)).toEqual({
      field: "created_at",
      direction: SORT_ASC
    });
  });

  describe("selection", () => {
    it(`handles ${fetchFilesResponse}`, () => {
      // Given
      let state = { files: { selection: { ids: [1, 2, 3] } } };

      // When
      state = reducer(state, fetchFilesResponse([{ id: 1 }, { id: 2 }]));

      // Then
      expect(state.files.selection).toEqual(DEFAULT_SELECTION_STATE);
    });

    it(`handles ${deleteFileResponse}`, () => {
      // Given
      const initialState = {
        files: { selection: { ids: [1, 2, 3] } }
      };

      // When
      const state = reducer(initialState, deleteFileResponse(2));

      // Then
      expect(state.files.selection).toEqual({ ids: [] });
    });

    it(`handles ${toggleFileSelection}, subtracting`, () => {
      // Given
      let state = { files: { selection: { ids: [1, 2, 3] } } };

      // When
      state = reducer(state, toggleFileSelection({ id: 2, isSelected: false }));

      // Then
      expect(state.files.selection).toEqual({ ids: [1, 3] });
    });

    it(`handles ${toggleFileSelection}, adding`, () => {
      // Given
      let state = { files: { selection: { ids: [1, 3] } } };

      // When
      state = reducer(state, toggleFileSelection({ id: 2, isSelected: true }));

      // Then
      expect(state.files.selection).toEqual({ ids: [1, 3, 2] });
    });

    it(`handles ${setFilesSelection}`, () => {
      // Given
      let state = { files: { selection: { ids: [1, 2, 3] } } };

      // When
      state = reducer(state, setFilesSelection([1]));

      // Then
      expect(state.files.selection).toEqual({ ids: [1] });
    });
  });
});
