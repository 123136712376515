import { format } from "date-fns";
import { Formik, Form, ErrorMessage } from "formik";
import React, { useCallback, useState } from "react";
import * as yup from "yup";

import { useApiClient } from "../../../common/apiClient";
import { FormGroup } from "../../../common/forms/FormGroup";
import { RailsErrors } from "../../../common/RailsErrors";
import { SelectOptions } from "../../../common/SelectOptions";
import { SpecialistSelection } from "../SpecialistSelection/SpecialistSelection";
import { HostPreferencesHint } from "./HostPreferencesHint";
import { PropertySelection } from "./PropertySelection/PropertySelection";

const {
  LOCATIONS,
  SEVERITIES,
  TYPES,
  ATTRIBUTIONS
} = window.AirbaseConstants.Maintenance;

const validationSchema = yup.object().shape({
  property_id: yup.number().required("Please select property"),
  location: yup.string().required("Please select location"),
  type: yup.string().required("Please select type"),
  severity: yup.string().required("Please select severity"),
  specialist: yup.string().required("Please select specialist"),
  attribution: yup.string().required("Please select charge attribution"),
  description: yup.string().required("Please enter description"),
  host_communications_link: yup
    .string()
    .url(
      "Please enter correct url containing 'http' or 'https' part e.g. http://adomain.com/i/123"
    ),
  requested_repair_date: yup.date().required("Please set requested date")
});

export const MaintenanceForm = ({ property: propertyParam = null }) => {
  const [property, setProperty] = useState(propertyParam);
  const [serverErrors, setServerErrors] = useState([]);

  const apiClient = useApiClient();

  const handleSubmit = useCallback(
    async ({ specialist, redirectToEdit, ...values }, { setSubmitting }) => {
      setSubmitting(true);

      try {
        const { data } = await apiClient.post(`/api/maintenance/`, {
          maintenance: { ...values, specialist_needed: specialist }
        });

        window.location.href = redirectToEdit ? data.edit_path : "/maintenance";
      } catch (e) {
        if (e.response?.data?.errors) {
          setServerErrors(e.response?.data?.errors);
        }
      } finally {
        setSubmitting(false);
      }
    },
    [apiClient]
  );

  const handleSpecialistSelection = useCallback(
    (setFieldValue) => (value) => {
      setFieldValue("specialist", value);
    },
    []
  );

  const handlePropertyChange = (setFieldValue) => (newProperty) => {
    setProperty(newProperty);
    setFieldValue("property_id", newProperty?.id);
  };

  const handleSaveAndEdit = useCallback((setFieldValue, submitForm) => {
    setFieldValue("redirectToEdit", true);
    submitForm();
  }, []);

  const initialValues = {
    property_id: property?.id,
    location: "",
    type: "",
    severity: "",
    specialist: "",
    attribution: "",
    description: "",
    host_communications_link: "",
    requested_repair_date: "",
    hide_from_host: false,
    redirectToEdit: false
  };

  const VisibleInHostDashboard = () => (
    <span className="form-text mb-2">
      <i className="fa fa-eye" />{" "}
      <span className="text-secondary">Visible in host dashboard.</span>
    </span>
  );

  return (
    <div className="maintenance-form twbs neo_design">
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
        validateOnChange={false}
        validateOnBlur={false}
      >
        {({ isSubmitting, setFieldValue, values, errors, submitForm }) => (
          <Form>
            <h2>Create maintenance</h2>

            <RailsErrors errors={serverErrors} />

            <PropertySelection
              handlePropertyChange={handlePropertyChange(setFieldValue)}
              property={property}
            />

            <div className="with-visible-in-host">
              <FormGroup label="Location" name="location" component="select">
                <SelectOptions options={LOCATIONS} />
              </FormGroup>
              <VisibleInHostDashboard />
            </div>

            <div className="with-visible-in-host">
              <FormGroup name="type" label="Issue Type" component="select">
                <SelectOptions options={TYPES} />
              </FormGroup>
              <VisibleInHostDashboard />
            </div>

            <div className="with-visible-in-host">
              <FormGroup
                label="Issue Severity"
                name="severity"
                component="select"
              >
                <SelectOptions options={SEVERITIES} />
              </FormGroup>
              <VisibleInHostDashboard />
            </div>
            <HostPreferencesHint
              hostPreferences={property?.host_preferences}
              currencySymbol={property?.currency_symbol}
            />

            <div className="form-group">
              <SpecialistSelection
                hasError={errors.specialist}
                onChange={handleSpecialistSelection(setFieldValue)}
              />
              <ErrorMessage name="specialist">
                {(message) => <div className="invalid-feedback">{message}</div>}
              </ErrorMessage>
            </div>

            <FormGroup
              label="Charge Attribution"
              name="attribution"
              component="select"
            >
              <SelectOptions options={ATTRIBUTIONS} />
            </FormGroup>

            <FormGroup
              label="Hide From Host"
              name="hide_from_host"
              type="checkbox"
              checked={values.hide_from_host}
            />

            <div className="with-visible-in-host">
              <FormGroup
                label="Issue Description"
                name="description"
                component="textarea"
                value={values.description || ""}
                rows="4"
              />
              <VisibleInHostDashboard />
            </div>

            <FormGroup
              label="Host communications link"
              name="host_communications_link"
            />

            <FormGroup
              label="Requested Repair Date"
              name="requested_repair_date"
              type="datetime-local"
              min="2019-09-01T00:00"
              value={
                values.requested_repair_date
                  ? format(
                      new Date(values.requested_repair_date),
                      "yyyy-MM-dd'T'HH:mm"
                    )
                  : ""
              }
            />

            <input
              type="submit"
              name="commit"
              value="Save"
              disabled={isSubmitting}
            />

            <input
              type="button"
              name="commit"
              value="Save and edit"
              onClick={() => handleSaveAndEdit(setFieldValue, submitForm)}
              disabled={isSubmitting}
            />
          </Form>
        )}
      </Formik>
    </div>
  );
};
