import React from "react";

export const SwimmingPoolInstructions = () => (
  <ul>
    <li>Pool location access details</li>
    <li>Indoor or outdoor?</li>
    <li>Private or shared pool?</li>
    <li>Opening hours/ opening season/ opening months?</li>
  </ul>
);

export const BBQInstructions = () => (
  <ul>
    <li>Location access details</li>
    <li>Private or shared?</li>
    <li>BBQ area opening hours</li>
  </ul>
);

export const GymInstructions = () => (
  <ul>
    <li>Gym location access details</li>
    <li>Indoor or outdoor?</li>
    <li>Private or shared?</li>
    <li>Opening hours/ opening season/ opening months?</li>
  </ul>
);

export const LoadSheddingAmenitiesInstructions = () => (
  <ul>
    <li>Generator location</li>
    <li>Power banks location</li>
    <li>Re-chargable battery powered lights location</li>
    <li>Candles location</li>
  </ul>
);

export const DesignatedWorkspaceInstructions = () => (
  <ul>
    <li>Designated workspace location?</li>
    <li>
      What is included in designated workspace, e.g. desk, monitor, office
      chair?
    </li>
  </ul>
);

const SmokeAlarmInstructions = () => (
  <ul>
    <li>Smoke alarm battery type</li>
    <li>Smoke alarm spare battery location (for our use, not guests)</li>
  </ul>
);

export const getInstructions = (key) => {
  switch (key) {
    case "swimming_pool":
      return <SwimmingPoolInstructions />;
    case "barbecue":
      return <BBQInstructions />;
    case "gym":
      return <GymInstructions />;
    case "load_shedding_amenities":
      return <LoadSheddingAmenitiesInstructions />;
    case "designated_workspace":
      return <DesignatedWorkspaceInstructions />;
    case "smoke_alarm":
      return <SmokeAlarmInstructions />;
    default:
      return null;
  }
};
