import { fireEvent, render } from "@testing-library/react";
import React from "react";

import { CleanStatusFilter } from "./CleanStatusFilter";

const {
  STATUS_COMPLETED,
  STATUS_PAID,
  STATUS_REQUESTED,
  STATUS_STARTED
} = window.AirbaseConstants.Clean;

describe("<CleanStatusFilter />", () => {
  it("renders checkboxes", () => {
    // Given
    const filter = {
      id: "status",
      value: [STATUS_COMPLETED, STATUS_PAID]
    };

    // When
    const { getByLabelText } = render(<CleanStatusFilter filter={filter} />);

    // Then
    expect(getByLabelText("Completed")).toHaveAttribute("checked");
    expect(getByLabelText("Paid")).toHaveAttribute("checked");

    expect(getByLabelText("Requested")).not.toHaveAttribute("checked");
    expect(getByLabelText("Confirmed")).not.toHaveAttribute("checked");
    expect(getByLabelText("Started")).not.toHaveAttribute("checked");
    expect(getByLabelText("Expired")).not.toHaveAttribute("checked");
    expect(getByLabelText("Declined")).not.toHaveAttribute("checked");
    expect(getByLabelText("Cancelled")).not.toHaveAttribute("checked");
  });

  it("handles status toggle", () => {
    // Given
    const filter = {
      id: "status",
      value: [STATUS_REQUESTED, STATUS_PAID]
    };
    const onFilterChange = jest.fn();

    // When
    const { getByLabelText } = render(
      <CleanStatusFilter filter={filter} onFilterChange={onFilterChange} />
    );

    fireEvent.click(getByLabelText("Started"));

    // Then
    expect(onFilterChange).toHaveBeenCalledWith("status", [
      STATUS_REQUESTED,
      STATUS_PAID,
      STATUS_STARTED
    ]);
  });
});
