import { Form, Formik } from "formik";
import React, { useCallback, useMemo, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import validFilename from "valid-filename";
import * as yup from "yup";

import { formatDate } from "../../../../common/date";
import {
  isUnprocessableEntityError,
  mapUnprocessableEntityErrors
} from "../../../../common/errors";
import { FormGroup } from "../../../../common/forms/FormGroup";
import { Modal } from "../../../../common/Modal";
import { showErrorToast } from "../../../../common/modals";
import { fetchCleaningTasks } from "../api";
import { FOLDERS } from "../constants";
import { updateFile } from "../data/files/thunks";
import { joinFileName, splitFileName } from "../helpers";
import { CleaningTaskSelect } from "./CleaningTaskSelect";

const validationSchema = yup.object().shape({
  file_name: yup
    .string()
    .required("File name is required")
    .test("valid-filename", "Invalid file name", validFilename)
});

export const EditFileModalContainer = ({ file, onClose }) => {
  const dispatch = useDispatch();
  const [tasks, setTasks] = useState([]);

  const handleSubmit = useCallback(
    async (values, { setSubmitting, setFieldError }) => {
      setSubmitting(true);

      try {
        const {
          file_name,
          file_extension,
          home_info_cleaning_task_id,
          ...file
        } = values;

        await dispatch(
          updateFile(file.id, {
            ...file,
            home_info_cleaning_task_id:
              parseInt(home_info_cleaning_task_id, 10) || null,
            file_name: joinFileName(file_name, file_extension)
          })
        );

        onClose();
      } catch (error) {
        if (isUnprocessableEntityError(error)) {
          mapUnprocessableEntityErrors(error, setFieldError);
        }
      } finally {
        setSubmitting(false);
      }
    },
    [dispatch, onClose]
  );

  const initialValues = useMemo(() => {
    const [file_name, file_extension] = splitFileName(file.file_name);

    return {
      ...file,
      file_name,
      file_extension,
      home_info_cleaning_task_id: tasks.find(
        (task) => task.property_file_id === file.id
      )?.id
    };
  }, [file, tasks]);

  useEffect(() => {
    fetchCleaningTasks({ propertyId: file.property_id })
      .then((res) => {
        setTasks(res);
      })
      .catch(() => {
        showErrorToast(
          "Something went wrong while fetching tasks, please try again."
        );
      });
  }, [file]);

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
      enableReinitialize
    >
      {({ values, isSubmitting }) => (
        <Form>
          <Modal onClose={onClose}>
            <Modal.Header>Editing file {file.file_name}</Modal.Header>

            <Modal.Body>
              <FormGroup
                name="file_name"
                label="File name"
                renderAppend={() => <span>.{values.file_extension}</span>}
                focus
              />

              <FormGroup name="folder_name" label="Folder" component="select">
                {FOLDERS.map(({ name: folder }) => (
                  <option key={folder} value={folder}>
                    {folder}
                  </option>
                ))}
              </FormGroup>

              <FormGroup name="notes" label="Notes" component="textarea" />

              {values.folder_name === "Tasks" && (
                <CleaningTaskSelect tasks={tasks} />
              )}

              <hr />

              <ul className="list-unstyled mb-0">
                <li>
                  {file.uploader ? (
                    <>
                      Uploaded by {file.uploader.first_name}{" "}
                      {file.uploader.last_name} on{" "}
                    </>
                  ) : null}
                  {formatDate(new Date(file.created_at))}
                </li>
              </ul>
            </Modal.Body>

            <Modal.Footer>
              <button
                type="button"
                className="btn btn-light"
                onClick={onClose}
                disabled={isSubmitting}
              >
                Cancel
              </button>

              <button
                type="submit"
                className="btn btn-submit"
                disabled={isSubmitting}
              >
                Update
              </button>
            </Modal.Footer>
          </Modal>
        </Form>
      )}
    </Formik>
  );
};
