import React, { useEffect, useState } from "react";

import { CompensationNotes } from "./CompensationNotes";
import { CompensationToggle } from "./CompensationToggle";

export const CompensationDetails = ({ bookingId, compensation, notes }) => {
  const [isNotesVisible, setIsNotesVisible] = useState(compensation);

  useEffect(() => {
    setIsNotesVisible(compensation);
  }, [compensation]);

  const handleToggleChange = (checked) => {
    setIsNotesVisible(checked);
  };

  return (
    <>
      <div className="row">
        <CompensationToggle
          bookingId={bookingId}
          compensation={compensation}
          onToggleChange={handleToggleChange}
        />
      </div>
      <div className="row">
        {isNotesVisible && (
          <CompensationNotes bookingId={bookingId} notes={notes} />
        )}
      </div>
    </>
  );
};
