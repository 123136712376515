import React from "react";

export const RailsErrors = ({ errors }) => (
  <div className="error_explanation">
    {errors.map((error) => (
      <div key={error} className="error">
        {error}
      </div>
    ))}
  </div>
);
