import {
  FILE_STATUS_ERROR,
  FILE_STATUS_PENDING,
  FILE_STATUS_QUEUED,
  FILE_STATUS_SUCCESS
} from "./constants";
import {
  selectIsUploadPending,
  selectQueuedFileItem,
  selectUploadQueue,
  selectUploadTotalProgress
} from "./selectors";

const state = {
  upload: {
    "file01.jpg": {
      status: FILE_STATUS_SUCCESS,
      uploadedBytes: 32,
      fileItem: { name: "file01.jpg", size: 32, type: "image/jpeg" }
    },
    "file02.jpg": {
      status: FILE_STATUS_ERROR,
      errorMessage: "File name has already been taken",
      uploadedBytes: 16,
      fileItem: { name: "file02.jpg", size: 56, type: "image/jpeg" }
    },
    "file03.jpg": {
      status: FILE_STATUS_PENDING,
      uploadedBytes: 8,
      fileItem: { name: "file03.jpg", size: 16, type: "image/jpeg" }
    },
    "archive.zip": {
      status: FILE_STATUS_QUEUED,
      uploadedBytes: 0,
      fileItem: { name: "archive.zip", size: 8, type: "application/zip" }
    }
  }
};

describe(".selectIsUploadPending", () => {
  it("returns true is at least one file is queued or pending", () => {
    expect(selectIsUploadPending(state)).toBe(true);
  });

  it("returns false when there are no pending uploads", () => {
    const state = {
      upload: {
        "file1.jpg": { state: FILE_STATUS_SUCCESS, fileItem: {} },
        "file2.jpg": { state: FILE_STATUS_ERROR, fileItem: {} }
      }
    };

    expect(selectIsUploadPending(state)).toBe(false);
  });

  it("returns false when the queue is empty", () => {
    const state = { upload: {} };
    expect(selectIsUploadPending(state)).toBe(false);
  });
});

test(".selectUploadQueue", () => {
  expect(selectUploadQueue(state)).toEqual([
    {
      fileName: "file01.jpg",
      fileType: "image/jpeg",
      percentage: 100,
      status: "success",
      errorMessage: undefined,
      totalBytes: 32,
      uploadedBytes: 32
    },
    {
      fileName: "file02.jpg",
      fileType: "image/jpeg",
      percentage: 29,
      status: "error",
      errorMessage: "File name has already been taken",
      totalBytes: 56,
      uploadedBytes: 16
    },
    {
      fileName: "file03.jpg",
      fileType: "image/jpeg",
      percentage: 50,
      status: "pending",
      errorMessage: undefined,
      totalBytes: 16,
      uploadedBytes: 8
    },
    {
      fileName: "archive.zip",
      fileType: "application/zip",
      percentage: 0,
      status: "queued",
      errorMessage: undefined,
      totalBytes: 8,
      uploadedBytes: 0
    }
  ]);
});

test(".selectUploadTotalProgress", () => {
  expect(selectUploadTotalProgress(state)).toEqual({
    percentage: 50,
    totalBytes: 112,
    totalCount: 4,
    uploadedBytes: 56,
    uploadedCount: 1
  });
});

test(".selectQueuedFileItem", () => {
  expect(selectQueuedFileItem(state, "archive.zip")).toEqual({
    name: "archive.zip",
    size: 8,
    type: "application/zip"
  });
});
