import "./MaintenanceGallery.scss";

import { Fancybox } from "@fancyapps/ui";
import React, { useCallback, useEffect, useState } from "react";

import { bindImageDelete } from "../../../common/fancybox/bindImageDelete";
import { LoadingSpinner } from "../../../common/LoadingSpinner";
import { showErrorToast, showSuccessToast } from "../../../common/modals";
import { simplePluralize } from "../../../common/stringUtils";
import videoIcon from "../../../images/video.png";
import { deleteMaintenanceImage } from "../api";
import { useMaintenanceImages } from "./useMaintenanceImages";

const { config: fancyboxConfig } = window.AirbaseConstants.fancybox;

// Adds note for video files that are not displaying correctly
const MaintenanceGallery = ({ maintenanceId, images: imagesParam }) => {
  const [images, setImages] = useState([]);
  const {
    isLoading,
    images: fetchedImages,
    fetchImages
  } = useMaintenanceImages(maintenanceId);

  useEffect(() => {
    if (imagesParam?.length > 0) {
      return setImages(imagesParam);
    }
    return fetchImages();
  }, [fetchImages, imagesParam]);

  useEffect(() => {
    setImages(fetchedImages);
  }, [fetchedImages]);

  const deleteImage = useCallback(
    async (id) => {
      try {
        const response = await deleteMaintenanceImage({ maintenanceId, id });
        Fancybox.getInstance().close();
        showSuccessToast("Image deleted");

        setImages(response.clean_images);
      } catch (e) {
        showErrorToast("Couldn't delete this image. Please try again.");
      }
    },
    [maintenanceId]
  );

  useEffect(() => {
    if (images.length === 0) {
      return false;
    }
    const fancyboxSelector = ".maintenance-image-thumbnail";
    bindImageDelete({ fancyboxSelector, deleteImageRequest: deleteImage });
    Fancybox.bind(fancyboxSelector, fancyboxConfig);

    return () => {
      Fancybox.destroy();
    };
  }, [deleteImage, images.length]);

  if (isLoading) {
    return <LoadingSpinner message="Fetching images..." delay={300} />;
  }

  if (images?.length === 0) {
    return null;
  }

  return (
    <>
      <div className="MaintenanceGallery">
        {images.map((image) => (
          <a
            key={image.id}
            className="maintenance-image-thumbnail"
            href={image.original_image_url}
            target="_blank"
            rel="noopener noreferrer"
            data-id={image.id}
            data-caption={image.description}
          >
            <img
              alt="thumbnail"
              src={image.video ? videoIcon : image.thumbnail_image_url}
              className="maintenance-image"
            />
          </a>
        ))}
      </div>
      <span className="MaintenanceGallery__assets-count">
        {simplePluralize("item", images.length)} uploaded
      </span>
    </>
  );
};

export default MaintenanceGallery;
