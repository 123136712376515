import { get, startCase } from "lodash";

import { humanize } from "./stringUtils";

export const UNPROCESSABLE_ENTITY = 422;

export const isUnprocessableEntityError = (error) =>
  get(error, "response.status") === UNPROCESSABLE_ENTITY;

export const mapUnprocessableEntityErrors = (error, cb) => {
  const errors = get(error, "response.data.errors", {});

  Object.entries(errors).forEach(([field, errors]) =>
    cb(field, `${humanize(field)} ${errors.join(", ")}`)
  );
};
export const formatErrors = (errors) =>
  Object.keys(errors)
    .map((key) => `${startCase(key)}: ${errors[key].join(", ")}`)
    .join("\n");
