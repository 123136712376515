import "./AutoCleanToggle.scss";

import React, { useState } from "react";

import { Toggle as GenericToggle } from "../../../../common/forms/Toggle";
import { useBookingToggle } from "../useBookingToggle";
import { confirmDisablingAutoClean } from "./confirmDisablingAutoClean";

const { SKIP_AUTO_CLEAN_REASONS } = window.AirbaseConstants.Booking;

export const AutoCleanToggle = ({ booking: initialBooking, disabled }) => {
  const [booking, setBooking] = useState(initialBooking);
  const { toggled, toggleAttribute } = useBookingToggle(
    booking,
    "skip_auto_clean"
  );
  const label = disabled
    ? "Automatic clean scheduling disabled for this property"
    : "Automatic clean scheduling";

  const handleToggle = async () => {
    const confirmIsNecessary = () =>
      toggled
        ? { confirmed: true, values: {} } // Switching on does not require a confirmation
        : confirmDisablingAutoClean(booking);

    try {
      const { confirmed, values: params } = await confirmIsNecessary();

      if (confirmed) {
        const booking = await toggleAttribute(params);
        setBooking(booking);
      }
    } catch (error) {
      alert("Unable to toggle automatic clean scheduling");
    }
  };

  return (
    <div className="auto-clean-toggle">
      <GenericToggle
        id="automatic-clean-toggle"
        label={label}
        checked={!toggled && !disabled}
        onChange={handleToggle}
        disabled={disabled}
      />

      {toggled && (
        <div className="reason">
          Reason: {SKIP_AUTO_CLEAN_REASONS[booking.skip_auto_clean_reason]}
        </div>
      )}
    </div>
  );
};
