import classNames from "classnames";
import React, { useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";

import { ENVIRONMENT } from "../../../../config";
import { setFilesSelection } from "../data/files/actions";
import {
  anyFilesSelected as anySelectedSelector,
  selectedFilesIds as selectedFilesIdsSelector
} from "../data/files/selectors";
import { BulkActionDownload } from "./BulkActionDownload";
import { BulkActionsMoveForm } from "./BulkActionsMoveForm";
import { DownloadAllButton } from "./DownloadAllButton";

const hideDownloadAll = ENVIRONMENT === "production";

export const BulkActionsMenu = ({ property }) => {
  const dispatch = useDispatch();
  const anyFilesSelected = useSelector(anySelectedSelector);
  const selectedFilesIds = useSelector(selectedFilesIdsSelector);

  const handleDeselectAll = useCallback(() => {
    dispatch(setFilesSelection([]));
  }, [dispatch]);

  return (
    <div className="bulk-actions-menu">
      <div
        className={classNames(
          "bulk-actions-card card shadow-sm bg-white rounded d-flex",
          { invisible: !anyFilesSelected }
        )}
      >
        <button
          type="button"
          className="btn clear-selection-button"
          onClick={handleDeselectAll}
        >
          <i className="fa fa-times" />
        </button>
        <p>Selected {selectedFilesIds.length} </p>

        <BulkActionsMoveForm />

        <BulkActionDownload propertyId={property.id} />
      </div>

      <div
        className={classNames({
          invisible: hideDownloadAll || anyFilesSelected
        })}
      >
        <DownloadAllButton property={property} />
      </div>
    </div>
  );
};
