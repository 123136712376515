import React from "react";

import { DropdownFilter } from "../../../common/tables/filters";

const VALUES = [
  ["Live", "live"],
  ["Offboarded", "offboarded"],
  ["Pending", "pending"],
  ["Lost", "lost"]
];

export const StatusFilter = ({ onChange, filter }) => (
  <DropdownFilter onChange={onChange} filter={filter} values={VALUES} />
);
