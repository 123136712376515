import { Formik } from "formik";
import React, { useContext } from "react";

import { SelectOptions } from "../../../../common/SelectOptions";
import { FormGroup } from "../components/FormGroup";
import { HomeInfoContext } from "../HomeInfoContext";
import { HomeInfoForm } from "../HomeInfoForm";

const EARLIEST_CHECKIN_OPTIONS = ["15:00", "16:00", "17:00", "18:00"];
const LATEST_CHECKIN_OPTIONS = [
  "19:00",
  "20:00",
  "21:00",
  "22:00",
  "23:00",
  "00:00",
  "01:00",
  "02:00",
  "03:00"
];
const EARLIEST_CHECKOUT_OPTIONS = ["9:00", "10:00", "11:00"];

export const PropertyCheckin = () => {
  const { homeInfo, updateHomeInfo } = useContext(HomeInfoContext);

  return (
    <Formik
      initialValues={homeInfo}
      enableReinitialize
      onSubmit={updateHomeInfo}
    >
      <HomeInfoForm sidebarName="Property">
        <h4 className="section-name">Check-In & Out Times</h4>

        <FormGroup
          name="earliest_checkin_time"
          label="Check-In time"
          helpText="If blank, default 15:00 applies"
          component="select"
          visibleToGuests
          airbnbSync
        >
          <SelectOptions options={EARLIEST_CHECKIN_OPTIONS} />
        </FormGroup>

        <FormGroup
          name="check_in_time_end"
          label="Check-In Time End"
          component="select"
          visibleToGuests
          airbnbSync
        >
          <SelectOptions options={LATEST_CHECKIN_OPTIONS} />
        </FormGroup>

        <FormGroup
          name="latest_checkout_time"
          label="Check-Out time"
          helpText="If blank, default 11:00 applies"
          component="select"
          visibleToGuests
          airbnbSync
        >
          <SelectOptions options={EARLIEST_CHECKOUT_OPTIONS} />
        </FormGroup>

        <h4 className="section-name">Leaving</h4>

        <FormGroup
          name="home_info_location_attributes.lock_up_instructions"
          label="Lockup instructions"
          placeholder="e.g. double lock front door, pull the door behind you"
          component="textarea"
          rows={5}
          visibleToGuests
        />

        <FormGroup
          name="checkout_extra_notes"
          label="Checkout extra notes"
          component="textarea"
          rows={5}
          visibleToGuests
        />
      </HomeInfoForm>
    </Formik>
  );
};
