import React, { useState } from "react";

import {
  SwalActions,
  modal,
  showErrorToast,
  showSuccessToast
} from "../../../common/modals";
import { updateMaintenance } from "../api";

const { STATUS_REJECTED } = window.AirbaseConstants.Maintenance;

const confirmationPopUp = () =>
  new Promise((res) => {
    const closeConfirmation = () => modal.close();
    const onConfirm = () => {
      res({ confirmed: true });
      closeConfirmation();
    };

    modal.fire({
      html: (
        <div className="ActionOptions__confirmation">
          <p>Are you sure you want to reject this issue?</p>
          <SwalActions onCancel={closeConfirmation} onConfirm={onConfirm} />
        </div>
      ),
      showConfirmButton: false,
      showCancelButton: false
    });
  });

export const RejectButton = ({ id, status: statusProp }) => {
  const [status, setStatus] = useState(statusProp);

  const handleRejectClick = async () => {
    const { confirmed } = await confirmationPopUp();

    if (confirmed) {
      try {
        const resp = await updateMaintenance(
          { id, status: "rejected" },
          { skipValidation: true }
        );
        setStatus(resp.status);

        showSuccessToast("Maintenance has been rejected");
      } catch (error) {
        showErrorToast("Unable to reject");
      }
    }
  };

  if (status === STATUS_REJECTED) {
    return null;
  }

  return (
    <button
      type="button"
      className="btn btn-danger mb-3"
      onClick={handleRejectClick}
    >
      <i role="img" className="fa fa-trash mr-2" />
      Reject
    </button>
  );
};
