import { act, fireEvent } from "@testing-library/react";
import React from "react";

import { renderWithMockStore } from "../data/testHelpers";
import { DeleteFileModalContainer } from "./DeleteFileModalContainer";

test("<DeleteFileModalContainer />", async () => {
  // Given
  const initialState = {
    property: { id: 123 }
  };

  const api = {
    deleteFile: jest.fn().mockReturnValue(Promise.resolve())
  };

  const file = {
    id: 1234,
    file_name: "image01.jpg"
  };

  // When
  const { getByText } = renderWithMockStore(
    <DeleteFileModalContainer file={file} />,
    { initialState, api }
  );

  // Then
  expect(
    getByText("Are you sure you want to delete image01.jpg?")
  ).toBeInTheDocument();

  // When
  await act(async () => {
    fireEvent.click(getByText("Ok"));
  });

  // Then
  expect(api.deleteFile).toHaveBeenCalledWith({
    id: file.id,
    property_id: initialState.property.id
  });
});
